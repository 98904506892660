<template>
  <div :class="wrapperClasses">
    <template v-if="!areAllAbsencesDisabled">
      <SkModalSection class="manage-shift-modal__content manage-shift-modal__content--first">
        <div class="manage-shift-modal__content-title">
          {{ selectUserOrAbsenceTitle }}
        </div>
        <div class="manage-shift-modal__content-input">
          <div class="manage-shift-modal__content-select">
            <ShiftEmployeeSelector
              v-if="isPostesView && isUpdateModal"
              :can-display-unassigned-shift="areUnassignedShiftsAllowed"
              :is-update-modal="isUpdateModal"
              :shift-user-id="shiftUserId"
              :users="users"
              data-test="absence-form__user-select"
              @update-user="updateUser"
              @keydown.native.enter.stop=""
            />
            <PlanningEmployeesSelect
              v-else-if="isPostesView && !isUpdateModal"
              :custom-label="employeesSelectLabel"
              :teams="teams"
              :users="users"
              data-test="absence-form__user-select"
              @update-users="updateMultipleUsers"
              @keydown.native.enter.stop=""
            />
            <SkSelectV2
              v-else
              v-model="absenceShiftPosteId"
              :options="formatedAbsences"
              :no-results-label="$t('search_bar.no_result')"
              :label="$t('plannings.table.manage_shift_modal.tabs.absence.absence_type')"
              data-test="absence-form__select-type"
              @keydown.native.enter.stop=""
            />
          </div>
          <div
            v-if="!isPostesView"
            class="manage-shift-modal__absences-type"
          >
            <SkOroraButton
              variant="tertiary"
              size="small"
              @click="showAbsencePanel"
            >
              {{ $t('plannings.table.manage_shift_modal.tabs.absence.check_absence_type') }}
            </SkOroraButton>
          </div>
        </div>
      </SkModalSection>
    </template>
    <SkModalSection
      class="manage-shift-modal__content manage-shift-modal__content--radio"
      border-bottom="none"
    >
      <div class="manage-shift-modal__content-title">
        {{ $t('plannings.table.manage_shift_modal.tabs.commun.duration_type') }}
      </div>
      <div class="manage-shift-modal__content-input">
        <div class="manage-shift-modal__content-radio">
          <SkRadio
            v-for="absenceCalculation in absenceCalculations"
            :id="absenceCalculation.id"
            :key="absenceCalculation.id"
            v-model="shiftAbsenceCalculation"
            :data-value="absenceCalculation.id"
            data-test="absence-form__duration-type"
            @keydown.native.enter.stop=""
          >
            {{ absenceCalculation.text }}
          </SkRadio>
        </div>
      </div>
    </SkModalSection>
    <SkModalSection class="manage-shift-modal__content">
      <div class="manage-shift-modal__content-title manage-shift-modal__content-title--align-top">
        {{ $t('plannings.table.manage_shift_modal.tabs.commun.number_hours') }}
      </div>
      <div class="manage-shift-modal__content__flex">
        <SkInput
          v-if="!customHours"
          v-model="absenceDurationInSeconds"
          type="number"
          class="custom-hours__input"
          :errored="!isCustomHoursDurationValid"
          :error-message="customHoursErrorMessage"
          :label="$t('plannings.table.manage_shift_modal.tabs.commun.number_hours')"
          data-test="absence-form__duration-time"
          @keydown.native.enter.stop=""
        />
        <SkInputGroup
          v-else
          class="manage-shift-modal__content-sk-time"
          separator
          @keydown.native.enter.stop=""
        >
          <template #prepend>
            <ShiftTimePicker
              v-model="absenceShift.attributes.startsAt"
              :current-shop="currentShop"
              :reference-date="date"
              :interval-in-minutes="15"
              :hide-last-option="is24hShop"
              x-offset="-6px"
              center
              data-test="absence-form__duration-starts-at"
              @input="updateShiftHours"
              @errored="handleStartsAtError"
            />
          </template>
          <template>-</template>
          <template #append>
            <ShiftTimePicker
              v-model="absenceShift.attributes.endsAt"
              :current-shop="currentShop"
              :reference-date="date"
              :interval-in-minutes="15"
              x-offset="6px"
              center
              data-test="absence-form__duration-ends-at"
              @input="updateShiftHours"
              @errored="handleEndsAtError"
            />
          </template>
        </SkInputGroup>
      </div>
    </SkModalSection>
    <SkModalSection
      border-bottom="none"
      class="manage-shift-modal__content"
    >
      <div class="manage-shift-modal__content-title manage-shift-modal__content-title--align-top">
        {{ $t('plannings.table.manage_shift_modal.tabs.commun.other_actions.notes') }}
      </div>
      <div class="manage-shift-modal__content-input">
        <!-- eslint-disable max-len -->
        <SkTextarea
          v-model="absenceShift.attributes.note"
          :label="$t('plannings.table.manage_shift_modal.tabs.commun.other_actions.add_description')"
          :max-height="90"
          :min-height="54"
          rows="3"
          auto-grow
          data-test="absence-form__note"
          @keydown.native.enter.stop=""
        />
      <!-- eslint-enable max-len -->
      </div>
    </SkModalSection>
    <SkModalSection
      v-if="shouldDisplayComments(absenceShift.attributes.comments)"
      class="manage-shift-modal__content"
    >
      <div class="shift-form__content-title shift-form__content-title__comment">
        {{ $t('plannings.table.manage_shift_modal.tabs.shift.comments.title') }}
      </div>
      <CommentsManager
        :comments="absenceShift.attributes.comments"
        :user="absenceShift.attributes.userId"
      />
    </SkModalSection>
    <DaySelection
      v-if="!isAbsenceShiftUpdate && selectedCellsCount <= 1"
      class="manage-shift-modal__content"
      :date="date"
      :row-item="rowItem"
      :possible-selection-dates="possibleSelectionDates"
      @keydown.native.enter.stop=""
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';

import { updateShiftEndsAtFor24hShop } from '@app-js/plannings/shared/utils/planning_helpers';

import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
  ABSENCE_TYPE_HOURS,
} from '@app-js/shared/constants/shift';
import { getI18nAbsenceKey } from '@app-js/shared/utils/absences_helper';
import PlanningEmployeesSelect from '@app-js/plannings/shared/components/PlanningEmployeesSelect';

import CommentsManager from '@app-js/plannings/shared/components/ShiftActivities/Comments/CommentsManager';
import DaySelection from './DaySelection';
import ShiftTimePicker from './ShiftTimePicker';
import ShiftEmployeeSelector from './ShiftEmployeeSelector';

export default {
  name: 'AbsenceTab',
  components: {
    CommentsManager,
    DaySelection,
    ShiftTimePicker,
    ShiftEmployeeSelector,
    PlanningEmployeesSelect,
  },
  props: {
    date: {
      type: Object,
      default: () => {},
    },
    rowItem: {
      type: Object,
      default: () => {},
    },
    isUpdateModal: {
      type: Boolean,
      required: true,
    },
    possibleSelectionDates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      absenceCalculations: [
        {
          id: ABSENCE_TYPE_DAY,
          text: this.$t('plannings.table.manage_shift_modal.tabs.absence.duration.all_day'),
        },
        {
          id: ABSENCE_TYPE_HALF_DAY,
          text: this.$t('plannings.table.manage_shift_modal.tabs.absence.duration.half_day'),
        },
        {
          id: ABSENCE_TYPE_HOURS,
          text: this.$t('plannings.table.manage_shift_modal.tabs.absence.duration.personalise'),
        },
      ],
    };
  },
  computed: {
    ...mapState('planningsShifts', ['absenceShift', 'shiftUserIds']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('planningsPostes', ['absences']),
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopTeams', ['teams']),
    ...mapGetters('currentShop', [
      'isShopOnPaidVacationCalculationTypeOpeningDay',
      'isShopOnPaidVacationCalculationTypeCalendarDay',
      'is24hShop',
    ]),
    ...mapGetters('planningsState', ['areUnassignedShiftsAllowed', 'isPostesView']),
    ...mapGetters('planningsShifts', ['shouldDisplayComments']),
    ...mapGetters('planningsBulkEdit', ['selectedCellsCount']),
    isAbsenceShiftUpdate() {
      return !!this.absenceShift.id;
    },
    selectedUserIds() {
      return this.shiftUserIds.main;
    },
    shiftUserId() {
      return Number(this.selectedUserIds[0]);
    },
    shiftAbsenceCalculation: {
      get() {
        return this.absenceShift.attributes.absenceCalculation;
      },

      set(newValue) {
        this.absenceShift.attributes.absenceCalculation = newValue;
        this.updateAbsenceDurationInSeconds(newValue);
      },
    },
    absenceDurationInSeconds: {
      get() {
        return parseFloat(
          (this.absenceShift.attributes.absenceDurationInSeconds / 3600).toFixed(2),
        );
      },

      set(newValue) {
        // This early return is required to avoid updating wrongly absenceDurationInSeconds
        // As we display a rounded value, we do not want to update the value in seconds
        // that has been properly set by another value
        // eg: With contract_hours: 40 and isShopOnPaidVacationCalculationTypeOpeningDay if I create PTO absence
        // I want 40/6 which gives 6,6666....667 - In seconds: 40/6*3600 = 24000
        // Without this early return, we'll update the value in second with 6.67*3600 = 24012
        // Those seconds are the reason behind weekly totals issues
        if (this.absenceDurationInSeconds === newValue) return;

        this.absenceShift.attributes.absenceDurationInSeconds = newValue * 3600;
      },
    },
    customHours() {
      return this.absenceShift.attributes.absenceCalculation === ABSENCE_TYPE_HOURS;
    },
    areAllAbsencesDisabled() {
      return this.formatedAbsences.length === 0;
    },
    absenceShiftPosteId: {
      get() {
        if (this.formatedAbsences.map(abs => abs.id)
          .includes(this.absenceShift.relationships.poste.id)
        ) {
          return this.absenceShift.relationships.poste.id;
        }
        // if selected absence has been disable (or none active absences) fallback on first absence
        return this.formatedAbsences[0].id;
      },

      set(newValue) {
        this.absenceShift.relationships.poste.id = newValue;
        this.updateAbsenceDurationInSeconds(this.shiftAbsenceCalculation);
      },
    },
    formatedAbsences() {
      return this.absences
        .filter(absence => absence.attributes.active)
        .map(absence => {
          const { id, attributes: { absenceKey } } = absence;
          const { absencesCountry } = this.currentShop.attributes;
          const i18nKey = getI18nAbsenceKey(
            absencesCountry,
            absenceKey,
          );
          const { name: text } = this.$t(i18nKey);

          return { id, text };
        })
        .sort((a, b) => a.text?.toLowerCase()?.localeCompare(b.text?.toLowerCase()));
    },
    isCustomHoursDurationValid() {
      const duration =
        this.absenceDurationInSeconds ? parseFloat(this.absenceDurationInSeconds) : 0;
      const isValidDuration = parseFloat(duration) <= 24;
      this.setAbsenceShiftError({ key: 'customHoursDuration', value: !isValidDuration });
      return isValidDuration;
    },
    customHoursErrorMessage() {
      return this.isCustomHoursDurationValid ?
        '' :
        this.$t('plannings.table.manage_shift_modal.tabs.absence.duration.custom_duration_limit');
    },
    selectUserOrAbsenceTitle() {
      return this.isPostesView ?
        this.$t('plannings.table.manage_shift_modal.tabs.absence.select_user') :
        this.$t('plannings.table.manage_shift_modal.tabs.absence.absence_type');
    },
    wrapperClasses() {
      return {
        'manage-shift-modal__absence__wrapper': !this.isPostesView,
        'manage-shift-modal__absence__wrapper--postes-view': this.isPostesView,
      };
    },
    employeesSelectLabel() {
      // If list is empty or just contains one null element -> unassigned shift
      return this.selectedUserIds.length === 0 ||
              (this.selectedUserIds.length === 1 && this.selectedUserIds[0] === null) ?
        this.$t('plannings.table.manage_shift_modal.tabs.shift.unassigned') :
        this.$t('plannings.toolbar.modal.shared.select.employees');
    },
  },
  mounted() {
    this.$emit('absence-tab-mounted');

    // When creating a new absence, we want it to be a full day absence by default
    if (!this.isUpdateModal) {
      this.shiftAbsenceCalculation = ABSENCE_TYPE_DAY;
      this.absenceShift.attributes.dayAbsence = true;
    }
  },
  methods: {
    ...mapMutations('planningsShifts', ['setAbsenceShiftError', 'setShiftUserIds']),
    ...mapMutations('planningsState', ['setAbsenceSidePanelOpeningOrigin']),
    updateAbsenceDurationInSeconds(absenceCalculation) {
      let absenceDurationInSeconds;
      let baseDayHoursValue;
      if (absenceCalculation === ABSENCE_TYPE_HOURS) {
        this.absenceShift.attributes.absenceDurationInSeconds = null;
        return;
      }

      // TODO: This will be corrected on ticket DEV-14145
      // Do not take this code as reference on how to deal with absences
      // since it was developed that way to handle a hot-fix
      const paidLeaveAbsenceId = this.absences.find(absence => (
        absence.attributes.absenceKey === this.config.absence_data.paid_leave_absence_key ||
          absence.attributes.absenceKey === this.config.absence_data.paid_leave_absence_key_es
      )).id;

      if (this.isShopOnPaidVacationCalculationTypeOpeningDay &&
        this.absenceShift.relationships.poste.id === paidLeaveAbsenceId) {
        baseDayHoursValue = this.config.absence_data.opening_day_hours_value;
      } else if (this.isShopOnPaidVacationCalculationTypeCalendarDay &&
        this.absenceShift.relationships.poste.id === paidLeaveAbsenceId) {
        baseDayHoursValue = this.config.absence_data.calendar_day_hours_value;
      } else {
        baseDayHoursValue = this.config.absence_data.working_day_hours_value;
      }

      if (
        this.isPostesView ||
        !this.rowItem.id ||
        this.rowItem.attributes.onDayRate ||
        this.rowItem.attributes.onExtra
      ) {
        absenceDurationInSeconds =
          (this.currentShop.attributes.legalWeeklyHours * 3600) / baseDayHoursValue;
      } else {
        absenceDurationInSeconds =
          (this.rowItem.attributes.currentContractHours * 3600) / baseDayHoursValue;
      }

      if (absenceCalculation === ABSENCE_TYPE_HALF_DAY) {
        absenceDurationInSeconds /= 2;
      }

      this.absenceShift.attributes.absenceDurationInSeconds = absenceDurationInSeconds;
    },
    handleStartsAtError(errored) {
      this.setAbsenceShiftError({ key: 'startsAtFormat', value: errored });
    },
    handleEndsAtError(errored) {
      this.setAbsenceShiftError({ key: 'endsAtFormat', value: errored });
    },
    updateShiftHours() {
      if (this.is24hShop) {
        updateShiftEndsAtFor24hShop(this.absenceShift);
      }

      this.setAbsenceShiftError({ key: 'duration', value: this.isNegativeDuration() });
    },
    isNegativeDuration() {
      const endsAt = skDate(this.absenceShift.attributes.endsAt);
      const startsAt = skDate(this.absenceShift.attributes.startsAt);

      return skDate.duration(endsAt.diff(startsAt)).asMinutes() < 0;
    },
    showAbsencePanel(event) {
      this.setAbsenceSidePanelOpeningOrigin('shift-modal');
      this.emitOnRoot('open-absences-panel');
    },
    updateUser(userId) {
      this.absenceShift.attributes.userId = userId;
      this.setShiftUserIds({ userIds: [userId], type: 'main' });
    },
    updateMultipleUsers(userIds) {
      const sanitizedUserIds = userIds.map(userId => (userId === 'unassigned' ? null : userId));
      this.setShiftUserIds({ userIds: sanitizedUserIds, type: 'main' });
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-shift-modal__absence__wrapper {
  padding-top: 13px;

  &--postes-view {
    padding-top: 16px;
  }
}
// Personalised modal asked by the product

::v-deep .sk-select__selected-option {
  height: 44px;
}

::v-deep .sk-modal__header .sk-modal__subtitle {
  margin-top: 6px;
}

// This to .sk-input-group .sk-input .sk-input__input
// align to center SkTimePicker
::v-deep .sk-input-group__prepend,
::v-deep .sk-input-group__append {
  flex: 2;
}

::v-deep .sk-input-group__separator {
  flex: 1;
  padding: 0;
}

::v-deep .sk-input-group {
  align-items: center;
}

::v-deep .sk-input-group .sk-input .sk-input__input {
  border-bottom: 0;
}

::v-deep .sk-modal {
  // Shrink tooltip
  .sk-popover--tooltip {
    padding: 5px 8px;
    font-size: $fs-text-s;
    text-align: center;
    max-width: 155px;

    * {
      margin: 0;
      padding: 0;
    }
  }
}

.manage-shift-modal__content {
  display: flex;
  flex-direction: row;
  padding: 8px 0 !important;
}

.manage-shift-modal__content--radio {
  padding-top: 21px !important;
  padding-bottom: 9px !important;
}

.manage-shift-modal__content-title {
  width: 141px;
  display: flex;
  align-items: center;
  font-weight: $fw-bold;

  &--align-top {
    align-items: start;
    margin-top: 11px;
  }
}

.manage-shift-modal__content-input {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.manage-shift-modal__content-radio {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.manage-shift-modal__popover {
  width: 100%;
}

.manage-shift-modal__content-select {
  width: 282px;
}

.manage-shift-modal__absences-type {
  display: flex;
  align-items: center;
  margin-left: 14px;
}

.manage-shift-modal__content-sk-time {
  width: 176px;
}

.manage-shift-modal__content__flex {
  display: flex;
}

/* Design adjustements to input error message */
.custom-hours__input {
  ::v-deep .sk-input__error-wrapper {
    line-height: 12px;
    overflow-x: visible;
    white-space: nowrap;
    width: 212px;
  }
}

.shift-form__content-title {
  width: 141px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  font-weight: $fw-bold;

  &__comment {
    align-items: start;
    width: 130px;
  }
}
</style>
