<template>
  <SkModal
    id="meal-rules-modal"
    ref="modal"
    :display-footer="false"
    :modal-title="modalTitle"
    size="large"
    @show="handleShow"
    @close="handleClose"
    @cancel="handleClose"
  >
    <template #body>
      <template v-if="isMealRuleFetching">
        <div class="sk-modal__loading-spinner">
          <SkSelectSpinner />
        </div>
      </template>
      <template v-else>
        <SkModalSection
          v-if="!isEditMode"
          class="sk-modal__section sk-modal-section__calcultation"
        >
          <div
            v-track="'meal_back_to_first_step'"
            :class="sectionHeaderClasses"
            @click="openCalculationMethodsChoice"
          >
            <SkMedallion
              v-if="!isCalculationMethodsOpen"
              background-color="#2b66fe"
              color="white"
              size="small"
              icon="CheckMarkIconSmall"
              class="sk-medallion-calculation-done"
              @click.stop="openCalculationMethodsChoice"
            />
            <h3 class="sk-header--3">
              <!-- eslint-disable-next-line max-len -->
              {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.title') }}
            </h3>
            <template v-if="isCalculationMethodsOpen">
              <CaretIcon
                height="9"
                width="15"
                class="sk-modal-section__header__caret sk-select__caret caret--down"
              />
              <p class="sk-subtitle--medium">
                <!-- eslint-disable-next-line max-len -->
                {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.sub_title') }}
              </p>
            </template>
            <template v-else>
              <p class="sk-subtitle--medium">
                <!-- eslint-disable-next-line max-len -->
                {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.calculation_methods.${calculationMethod}.label`) }}
              </p>
              <CaretIcon
                class="sk-modal-section__header__caret sk-select__caret caret--up"
                height="9"
                width="15"
              />
            </template>
          </div>
          <template v-if="isCalculationMethodsOpen">
            <div class="sk-modal-section__calcultation__radio-buttons">
              <div>
                <SkRadio
                  v-for="item in calculationMethods"
                  :id="item"
                  :key="item"
                  v-model="calculationMethod"
                  :data-value="item"
                >
                  <span class="sk-modal-section__calcultation__radio-label">
                    <p class="m-0">
                      <!-- eslint-disable-next-line max-len -->
                      {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.calculation_methods.${item}.label`) }}
                    </p>
                    <p class="sk-subtitle--medium">
                      <!-- eslint-disable-next-line max-len -->
                      {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.calculation_methods.${item}.subline`) }}
                    </p>
                  </span>
                </SkRadio>
              </div>
              <div class="sk-modal__actions__radio-buttons">
                <SkOroraButton
                  :disabled="disableSubmitCalculationMethod"
                  @click.stop="closeSubmitCalculationMethod"
                >
                  {{ $t("actions.next") }}
                </SkOroraButton>
              </div>
            </div>
          </template>
        </SkModalSection>
        <SkModalSection class="sk-modal__section sk-modal-section__parameter">
          <div :class="sectionHeaderClasses">
            <div v-if="isEditMode">
              <h3>
                <!-- eslint-disable-next-line max-len -->
                {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.edit_title') }}
              </h3>
            </div>
            <div v-else>
              <h3 class="sk-header--3">
                <!-- eslint-disable-next-line max-len -->
                {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.title') }}
              </h3>
              <template v-if="!isCalculationMethodsOpen">
                <CaretIcon
                  class="sk-modal-section__header__caret sk-select__caret caret--down"
                  height="9"
                  width="15"
                />
                <p class="sk-subtitle--medium">
                  <!-- eslint-disable-next-line max-len -->
                  {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.sub_title') }}
                </p>
              </template>
              <CaretIcon
                v-else
                class="sk-modal-section__header__caret sk-select__caret caret--up"
                height="9"
                width="15"
              />
            </div>
          </div>
          <template v-if="!isCalculationMethodsOpen">
            <div>
              <template v-if="displayStepBar">
                <div class="sk-modal-section__parameter__progress-bar">
                  <SkProgressBar
                    :max-step="2"
                    :current-step="currentStepNumber()"
                    :step-labels="progessBarLabels"
                  />
                </div>
              </template>
              <MealRuleSettingParameters
                :current-step="currentStep"
                @handle-error-form="handleErrorForm"
              />
              <template v-if="displayCriteria">
                <template v-if="displayCriteriaSettings">
                  <MealRuleSettingCriteria
                    v-for="(mealAdditionTrigger, index) in mealAdditionTriggers"
                    :key="mealAdditionTrigger.attributes.order"
                    :meal-addition-trigger="mealAdditionTrigger"
                    :index="index"
                    :length="mealAdditionTriggers.length"
                    :current-step="currentStep"
                    @add-meal-addition-trigger="addMealAdditionTrigger"
                    @delete-meal-addition-trigger="deleteMealAdditionTrigger"
                    @handle-error-form="handleErrorForm"
                  />
                </template>
                <template v-if="isMealStepTaken">
                  <div class="sk-modal-section__parameter__meal-emoji">
                    <div class="sk-modal-section__parameter__meal-emoji-title">
                      <!-- eslint-disable-next-line max-len -->
                      {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.meal_emoji.emoji_choice') }}
                    </div>
                    <IconSelect
                      v-if="isOroraShiftRedesignEnabled"
                      v-model="mealIcon"
                    />
                    <EmojiSelect
                      v-else
                      v-model="mealIcon"
                    />
                  </div>
                </template>
              </template>
              <div class="sk-modal__actions__radio-buttons">
                <SkOroraButton
                  :disabled="errorForm"
                  :loading="btnSpinner"
                  @click.stop="toggleNextStep"
                >
                  <span v-if="isMealStepTaken || !isCalculationMethodBenefitInKind">
                    {{ $t("actions.submit") }}
                  </span>
                  <span v-else>
                    {{ $t("actions.next") }}
                  </span>
                </SkOroraButton>
              </div>
            </div>
          </template>
        </SkModalSection>
      </template>
    </template>
  </SkModal>
</template>

<script>
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

import MealRuleSettingParameters from './components/MealRuleSettingParameters';
import MealRuleSettingCriteria from './components/MealRuleSettingCriteria';
import EmojiSelect from './components/EmojiSelect';
import IconSelect from './components/IconSelect';

export default {
  name: 'MealRulesModal',
  components: {
    MealRuleSettingParameters,
    MealRuleSettingCriteria,
    EmojiSelect,
    IconSelect,
  },
  data() {
    return {
      openCalculationMethods: true,
      modalOpen: false,
      currentStep: 'owed_meals',
      errorForm: false,
      btnSpinner: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('mealRule', ['mealRule', 'loading', 'defaultData']),
    ...mapState('config', ['config']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    isOroraShiftRedesignEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_ORORA_SHIFT_REDESIGN');
    },
    disableSubmitCalculationMethod() {
      return !this.calculationMethod || !this.calculationMethods.includes(this.calculationMethod);
    },
    displayCriteria() {
      return this.isMealStepTaken || this.isOwedMealsCalculationMethodHoursWorked;
    },
    displayCriteriaSettings() {
      return !this.isOwedMealsCalculationMethodHoursWorked ||
        this.isMealStepOwed ||
        !this.sameRulesOwedAndTaken;
    },
    displayStepBar() {
      return this.isCalculationMethodBenefitInKind;
    },
    isMealRuleFetching() {
      return !this.modalOpen || this.loading;
    },
    isEditMode() {
      return this.currentShop.attributes.mealCompensationDone;
    },
    isCalculationMethodsOpen() {
      if (this.isEditMode) {
        return false;
      }
      return !this.calculationMethod || this.openCalculationMethods;
    },
    isMealStepTaken() {
      return this.currentStep === this.config.meal_rules.meal_steps.taken_meals;
    },
    isMealStepOwed() {
      return this.currentStep === this.config.meal_rules.meal_steps.owed_meals;
    },
    isOwedMealsCalculationMethodHoursWorked() {
      // eslint-disable-next-line
      return this.owedMealsCalculationMethod === this.config.meal_rules.owed_meals_calculation_methods.hours_worked;
    },
    isCalculationMethodBenefitInKind() {
      return this.calculationMethod === this.config.meal_rules.calculation_methods.benefit_in_kind;
    },
    modalTitle() {
      if (this.isEditMode) {
        return this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.edit_title');
      }
      return this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.title');
    },
    calculationMethods() {
      return Object.values(this.config.meal_rules.calculation_methods);
    },
    sameRulesOwedAndTaken() {
      return this.mealRule.attributes.sameRulesOwedAndTaken;
    },
    calculationMethod: {
      get() {
        return this.mealRule.attributes.calculationMethod;
      },
      set(newValue) {
        if (this.mealRule.attributes.calculationMethod !== newValue) {
          this.initializeMealRuleAttributes();
        }

        this.mealRule.attributes.calculationMethod = newValue;
      },
    },
    mealIcon: {
      get() {
        return this.mealRule.attributes.mealEmoji;
      },
      set(newValue) {
        this.mealRule.attributes.mealEmoji = newValue;
      },
    },
    owedMealsCalculationMethod() {
      return this.mealRule.attributes.owedMealsCalculationMethod;
    },
    mealAdditionTriggers() {
      const mealAdditionTriggers = this.mealRule.relationships.mealAdditionTriggers.filter(
        mealAdditionTrigger => mealAdditionTrigger.attributes.triggerFor === this.currentStep,
      );

      return sortBy(mealAdditionTriggers,
        mealAdditionTrigger => mealAdditionTrigger.attributes.order,
      );
    },
    sectionHeaderClasses() {
      return {
        'sk-modal-section__calcultation-active': this.isCalculationMethodsOpen,
        'sk-modal-section__parameter-active': !this.isCalculationMethodsOpen && !this.isEditMode,
        'sk-modal-section__header': !this.isEditMode,
      };
    },
    progessBarLabels() {
      return [
        this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.steps.owed_meals'),
        this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.steps.taken_meals'),
      ];
    },
  },
  mounted() {
    if (Object.keys(this.mealRule).length === 0) {
      this.fillFetchedMealRuleWithDefaultValues(this.config);
    }
    this.setDefaultAttributes(this.currentShop);
  },
  methods: {
    ...mapActions('mealRule', [
      'updateMealRule',
      'createMealRule',
    ]),
    ...mapMutations('mealRule', [
      'setDefaultAttributes',
      'deleteMealAdditionTriggerById',
      'addDefaultMealAdditionTrigger',
      'initializeMealRuleWithDefaultValues',
      'resetMealRulesWithOriginalValues',
      'fillFetchedMealRuleWithDefaultValues',
    ]),
    ...mapMutations('currentShop', [
      'setShopAttributes',
    ]),
    toggleNextStep() {
      if (this.isCalculationMethodBenefitInKind && this.isMealStepOwed) {
        this.currentStep = this.config.meal_rules.meal_steps.taken_meals;
      } else {
        this.handleSubmitMealRule();
      }
    },
    initializeMealRuleAttributes() {
      this.backStepToOne();
      if (this.isEditMode) {
        this.resetMealRulesWithOriginalValues();
      } else {
        this.initializeMealRuleWithDefaultValues();
      }
    },
    currentStepNumber() {
      return [
        this.config.meal_rules.meal_steps.owed_meals,
        this.config.meal_rules.meal_steps.taken_meals,
      ].indexOf(this.currentStep) + 1;
    },
    backStepToOne() {
      this.currentStep = this.config.meal_rules.meal_steps.owed_meals;
    },
    openCalculationMethodsChoice() {
      this.openCalculationMethods = true;
    },
    closeSubmitCalculationMethod() {
      this.openCalculationMethods = false;
    },
    handleShow() {
      this.modalOpen = true;
      if (!this.isEditMode) {
        this.initializeMealRuleWithDefaultValues();
      } else {
        this.fillFetchedMealRuleWithDefaultValues(this.config);
      }
    },
    handleSubmitMealRule() {
      this.btnSpinner = true;

      if (this.isEditMode) {
        this.updateMealRule({ shopId: this.currentShop.id, mealRule: this.mealRule })
          .then(() => {
            this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_MEAL_SETTINGS_UPDATED, {
              calculationMethod: this.mealRule.attributes.calculationMethod,
            });

            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.update.success'),
              variant: 'success',
            });
            this.closeModal();
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.update.error'),
              variant: 'error',
            });
          });
        this.$skAnalytics.track('meal_rules_edit_validate');
      } else {
        this.createMealRule({ shopId: this.currentShop.id, mealRule: this.mealRule })
          .then(() => {
            this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_MEAL_SETTINGS_ENABLED, {
              calculationMethod: this.mealRule.attributes.calculationMethod,
            });
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.create.success'),
              variant: 'success',
            });

            this.closeModal();
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.create.error'),
              variant: 'error',
            });
          });

        this.$skAnalytics.track('meal_rules_validate');
      }
    },
    closeModal() {
      this.modalOpen = false;
      this.btnSpinner = false;
      this.resetModal();
      this.$refs.modal.hide();
    },
    handleClose(event) {
      if (!this.modalOpen) return;

      this.$root.$emit('confirm', event, {
        description: this.$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.cancel.confirm'),
        onConfirm: () => {
          this.closeModal();
          this.$skAnalytics.track(this.isEditMode ? 'meal_rules_edit_cancelled' : 'meal_rules_cancelled');
        },
      });
    },
    handleErrorForm(newValue) {
      this.errorForm = newValue;
    },
    resetModal() {
      this.initializeMealRuleAttributes();
      this.openCalculationMethodsChoice();
    },
    addMealAdditionTrigger(options) {
      this.addDefaultMealAdditionTrigger(merge(options, {
        triggerType: this.mealRule.attributes.triggerType[this.currentStep],
      }));
    },
    deleteMealAdditionTrigger(mealAdditionTriggerId) {
      this.deleteMealAdditionTriggerById({ idToDelete: mealAdditionTriggerId });
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.caret--down {
  transform: rotate(180deg);
  transition: transform .3s;
}

.caret--up {
  transform: rotate(0deg);
  transition: transform .3s;
}

.sk-modal__loading-spinner {
  display: flex;
  height: 270px;
  justify-content: center;
}

.sk-modal-section__header .sk-modal-section__header__caret {
  position: absolute;
  top: 0;
  right: 10px;
}

.sk-modal-section__header.sk-modal-section__calcultation,
.sk-modal-section__header.sk-modal-section__parameter {
  margin-left: -12px;
  margin-right: -12px;
}

.sk-modal-section__calcultation .sk-modal-section__calcultation--active,
.sk-modal-section__parameter .sk-modal-section__parameter-active {
  background-color: $sk-grey-5;
  border-radius: 5px;
}

.sk-modal-section__calcultation .sk-modal-section__calcultation-hidden,
.sk-modal-section__parameter .sk-modal-section__parameter-hidden {
  display: none;
}

.sk-modal-section__calcultation .sk-modal-section__header,
.sk-modal-section__parameter .sk-modal-section__header {
  position: relative;
  padding: 13px 23px;
}

.sk-modal-section__calcultation {
  .sk-modal-section__header.sk-modal-section__parameter-active {
    padding: 0 50px;
    cursor: pointer;
  }

  .sk-medallion-calculation-done {
    position: absolute;
    left: 15px;
  }

  .sk-modal-section__calcultation__radio-buttons {
    margin-top: 10px;
    margin-left: 20px;
  }
}

.sk-modal__actions__radio-buttons {
  display: flex;
  justify-content: flex-end;
}

.sk-modal-section__parameter {
  .sk-modal-section__parameter__progress-bar {
    margin-top: 25px;
    padding: 0 104px;
  }
}

.sk-modal-section__parameter__meal-emoji {
  display: flex;
  margin-top: 20px;

  .sk-modal-section__parameter__meal-emoji-title {
    font-weight: bold;
    width: 70%;
  }
}
</style>
