<template>
  <div
    v-if="!isEmployee"
    class="plannings__wrapper"
  >
    <Toolbar
      v-if="(isProgressiveLoadingEnabled && !isGlobalDataLoading)
        || !isProgressiveLoadingEnabled"
    >
      <DatePicker
        @navigate-to-planning-v2="setFiltersForV2"
      />
      <Navigation @navigate-to-planning-v2="setFiltersForV2" />
      <ActionsBar />
    </Toolbar>

    <LoadingPage
      v-if="(isGlobalDataLoading || loadingInitialData)
        && !isProgressiveLoadingEnabled"
    />

    <div
      v-if="!isGlobalDataLoading && !loadingInitialData"
      class="plannings__wrapper"
    >
      <GlobalEvents @keydown="handleKeydownEvents" />
      <router-view @planning-loaded="handlePlanningLoaded" />
      <AttendanceSheetsEsignatureSidePanel />
      <TotalEmployees v-if="isDailyView && !isWorkloadPlanEnabled" />
      <Kpis
        v-if="showKPIs"
        ref="kpisWeeklyTableComponent"
        :tab-opened-by-default="openKpisPanelByDefaultOnTab"
        :fetch-kpis-params="fetchKpisParams"
        @clean-default-tab="cleanDefaultTab"
      />
      <WorkloadPlanBottomPanel v-if="isWorkloadplanBottomPanelEnabled" />
      <ConflictSidePanel />
      <AutomaticPlanningSidePanel />
      <OptimizationSidePanel />

      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <NoUsersOrPostesModal
          :has-shop-users="hasShopUsers"
          :has-shop-postes="hasShopPostes"
          :shop-id="shopId"
        />
      </MountingPortal>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';
import GlobalEvents from 'vue-global-events';
import skDate from '@skello-utils/dates';
import { displayDelightedSurvey } from '@skello-utils/delighted_survey.js';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import LoadingPage from '@app-js/plannings/shared/components/LoadingPage';
import NoUsersOrPostesModal from '@app-js/plannings/shared/components/modal/NoUsersOrPostesModal';
import PlanningFilterHandler from '@app-js/plannings/shared/utils/planning_filter_handler';
import {
  createWebsockets, disconnectWebsockets,
} from '@app-js/plannings/shared/utils/planning_websockets';
import { websocketApiGatewayUrl } from '@config/env';
import Toolbar from '../shared/components/Toolbar';
import DatePicker from './shared/components/Toolbar/DatePicker';
import Navigation from './shared/components/Toolbar/Navigation';
import ActionsBar from './shared/components/Toolbar/ActionsBar';
import ConflictSidePanel from './shared/components/ConflictSidePanel';
import Kpis from './shared/components/kpis';
import AutomaticPlanningSidePanel from './shared/components/AutomaticPlanningSidePanel';
import AttendanceSheetsEsignatureSidePanel from './shared/components/AttendanceSheetsEsignatureSidePanel';
import OptimizationSidePanel from './shared/components/OptimizationSidePanel';
import TotalEmployees from './pages/Days/TotalEmployees';
import WorkloadPlanBottomPanel from './shared/components/WorkloadPlanBottomPanel';

export default {
  name: 'Plannings',
  components: {
    Toolbar,
    DatePicker,
    Navigation,
    ActionsBar,
    Kpis,
    ConflictSidePanel,
    AutomaticPlanningSidePanel,
    AttendanceSheetsEsignatureSidePanel,
    OptimizationSidePanel,
    TotalEmployees,
    WorkloadPlanBottomPanel,
    NoUsersOrPostesModal,
    GlobalEvents,
    LoadingPage,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.isEmployee) {
        vm.redirectToHome();

        next(false);

        return;
      }

      if (to.query.selected_tab === 'predicted') {
        vm.openKpisPanelByDefaultOnTab = 'PredictedTab';
      } else if (to.query.selected_tab === 'real') {
        vm.openKpisPanelByDefaultOnTab = 'RealTab';
      }

      vm.filterHandler = new PlanningFilterHandler(vm.setFilters);
      const query = vm.filterHandler.applyFilters(to, from);
      next({
        name: to.name,
        params: to.params,
        query,
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.shop_id !== from.params.shop_id) {
      const { shop_id: shopId } = to.params;
      this.updateCurrentShop({ shopId }).then(() => {
        this.initPlanningDataLoad(true);
      });
    }

    this.resetKpisPanelByDefault();
    this.setTrackerSource(to.name);

    const query = this.filterHandler.applyFilters(to, from);
    next({ query });
  },
  data() {
    return {
      openKpisPanelByDefaultOnTab: null,
      filterHandler: null,
      loadingInitialData: false,
      websockets: [],
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('overlays', ['isAnyModalOpen']),
    ...mapState('planningsPostes', ['postes', 'postesLoading']),
    ...mapState('planningsUsers', ['usersLoading', 'users']),
    ...mapState('planningsState', ['undoRedoLoading', 'filters', 'weeklyOptionsLoading']),
    ...mapState('planningsLoading', ['isGlobalDataLoading']),
    ...mapGetters('planningsLoading', ['isLoadingInitialData', 'isProgressiveLoadingEnabled']),
    ...mapGetters('annualization', ['annualizationMonday']),
    ...mapGetters('currentLicense', ['isEmployee']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('currentShop', ['isAnnualizedWorkingTimeAvailable']),
    ...mapGetters('planningsState', [
      'isEmployeesView',
      'isPlanningLoading',
      'isDailyView',
      'isPostesView',
      'isWeeklyView',
      'isMonthlyView',
      'monday',
      'sunday',
      'isAtBeginningOfHistory',
      'isAtEndOfHistory',
      'periodDates',
      'monthlyVisibleWeeks',
    ]),
    websocketDates() {
      if (this.isMonthlyView) {
        return this.mondaysOfTheMonth;
      }
      return [this.monday];
    },
    hasShopUsers() {
      return this.currentShop.attributes.hasPlanningUsers;
    },
    hasShopPostes() {
      return this.postes.length > 0;
    },
    shouldDisplayNoUsersOrPostesModal() {
      const dataLoaded = !this.postesLoading && !this.usersLoading;
      return dataLoaded && (!this.hasShopUsers || !this.hasShopPostes);
    },
    shopId() {
      return this.$route.params.shop_id;
    },
    routePosteIds() {
      return this.paramAsArray(this.$route.query.poste_ids);
    },
    routeTeamIds() {
      return this.paramAsArray(this.$route.query.team_ids);
    },
    hasUrlFilters() {
      return this.routePosteIds || this.routeTeamIds;
    },
    npsConfig() {
      return this.config.nps_data;
    },
    isWorkloadPlanEnabled() {
      return this.checkPackOfferFlag('workload_plan_enabled');
    },
    isWorkloadplanBottomPanelEnabled() {
      return this.isDailyView && this.isWorkloadPlanEnabled;
    },
    showKPIs() {
      // KPIs are not available on day view
      if (!this.isPostesView && !this.isEmployeesView) return false;

      // Needs planning variables to work - wait until loaded
      if (!this.isProgressiveLoadingEnabled) {
        if (this.isPlanningLoading) return false;
      } else if (this.isLoadingInitialData) {
        return false;
      }

      // If there are no users -> no need to call MS
      // Call breaks when shop was just created, and is not in ms database yet
      return this.users.length > 0;
    },
    canUndoPlanning() {
      return this.currentOrganisation.attributes.packOffer.plannings_undo_enabled &&
        !this.isAtBeginningOfHistory &&
        !this.undoRedoLoading;
    },
    canRedoPlanning() {
      return this.currentOrganisation.attributes.packOffer.plannings_undo_enabled &&
        !this.isAtEndOfHistory &&
        !this.undoRedoLoading;
    },
    mondaysOfTheMonth() {
      // reutrn all mondays of the current month
      return Object.keys(this.monthlyVisibleWeeks);
    },
  },
  watch: {
    $route(to, from) {
      const hasShopChanged = to.params.shop_id !== from.params.shop_id;

      if (hasShopChanged) {
        this.emitOnRoot(MODAL_HIDE_EVENT, event, 'no-users-or-postes-modal');
      }
    },
    websocketDates: {
      handler(newDates, oldDates) {
        if (this.areDatesArraysEqual(newDates, oldDates)) return;
        this.cleanupWebsockets();
        this.setupWebsockets();
      },
    },
  },
  updated() {
    if (this.$route.query?.open_esignature_upsell === 'true' &&
        !this.isGlobalDataLoading &&
        !this.isPlanningLoading &&
        !this.currentShop.attributes.esignatureActive) {
      this.$skAnalytics.track('esignature_upsell_open');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'esignature-upsell-modal');
    }
  },
  beforeMount() {
    if (this.$route.name === 'plannings_days' && !this.checkPackOfferFlag('day_planning_enabled')) {
      this.$router.push({ name: 'plannings_weeks' });
    }
  },
  mounted() {
    this.setupWebsockets();
  },
  destroyed() {
    this.cleanupWebsockets();
  },
  created() {
    this.loadingInitialData = true;

    if (this.$route.query.open_automatic_planning) {
      // Need to wait for AutomaticPlanningSidePanel to be rendered before opening it
      this.$nextTick(() => {
        this.emitOnRoot('open-automatic-planning');
      });
    }

    // When navigating from other v3 page in "all shops" view
    // the current shop must be set before displaying planning
    const prevShopWasAll = this.currentShop.id === 'all';

    const isExtendedInfoLoaded = !!this.currentShop.attributes.absencesCountry;

    if (prevShopWasAll || !isExtendedInfoLoaded) {
      const { shop_id: shopId } = this.$route.params;
      this.updateCurrentShop({ shopId })
        .then(() => {
          this.initPlanningDataLoad(true);
        });
    } else {
      this.initPlanningDataLoad();
    }

    this.setTrackerSource(this.$route.name);
  },
  methods: {
    ...mapActions('planningsKpis', ['fetchUserKpisSettings', 'fetchKpis']),
    ...mapActions('annualization', [
      'fetchEmployeeAnnualizationConfigs',
      'fetchShopAnnualizationConfig',
    ]),
    ...mapActions('planningsLoading', ['fetchPlanningShopGlobalData']),
    ...mapActions('planningsState', ['undoRedoAction']),
    ...mapActions('currentShop', ['updateCurrentShop', 'fetchShops']),
    ...mapActions('navContext', ['selectShop']),
    ...mapMutations('planningsState', ['setFilters']),
    ...mapMutations('annualization', ['setPeriodAt']),
    setupWebsockets() {
      if (websocketApiGatewayUrl && (this.isMonthlyView || this.isWeeklyView)) {
        this.websockets = createWebsockets(
          websocketApiGatewayUrl,
          this.currentShop.id,
          this.websocketDates,
          this.handleWebsocket,
        );
      }
    },
    cleanupWebsockets() {
      disconnectWebsockets(this.websockets);
      this.websockets = [];
    },
    fetchKpisParams(workedHoursOnly, source) {
      return {
        shopId: this.currentShop.id,
        date: this.isWeeklyView ? this.monday : this.mondaysOfTheMonth[0],
        workedHoursOnly,
        filters: this.filters,
        source,
        weeksToFetch: this.isWeeklyView ? 1 : 5,
        // The following line will be removed in ticket DEV-15880
        absencesCountry: this.currentShop.attributes.absencesCountry,
      };
    },
    handleWebsocket(event) {
      // pingToShopId is the name of the relevant websocket / SQS
      if (event.data === 'pingToShopIdToFetchKpis') {
        // we fetch only the worked hours if the kpis table is not open, else fetch all
        const kpisWeeklyComponent = this.$refs.kpisWeeklyTableComponent;
        const fetchWorkedHoursOnly = !(kpisWeeklyComponent?.allKpisTableOpen);
        this
          .fetchKpis(this.fetchKpisParams(fetchWorkedHoursOnly, 'socket'))
          .then(() => this.emitOnRoot('kpi-data-updated'));
      }
    },
    redirectToHome() {
      this.$skToast({
        message: this.$t('errors.unauthorized'),
        variant: 'error',
      });

      setTimeout(() => {
        window.location = '/';
      }, 1000);
    },

    loadInitialPlanningData(doSelectShop = false) {
      this.fetchPlanningShopGlobalData({ shopId: this.currentShop.id })
        .then(() => {
          this.afterInitialPlanningDataLoad(doSelectShop);
          this.handlePlanningLoaded();
          this.loadingInitialData = false;
        })
        .catch(error => {
          this.makeErrorToast();
          throw error;
        });
    },
    // TODO : DEV-11037 - Fetch only once all shop configurations
    // Use this method for all queries that are needed only once
    // for the initial planning load, but dont have to be re-fetch
    // for every day/week change
    initPlanningDataLoad(doSelectShop = false) {
      if (!this.isEmployee) {
        this.fetchShops(true);
      }
      this.loadInitialPlanningData(doSelectShop);
      if (
        this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
        this.currentShop.attributes.isAnnualizationV2Active
      ) {
        this.fetchEmployeeAnnualizationConfigs({ shopId: this.currentShop.id })
          .catch(error => {
            this.makeErrorToast();

            throw error;
          });

        const monday = skDate.utc(this.monday);
        this.fetchShopAnnualizationConfig({
          shopId: this.currentShop.id,
          date: monday.format(),
        })
          .then(() => {
            // pivotal week
            if (!monday.isSame(this.annualizationMonday, 'day')) {
              this.fetchShopAnnualizationConfig({
                shopId: this.currentShop.id,
                date: monday.add(1, 'week').format(),
              });
            }

            this.setPeriodAt(this.annualizationMonday.toDate());
          })
          .catch(error => {
            this.makeErrorToast();

            throw error;
          });
      }
    },
    afterInitialPlanningDataLoad(doSelectShop) {
      if (doSelectShop) {
        this.selectShop(this.currentShop);
      }
      if (!this.isDailyView) {
        this.displaySkelloNps();
      }
    },
    cleanDefaultTab() {
      this.resetKpisPanelByDefault();
      this.cleanUrlFromDefaultTab();
    },
    resetKpisPanelByDefault() {
      this.openKpisPanelByDefaultOnTab = null;
    },
    cleanUrlFromDefaultTab() {
      if (this.$router.currentRoute.fullPath.includes('selected_tab')) {
        const query = { ...this.$route.query };
        delete query.selected_tab;
        this.$router.replace({ query });
      }
    },
    setTrackerSource(name) {
      if (name === 'plannings_days') {
        this.$skAnalytics.stdProperties.source = 'Day';
      }
      if (name === 'plannings_weeks_employees') {
        this.$skAnalytics.stdProperties.source = 'Week';
      }
      if (name === 'plannings_months') {
        this.$skAnalytics.stdProperties.source = 'Month';
      }
      if (name === 'plannings_weeks_postes') {
        this.$skAnalytics.stdProperties.source = 'Poste';
      }
    },
    paramAsArray(param) {
      // Param isn't set in URL
      if (param === undefined || param.length === 0) {
        return null;
      }
      // Only one value for param in URL -> string
      if (typeof param === 'string') {
        return JSON.stringify([param]);
      }
      // Several values for param in URL -> array
      return JSON.stringify([...param]);
    },
    // When navigating to v2 -> set cookies to keep the same filters
    setFiltersForV2() {
      if (this.hasUrlFilters) {
        this.setCookie('postes', this.routePosteIds);
        this.setCookie('teams', this.routeTeamIds);
        this.setCookie('selectAllPostes', !this.routePosteIds);
        this.setCookie('selectAllTeams', !this.routeTeamIds);
      } else {
        this.setCookie('postes', null);
        this.setCookie('teams', null);
        this.setCookie('selectAllPostes', true);
        this.setCookie('selectAllTeams', true);
      }
    },
    setCookie(cname, cvalue) {
      // Cookie lasts 30 minutes
      const date = new Date();
      date.setTime(date.getTime() + (30 * 60 * 1000));
      document.cookie = `${cname}=${cvalue}; expires=${date};domain=;path=/`;
    },
    displaySkelloNps() {
      // logImpersonate is false if environment is production and impersonating mode is active
      if (!this.npsConfig.log_impersonate) return;
      if (!this.npsConfig.old_enough) return;

      const trueUser = this.npsConfig.true_user;
      const trueUserShop = this.npsConfig.true_user_affiliated_shop;
      const trueUserOrganisation = this.npsConfig.true_user_organisation;
      const trueUserHighestLicense = this.npsConfig.true_user_highest_license;

      if (trueUserHighestLicense.employee) return;
      if (!trueUserShop.id) return;
      if (!trueUserOrganisation.id) return;

      const userParams = {
        email: trueUser.email,
        name: trueUser.name,
        properties: {
          id: trueUser.id,
          phone: trueUser.phone,
          shop_id: trueUserShop.id,
          orga_id: trueUserOrganisation.id,
          pack: trueUserOrganisation.pack_offer_name,
          badging: trueUserShop.badging,
          shop_created_at: trueUserShop.created_at,
          payment_method: trueUserShop.payment_type,
          billing_on: trueUserOrganisation.payment_entity,
          highest_license: trueUserHighestLicense.original_type,
          user_birthday: trueUser.birthday,
          gender: trueUser.gender,
        },
      };

      displayDelightedSurvey('planningNpsKey', userParams);
    },
    handlePlanningLoaded() {
      if (!this.shouldDisplayNoUsersOrPostesModal) return;

      this.emitOnRoot(MODAL_SHOW_EVENT, null, 'no-users-or-postes-modal');
    },
    makeErrorToast() {
      this.$skToast({
        message: this.$t('errors.standard_message'),
        variant: 'error',
      });
    },
    handleKeydownEvents(event) {
      // Cmd or Ctrl + z
      if ((event.ctrlKey || event.metaKey) && event.key === 'z' && !event.repeat) {
        this.onUndoShortcut();
      }
      // Cmd or Ctrl + shift + z
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'Z' && !event.repeat) {
        this.onRedoShortcut();
      }
      // Cmd or Ctrl + p
      if ((event.ctrlKey || event.metaKey) && event.key === 'p' && !event.repeat) {
        this.onPrintShortcut(event);
      }
    },
    onUndoShortcut() {
      if (!this.canUndoPlanning || this.isAnyModalOpen) return;

      const dates = this.periodDates(this.period);

      this.$skAnalytics.track('sc_undo_action', { source: this.isWeeklyView ? 'Week' : 'Month' });

      this.undoRedoAction({
        periodStartsAt: dates.startsAt,
        periodEndsAt: dates.endsAt,
        isRedo: false,
      }).catch(() => this.makeErrorToast());
    },
    onRedoShortcut() {
      if (!this.canRedoPlanning || this.isAnyModalOpen) return;

      const dates = this.periodDates(this.period);

      this.$skAnalytics.track('sc_redo_action', { source: this.isWeeklyView ? 'Week' : 'Month' });

      this.undoRedoAction({
        periodStartsAt: dates.startsAt,
        periodEndsAt: dates.endsAt,
        isRedo: true,
      }).catch(this.makeErrorToast);
    },
    onPrintShortcut(event) {
      event.preventDefault();

      if (this.isAnyModalOpen) {
        return;
      }

      this.$skAnalytics.track('sc_open_print_modale');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'print-modal');
    },
    areDatesArraysEqual(newDates, oldDates) {
      return newDates.length === oldDates.length &&
           newDates.every((date, index) => date === oldDates[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
.plannings__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sk-toolbar__wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
