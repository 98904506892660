<template>
  <ModalBase
    ref="publishPlanningModalMonthly"
    v-bind="modalOptions"
    @show="handleShow"
    @close="handleClose"
    @submit="handleSubmit"
  >
    <template #content>
      <div
        v-if="isSvcCommunicationsV2Enabled"
        class="publish-planning__notice"
      >
        {{ $t('plannings.toolbar.modal.publish_planning.notice') }}
      </div>
      <div class="modal-row">
        <span class="label">
          {{ $t('plannings.toolbar.modal.publish_planning.select.label') }}
        </span>

        <WeeksSelector v-model="selectedWeeks" />
      </div>
    </template>
  </ModalBase>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import WeeksSelector from './WeeksSelector';
import ModalBase from './ModalBase';

export default {
  name: 'ModalMonthly',
  components: {
    ModalBase,
    WeeksSelector,
  },
  data() {
    return {
      selectedWeeks: [],
      isPublishing: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsState', ['monthlyOptions', 'monthlyOptionsLoading']),
    ...mapState('planningsUsers', ['users']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    isSvcCommunicationsV2Enabled() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_SVC_COMMUNICATIONS_V2');
    },
    modalOptions() {
      return {
        subtitle: '',
        isLoading: this.monthlyOptionsLoading,
        isButtonDisabled: this.selectedWeeks.length === 0,
        isPublishing: this.isPublishing,
      };
    },
  },
  methods: {
    ...mapActions('planningsState', ['fetchMonthlyOptions', 'updateUserPlanningConfig', 'publishPlanning']),

    async handleShow() {
      this.fetchMonthlyOptions({ shopId: this.currentShop.id });
    },
    async handleSubmit({ email, sms, emailAdditionalComment }) {
      this.isPublishing = true;

      try {
        const selectedWeeklyOptions = this.monthlyOptions
          .filter(({ date }) => this.selectedWeeks.includes(skDate(date).isoWeek()));

        const promises = selectedWeeklyOptions
          .map(({ date }) => this.publishOneWeek({ date, email, sms, emailAdditionalComment }));

        await Promise.all(promises);

        this.fetchMonthlyOptions({ shopId: this.currentShop.id });
        this.emitOnRoot(MODAL_HIDE_EVENT, event, 'publish-planning-modal');
        this.selectedWeeks = [];
        this.updateUserPlanningConfig({
          user_planning_config_params: {
            publish_send_sms: sms,
            publish_send_mail: email,
          },
        });

        if (email && emailAdditionalComment) {
          this.$skAnalytics.track('click_on_publish_planning_with_payload');
        }
        this.$skToast({
          message: this.$t('plannings.toolbar.modal.publish_planning.action.success'),
          variant: 'success',
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('plannings.toolbar.modal.publish_planning.action.error'),
          variant: 'error',
        });
      } finally {
        this.isPublishing = false;
      }
    },

    async publishOneWeek({ date, email, sms, emailAdditionalComment }) {
      const allUsers = this.users.map(user => user.id);

      await this.publishPlanning({
        shop_id: this.currentShop.id,
        date,
        selected_user_ids: allUsers,
        sms_notification: sms,
        email_notification: email,
        comment: emailAdditionalComment,
      });

      await this.$svcEvents.create(
        EVENT_SUBTYPE_ACTION.PLANNING_PUBLISH,
        {
          date,
          userIds: allUsers,
          sms,
          email,
        },
      );
    },

    handleClose() {
      this.selectedWeeks = [];
    },
  },
};
</script>

<style lang="scss" scoped>

.modal-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 53px;

  .label {
    font-weight: $fw-bold;
    display: flex;
    align-items: center;
  }
}

.publish-planning__notice {
  margin-bottom: 16px;
}

</style>
