import Vue from 'vue';
import { cloneDeep } from 'lodash';
import * as Sentry from '@sentry/vue';
import { httpClient } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import { capitalize } from '@skello-utils/formatting/strings';
import { TokenWebModel } from '@skelloapp/skello-auth-client';

const initialState = {
  currentUser: {
    id: '',
    type: 'user',
    avatarUrl: null,
    attributes: {
      firstName: '',
      lastName: '',
      birthName: '',
      email: '',
      admin: '',
      organisationName: '',
      shopId: null,
      impersonate: false,
      paymentMethod: null,
      planningZoom: null,
      showStartTime: null,
      showEndTime: null,
      showDuration: null,
      isOnboardingEmployeeFilled: true,
      activityReportAt: null,
      receivesText: false,
      receivesEmail: false,
      receivesNotificationDaily: false,
      receivesNotificationWeekly: false,
      receivesNotificationMonthly: false,
      receivesNotificationTrialsEnd: false,
      receivesDocumentNotification: false,
      notificationOptions: {
        absences: false,
        justArrived: false,
        turnover: false,
        trialEnd: false,
        overHours: false,
        activity: false,
        revenues: false,
        productivity: false,
        salaryMass: false,
        personnelRatio: false,
        hoursWorked: false,
      },
    },
    relationships: {
      teams: {
        data: [],
      },
      memberships: [],
    },
  },
  originalCurrentUserData: {
    id: '',
    type: 'user',
    avatarUrl: null,
    attributes: {
      firstName: '',
      lastName: '',
      email: '',
      admin: '',
      organisationName: '',
      shopId: null,
      impersonate: false,
      planningZoom: null,
    },
    relationships: {
      teams: {
        data: [],
      },
    },
  },
  currentUserUserLicenses: [],
  organisationLicenses: [],
  visibleOrganisationLicenses: [],
  error: null,
  loading: false,
};

const matchRelationshipToUsers = (user, included) => {
  const highestLicense = associationMatcherItem(user, included, { key: 'highestLicense', type: 'license' });
  const shop = associationMatcherItem(user, included, { key: 'shop', type: 'shop' });
  Object.assign(user.relationships, { shop, highestLicense });

  return user;
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  setCurrentUserAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.currentUser.attributes[attribute] = payload[attribute];
    });
  },

  squashOriginalCurrentUser(state) {
    state.originalCurrentUserData = cloneDeep(state.currentUser);
  },

  squashChangesToCurrentUser(state) {
    state.currentUser = cloneDeep(state.originalCurrentUserData);
  },

  currentUserSuccess(state, payload) {
    const user = matchRelationshipToUsers(payload.data, payload.included);

    Vue.set(state, 'currentUser', user);

    Sentry.setUser({
      id: state.currentUser.id,
      email: state.currentUser.attributes.email,
      // eslint-disable-next-line no-use-before-define
      username: getters.fullName({ currentUser: state.currentUser }),
    });
  },

  currentUserError(state, error) {
    state.error = error;
  },

  currentUserAccessSuccess(state, payload) {
    state.currentUserUserLicenses = payload.user_licenses.data;
    state.organisationLicenses = payload.organisation_licenses.data.filter(license => (
      license.attributes.active
    ));
    state.visibleOrganisationLicenses = payload.organisation_licenses.data;
  },

  currentUserLicensesError(state, error) {
    state.error = error;
  },

  setShiftDisplayTemplate(state, payload) {
    state.currentUser.attributes.showStartTime = payload.attributes.showStartTime;
    state.currentUser.attributes.showEndTime = payload.attributes.showEndTime;
    state.currentUser.attributes.showDuration = payload.attributes.showDuration;
  },

  setAvatarUrl(state, params) {
    Vue.set(state.currentUser, 'avatarUrl', params.url);
  },

  performingFetchCurrentUserMembershipsRequest(state) {
    state.loading = true;
  },

  performingFetchCurrentUserMembershipsComplete(state) {
    state.loading = false;
  },

  fetchCurrentUserMembershipsSuccess(state, payload) {
    Vue.set(state.currentUser.relationships, 'memberships', payload.data);

    // Clean error flag (in case of previous fetch error)
    state.error = false;
  },

  catchFetchCurrentUserMembershipsError(state, error) {
    state.error = error;
  },
};

const actions = {
  fetchCurrentUser({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest');

      httpClient
        .get('/v3/api/current_user')
        .then(response => {
          commit('currentUserSuccess', response.data);
          dispatch('fetchCurrentUserAvatar');
          commit('squashOriginalCurrentUser');
          resolve(response);
        })
        .catch(error => {
          commit('currentUserError', error);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },

  fetchCurrentUserAccess({ commit }) {
    commit('performingRequest');

    return httpClient
      .get('/v3/api/current_user/user_licenses')
      .then(response => {
        commit('currentUserAccessSuccess', response.data);
      })
      .catch(error => {
        commit('currentUserLicensesError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },
  updateCurrentUser({ commit }, params) {
    commit('performingRequest');

    return httpClient
      .put('/v3/api/current_user', params)
      .then(response => {
        commit('currentUserSuccess', response.data);
        commit('squashOriginalCurrentUser');
        return response;
      })
      .catch(error => {
        commit('currentUserError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  /* FIXME: that's a step toward migrating to the api version,
  ** in order to take a shorcut in migrating from erb views to Vue ones
  ** we're using the old endpoint for now and will migrate to the new one
  ** as soon as it is implemented.
  */
  updateCurrentUserProfile({ commit }, params) {
    commit('performingRequest');

    return httpClient
      .patch('/employee/profile_update.json', params)
      .then(response => {
        commit('setCurrentUserAttributes', response.data.data.attributes);
        commit('squashOriginalCurrentUser');
        return response;
      })
      .catch(error => {
        commit('currentUserError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  fetchCurrentUserAvatar({ commit, state }) {
    return httpClient
      .get('/v3/api/avatars', { params: { user_ids: [state.currentUser.id] } })
      .then(response => {
        const userAvatar =
          response.data.find(avatar => avatar.user_id === parseInt(state.currentUser.id, 10));
        commit('setAvatarUrl', { url: userAvatar?.url });
      });
  },

  authWithJWT({ state }, query = {}) {
    /* # TODO: [Clean Jwts Auth] */
    let authWithJwtUrl = '/v3/api/current_user/retrieve_jwt_tokens';
    if (!!query.token && !!query.purpose) {
      const token = encodeURIComponent(query.token);
      const purpose = encodeURIComponent(query.purpose);

      authWithJwtUrl = `${authWithJwtUrl}?token=${token}&purpose=${purpose}`;
    }
    return httpClient
      .get(authWithJwtUrl)
      .then(response => {
        authClient.setAuthToken(new TokenWebModel({
          token: response.data.jwt_token,
          refreshToken: response.data.jwt_refresh_token,
          user: state.currentUser,
          tokenData: { impersonating: false },
        }));

        return response;
      });
  },

  fetchCurrentUserMemberships({ commit }, params) {
    commit('performingFetchCurrentUserMembershipsRequest');

    return httpClient
      .get('/v3/api/memberships')
      .then(response => {
        commit('fetchCurrentUserMembershipsSuccess', response.data);
      })
      .catch(error => {
        commit('catchFetchCurrentUserMembershipsError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('performingFetchCurrentUserMembershipsComplete');
      });
  },
};

const getters = {
  initials: ({ currentUser }) => `${(currentUser.attributes.firstName.trim()[0].toUpperCase())}${currentUser.attributes.lastName.trim()[0].toUpperCase()}`,
  fullName: ({ currentUser }) => `${capitalize(currentUser.attributes.firstName.trim())} ${capitalize(currentUser.attributes.lastName.trim())}`,
  impersonate: ({ currentUser }) => (
    currentUser.attributes.impersonate || authClient.authToken.tokenData.impersonating
  ),
  // TODO remove this getter when v2 will end
  planningZoom: ({ currentUser }) => {
    const planningZoom = currentUser.attributes.planningZoom;
    if (planningZoom <= 20) return 25;
    if (planningZoom === 30) return 50;
    if (planningZoom === 40) return 75;
    if (planningZoom === 50) return 100;
    if (planningZoom === 60) return 125;
    if (planningZoom === 70) return 150;
    if (planningZoom >= 80) return 175;
    return 100;
  },
  // eslint-disable-next-line max-len
  isSystemAdmin: ({ currentUserUserLicenses }) => currentUserUserLicenses[0]?.attributes.position === 0,

  currentUserInPlanningShopIds: ({ currentUser }) => {
    if (!currentUser.relationships.memberships) return [];

    return currentUser.relationships.memberships
      .filter(membership => membership.attributes.inPlanning)
      .map(membership => parseInt(membership.attributes.shopId, 10));
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
