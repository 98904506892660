<template>
  <SkModal
    id="manageShiftModal"
    ref="manageShiftModal"
    :modal-title="modalTitle"
    :modal-subtitle="date.format('dddd Do MMMM YYYY')"
    :header-color="headerColor"
    :class="coloredModalClass"
    :has-nested-modal="hasNestedModal"
    size="ds-medium"
    @cancel.prevent="handleClose"
    @close.prevent="handleClose"
    @hidden="handleHide"
    @show="handleShow"
  >
    <template #body>
      <SkModalSection
        v-if="!isPostesView"
        border-bottom="none"
        class="manage-shift-modal__navigation"
      >
        <SkNav
          :style="navStyle"
          :class="navClass"
        >
          <SkNavItem
            :active="isWorkShiftSelected"
            data-test="shift-modal__tab-shift"
            @click="selectTab('workShifts')"
          >
            {{ $t('plannings.table.manage_shift_modal.tabs.shift.title') }}
          </SkNavItem>
          <SkNavItem
            v-tooltip="absenceTabTooltip"
            :active="!isWorkShiftSelected"
            :disabled="areAllAbsencesDisabled"
            data-test="shift-modal__tab-absence"
            @click="selectTab('absenceShift')"
          >
            {{ $t('plannings.table.manage_shift_modal.tabs.absence.title') }}
          </SkNavItem>
        </SkNav>
      </SkModalSection>
      <div
        v-if="modalLoading"
        class="manage-shift-modal__spinner"
      >
        <SkLoader size="large" />
      </div>
      <template v-else>
        <GlobalEvents
          v-if="!modalHidden && !modalLoading && !isAnotherModalOpen"
          @keydown.enter="handleSubmit"
        />
        <WorkShiftsTab
          v-if="isWorkShiftSelected"
          :date="date"
          :day-cell-shifts="dayCellShifts"
          :row-item="rowItem"
          :possible-selection-dates="possibleSelectionDays"
          :availabilities="availabilities"
          :is-update-modal="isUpdateModal"
          @work-shift-form-mounted="copyOriginalShift"
          @handle-submit="handleSubmit"
          @update-modal-state="handleAnotherModalOpening"
        />
        <AbsenceTab
          v-else
          :date="date"
          :row-item="rowItem"
          :possible-selection-dates="possibleSelectionDays"
          :is-update-modal="isUpdateModal"
          class="manage-shift-modal__absence-tab"
          @absence-tab-mounted="copyOriginalShift"
        />
      </template>
    </template>
    <template #footer>
      <div class="manage-shift-modal__footer-buttons">
        <div class="manage-shift-modal__footer-delete-button">
          <SkOroraButton
            v-if="isUpdateModal && currentLicense.attributes.canCreateShifts"
            v-track="'click_on_shift_destroy_in_modal_week'"
            :loading="shiftsDestroyLoading"
            :disabled="modalLoading"
            variant="secondary"
            :variant-color="$skColors.skError"
            icon="TrashCanV2Icon"
            data-test="shift-modal__delete"
            @click="handleDestroy"
            @keydown.native.enter.stop=""
          >
            {{ $t('actions.delete') }}
          </SkOroraButton>
        </div>
        <SkOroraButton
          variant="secondary"
          data-test="shift-modal__cancel"
          @click="handleClose"
          @keydown.native.enter.stop=""
        >
          {{ $t('actions.cancel') }}
        </SkOroraButton>
        <div
          v-tooltip="submitTooltip"
          class="sk-button__submit__wrapper"
        >
          <SkOroraButton
            :loading="loadingSubmit"
            :disabled="hasShiftError || modalLoading"
            data-test="shift-modal__submit"
            @click="handleSubmit"
            @keydown.native.enter.stop=""
          >
            {{ isUpdateModal ? $t('actions.update') : $t('actions.create') }}
          </SkOroraButton>
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';
import { displayDelightedSurvey } from '@skello-utils/delighted_survey.js';
import {
  getValidShiftTimes,
  openingAndClosingTimeAt,
  sanitizeShift,
} from '@app-js/plannings/shared/utils/planning_helpers';
import { ABSENCE_TYPE_HOURS } from '@app-js/shared/constants/shift';

import cloneDeep from 'lodash/cloneDeep';
import isEqualWith from 'lodash/isEqualWith';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

import GlobalEvents from 'vue-global-events';
import { prepareDocumentsCreateAndUpload } from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/ManageShiftModal/utils/documents';
import { ERROR_TYPES } from '@skello-store/modules/plannings/shifts-documents';
import WorkShiftsTab from './WorkShiftsTab';
import AbsenceTab from './AbsenceTab';

export default {
  name: 'ManageShiftModal',
  components: { WorkShiftsTab, AbsenceTab, GlobalEvents },
  props: {
    hasNestedModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTabName: 'workShifts',
      rowItem: null, // Either planning line user (employees view) or poste (postes view)
      date: skDate(),
      dayCellShifts: [],
      possibleSelectionDays: [],
      loadingSubmit: false,
      modalInitializing: true,
      modalHidden: true,
      isAnotherModalOpen: false,
      availabilities: [],
      originalShift: null,
      options: {
        ERROR_TYPES,
      },
    };
  },

  computed: {
    ...mapState('planningsState', ['shiftSelectedDays', 'absenceSidePanelOpeningOrigin', 'shopPlanningConfig']),
    ...mapState('planningsPostes', [
      'postes',
      'absences',
      'lastUserPoste',
      'postesLoading',
      'absencesLoading',
      'lastUserPosteLoading',
    ]),
    ...mapState('config', ['config']),
    ...mapState('planningsShifts', [
      'absenceShift',
      'workShifts',
      'shiftsDestroyLoading',
      'isMultipleWorkShiftsActive',
      'shiftUserIds',
    ]),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsBulkEdit', ['selectedCells']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('planningsShifts', ['unassignedShifts']),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('planningsState', [
      'visibleDays',
      'monthlyVisibleWeeks',
      'isMonthlyView',
      'monday',
      'sunday',
      'isDailyView',
      'isPostesView',
    ]),
    ...mapGetters('currentUser', ['impersonate']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('shiftsDocuments', ['uploadDocumentsQueue']),
    ...mapGetters('planningsBulkEdit', ['selectedCellsCount']),

    isAttachDocumentsEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_ATTACH_DOCUMENTS');
    },
    isUnassignEnabledOnShop() {
      return (this.shopPlanningConfig?.attributes?.allowUnassignedShifts &&
        this.checkPackOfferFlag('unassigned_shifts_enabled')) ||
        (this.unassignedShifts?.length > 0);
    },
    modalTitle() {
      if (!this.rowItem) return '';
      if (this.isPostesView) return this.rowItem.attributes.name;
      const action = this.isUpdateModal ? 'update' : 'add';
      const assignedShift = this.isUnassignedShift ? 'unassigned' : 'assigned';
      const shiftType = this.isWorkShiftSelected ? 'worked_shift' : 'absence_shift';
      const userParams = this.isUnassignedShift ? {} : { name: this.fullName(this.rowItem) };

      if (!this.isUpdateModal && this.isWorkShiftSelected) {
        return this.$tc(
          `plannings.table.manage_shift_modal.header.title.add.${assignedShift}.worked_shift`,
          !this.isMultipleWorkShiftsActive,
          userParams,
        );
      }

      return this.$t(`plannings.table.manage_shift_modal.header.title.${action}.${assignedShift}.${shiftType}`, userParams);
    },
    isWorkShiftSelected() {
      return this.selectedTabName === 'workShifts';
    },
    isUpdateModal() {
      return !!(this.absenceShift.id || this.mainWorkShift.id);
    },
    areAllAbsencesDisabled() {
      return !this.absences.some(absence => absence.attributes.active);
    },
    submitTooltip() {
      if (!this.hasShiftError) return '';

      if (this.shifts.some(shift => shift.errors.duration)) {
        return this.$t('plannings.table.manage_shift_modal.errors.no_negative_duration_shift');
      }

      if (this.shifts.some(shift => shift.errors.durationOverADay)) {
        return this.$t('plannings.table.manage_shift_modal.errors.no_duration_over_a_day');
      }

      if (this.shifts.some(shift => shift.errors.customHoursDuration)) {
        return this.$t('plannings.table.manage_shift_modal.errors.invalid_custom_duration');
      }

      return '';
    },
    absenceTabTooltip() {
      if (!this.areAllAbsencesDisabled) return '';
      return this.$t('plannings.table.manage_shift_modal.errors.no_absences_availables');
    },
    modalLoading() {
      return this.modalInitializing || this.postesLoading || this.absencesLoading ||
        this.lastUserPosteLoading;
    },
    shifts() {
      const shifts = [];
      const mainShiftWithoutUser = this.isWorkShiftSelected ?
        this.mainWorkShift :
        this.absenceShift;
      this.shiftUserIds.main.forEach(userId => {
        shifts.push(this.assignUserToShift(cloneDeep(mainShiftWithoutUser), userId));
      });
      if (this.isWorkShiftSelected && this.isMultipleWorkShiftsActive) {
        this.shiftUserIds.secondary.forEach(userId => {
          shifts.push(this.assignUserToShift(cloneDeep(this.workShifts.secondary), userId));
        });
      }
      return shifts;
    },
    mainWorkShift() {
      return this.workShifts.main;
    },
    hasShiftError() {
      return this.shifts.some(shift => Object.values(shift.errors).some(error => error));
    },
    navStyle() {
      if (!this.headerColor) return {};
      return { background: this.headerColor };
    },
    navClass() {
      return {
        'manage-shift-modal__nav--colored': this.headerColor,
        'manage-shift-modal__nav': true,
        'manage-shift-modal__nav--white-background': !this.headerColor,
      };
    },
    coloredModalClass() {
      return {
        'manage-shift-modal--colored': this.headerColor,
        'manage-shift-modal--sidepanel-opened': this.absenceSidePanelOpeningOrigin === 'shift-modal',
        'manage-shift-modal--postes-view': this.isPostesView,
      };
    },
    headerColor() {
      // modal header for creating shifts is always white
      if (!this.isUpdateModal) return '';
      // modal header for absences is Primary Grey ($sk-grey-50)
      if (this.selectedTabName === 'absenceShift') return '#727272';
      // modal header when editing work shift is poste color
      return this.mainWorkShift.relationships.poste.attributes.color;
    },
  },
  created() {
    this.defaultShiftsValues();
    this.listenOnRoot('manageShiftModal', (event, props) => {
      this.resetModal();
      this.rowItem = props.rowItem;
      this.date = props.date;
      this.dayCellShifts = props.dayCellShifts || [];
      this.isUnassignedShift = props.isUnassignedShift;
      this.availabilities = props.availabilities;

      if (this.isMonthlyView) {
        this.possibleSelectionDays = this.monthlyVisibleWeeks[skDate(props.date).startOf('isoWeek').format('YYYY-MM-DD')].days;
      } else {
        this.possibleSelectionDays = this.visibleDays;
      }

      this.initDaySelectionArray({
        currentDayIndex: this.possibleSelectionDays.findIndex(d => d.date === this.date.format('YYYY-MM-DD')),
        visibleDays: this.possibleSelectionDays,
      });

      this.modalInitializing = true;
      this.toggleIsMultipleWorkShiftsActive(false);

      if (props.shift) { // update modal
        this.setSelectedShiftValues({ ...props.shift });
        // updates an absence
        if (props.shift.relationships.poste.attributes.absenceKey) {
          this.selectedTabName = 'absenceShift';
          this.setShiftUserIds({ userIds: [props.shift.attributes.userId], type: 'main' });
        } else { // updates a work shift
          this.selectedTabName = 'workShifts';
          this.setShiftUserIds({ userIds: [props.shift.attributes.userId], type: 'main' });
        }
      } else if (!this.isPostesView || !this.rowItem.attributes.absenceKey) { // create modal
        this.selectedTabName = 'workShifts';
        const userIds = this.isPostesView ? [] : [this.rowItem.id];
        this.setShiftUserIds({ userIds, type: 'main' });
        this.setShiftUserIds({ userIds, type: 'secondary' });
      } else {
        this.setShiftUserIds({ userIds: [], type: 'main' });
        this.selectedTabName = 'absenceShift';
      }

      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'manageShiftModal');

      // Todo those requests are made everytime by the planning to delete
      const queries = [];
      if (!this.isPostesView && !this.isUnassignedShift && !props.shift) {
        queries.push(
          this.fetchlastUserPoste({ shopId: this.currentShop.id, userId: this.rowItem.id }),
        );
      }
      if (this.currentShop.attributes.mealCompensationDone &&
        this.currentShop.attributes.isMealRuleBenefitInKind) {
        queries.push(this.fetchMealRule(this.currentShop.id));
      }

      Promise.all(queries)
        .then(() => { // after fetch populate shift with poste relationships needed
          if (props.shift) { // updates shift
            // updates an absence
            if (props.shift.relationships.poste.attributes.absenceKey) {
              // set default values for the work shift tab
              const params = {
                date: this.date,
                currentShop: this.currentShop,
                currentUser: this.currentUser,
                postes: this.postes,
              };
              if (!this.isPostesView) {
                params.lastUserPoste = this.lastUserPoste;
              }
              this.setDefaultWorkShiftsValues(params);
              // set same id as absence tab
              this.mainWorkShift.id = this.absenceShift.id;
            } else { // updates a work shift
              // set default values for the absence tab
              this.setDefaultAbsenceShiftValues({
                date: this.date,
                currentShop: this.currentShop,
                config: this.config,
                absences: this.absences,
              });
              // set same id as work shift tab
              this.absenceShift.id = this.mainWorkShift.id;
            }
          } else { // creates a new shift
            const params = {
              date: this.date,
              startsAt: props.startsAt,
              endsAt: props.endsAt,
              currentShop: this.currentShop,
              currentUser: this.currentUser,
              postes: this.postes,
            };
            if (!this.isPostesView) {
              params.lastUserShift = props.lastUserShift;
              params.lastUserPoste = this.lastUserPoste;
            }
            // set default values for the work shift tab
            this.setDefaultWorkShiftsValues(params);
            // set default values for the absence tab
            this.setDefaultAbsenceShiftValues({
              date: this.date,
              currentShop: this.currentShop,
              config: this.config,
              absences: this.absences,
            });
          }
          this.modalInitializing = false;
        });
    });
  },
  methods: {
    ...mapActions('planningsShifts', ['createShift', 'updateShift', 'destroyShift']),
    ...mapActions('planningsPostes', ['fetchPostes', 'fetchAbsences', 'fetchlastUserPoste']),
    ...mapActions('mealRule', ['fetchMealRule']),
    ...mapActions('shiftsDocuments', ['fetchDocuments', 'createDocuments', 'uploadDocuments', 'deleteDocuments']),
    ...mapMutations('planningsState', ['initDaySelectionArray', 'resetDaySelection', 'setManageShiftModalOpen']),
    ...mapMutations('planningsShifts', [
      'setSelectedShiftValues',
      'setDefaultWorkShiftsValues',
      'setDefaultAbsenceShiftValues',
      'defaultShiftsValues',
      'toggleIsMultipleWorkShiftsActive',
      'setShiftUserIds',
    ]),
    ...mapMutations('planningsPostes', ['resetLastUserPoste']),
    ...mapMutations('shiftsDocuments', ['resetDocuments']),

    async selectTab(tabName) {
      //  Special case : prevent absence tab selection when no active absence available
      if (this.areAllAbsencesDisabled && tabName === 'absenceShift') return;
      //  Regular case
      this.selectedTabName = tabName;

      if (this.isAttachDocumentsEnabled) {
        this.resetDocuments();
        if (tabName === 'workShifts') {
          try {
            await this.fetchDocuments({
              shiftId: this.mainWorkShift.id,
              shopId: this.currentShop.id,
            });
          } catch {
            this.displayDocumentError(this.options.ERROR_TYPES.DOCUMENT_LOAD);
          }
        }
      }
    },
    assignUserToShift(shift, userId) {
      shift.attributes.userId = userId;
      return shift;
    },
    handleHide() {
      this.modalHidden = true;
      this.setManageShiftModalOpen(false);
    },
    async handleShow() {
      this.modalHidden = false;
      this.setManageShiftModalOpen(true);
      if (this.isAttachDocumentsEnabled) {
        this.resetDocuments();
        if (this.isUpdateModal) {
          try {
            await this.fetchDocuments({
              shiftId: this.mainWorkShift.id,
              shopId: this.currentShop.id,
            });
          } catch {
            this.displayDocumentError(this.options.ERROR_TYPES.DOCUMENT_LOAD);
          }
        }
      }
    },
    async handleDestroy(event) {
      const shiftId = this.isWorkShiftSelected ? this.mainWorkShift.id : this.absenceShift.id;
      try {
        await this.destroyShift({
          shiftId,
          shopId: this.currentShop.id,
          periodStartsAt: this.isMonthlyView ? skDate(this.date).startOf('isoWeek').format('YYYY-MM-DD') : this.monday,
          periodEndsAt: this.isMonthlyView ? skDate(this.date).endOf('isoWeek').format('YYYY-MM-DD') : this.sunday,
        });
        this.closeModal();
        this.$skToast({
          message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.destroy.success'),
          variant: 'success',
        });
      } catch (error) {
        this.showGenericErrorToast();
        throw error;
      }
    },
    handleSubmit(event) {
      if (this.loadingSubmit) return;

      // If user ids are empty -> push one null to array to create an unassigned shift
      if (this.shiftUserIds.main.length === 0) {
        this.setShiftUserIds({ userIds: [null], type: 'main' });
      }
      if (
        this.isWorkShiftSelected &&
        this.isMultipleWorkShiftsActive &&
        this.shiftUserIds.secondary.length === 0
      ) {
        this.setShiftUserIds({ userIds: [null], type: 'secondary' });
      }

      const shifts = this.getShiftsForSelectedDays();

      const shiftParams = {
        shopId: this.currentShop.id,
        shifts,
        periodStartsAt: this.isMonthlyView ? skDate(this.date).startOf('isoWeek').format('YYYY-MM-DD') : this.monday,
        periodEndsAt: this.isMonthlyView ? skDate(this.date).endOf('isoWeek').format('YYYY-MM-DD') : this.sunday,
      };
      this.loadingSubmit = true;
      let nbSelectedDays = shifts.length;
      if (this.isMultipleWorkShiftsActive) {
        nbSelectedDays /= 2;
      }
      if (nbSelectedDays > 1) {
        this.$skAnalytics.track(`create_repeated_shift_${nbSelectedDays - 1}`);
      }

      if (this.isUpdateModal) {
        this.handleUpdate(event, shiftParams);
        const tracker = this.isPostesView ? 'shift_edited_poste' : 'shift_edited';
        this.$skAnalytics.track(tracker);
      } else {
        this.handleCreate(event, shiftParams);
        this.$skAnalytics.track(this.getTrackingEvent());
      }
    },
    getTrackingEvent() {
      if (!this.isWorkShiftSelected && this.isUnassignedShift) {
        return 'absence_unassigned_shift_created';
      }

      if (this.isUnassignedShift) {
        return this.isPostesView ? 'unassigned_shift_created_poste' : 'unassigned_shift_created';
      }

      return this.isPostesView ? 'shift_created_poste' : 'shift_created';
    },
    handleAnotherModalOpening(isOpen) {
      this.isAnotherModalOpen = isOpen;
    },
    async handleCreate(_event, shiftParams) {
      try {
        this.loadingSubmit = true;

        if (this.selectedCellsCount > 1) {
          await this.handleCreateOnMultipleCells(shiftParams);
        } else {
          await this.handleCreateOnSingleCell(shiftParams);
        }

        this.closeModal();
        this.showPlanningCesSurvey();
      } catch (error) {
        this.showGenericErrorToast();
        throw error;
      } finally {
        this.loadingSubmit = false;
      }
    },
    async handleCreateOnMultipleCells(shiftParams) {
      const selectedCellsParams = this.selectedCellsParams();
      // We duplicate shifts to create on each selected cell
      shiftParams.shifts = shiftParams.shifts.map(shift => (
        selectedCellsParams.map(({ date, userId }) => {
          const { startsAt, endsAt } = getValidShiftTimes(shift.attributes, this.currentShop, date);
          return {
            ...shift,
            attributes: { ...shift.attributes, startsAt, endsAt, userId },
          };
        })
      )).flat();

      const response = await this.createShift(shiftParams);

      if (this.isAttachDocumentsEnabled) {
        await this.handleDocumentUpload(response.data.data);
      }
    },
    async handleCreateOnSingleCell(shiftParams) {
      const response = await this.createShift(shiftParams);

      if (this.isAttachDocumentsEnabled) {
        await this.handleDocumentUpload(response.data.data);
      }
    },
    selectedCellsParams() {
      // selectedCells is an object where keys are coordinates "x,y" of selected cells in the planning grid
      return this.selectedCells
        .map(selectedCellKey => {
          const { rowItemId, date, x } = JSON.parse(selectedCellKey);

          // Skip if day is out of range or locked
          if (this.visibleDays[x].isLocked) return null;

          // Get user ID for the row (null for unassigned shifts)
          const userId = rowItemId || null;

          // Return shift parameters for this cell
          return {
            date,
            userId,
          };
        });
    },
    async handleDocumentUpload(shiftData) {
      try {
        await this.createAndUploadDocuments(shiftData);
      } catch (error) {
        if (error.config.url.includes('documents')) {
          this.handleDocumentError(error);
        } else {
          this.showGenericErrorToast();
        }
      }
    },
    handleDocumentError(error) {
      const errorType = error.config.method === 'post' ?
        this.options.ERROR_TYPES.DOCUMENT_UPLOAD :
        this.options.ERROR_TYPES.DOCUMENT_DELETE;

      this.displayDocumentError(errorType);
    },
    createAndUploadDocuments(shifts) {
      // When creating main+secondary shifts, the response is ordered like
      // [main1, secondary1, main2, secondary2, ...]. We depend on this order
      // to match the correct document with the correct shift.
      if (this.uploadDocumentsQueue.length === 0) return Promise.resolve();
      const { mainShifts, secondaryShifts } = this.separateShifts(shifts);
      const { dtos: documentCreateDtos, files } =
        prepareDocumentsCreateAndUpload(mainShifts, secondaryShifts,
          this.uploadDocumentsQueue, this.currentUser.id, this.currentShop.id);

      if (documentCreateDtos.length === 0) return Promise.resolve();
      return this.createDocuments(documentCreateDtos)
        .then(response => Promise.all(response.map((document, index) => this.uploadDocuments({
          url: document.uploadUrl,
          file: files[index % files.length],
        }))));
    },
    separateShifts(shifts) {
      const mainShifts = [];
      const secondaryShifts = [];

      if (
        this.isWorkShiftSelected &&
        this.isMultipleWorkShiftsActive &&
        this.shiftUserIds.secondary.length > 0
      ) {
        shifts.forEach((shift, index) => {
          if (index % 2 === 0) {
            mainShifts.push(shift);
          } else {
            secondaryShifts.push(shift);
          }
        });
      } else {
        mainShifts.push(...shifts);
      }

      return { mainShifts, secondaryShifts };
    },
    handleUpdate(event, shiftParams) {
      const promises = [
        this.updateShift(shiftParams),
      ];

      if (this.isAttachDocumentsEnabled) {
        promises.push(
          this.createAndUploadDocuments(shiftParams.shifts),
          this.deleteDocuments(),
        );
      }

      Promise.all(promises)
        .then(() => {
          this.$skToast({
            message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.update.success'),
            variant: 'success',
          });
        })
        .catch(error => {
          if (error.config.url.includes('documents')) {
            this.handleDocumentError(error);
          } else {
            const message = error.response?.data?.message === this.$t('plannings.table.manage_shift_modal.errors.backend') ?
              this.$t('plannings.table.manage_shift_modal.errors.no_postes') :
              this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.error');
            this.$skToast({
              message,
              variant: 'error',
            });
          }

          throw error;
        })
        .finally(() => {
          this.loadingSubmit = false;
          this.closeModal();
          this.showPlanningCesSurvey();
        });
    },
    closeModal() {
      this.handleAnotherModalOpening(false);
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'manageShiftModal');
    },
    resetModal() {
      // reset shifts value to null when modal is closed
      this.defaultShiftsValues();
      this.resetLastUserPoste();
      this.resetDaySelection();
      // make sure absences sidepanel is closed, in case it was opened on absences tab
      this.emitOnRoot('close-absences-panel');
      this.emitOnRoot('manage-shift-modal-closed');
    },
    handleClose() {
      if (!this.isShiftDirty()) {
        this.closeModal();

        return;
      }

      this.handleAnotherModalOpening(true);
      this.$root.$emit('confirm', event, {
        description: this.$t('plannings.table.manage_shift_modal.confirmation_modal.description'),
        title: this.$t(`plannings.table.manage_shift_modal.confirmation_modal.title.${this.isUpdateModal ? 'update' : 'create'}`),
        submitLabel: this.$t('plannings.table.manage_shift_modal.confirmation_modal.submit_label'),
        cancelLabel: this.$t(`plannings.table.manage_shift_modal.confirmation_modal.cancel_label.${this.isUpdateModal ? 'update' : 'create'}`),
        variant: 'danger',
        onConfirm: () => {
          this.closeModal();
        },
        onCancel: () => {
          this.handleAnotherModalOpening(false);
        },
      });
    },
    copyOriginalShift() {
      this.originalShift =
        cloneDeep(this.isWorkShiftSelected ? this.mainWorkShift : this.absenceShift);
    },
    isShiftDirty() {
      return !isEqualWith(
        omit(this.originalShift, ['errors']),
        omit(this.isWorkShiftSelected ? this.mainWorkShift : this.absenceShift, ['errors']),
        (a, b) => {
          // we want "null", "undefined" or "" to be equal
          if ((typeof a !== 'number' || typeof b !== 'number') && (isEmpty(a) || isEmpty(b))) {
            return isEmpty(a) && isEmpty(b);
          }
          // we want '2023-11-09T09:30:00.000Z' and '2023-11-09T09:30:00Z' to be equal
          if (skDate(a, skDate.ISO_8601).isValid() && skDate(b.ISO_8601).isValid()) {
            return skDate(a).isSame(skDate(b));
          }

          return undefined;
        },
      );
    },
    getShiftsForSelectedDays() {
      this.shifts.forEach(shift => this.formatShiftAttributes(shift));

      return this.computeShiftsForSelectedDaysFromBaseShift();
    },
    formatShiftAttributes(shift) {
      // Attributes need to be sanitized when we are updating an absenceShift into a workShift and vice versa
      sanitizeShift(shift);
      let startsAt;
      let endsAt;
      // if absenceCalculation is day or half day, startsAt and endsAt are shop opening and closing
      if (shift.attributes.absenceCalculation &&
        shift.attributes.absenceCalculation !== ABSENCE_TYPE_HOURS) {
        const { openingTime, closingTime } = openingAndClosingTimeAt(
          this.currentShop.attributes.openingTime,
          this.currentShop.attributes.closingTime,
          shift.attributes.startsAt,
        );
        startsAt = openingTime.format();
        endsAt = closingTime.format();
      } else {
        startsAt = shift.attributes.startsAt;
        endsAt = shift.attributes.endsAt;
      }
      shift.attributes.startsAt = startsAt;
      shift.attributes.endsAt = endsAt;
      if (this.isPostesView) {
        shift.relationships.poste = this.rowItem;
      } else {
        shift.attributes.userId = this.rowItem.id;
      }

      shift.attributes.shopId = this.currentShop.id;
    },
    computeShiftsForSelectedDaysFromBaseShift() {
      // select only days selected with date in array (date is null for the day of referenceShift)
      return this.shifts.concat(
        this.shiftSelectedDays
          .filter(selectedDay => (selectedDay.selectedStatus && selectedDay.date))
          .flatMap(selectedDay => {
            const duplicateShifts = cloneDeep(this.shifts);

            duplicateShifts.forEach(duplicateShift => {
              duplicateShift.id = null;

              const { startsAt, endsAt } =
                getValidShiftTimes(duplicateShift.attributes, this.currentShop, selectedDay.date);
              duplicateShift.attributes.startsAt = startsAt;
              duplicateShift.attributes.endsAt = endsAt;
            });

            // for each selected day, a duplicated shift is push into the shifts array to create
            return duplicateShifts;
          }),
      );
    },
    showPlanningCesSurvey() {
      if (this.impersonate || !this.isDailyView) return;

      const params = {
        email: this.currentUser.attributes.email,
        name: this.fullName(this.currentUser),
        properties: {
          id: this.currentUser.id,
        },
      };

      displayDelightedSurvey('dayViewCesKey', params);
    },
    displayDocumentError(errorType) {
      const errorMessages = {
        [this.options.ERROR_TYPES.DOCUMENT_LOAD]: this.$t(`plannings.table.manage_shift_modal.tabs.shift.documents.errors.${errorType}`),
        [this.options.ERROR_TYPES.DOCUMENT_UPLOAD]: this.$t(`plannings.table.manage_shift_modal.tabs.shift.documents.errors.${errorType}`),
        [this.options.ERROR_TYPES.DOCUMENT_DELETE]: this.$t(`plannings.table.manage_shift_modal.tabs.shift.documents.errors.${errorType}`),
      };

      this.$skToast({
        message: errorMessages[errorType] ?? errorMessages[this.options.ERROR_TYPES.INVALID],
        variant: 'error',
      });
    },
    showGenericErrorToast() {
      this.$skToast({
        message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.error'),
        variant: 'error',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// Personalised modal asked by the product
::v-deep .sk-modal {
  width: 100%;

  // Capitalize the subtitle
  .sk-modal__subtitle {
    text-transform: capitalize;
  }

  .sk-modal__header {
    height: 47px;
    width: 100%;
    border: none;
    padding: 40px 20px 20px 24px;
    border-radius: 6px 6px 0 0;

    .sk-modal__header__left {
      width: 100%;

      .sk-modal__title-container {
        width: 100%;

        h1 {
          text-overflow: ellipsis;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .manage-shift-modal__navigation {
    margin: 0;
    padding: 0 !important;
    height: 70px;
  }

  .sk-nav {
    position: fixed;
    width: 620px;
    z-index: 1;
    padding-top: 15px;
  }

  // Change font weight
  .sk-link--medium {
    font-weight: 700;
  }

  // Shrink tooltip
  .sk-popover--tooltip {
    padding: 5px 8px;
    font-size: $fs-text-s;
    text-align: center;
    max-width: 155px;

    * {
      margin: 0;
      padding: 0;
    }
  }

  // this to sk-textarea : Design point for product
  #manageShiftModal,
  .sk-select__selected-option {
    height: 44px;
  }

  .sk-modal__header .sk-modal__subtitle {
    margin-top: 6px;
  }

  footer.sk-modal__footer {
    padding: 15px 24px 24px;
  }

  .sk-textarea {
    height: 100px;
  }
}

.manage-shift-modal--sidepanel-opened {
  transform: translateX(-133px);
  transition: all .25s;
}

// asked by product
// customize close button color when modal header is colored
.manage-shift-modal--colored {
  ::v-deep .sk-modal {
    .sk-icon-button {
      background: #ffffff30;

      &:hover:not([disabled]) {
        background: $sk-grey-10;
      }
    }

    .sk-modal__subtitle,
    .sk-modal__header {
      color: white;
    }
  }
}

// Design spec: have 24px bottom padding on modal header with a separator line
.manage-shift-modal--postes-view {
  ::v-deep .sk-modal__header {
    padding-bottom: 42px;
    border-bottom: 1px solid $sk-grey-10;
  }
}

.manage-shift-modal__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}

.manage-shift-modal__nav {
  border-bottom: 1px solid $sk-grey-10;
  padding-left: 24px;
}

.manage-shift-modal__nav--white-background {
  background-color: white;
}

// asked by product
// navbar text is white when header is colored
::v-deep .manage-shift-modal__nav--colored {
  .sk-nav__item__link {
    color: #fff;
  }
}

.manage-shift-modal__footer-buttons {
  width: 100%;
  display: flex;
}

.manage-shift-modal__footer-delete-button {
  flex-grow: 2;
}

.sk-button__submit__wrapper {
  margin-left: 12px;
}
</style>
