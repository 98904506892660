import { httpClient } from '@skello-utils/clients/http_client';
import {
  matchPosteToShift, matchUserToShift,
} from '@skello-utils/association_matchers';

export default class EmployeeSpaceClient {
  constructor() {
    this.rootPrefix = '/v3/api/employees';
  }

  async fetchEmployeeShifts({ employeeId, date }) {
    const url = `${this.rootPrefix}/${employeeId}/shops/shifts`;

    const response = await httpClient.get(url, { params: { date } });
    const {
      shifts_by_shop: shiftsByShop, included, published_shops: publishedShops,
    } = response.data;

    const postes = included.reduce((result, poste) => {
      if (poste.type !== 'poste') return result;

      result[poste.id] = { id: poste.id, ...poste.attributes };
      return result;
    }, {});

    Object.keys(shiftsByShop).forEach(shopId => {
      shiftsByShop[shopId].forEach(shift => matchPosteToShift(shift, included));
    });

    return { shiftsByShop, postes, publishedShops };
  }

  async fetchShopShifts({ employeeId, shopId, date }) {
    const url = `${this.rootPrefix}/${employeeId}/shops/${shopId}/shifts`;
    const response = await httpClient.get(url, { params: { date } });
    const {
      shifts_by_user: shiftsByUser, users, included, published_users: publishedUsers,
    } = response.data;

    const reducedUsers = users.reduce((result, user) => {
      result[user.id] = { id: user.id, ...user.attributes };
      return result;
    }, {});

    Object.keys(shiftsByUser).forEach(userId => {
      shiftsByUser[userId].forEach(shift => {
        matchUserToShift(shift, included);
        matchPosteToShift(shift, included);
      });
    });

    return { shiftsByUser, users: reducedUsers, publishedUsers };
  }
}

export const employeeSpaceClient = new EmployeeSpaceClient();
