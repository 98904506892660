<template>
  <SkModalV2
    id="duplicate-workload-plan-modal"
    ref="duplicateWorkloadPlanModal"
    :icon-options="{
      color: '#000000',
      backgroundColor: 'white',
      name: 'DuplicateContractIcon',
    }"
    :title="$t('workload_plan.modals.duplicate.title')"
    @hidden="onModalClose"
    @show="onModalOpen"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div class="duplicate-workload-plan-modal__content">
          <span class="duplicate-workload-plan-modal__content__subtitle">
            {{ $t('workload_plan.modals.duplicate.subtitle') }}
          </span>
          <SelectDayToDuplicate
            v-model="dateToDuplicate"
            class="duplicate-workload-plan-modal__content__select-day"
          />
          <SelectPostes
            v-model="selectedPosteIds"
            :postes="sortedPostes"
            :disabled-poste-ids="posteIdsWithoutNeedsForDateToDuplicate"
            class="duplicate-workload-plan-modal__content__select-postes"
          />
          <MultipleDatePicker
            v-model="selectedDays"
            :date-to-duplicate="skDateToDuplicate.format('YYYY-MM-DD')"
            class="duplicate-workload-plan-modal__content__datepicker"
          />
        </div>
      </SkModalSectionV2>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="isButtonDisabled"
        :loading="isLoading"
        @click="handleSubmit"
      >
        {{ $t('workload_plan.modals.duplicate.action.submit') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import skDate from '@skello-utils/dates';
import {
  mapGetters,
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import { duplicateOnDays } from '@skello-utils/workload_plan/workload_plan_duplicator';
import { manageConflicts }
  from '@skello-utils/workload_plan/workload_plan_conflicts_manager';
import SelectDayToDuplicate from './SelectDayToDuplicate';
import MultipleDatePicker from './MultipleDatePicker';
import SelectPostes from './SelectPostes';

const SELECT_DATE_FORMAT = 'dddd Do MMMM YYYY';

export default {
  name: 'DuplicateWorkloadPlanModal',
  components: {
    SelectDayToDuplicate,
    MultipleDatePicker,
    SelectPostes,
  },
  data() {
    return {
      localDateToDuplicate: null,
      isLoading: false,
      selectedDays: [],
      selectedPosteIds: [],
      workloadPlanForDateToDuplicate: null,
    };
  },
  computed: {
    ...mapState('planningsPostes', ['postes']),
    ...mapState('planningsWorkloadPlans', ['isDuplicateModalOpen']),
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('currentShop', ['currentShopOpeningAndClosingTime']),
    ...mapGetters('planningsWorkloadPlans', ['fetchParamsByDay']),

    dateToDuplicate: {
      get() {
        return this.localDateToDuplicate;
      },

      async set(value) {
        this.localDateToDuplicate = value;
        await this.fetchWorkloadPlan();

        if (!this.postes?.length) {
          return;
        }

        this.selectedPosteIds = [];
      },
    },

    sortedPostes() {
      return [...this.postes].sort((posteA, posteB) => (
        posteA.attributes.name.toLowerCase().localeCompare(posteB.attributes.name.toLowerCase())
      ));
    },
    isButtonDisabled() {
      return !(
        this.dateToDuplicate &&
        this.selectedPosteIds.length > 0 &&
        this.selectedDays.length > 0 &&
        !this.isLoading
      );
    },
    skDateToDuplicate() {
      return skDate.utc(this.dateToDuplicate, SELECT_DATE_FORMAT);
    },
    sortedSelectedDays() {
      return [...this.selectedDays]
        .sort((dayA, dayB) => skDate(dayA).valueOf() - skDate(dayB).valueOf());
    },
    posteIdsWithoutNeedsForDateToDuplicate() {
      if (!this.workloadPlanForDateToDuplicate) return [];
      return Object.keys(this.workloadPlanForDateToDuplicate).filter(
        posteId => {
          const needsForPoste = this.workloadPlanForDateToDuplicate[posteId];
          return !needsForPoste.some(({ value }) => value > 0);
        },
      );
    },
    everyPosteIds() {
      return this.postes.map(({ id }) => id);
    },
    workloadPlanForDateToDuplicateForSelectedPostes() {
      const workloadPlan = { ...this.workloadPlanForDateToDuplicate };

      this.everyPosteIds.forEach(posteId => {
        if (!this.selectedPosteIds.includes(posteId)) delete workloadPlan[posteId];
      });
      return workloadPlan;
    },
  },
  watch: {
    currentDate(newValue) {
      this.dateToDuplicate = this.formatDateForSelect(newValue);
    },
    selectedDays(newValue) {
      if (newValue === null) {
        this.selectedDays = [];
      }
    },
  },
  created() {
    this.dateToDuplicate = this.formatDateForSelect(this.currentDate);
  },
  methods: {
    ...mapActions('planningsWorkloadPlans', ['fetchWorkloadPlans', 'upsertWorkloadPlans']),
    ...mapMutations('planningsWorkloadPlans', [
      'setIsDuplicateModalOpen',
      'setWorkloadPlanConflicts',
      'setWorkloadPlanToSave',
      'setPeriod',
    ]),

    async fetchWorkloadPlan() {
      if (!this.isDuplicateModalOpen) return;

      this.workloadPlanForDateToDuplicate =
        await this.fetchWorkloadPlansForDayToDuplicate(this.everyPosteIds);
    },

    onModalOpen() {
      this.setIsDuplicateModalOpen(true);
      this.fetchWorkloadPlan();
    },
    onModalClose() {
      this.selectedDays = [];
      this.selectedPosteIds = [];
      this.setIsDuplicateModalOpen(false);
    },
    async handleSubmit() {
      if (this.isLoading) return;

      this.isLoading = true;

      try {
        await this.duplicateAndSaveWorkloadPlans();
      } catch {
        this.handleError();
      } finally {
        this.isLoading = false;
      }
    },
    async duplicateAndSaveWorkloadPlans() {
      // Generate the workload plans that the user wants to create
      const duplicatedWorkloadPlans =
        this.generateDuplicatedWorkloadPlans(this.workloadPlanForDateToDuplicateForSelectedPostes);

      // Fetch all the workload plans on the days the user wants the duplication to apply to
      const dayToDuplicateToWorkloadPlans = await this.fetchWorkloadPlansOnAllDuplicatedDays();

      const { workloadPlansToSave, conflicts } = await this.getConflicts(
        dayToDuplicateToWorkloadPlans,
        duplicatedWorkloadPlans,
      );

      // If there is no conflicts : upsert + quit modal
      if (Object.keys(conflicts).length === 0) {
        await this.upsertWorkloadPlans(workloadPlansToSave);
        this.$skAnalytics.track('added_workload_plan', { source: 'duplicated' });
        this.handleSuccess();
        return;
      }

      // In case of conflicts, redirect to the conflicts modal
      this.redirectToConflictsModal(conflicts, workloadPlansToSave);
    },
    // Fetch workload plans for dateToDuplicate
    fetchWorkloadPlansForDayToDuplicate(posteIds = this.selectedPosteIds) {
      const workloadPlanToDuplicateParams =
        this.fetchParamsByDay(
          this.skDateToDuplicate.format(),
          posteIds,
        );

      return this.fetchWorkloadPlans({ ...workloadPlanToDuplicateParams, saveToStore: false });
    },
    // Fetch workload plans for the days to duplicate to
    async fetchWorkloadPlansOnAllDuplicatedDays() {
      const workloadPlansForDaysPromises = this.selectedDays.map(date => {
        const fetchParams = this.fetchParamsByDay(date, this.selectedPosteIds);

        return this.fetchWorkloadPlans({ ...fetchParams, saveToStore: false });
      });

      const workloadPlansForDays = await Promise.all(workloadPlansForDaysPromises);

      // Merge all returned objects
      return workloadPlansForDays.reduce((acc, workloadPlansObject) => {
        Object.keys(workloadPlansObject).forEach(posteId => {
          if (!acc[posteId]) acc[posteId] = [];

          acc[posteId].push(...workloadPlansObject[posteId]);
        });

        return acc;
      }, {});
    },
    getConflicts(duplicatedWorkloadPlans, dayToDuplicatesToWorkloadPlans) {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;

      const formattedOpeningTime = openingTime.format('HH:mm');
      const formattedClosingTime = closingTime.format('HH:mm');

      return manageConflicts(
        duplicatedWorkloadPlans,
        dayToDuplicatesToWorkloadPlans,
        formattedOpeningTime,
        formattedClosingTime,
      );
    },
    generateDuplicatedWorkloadPlans(workloadPlanToDuplicate) {
      return duplicateOnDays(workloadPlanToDuplicate, this.selectedDays);
    },
    displaySuccessToast() {
      this.$skToast({
        message: this.$t('workload_plan.modals.duplicate.success'),
        variant: 'success',
      });
    },
    handleError() {
      this.$skToast({
        message: this.$t('workload_plan.modals.errors.generic'),
        variant: 'error',
      });
    },
    handleSuccess() {
      this.displaySuccessToast();
      this.refetchIfNecessary();
      this.$refs.duplicateWorkloadPlanModal.hide();
    },
    refetchIfNecessary() {
      if (this.selectedDays.includes(this.currentDate)) {
        this.fetchWorkloadPlans(
          this.fetchParamsByDay(this.currentDate, this.everyPosteIds),
        );
      }
    },
    redirectToConflictsModal(conflicts, workloadPlansToSave) {
      const numberOfDays = this.sortedSelectedDays.length;
      const start = this.sortedSelectedDays[0];
      const end = this.sortedSelectedDays[numberOfDays - 1];

      this.setPeriod({ start, end });
      this.setWorkloadPlanConflicts(conflicts);
      this.setWorkloadPlanToSave(workloadPlansToSave);

      this.$emit('redirect-to-conflicts');
    },
    formatDateForSelect(date) {
      return skDate(date).subtract(1, 'd').format(SELECT_DATE_FORMAT);
    },
  },
};
</script>

<style lang="scss" scoped>
.duplicate-workload-plan-modal {
  &__content {
    display: flex;
    flex-direction: column;
    max-height: 300px;

    &__subtitle {
      font-size: $fs-text-m;
      margin-bottom: 24px;
    }

    &__select {
      font-size: $fs-text-m;

      &-day { margin-bottom: 8px; }
      &-postes { margin-bottom: 24px; }
    }

    &__datepicker {
      border-top: 1px solid $sk-grey-10;
      padding-top: 24px;
    }
  }
}
</style>
