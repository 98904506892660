<template>
  <div
    :class="planningRowWrapperClasses"
    :style="rowStyle"
  >
    <div
      v-if="!globalConfig.isDailyView"
      :class="planningMaskClasses"
      @drop="handleDrop"
      @dragover.prevent
      @dragenter="handleDragEnter"
      @dragleave="handleDragLeave"
    />
    <SidebarCell
      :row-item="rowItem"
      :is-unassigned-shifts-row="isUnassignedShiftsRow"
      :store-infos="sidebarCellInfos"
      :contract-warning-text="contractWarningText"
      :is-mouse-inside-row="displayShiftSwapper"
      @mouseenter.native="setMouseInsideRow(true)"
      @mouseleave.native="setMouseInsideRow(false)"
    />
    <UserShiftSwapper
      v-if="!globalConfig.isDailyView"
      :is-dragged-over="isDraggedOver"
      :display-shift-swapper="displayShiftSwapper"
      :draggable="displayShiftSwapper"
      :class="planningRowClasses"
      :style="zoomRange.planningRow"
      @mouse-inside-shift-swapper="handleMouseInsideShiftSwapper"
      @dragstart.native="handleDragStart"
      @dragend.native="handleDragEnd"
    >
      <template #dayCells>
        <DayCell
          v-for="(day, index) in visibleDays"
          :key="day.date"
          :availabilities="availabilitiesForDay(availabilities, day)"
          :day="day"
          :row-item="rowItem"
          :day-cell-shifts="shiftsByDate[day.date]"
          :pending-leave-requests="pendingLeaveRequestsByDate[day.date]"
          :is-unassigned-shifts-row="isUnassignedShiftsRow"
          :global-config="globalConfig"
          :is-selected="rowSelectedDays.includes(index)"
          :max-weekly-shift-elements="maxWeeklyShiftElements"
          :multiple-selection-enabled="multipleSelectionEnabled"
        />
      </template>
    </UserShiftSwapper>
    <DailyViewRow
      v-else
      :global-config="globalConfig"
      :is-unassigned-shifts-row="isUnassignedShiftsRow"
      :row-item="rowItem"
      :availabilities="availabilities"
      :shifts="shifts"
      :visible-days="visibleDays"
    />
    <CountersCell
      :shifts="shiftsForView"
      :period-shifts="periodShifts"
      :row-item="rowItem"
      :days-worked="globalConfig.daysWorked"
      :is-unassigned-shifts-row="isUnassignedShiftsRow"
      :contract-warning-text="contractWarningText"
      class="planning-row__counters-cell"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  zoomPlanningWeek,
  zoomPlanningDay,
  zoomPlanningPoste,
} from '@app-js/plannings/shared/utils/zoom/zoom_helpers';

import { sanitizeShift } from '@app-js/plannings/shared/utils/planning_helpers';
import skDate from '@skello-utils/dates';
import SidebarCell from '@app-js/plannings/shared/PlanningTable/PlanningRow/SidebarCell';
import DayCell from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/DayCell';
import CountersCell from '@app-js/plannings/shared/components/CountersCell';
import UserShiftSwapper from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/UserShiftSwapper';
import DailyViewRow from '@app-js/plannings/pages/Days/DailyViewRow';
import { availabilitiesForDay } from '@app-js/shared/utils/availabilities_helper';
import { getDragDataFormat } from '@skello-utils/drag_and_drop';

export default {
  name: 'PlanningRow',
  components: {
    DayCell,
    DailyViewRow,
    SidebarCell,
    CountersCell,
    UserShiftSwapper,
  },
  props: {
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
    rowItem: {
      type: Object,
      required: true,
    },
    rowOrder: {
      type: Number,
      default: () => 0,
    },
    shifts: {
      type: Array,
      required: true,
    },
    visibleDays: {
      type: Array,
      required: true,
    },
    globalConfig: {
      type: Object,
      required: true,
    },
    periodShifts: {
      type: Array,
      default: () => [],
    },
    availabilities: {
      type: Array,
      default: () => [],
    },
    rowSelectedDays: {
      type: Array,
      default: () => [],
    },
    multipleSelectionEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMouseInsideRow: false,
      isMouseInsideShiftSwapper: false,
      isRowDragged: false,
      shiftSwapperScreenshot: false,
      isDraggedOver: false,
      availabilitiesForDay,
    };
  },
  computed: {
    ...mapState('planningsLoading', ['lastFetchParams']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled']),

    planningRowClasses() {
      return {
        'planning-row__days__wrapper': true,
        'planning-row__days__wrapper--disabled':
          !this.globalConfig.currentLicense.attributes.canCreateShifts &&
          !this.globalConfig.currentLicense.attributes.canEditShifts,
        'planning-row__days__wrapper--row-dragged': this.isRowDragged,
        'planning-row__days__wrapper--screenshot': this.shiftSwapperScreenshot,
      };
    },
    planningMaskClasses() {
      return {
        'planning-row__mask': true,
        'planning-row__mask--displayed': this.globalConfig.isMaskDisplayed,
      };
    },
    rowShiftsFromCurrentShop() {
      return this.shifts.filter(shift => (
        shift.attributes.shopId === parseInt(this.globalConfig.currentShop.id, 10)
      ));
    },
    displayShiftSwapper() {
      return (this.isMouseInsideRow || this.isMouseInsideShiftSwapper) &&
        !this.isRowDragged &&
        !this.globalConfig.isAnyDayLocked &&
        !this.globalConfig.isPostesView;
    },
    contractWarningText() {
      if (this.isUnassignedShiftsRow ||
        !this.globalConfig.isMulticontractsEnabled ||
        this.rowItem.attributes.onExtra) return '';

      const userActiveContractPeriod = this.rowItem.attributes.activeContractPeriod;
      const hasContractHours = this.rowItem.attributes.currentContractHours !== 0;
      const onDayRate = this.rowItem.attributes.onDayRate;

      if (userActiveContractPeriod !== 'current') {
        return this.$t(`plannings.table.contracts.tooltip.missing_contract_on_${userActiveContractPeriod}`);
      }

      if (!hasContractHours && !onDayRate) {
        return this.$t('plannings.table.contracts.tooltip.missing_hourly_wage');
      }
      return '';
    },
    planningRowWrapperClasses() {
      return {
        'planning-row__wrapper': true,
        'fade-item': this.isProgressiveLoadingEnabled,
      };
    },
    zoomRange() {
      let zoomPlanning;
      if (this.globalConfig.isDailyView) {
        zoomPlanning = zoomPlanningDay;
      } else if (this.globalConfig.isPostesView) {
        zoomPlanning = zoomPlanningPoste;
      } else {
        zoomPlanning = zoomPlanningWeek;
      }
      const { planningRow } = zoomPlanning(this.globalConfig.planningZoom);

      return { planningRow };
    },
    animationDelay() {
      return { animationDelay: `${(this.rowOrder % this.lastFetchParams.batchSize) * 0.02}s` };
    },
    rowStyle() {
      if (this.isProgressiveLoadingEnabled) {
        return {
          ...this.zoomRange.planningRow,
          ...this.animationDelay,
        };
      }
      return { ...this.zoomRange.planningRow };
    },
    shiftsForView() {
      return this.globalConfig.isDailyView ?
        this.globalConfig.dayCellShifts(this.globalConfig.currentDate, this.shifts) : this.shifts;
    },
    sidebarCellInfos() {
      return {
        // TODO: DEV-11588 rework on getters ( isEmployeesView: this.isEmployeesView )
        isEmployeesView: !this.globalConfig.isPostesView,
        userInitials: this.globalConfig.userInitials,
        currentLicense: this.globalConfig.currentLicense,
        employeeAnnualizationConfigs: this.globalConfig.employeeAnnualizationConfigs,
        isAnnualizationCurrentlyActive: this.globalConfig.isAnnualizationCurrentlyActive,
        periodTheoreticalBalanceAt: this.globalConfig.periodTheoreticalBalanceAt,
        isAnnualizedWorkingTimeAvailable: this.globalConfig.isAnnualizedWorkingTimeAvailable,
        planningZoom: this.globalConfig.planningZoom,
        getAvatarUrlForUser: this.globalConfig.getAvatarUrlForUser,
        monday: this.globalConfig.monday,
        isDailyView: this.globalConfig.isDailyView,
        currentDate: this.globalConfig.currentDate,
        areEmployeeAnnualizationConfigsLoading:
          this.globalConfig.areEmployeeAnnualizationConfigsLoading,
        currentShop: this.globalConfig.currentShop,
        shopPlanningConfig: this.globalConfig.shopPlanningConfig,
        teamSchedules: this.globalConfig.teamSchedules,
      };
    },
    // Hashmap of visible days with their shifts
    shiftsByDate() {
      return this.visibleDays.reduce((acc, { date }) => {
        acc[date] = this.globalConfig.dayCellShifts(date, this.shifts);
        return acc;
      }, {});
    },
    pendingLeaveRequestsByDate() {
      return this.visibleDays.reduce((acc, { date }) => {
        acc[date] = this.pendingLeaveRequests(date);
        return acc;
      }, {});
    },
    maxWeeklyShiftElements() {
      const dailyShiftElements = this.visibleDays.map(({ date }) => (
        this.shiftsByDate[date].length +
        this.pendingLeaveRequestsByDate[date].length
      ));

      return Math.max(...dailyShiftElements);
    },
  },
  methods: {
    ...mapActions('planningsShifts', ['updateShift']),
    ...mapMutations('planningsState', ['setMaskToRows']),
    setMouseInsideRow(value) {
      if (!this.globalConfig.currentLicense.attributes.canCreateShifts) return;
      this.isMouseInsideRow = value;
    },
    handleDragStart(event) {
      // Prevent conflicts with shift drag and drop
      if (this.globalConfig.shiftDragging || this.globalConfig.popularShiftDragging) return;

      this.shiftSwapperScreenshot = true;

      // Modify properties of the element without affecting dragged object
      // inspired by: https://stackoverflow.com/a/36379727
      setTimeout(() => {
        this.isMouseInsideRow = false;
        this.isRowDragged = true;
        this.setMaskToRows(true);

        this.shiftSwapperScreenshot = false;
      }, 1);

      event.dataTransfer.setData(
        getDragDataFormat('shifts'),
        JSON.stringify({ shifts: this.rowShiftsFromCurrentShop, userId: this.rowItem.id }),
      );
    },
    handleDragEnd() {
      this.$skAnalytics.track('week_planning_switch_line');
      this.isRowDragged = false;
      this.setMaskToRows(false);
    },
    handleDrop(event) {
      // Prevent conflicts with shift drag and drop
      if (this.globalConfig.shiftDragging || this.globalConfig.popularShiftDragging) return;

      const obj = JSON.parse(event.dataTransfer.getData(getDragDataFormat('shifts')));

      this.isDraggedOver = false;

      // Prevent drop on itself
      if (obj.userId === this.rowItem.id) return;

      const draggedUserId = parseInt(obj.userId, 10);
      const droppedOnUserId = parseInt(this.rowItem.id, 10);

      const droppedOnShifts = this.changeShiftsUserId(this.rowShiftsFromCurrentShop, draggedUserId);
      const draggedShifts = this.changeShiftsUserId(obj.shifts, droppedOnUserId);

      this.updateShift({
        shifts: draggedShifts.concat(droppedOnShifts),
        shopId: this.globalConfig.currentShop.id,
        periodStartsAt: this.globalConfig.monday,
        periodEndsAt: this.globalConfig.sunday,
        isFromDragAndDrop: true,
        isSwappingUserShifts: true,
      });
    },
    changeShiftsUserId(rowShifts, id) {
      return rowShifts.map(shift => {
        const updatedShift = cloneDeep(shift);
        this.resetBadging(updatedShift);
        sanitizeShift(updatedShift);
        updatedShift.attributes.userId = id;
        return updatedShift;
      });
    },
    resetBadging(shift) {
      shift.attributes.delay = 0;
      shift.attributes.previsionalStart = null;
      shift.attributes.previsionalEnd = null;
      shift.attributes.previsionalSaved = null;
      shift.attributes.previsionalPosteId = null;
    },
    handleDragEnter() {
      // Prevent conflicts with shift drag and drop
      if (this.globalConfig.shiftDragging || this.globalConfig.popularShiftDragging) return;

      this.isDraggedOver = true;
    },
    handleDragLeave() {
      // Prevent conflicts with shift drag and drop
      if (this.globalConfig.shiftDragging || this.globalConfig.popularShiftDragging) return;
      this.isDraggedOver = false;
    },
    handleMouseInsideShiftSwapper(value) {
      // Triggered when mouse enter and leave <UserShiftSwapper> (even if invisible)
      this.isMouseInsideShiftSwapper = value;
    },
    pendingLeaveRequests(date) {
      if (!this.globalConfig.currentLicense.attributes.canManageEmployeeRequests) {
        return [];
      }

      const dateUtc = skDate.utc(date);
      const rowItemId = parseInt(this.rowItem.id, 10);
      const dayPendingLeaveRequests =
        this.globalConfig.pendingLeaveRequestShifts.filter(pendingRequest => (
          (
            (pendingRequest.attributes.userId === rowItemId &&
            this.rowItem.type === 'user') || (pendingRequest.attributes.posteId === rowItemId &&
            this.rowItem.type === 'poste')
          ) &&
          dateUtc.isBetween(
            skDate.utc(pendingRequest.attributes.startDate),
            skDate.utc(pendingRequest.attributes.endDate),
            undefined,
            '[]',
          )
        ));

      return dayPendingLeaveRequests.map(dayPendingLeaveRequest => {
        const absenceKey = this.globalConfig.absences.find(
          absence => parseInt(absence.id, 10) === dayPendingLeaveRequest.attributes.posteId,
        )?.attributes.absenceKey;

        return {
          id: `tmp${dayPendingLeaveRequest.id}`,
          attributes: {
            userId: dayPendingLeaveRequest.attributes.userId,
            startsAt: dayPendingLeaveRequest.attributes.startDate,
            endsAt: dayPendingLeaveRequest.attributes.endDate,
            isPendingLeaveRequest: true,
            absenceCalculation: dayPendingLeaveRequest.attributes.absenceCalculation,
            shopId: dayPendingLeaveRequest.attributes.shopId,
            posteId: dayPendingLeaveRequest.attributes.posteId,
          },
          relationships: {
            poste: {
              id: dayPendingLeaveRequest.attributes.posteId,
              attributes: { absenceKey },
            },
          },
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-row__wrapper {
  display: flex;
  position: relative;

  &:has(svg.planning-row__shift-quick-select__caret-icon.caret--down) {
    z-index: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-item {
  opacity: 0;
  animation: fadeIn .3s ease-out forwards;
}

// add border only to the last CountersCell
.planning-row__last-item > .planning-row__counters-cell {
  border-bottom: solid 1px $sk-grey-10;
}

.planning-row__mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: none;
  display: none;

  &--displayed {
    display: block;
  }
}

.planning-row__days__wrapper {
  display: flex;
  width: 100%;
  min-width: 0; // allow the planning row to be smaller than its content

  &--disabled {
    background-color: rgba($sk-grey-10, .2);
    pointer-events: none;
  }

  // Shifts on dragged line are dimmed
  &--row-dragged ::v-deep .planning-row__shift-wrapper {
    opacity: .3;
  }

  // Remove or hide some elements before screenshot for drag image
  &--screenshot {

    // Hide shifts from other shops
    ::v-deep .planning-row__shift-wrapper--other-shop {
      visibility: hidden;
    }

    ::v-deep .planning-row__day-cell__wrapper {
      border-right: none; // Hide columns separators
      background: none; // Remove "today" background
    }

    // Remove any availabilty
    ::v-deep .day-cell__availabilities-corner__wrapper,
    ::v-deep .day-cell__availability-full {
      display: none;
    }
  }
}
</style>
