<template>
  <div v-if="isLoading">
    <AbsencesSectionSkeleton />
    <OverHoursSectionSkeleton
      v-if="isOverHoursFlagActive"
    />
    <ComplementaryHoursSectionSkeleton
      v-if="isOverHoursFlagActive"
    />
  </div>
  <div v-else>
    <div class="annualization-details__section annualization-details__section--bordered">
      <div class="annualization-details__section--title">
        {{ $t('shop_settings.tabs.details.annualization.balance_section.title') }}
      </div>
      <div class="annualization-details__row">
        <span class="annualization-details__label">
          {{ $t('shop_settings.tabs.details.annualization.balance_section.absences.label') }}
          <CircledQuestionMarkIcon
            v-tooltip.right="$t('shop_settings.tabs.details.annualization.balance_section.absences.tooltip')"
            fill="black"
            width="16"
            height="16"
          />
        </span>
        <span class="annualization-details__single-input">
          <SkSelectV2
            v-model="localIncludedAbsenceIds"
            v-track="'annualization_absences_param_clicked'"
            :disabled-options="canEditShopRulesAndAbsences ? [] : localAbsenceOptionIds"
            :label="$t('shop_settings.tabs.details.annualization.balance_section.absences.select.label')"
            :no-results-label="$t('search_bar.no_result')"
            :empty-option-display="$t('shop_settings.tabs.details.annualization.balance_section.absences.select.no_active_absence')"
            :options="localAbsenceOptions"
            :testing-options="{
              cancel: 'annualization_absences_param_cancelled',
              submit: 'annualization_absences_param_validated',
            }"
            append-to-body
            multi
            width="500px"
          >
            <template #selected-option="{ value }">
              {{ $tc('shop_settings.tabs.details.annualization.balance_section.absences.select.values', value.length) }}
            </template>
          </SkSelectV2>
        </span>
      </div>
      <div class="annualization-details__row">
        <span class="annualization-details__label">
          {{ $t('shop_settings.tabs.details.annualization.balance_section.excluded_days.label') }}
          <CircledQuestionMarkIcon
            v-tooltip.right="$t('shop_settings.tabs.details.annualization.balance_section.excluded_days.tooltip')"
            fill="black"
            width="16"
            height="16"
          />
        </span>
        <span class="annualization-details__switch">
          <SkSwitch v-model="areWeekdaysIncluded" />
        </span>
      </div>
    </div>
    <OverHoursSection
      v-if="isOverHoursFlagActive"
      :over-hours-slices="localOverHoursSlices"
      :complementary-hours-slices="localComplementarySlices"
      :local-are-overhours-activated="localAreOverhoursActivated"
      @update-overhours-switch="(isActive) => localAreOverhoursActivated = isActive"
    />
    <EnhancedStickyBar
      v-show="isDirty"
      :is-disabled="!canEditShopRulesAndAbsences"
      :is-submitting="isSubmitting"
      :cancel-label="$t('shop_settings.tabs.details.annualization.update.cancel')"
      :submit-label="$t('shop_settings.tabs.details.annualization.update.submit')"
      @cancel="handleCancel"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import { getPeriodDatesAt } from '@skelloapp/skello-annualization';

import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import { cloneDeep } from 'lodash';
import { WEEK_DAYS_INDICES } from '@app-js/shared/constants/dates';

import { ABSENCE_POSTE_FIELDS } from '@app-js/shared/constants/annualization';

import EnhancedStickyBar from '@app-js/shared/components/EnhancedStickyBar';

import AbsencesSectionSkeleton from './AbsencesSectionSkeleton';
import ComplementaryHoursSectionSkeleton from './ComplementaryHoursSectionSkeleton';
import OverHoursSectionSkeleton from './OverHoursSectionSkeleton';
import OverHoursSection from './OverHoursSection';

export default {
  name: 'AnnualizationCounterDetails',
  components: {
    AbsencesSectionSkeleton,
    OverHoursSectionSkeleton,
    OverHoursSection,
    ComplementaryHoursSectionSkeleton,
    EnhancedStickyBar,
  },
  data() {
    return {
      baseAbsences: [],
      baseIncludedAbsences: [],
      isSubmitting: false,
      isLoading: true,
      localComplementarySlices: [],
      localExcludedDays: [],
      localIncludedAbsenceIds: [],
      localOverHoursSlices: [],
      localAreOverhoursActivated: null,
    };
  },
  computed: {
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences']),
    ...mapGetters('currentShop', ['isDevFlagEnabled', 'checkFeatureFlag']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopAnnualizationConfig', ['shopAnnualizationConfig']),
    isDirty() {
      return (
        this.isAbsenceSectionUpdated ||
        this.isExcludedDaysSectionUpdated ||
        this.isOverHoursSectionUpdated
      );
    },
    isAbsenceSectionUpdated() {
      return JSON.stringify(this.localIncludedAbsenceIds) !==
        JSON.stringify(this.baseIncludedAbsenceIds);
    },
    isExcludedDaysSectionUpdated() {
      return this.localExcludedDays.length !==
        this.shopAnnualizationConfig.attributes.excludedDays.length;
    },
    isOverHoursSectionUpdated() {
      const {
        attributes: {
          overhoursActivated,
          overHoursSlices,
          complementarySlices,
        },
      } = this.shopAnnualizationConfig;

      return this.localAreOverhoursActivated !== overhoursActivated ||
        JSON.stringify(this.localOverHoursSlices) !== JSON.stringify(overHoursSlices) ||
        JSON.stringify(this.localComplementarySlices) !== JSON.stringify(complementarySlices);
    },
    localAbsenceOptions() {
      return this.baseAbsences.map(absence => ({
        id: absence.id,
        text: absence.attributes.name,
      }));
    },
    localAbsenceOptionIds() {
      return this.localAbsenceOptions.map(({ id }) => id);
    },
    baseIncludedAbsenceIds() {
      return this.baseIncludedAbsences.map(({ id }) => id);
    },
    baseExcludedAbsenceIds() {
      return this.baseAbsences
        .filter(({ id }) => !this.baseIncludedAbsenceIds.includes(id))
        .map(({ id }) => id);
    },
    isOverHoursFlagActive() {
      return this.checkFeatureFlag('FEATURE_ANNUALIZED_WORKING_TIME_V2_OVERHOURS');
    },
    currentlyModifiedPeriodDates() {
      const now = skDate.utc();
      const initDate = skDate.utc(this.shopAnnualizationConfig.attributes.initializationDate);
      const referenceDate = skDate.max([now, initDate]);

      const period = getPeriodDatesAt(
        referenceDate, new Date(this.shopAnnualizationConfig.attributes.resetDate),
      );
      const startDate = skDate.utc(period.startDate);
      const endDate = skDate.utc(period.endDate);

      return `${startDate.format('L')} - ${endDate.format('L')}`;
    },
    areWeekdaysIncluded: {
      get() {
        // only true when sunday is not excluded
        return !this.localExcludedDays.includes(WEEK_DAYS_INDICES.sunday);
      },
      set(isActivating) {
        if (isActivating) {
          const sundayIndex = this.localExcludedDays.indexOf(WEEK_DAYS_INDICES.sunday);
          this.localExcludedDays.splice(sundayIndex, 1);
        } else {
          this.localExcludedDays.push(WEEK_DAYS_INDICES.sunday);
        }
      },
    },
  },
  async mounted() {
    this.isLoading = true;

    const promises = [this.fetchActiveAbsences()];

    // config is fetched by AnnualizationCounter component when navigating "normally"
    // but it's not fetched yet when accessing the page directly through URL
    if (!this.shopAnnualizationConfig) {
      promises.push(this.fetchShopAnnualizationConfig({
        shopId: this.currentShop.id,
      }));
    }
    await Promise.all(promises);
    this.initOverhoursActivation();
    this.initSlices();
    this.initExcludedDays();

    this.initAbsences();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('shopAnnualizationConfig', ['fetchShopAnnualizationConfig', 'updateShopAnnualizationConfig']),
    initAbsences() {
      const { balanceExcludedAbsenceKeys } = this.shopAnnualizationConfig.attributes;
      this.baseIncludedAbsences = this.baseAbsences.filter(({ attributes }) => (
        !balanceExcludedAbsenceKeys.includes(attributes.absenceKey)
      ));

      this.localIncludedAbsenceIds = this.baseIncludedAbsences.map(({ id }) => id);
    },
    initSlices() {
      this.localOverHoursSlices =
        cloneDeep(this.shopAnnualizationConfig.attributes.overHoursSlices);
      this.localComplementarySlices =
        cloneDeep(this.shopAnnualizationConfig.attributes.complementarySlices);
    },
    initOverhoursActivation() {
      this.localAreOverhoursActivated = this.shopAnnualizationConfig.attributes.overhoursActivated;
    },
    initExcludedDays() {
      this.localExcludedDays = [...this.shopAnnualizationConfig.attributes.excludedDays];
    },
    async fetchActiveAbsences() {
      try {
        const response = await httpClient.get('/v3/api/postes', {
          params: {
            fields: [...ABSENCE_POSTE_FIELDS, 'absence_type', 'active', 'name', 'shop_id'],
            shop_id: this.currentShop.id,
          },
        });

        this.baseAbsences = response.data.data
          .filter(({ attributes: { active, absenceType } }) => active && absenceType)
          .sort(({ attributes: one }, { attributes: two }) => one.name.localeCompare(two.name));
      } catch (error) {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });

        throw error;
      }
    },
    handleCancel(event) {
      this.$root.$emit('confirm', event, {
        title: this.$t('shop_settings.tabs.details.annualization.confirm.cancel.title'),
        description: this.$t('shop_settings.tabs.details.annualization.confirm.cancel.description'),
        cancelLabel: this.$t('shop_settings.tabs.details.annualization.confirm.cancel.answer_no'),
        submitLabel: this.$t('shop_settings.tabs.details.annualization.confirm.cancel.answer_yes'),
        onConfirm: this.cancelModifications,
      });
    },
    handleSubmit(event) {
      this.$root.$emit('confirm', event, {
        title: this.$t('shop_settings.tabs.details.annualization.confirm.submit.title'),
        description: this.$t('shop_settings.tabs.details.annualization.confirm.submit.description', { period: this.currentlyModifiedPeriodDates }),
        cancelLabel: this.$t('shop_settings.tabs.details.annualization.confirm.submit.answer_no'),
        submitLabel: this.$t('shop_settings.tabs.details.annualization.confirm.submit.answer_yes'),
        onConfirm: this.updateConfig,
      });
    },
    async updateConfig() {
      this.isSubmitting = true;

      // formatting empty values to 0
      this.localOverHoursSlices = this.localOverHoursSlices.map(slice => slice.map(value => (value === '' ? 0 : value)));
      this.localComplementarySlices = this.localComplementarySlices.map(slice => slice.map(value => (value === '' ? 0 : value)));

      const updatedIncludedAbsenceIds = this.baseExcludedAbsenceIds.filter(
        id => this.localIncludedAbsenceIds.includes(id),
      );

      const updatedExcludedAbsenceIds = this.baseIncludedAbsenceIds.filter(
        id => !this.localIncludedAbsenceIds.includes(id),
      );

      const updatedIncludedAbsence = this.baseAbsences
        .filter(({ id }) => updatedIncludedAbsenceIds.includes(id))
        .map(({ attributes: { absenceKey } }) => absenceKey);

      const updatedExcludedAbsence = this.baseAbsences
        .filter(({ id }) => updatedExcludedAbsenceIds.includes(id))
        .map(({ attributes: { absenceKey } }) => absenceKey);

      const { balanceExcludedAbsenceKeys } = this.shopAnnualizationConfig.attributes;

      const updatedAbsences = balanceExcludedAbsenceKeys
        .filter(absenceKey => !updatedIncludedAbsence.includes(absenceKey))
        .concat(updatedExcludedAbsence);

      const params = {
        shop_id: this.currentShop.id,
        annualization_config: {
          balance_excluded_absence_keys: updatedAbsences,
          excluded_days: this.localExcludedDays,
          complementary_slices: this.localComplementarySlices,
          over_hours_slices: this.localOverHoursSlices,
          overhours_activated: this.localAreOverhoursActivated,
        },
      };

      if (this.localComplementarySlices.length >= 2) {
        if (this.localComplementarySlices[1][0] < this.localComplementarySlices[0][0]) {
          this.$skToast({
            message: this.$t('shop_settings.tabs.details.annualization.over_hours_section.complementary_hours.error'),
            variant: 'error',
          });
          this.isSubmitting = false;
          throw new Error('Complementary slices are not valid');
        }
      }

      this.$skAnalytics.track('annualization_advanced_settings_edited');

      try {
        await this.updateShopAnnualizationConfig(params);
        this.initAbsences();

        this.$skToast({
          message: this.$t('shop_settings.tabs.details.annualization.success'),
          variant: 'success',
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('alert_messages.default'),
          variant: 'error',
        });

        throw error;
      } finally {
        this.isSubmitting = false;
        this.initSlices();
        this.initOverhoursActivation();
      }
    },
    cancelModifications() {
      this.initExcludedDays();
      this.initSlices();
      this.initOverhoursActivation();
      this.localIncludedAbsenceIds = [...this.baseIncludedAbsenceIds];
    },
  },
};
</script>

<style lang="scss" scoped>
.annualization-details__section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  &--bordered {
    border-bottom: 1px solid $sk-grey-10;
  }

  &--title {
    color: $sk-black;
    font-size: $fs-heading-xs;
    font-weight: $fw-semi-bold;
  }

  &--subtitle {
    color: $sk-black;
    font-size: $fs-text-m;
    font-weight: $fw-semi-bold;
  }
}

.annualization-details__row {
  display: flex;
  align-items: center;
  gap: 24px;
}

.annualization-details__label {
  display: flex;
  align-items: center;
  width: 360px;
  gap: 16px;
}

.annualization-details__cta {
  display: flex;
  justify-content: flex-end;
}

.annualization-details__single-input {
  width: 500px;
}
</style>
