<template>
  <div class="team-schedule--wrapper">
    <LoadingPage
      v-if="isDataLoading"
      class="loader"
    />

    <Table
      v-else
      :shifts-owners="users"
      :grouped-shifts="shiftsByUser"
      :published-owners="publishedUsers"
      :global-config="globalConfig"
    />
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions,
} from 'vuex';
import { employeeSpaceClient } from '@skello-utils/clients';
import Table from '@app-js/employees/shared/components/Table';
import LoadingPage from '@app-js/plannings/shared/components/LoadingPage';

export default {
  name: 'TeamSchedulePlanning',
  components: { Table, LoadingPage },
  data() {
    return {
      isDataLoading: false,
      shiftsByUser: {},
      users: {},
      publishedUsers: [],
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentLicense', ['canReadEmployeeInfo']),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('shiftsDocuments', ['getDocumentsCountByShiftId']),

    globalConfig() {
      return {
        currentShop: this.currentShop,
        planningZoom: this.planningZoom,
        getDocumentsCountByShiftId: this.getDocumentsCountByShiftId,
      };
    },
  },
  watch: {
    $route() {
      this.initializeData();
    },
  },
  created() {
    this.initializeData();
  },
  methods: {
    ...mapActions('employees', ['fetchAvatars']),
    ...mapActions('currentShop', ['updateCurrentShop']),
    async initializeData() {
      this.isDataLoading = true;

      try {
        await Promise.all([
          this.updateCurrentShop({ shopId: this.$route.params.shop_id }),
          this.fetchPlanningData(),
        ]);
      } catch (error) {
        this.$emit('error-happened', { error });
      }

      this.isDataLoading = false;
    },
    async fetchPlanningData() {
      const {
        users, shiftsByUser, publishedUsers,
      } = await employeeSpaceClient.fetchShopShifts({
        employeeId: this.currentUser.id,
        shopId: this.$route.params.shop_id,
        date: this.currentDate,
      });

      if (this.canReadEmployeeInfo) {
        await this.fetchAvatars({ user_ids: Object.keys(users) });
      }

      this.shiftsByUser = shiftsByUser;
      this.users = users;
      this.publishedUsers = publishedUsers;
    },
  },
};
</script>

<style lang="scss" scoped>
.team-schedule--wrapper {
  height: 100%;
  display: flex;
  overflow: hidden;
}
</style>
