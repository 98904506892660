<template>
  <span>
    <slot />
    <span
      v-for="(icon, index) in iconList"
      :key="index"
      class="icon-wrapper"
    >
      <SkCircleButton
        :icon="icon.icon"
        :active="selected(icon.name)"
        size="small"
        background-color="transparent"
        @click="handleChange(icon.name)"
      />
    </span>
  </span>
</template>

<script>
import clone from 'lodash/clone';
import { SkCircleButton } from '@skelloapp/skello-ui';

const FOOD_ICONS = [
  {
    icon: 'CutleryIcon',
    name: 'em-fork_and_knife',
  },
  {
    icon: 'SoupIcon',
    name: 'em-poultry_leg',
  },
  {
    icon: 'RiceBowlIcon',
    name: 'em-rice_ball',
  },
  {
    icon: 'BurgerIcon',
    name: 'em-hamburger',
  },
  {
    icon: 'PizzaIcon',
    name: 'em-pizza',
  },
  {
    icon: 'FishIcon',
    name: 'em-fried_shrimp',
  },
  {
    icon: 'FrenchFriesIcon',
    name: 'em-fries',
  },
  {
    icon: 'PastaIcon',
    name: 'em-spaghetti',
  },
  {
    icon: 'CookieIcon',
    name: 'em-cookie',
  },
  {
    icon: 'BreadIcon',
    name: 'em-apple',
  },
  {
    icon: 'MuffinIcon',
    name: 'em-sushi',
  },
  {
    icon: 'CoffeeIcon',
    name: 'em-curry',
  },
  {
    icon: 'CocktailIcon',
    name: 'em-icecream',
  },
  {
    icon: 'CheeseIcon',
    name: 'em-watermelon',
  },
  {
    icon: 'BeerIcon',
    name: 'em-herb',
  },
  {
    icon: 'WineGlassIcon',
    name: 'em-wine_glass',
  },
];

export default {
  name: 'IconSelect',
  components: {
    SkCircleButton,
  },
  props: {
    value: {
      type: String,
      default: 'em-fork_and_knife',
    },
    iconList: {
      type: Array,
      default: () => FOOD_ICONS,
    },
  },
  data() {
    return {
      localValue: this.value || 'em-fork_and_knife',
    };
  },
  watch: {
    value(newValue) {
      if (newValue !== this.localValue) {
        this.localValue = clone(newValue);
      }
    },
  },
  created() {
    if (!this.value) {
      this.$emit('input', 'em-fork_and_knife');
    }
  },
  methods: {
    handleChange(newValue) {
      this.localValue = newValue;
      this.$emit('input', this.localValue);
    },
    selected(icon) {
      return this.localValue === icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: inline-block;
}

:deep(.sk-circle-button) {
  width: 28px !important;
  height: 28px !important;
  border-radius: 14px !important;

  &.selected {
    background-color: $sk-blue-10 !important;
    color: $sk-azure-blue !important;
  }
}
</style>
