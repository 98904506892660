import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';
import { associationMatcherCollection } from '@skello-utils/association_matchers';
import { MEAL_RULES_DEFAULT } from '@app-js/shared/constants/meal_rules_defaults';
import { httpClient } from '@skello-utils/clients';

const defaultMealAdditionTriggerAttributes = ({ order, step }) => ({
  id: uuidv4(),
  type: 'mealAdditionTriggers',
  attributes: {
    order,
    triggerFor: step,
    mealCount: 1,
    workDurationMin: 0,
    workDurationMax: 5,
    rangeStartsAtHour: 11,
    rangeStartsAtMin: 30,
    rangeEndsAtHour: 14,
    rangeEndsAtMin: 30,
  },
}
);

const initialState = {
  mealRule: {},
  originalData: {},
  error: false,
  loading: false,
  defaultData: {
    type: 'mealRule',
    attributes: {
      calculationMethod: 'benefit_in_kind',
      owedMealsCalculationMethod: 'days_worked',
      averageMealsOwedPerDay: 1,
      averageMealsOwedPerMonth: 44,
      mealEmoji: 'em-fork_and_knife',
      triggerType: {
        owed_meals: 'work_duration',
        taken_meals: 'work_duration',
      },
      sameRulesOwedAndTaken: true,
    },
    relationships: {
      mealAdditionTriggers: [
        defaultMealAdditionTriggerAttributes({
          order: 1,
          step: 'owed_meals',
        }),
        defaultMealAdditionTriggerAttributes({
          order: 1,
          step: 'taken_meals',
        }),
      ],
    },
  },
};

const workDurationValue = (triggerType, value, hours) => {
  if (triggerType === hours) return null;

  return value;
};

const rangeValue = (triggerType, value, workDuration) => {
  if (triggerType === workDuration) return null;

  return value;
};

const triggerParams = (mealRule, trigger, config) => {
  const triggerType = mealRule.attributes.triggerType[trigger.triggerFor];
  const hours = config.meal_rules.trigger_types.hours;
  const workDuration = config.meal_rules.trigger_types.work_duration;

  return {
    order: trigger.order,
    trigger_for: trigger.triggerFor,
    trigger_type: triggerType,
    meal_count: trigger.mealCount,
    work_duration_min: workDurationValue(triggerType, trigger.workDurationMin, hours),
    work_duration_max: workDurationValue(triggerType, trigger.workDurationMax, hours),
    range_starts_at_hour: rangeValue(triggerType, trigger.rangeStartsAtHour, workDuration),
    range_starts_at_min: rangeValue(triggerType, trigger.rangeStartsAtMin, workDuration),
    range_ends_at_hour: rangeValue(triggerType, trigger.rangeEndsAtHour, workDuration),
    range_ends_at_min: rangeValue(triggerType, trigger.rangeEndsAtMin, workDuration),
  };
};

const mealAdditionTriggersParams = (mealRule, config) => {
  const mealAdditionTriggers = mealRule.relationships.mealAdditionTriggers;

  let owedMealsAdditionTriggersParams = [];
  const { hours_worked: hoursWorked } = config.meal_rules.owed_meals_calculation_methods;
  const { benefit_in_kind: benefitInKind } = config.meal_rules.calculation_methods;

  // eslint-disable-next-line
  if (mealRule.attributes.owedMealsCalculationMethod === hoursWorked) {
    owedMealsAdditionTriggersParams = mealAdditionTriggers.filter(
      trigger => trigger.attributes.triggerFor === config.meal_rules.meal_steps.owed_meals,
    ).map(
      trigger => triggerParams(mealRule, trigger.attributes, config),
    );
  }

  if (mealRule.attributes.calculationMethod !== benefitInKind) {
    return owedMealsAdditionTriggersParams;
  }

  let takenMealAdditionTriggersParams = [];
  // eslint-disable-next-line
  if (mealRule.attributes.owedMealsCalculationMethod === hoursWorked && mealRule.attributes.sameRulesOwedAndTaken) {
    takenMealAdditionTriggersParams = cloneDeep(owedMealsAdditionTriggersParams);
    takenMealAdditionTriggersParams.forEach(takenMealAdditionTriggerParams => {
      takenMealAdditionTriggerParams.trigger_for = config.meal_rules.meal_steps.taken_meals;
    });
  } else {
    takenMealAdditionTriggersParams = mealAdditionTriggers.filter(
      trigger => trigger.attributes.triggerFor === config.meal_rules.meal_steps.taken_meals,
    ).map(
      trigger => triggerParams(mealRule, trigger.attributes, config),
    );
  }

  return owedMealsAdditionTriggersParams.concat(takenMealAdditionTriggersParams);
};

const mealRulesParams = (mealRule, config) => {
  const owedMealsCalculationMethod = mealRule.attributes.owedMealsCalculationMethod;
  const {
    monthly_average: monthlyAverage,
    days_worked: daysWorked,
  } = config.meal_rules.owed_meals_calculation_methods;

  let averageMealsOwedPerMonth = null;
  if (owedMealsCalculationMethod === monthlyAverage) {
    averageMealsOwedPerMonth = mealRule.attributes.averageMealsOwedPerMonth;
  }

  let averageMealsOwedPerDay = null;
  if (owedMealsCalculationMethod === daysWorked) {
    averageMealsOwedPerDay = mealRule.attributes.averageMealsOwedPerDay;
  }

  return {
    meal_rule: {
      calculation_method: mealRule.attributes.calculationMethod,
      owed_meals_calculation_method: owedMealsCalculationMethod,
      average_meals_owed_per_month: averageMealsOwedPerMonth,
      average_meals_owed_per_day: averageMealsOwedPerDay,
      meal_emoji: mealRule.attributes.mealEmoji,
    },
    meal_addition_triggers: {
      triggers: mealAdditionTriggersParams(mealRule, config),
    },
  };
};

const matchMealAdditionTriggersToMealRule = (mealRule, included) => {
  const mealAdditionTriggers = associationMatcherCollection(mealRule, included, {
    key: 'mealAdditionTriggers',
    type: 'mealAdditionTriggers',
  });
  Object.assign(mealRule.relationships, { mealAdditionTriggers });
  return mealRule;
};

const success = (state, payload) => {
  state.mealRule = payload.data;
  matchMealAdditionTriggersToMealRule(state.mealRule, payload.included);
  state.originalData = cloneDeep(state.mealRule);
};

const fillWithDefaultValues = (state, config) => {
  const owedMeals = config.meal_rules.meal_steps.owed_meals;
  const takenMeals = config.meal_rules.meal_steps.taken_meals;

  if (Object.keys(state.mealRule).length === 0) {
    // fill with default data if meal rule is empty
    state.mealRule = state.defaultData;
  } else {
    // fill with default values all attributes which are null
    Object.keys(state.mealRule.attributes).forEach(key => {
      if (state.mealRule.attributes[key] === null) {
        state.mealRule.attributes[key] = state.defaultData.attributes[key];
      }
    });
  }
  // fill the trigger type for owed and taken meals if null
  if (state.mealRule.attributes.triggerType.owed_meals === null) {
    // eslint-disable-next-line
    state.mealRule.attributes.triggerType.owed_meals = state.defaultData.attributes.triggerType.owed_meals;
  }
  if (state.mealRule.attributes.triggerType.taken_meals === null) {
    // eslint-disable-next-line
    state.mealRule.attributes.triggerType.taken_meals = state.defaultData.attributes.triggerType.taken_meals;
  }

  // add at least one mealAdditionTrigger if no exists
  const owedMealAdditionTriggers = state.mealRule.relationships.mealAdditionTriggers.filter(
    mealAdditionTrigger => mealAdditionTrigger.attributes.triggerFor === owedMeals,
  );

  if (owedMealAdditionTriggers.length === 0) {
    state.mealRule.relationships.mealAdditionTriggers.push(
      defaultMealAdditionTriggerAttributes({ order: 1, step: owedMeals }),
    );
  } else {
    owedMealAdditionTriggers.forEach(mealAdditionTrigger => {
      Object.keys(mealAdditionTrigger.attributes).forEach(key => {
        if (mealAdditionTrigger.attributes[key] === null) {
          // eslint-disable-next-line
          mealAdditionTrigger.attributes[key] = state.defaultData.relationships.mealAdditionTriggers[0].attributes[key];
        }
      });
    });
  }

  const takenMealAdditionTriggers = state.mealRule.relationships.mealAdditionTriggers.filter(
    mealAdditionTrigger => mealAdditionTrigger.attributes.triggerFor === takenMeals,
  );

  if (takenMealAdditionTriggers.length === 0) {
    state.mealRule.relationships.mealAdditionTriggers.push(
      defaultMealAdditionTriggerAttributes({ order: 1, step: takenMeals }),
    );
  } else {
    takenMealAdditionTriggers.forEach(mealAdditionTrigger => {
      Object.keys(mealAdditionTrigger.attributes).forEach(key => {
        if (mealAdditionTrigger.attributes[key] === null) {
          // eslint-disable-next-line
          mealAdditionTrigger.attributes[key] = state.defaultData.relationships.mealAdditionTriggers[0].attributes[key];
        }
      });
    });
  }
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },
  requestComplete(state) {
    state.loading = false;
  },
  catchMealRuleError(state, error) {
    state.error = error;
  },
  initializeMealRuleWithDefaultValues(state) {
    state.mealRule = cloneDeep(state.defaultData);
  },
  resetMealRulesWithOriginalValues(state) {
    state.mealRule = cloneDeep(state.originalData);
  },
  fetchMealRuleSuccess(state, payload) {
    success(state, payload);
  },
  fillFetchedMealRuleWithDefaultValues(state, config) {
    fillWithDefaultValues(state, config);
  },
  updateMealRuleSuccess(state, { payload }) {
    success(state, payload);
  },
  setDefaultAttributes(state, currentShop) {
    const { sector } = currentShop.relationships.convention.attributes;

    state.defaultData.attributes.calculationMethod = MEAL_RULES_DEFAULT[sector].calculation_method;
    state.defaultData.attributes.owedMealsCalculationMethod =
      MEAL_RULES_DEFAULT[sector].owed_meals_calculation_method;
  },
  createMealRuleSuccess(state, payload) {
    const shop = payload.data.included.find(included => included.type === 'shop');

    // keep current_shop and meal_rule synchronized
    payload.rootState.currentShop.currentShop.attributes = shop.attributes;

    success(state, payload.data);
  },
  destroyMealRulesSuccess(state) {
    state.mealRule = state.defaultData;
  },
  deleteMealAdditionTriggerById(state, { idToDelete }) {
    const mealAdditionTriggerToDelete = state.mealRule.relationships.mealAdditionTriggers.find(
      mealAdditionTrigger => mealAdditionTrigger.id.toString() === idToDelete,
    );

    const id = state.mealRule.relationships.mealAdditionTriggers
      .indexOf(mealAdditionTriggerToDelete);

    state.mealRule.relationships.mealAdditionTriggers.splice(id, 1);
  },
  addDefaultMealAdditionTrigger(state, { order, step }) {
    state.mealRule.relationships.mealAdditionTriggers.push(
      defaultMealAdditionTriggerAttributes({ order, step }),
    );
  },
  deleteMealAdditionTriggerForTakenMeals(state, config) {
    const owedMealsStep = config.meal_rules.meal_steps.owed_meals;
    const takenMealsStep = config.meal_rules.meal_steps.taken_meals;

    const owedMealAdditionTriggers = state.mealRule.relationships.mealAdditionTriggers.filter(
      // eslint-disable-next-line
      mealAdditionTrigger => mealAdditionTrigger.attributes.triggerFor === owedMealsStep,
    );

    state.mealRule.relationships.mealAdditionTriggers = owedMealAdditionTriggers;
    state.mealRule.relationships.mealAdditionTriggers.push(
      defaultMealAdditionTriggerAttributes({
        order: 1,
        step: takenMealsStep,
      }),
    );
  },
};

const actions = {
  fetchMealRule({ commit }, shopId) {
    commit('performingRequest');

    return httpClient
      .get(`/v3/api/shops/${shopId}/meal_rule`)
      .then(response => {
        commit('fetchMealRuleSuccess', response.data);

        return response.data;
      })
      .catch(error => {
        commit('catchMealRuleError', error.response);
        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },
  updateMealRule({ commit, rootState }, { shopId, mealRule }) {
    const params = mealRulesParams(mealRule, rootState.config.config);

    return httpClient
      .patch(`/v3/api/shops/${shopId}/meal_rule`, params)
      .then(response => {
        commit('updateMealRuleSuccess', { payload: response.data });
        return response;
      })
      .catch(error => {
        commit('catchMealRuleError', error.response);
        throw error;
      });
  },
  createMealRule({ commit, rootState }, { shopId, mealRule }) {
    const params = mealRulesParams(mealRule, rootState.config.config);

    return httpClient
      .post(`/v3/api/shops/${shopId}/meal_rule`, params)
      .then(response => {
        commit('createMealRuleSuccess', { data: response.data, rootState });
        commit('currentShop/squashOriginalCurrentShopData', null, { root: true });
      })
      .catch(error => {
        commit('catchMealRuleError', error.response);
        throw error;
      });
  },
  destroyMealRules({ commit }, shopId) {
    return httpClient
      .delete(`/v3/api/shops/${shopId}/meal_rule`)
      .then(response => {
        commit('destroyMealRulesSuccess');
      })
      .catch(error => {
        commit('catchMealRuleError', error.response);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
