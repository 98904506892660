<template>
  <ModalBase
    ref="publishPlanningModalWeekly"
    v-bind="modalOptions"
    @show="handleShow"
    @close="handleClose"
    @submit="handleSubmit"
  >
    <template #content>
      <div
        v-if="isWeekAlreadyPublished"
        :class="infoClasses"
      >
        <div class="icon">
          <CircledIIcon
            fill="#2b66fe"
            width="24"
            height="24"
          />
        </div>
        <div class="text">
          {{ $t('plannings.toolbar.modal.publish_planning.info') }}
        </div>
      </div>
      <div
        v-if="isSvcCommunicationsV2Enabled"
        class="publish-planning__notice"
      >
        {{ $t('plannings.toolbar.modal.publish_planning.notice') }}
      </div>
      <div class="modal-row">
        <span class="label">
          {{ $t('plannings.toolbar.modal.publish_planning.select.publish_to.label') }}
          <CircledQuestionMarkIcon
            v-tooltip.bottom="$t('plannings.toolbar.modal.publish_planning.select.publish_to.tooltip')"
            class="icon"
            width="18"
            height="18"
            color="#727272"
          />
        </span>

        <UsersSelector v-model="selectedUserIds" />
      </div>
      <div class="modal-row last-published-users">
        <div v-if="isWeekAlreadyPublished">
          {{ lastPublishedInfoText }}

          <div v-tooltip="publishedUsersTooltip">
            {{ publishedUsersTextInfo }}
          </div>
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';

import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import skDate from '@skello-utils/dates';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

import ModalBase from './ModalBase';
import UsersSelector from './UsersSelector';

export default {
  name: 'ModalWeekly',
  components: {
    ModalBase,
    UsersSelector,
  },
  data() {
    return {
      selectedUserIds: [],
      isPublishing: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', [
      'currentDate',
      'monday',
      'sunday',
    ]),
    ...mapGetters('employees', ['fullName']),
    ...mapState('planningsState', [
      'weeklyOptions',
      'weeklyOptionsLoading',
      'userPlanningConfig',
      'userPlanningConfigLoading',
    ]),
    ...mapState('planningsUsers', ['users']),
    ...mapState('shopTeams', ['teams']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('planningsLoading', ['planningDataStatus']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isLoadingCompleted', 'isFirstBatchLoaded']),
    isSvcCommunicationsV2Enabled() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_SVC_COMMUNICATIONS_V2');
    },
    infoClasses() {
      return {
        info: true,
        'info--extended': this.isSvcCommunicationsV2Enabled,
      };
    },
    isPlanningDataLoading() {
      if (this.isProgressiveLoadingEnabled) {
        return !this.isLoadingCompleted || this.weeklyOptionsLoading;
      }
      return this.weeklyOptionsLoading;
    },
    isSubmitButtonDisabled() {
      const isUserListEmpty = this.selectedUserIds.length === 0;

      if (this.isProgressiveLoadingEnabled) {
        return !this.isLoadingCompleted || isUserListEmpty;
      }
      return isUserListEmpty;
    },
    modalOptions() {
      return {
        subtitle: this.$t('plannings.toolbar.modal.publish_planning.header.subtitle', {
          first_day: skDate(this.monday).format('DD MMMM'),
          last_day: skDate(this.sunday).format('DD MMMM YYYY'),
          week: skDate(this.currentDate).isoWeek(),
        }),
        isLoading: this.isPlanningDataLoading,
        isButtonDisabled: this.isSubmitButtonDisabled,
        isPublishing: this.isPublishing,
      };
    },

    isWeekAlreadyPublished() {
      return this.publishedUsers.length > 0;
    },
    lastPublisher() {
      const { lastPublisher } = this.weeklyOptions.attributes;
      return lastPublisher ? this.fullName(lastPublisher) : '';
    },
    publishedUsers() {
      return this.weeklyOptions
        .attributes
        .publishedUserIds
        .map(publishedUserId => {
          const user = this.users.find(
            planningUser => publishedUserId === parseInt(planningUser.id, 10),
          );
          return (user) ? this.fullName(user) : null;
        })
        .filter(Boolean);
    },
    lastPublishedInfoText() {
      const lastPublishedDate = skDate(this.weeklyOptions.attributes.lastPublishedDate);

      return this.$t(
        'plannings.toolbar.modal.publish_planning.select.last_published_info_text',
        {
          date: lastPublishedDate.format('DD/MM/YY'),
          time: lastPublishedDate.format('HH:mm'),
          publisher: this.lastPublisher,
        },
      );
    },
    publishedUsersTextInfo() {
      return this.$t(
        'plannings.toolbar.modal.publish_planning.select.published_users',
        {
          user_list: this.publishedUsers.join(', '),
        },
      );
    },
    publishedUsersTooltip() {
      return this.publishedUsers.join('<br>');
    },
  },
  watch: {
    planningDataStatus(newValue, oldValue) {
      if (
        this.isProgressiveLoadingEnabled &&
        newValue !== oldValue &&
        newValue === PLANNING_DATA_STATUS.ALL_LOADED
      ) {
        this.setInitialSelectedUserIds();
      }
    },
  },
  methods: {
    ...mapActions('planningsState', ['fetchWeeklyOptions', 'publishPlanning', 'updateUserPlanningConfig']),
    ...mapActions('planningsShifts', ['resetUpdatedUserIds']),
    handleShow() {
      this.fetchWeeklyOptions(this.currentShop.id)
        .then(() => {
          this.setInitialSelectedUserIds();
        });

      if (this.isProgressiveLoadingEnabled && this.isFirstBatchLoaded) {
        this.$root.$emit('need-all-planning-data');
      }
    },

    setInitialSelectedUserIds() {
      if (this.isWeekAlreadyPublished) {
        this.selectedUserIds = this.users.reduce((userIdsAcc, user) => {
          if (user.id &&
        this.weeklyOptions.attributes.employeesWithChange.includes(parseInt(user.id, 10))) {
            return [...userIdsAcc, user.id];
          }
          return userIdsAcc;
        }, []);
      } else {
        this.selectedUserIds = this.users.map(user => user.id);
      }
    },

    async handleSubmit({ email, sms, emailAdditionalComment }) {
      this.isPublishing = true;

      this.$skAnalytics.track('click_on_publish_planning_with_payload', {
        selected_user_ids: this.selectedUserIds,
        sms_notification: sms,
        email_notification: email,
        email_additional_comment: emailAdditionalComment,
      });

      try {
        await this.publishPlanning({
          shop_id: this.currentShop.id,
          date: this.monday,
          selected_user_ids: this.selectedUserIds,
          sms_notification: sms,
          email_notification: email,
          comment: emailAdditionalComment,
        });

        await this.$svcEvents.create(
          EVENT_SUBTYPE_ACTION.PLANNING_PUBLISH,
          {
            sms,
            email,
            date: this.monday,
            userIds: this.selectedUserIds,
          },
        );
        this.resetUpdatedUserIds();
        this.fetchWeeklyOptions(this.currentShop.id);
        this.emitOnRoot(MODAL_HIDE_EVENT, event, 'publish-planning-modal');

        this.selectedUserIds = [];
        this.updateUserPlanningConfig({
          user_planning_config_params: {
            publish_send_sms: sms,
            publish_send_mail: email,
          },
        });

        this.$skToast({
          message: this.$t('plannings.toolbar.modal.publish_planning.action.success'),
          variant: 'success',
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('plannings.toolbar.modal.publish_planning.action.error'),
          variant: 'error',
        });
      } finally {
        this.isPublishing = false;
      }
    },
    handleClose() {
      this.selectedUserIds = [];
    },
  },
};
</script>

<style lang="scss" scoped>

.info {
  display: flex;
  padding: 12px 13px;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;
  margin-bottom: 10px;

  &--extended {
    margin-bottom: 16px;
  }

  .icon {
    margin: 5px;
  }

  .text {
    color: $sk-grey;
    margin: 0 13px;
    line-height: 20px;
  }
}

.publish-planning__notice {
  margin-bottom: 16px;
}

.modal-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 53px;

  .label {
    font-weight: $fw-bold;
    display: flex;
    align-items: center;
  }

  .icon {
    margin-left: 13px;
  }

  &.last-published-users {
    min-height: 0;
    justify-content: flex-end;
    margin-bottom: 16px;

    div {
      font-size: $fs-text-s;
      color: $sk-grey;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      width: 320px;

      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
    }
  }
}

</style>
