<template>
  <div
    class="reports__wrapper"
  >
    <Toolbar :class="toolbarClass">
      <div class="toolbar__left-wrapper">
        <ToolbarDatePicker
          :additional-query-params="additionalQueryParams"
          :current-date="rangeDate"
          :max-month-range="maxMonthRange"
          :is-one-day-range-authorized="true"
          data-test="reports__date-picker"
          range
          tracker-prefix="reports"
        />
        <PeriodLock
          v-if="currentLicense.attributes.canLockPlanning"
          data-test="reports__period-lock"
          :end-date="formatLockDate(endDate)"
          :is-loader-active="isPeriodLoaderActive"
          :lock-value="lockValue"
          :parent-node-id="parentNodeId"
          :shop-ids="lockShopIds"
          :start-date="formatLockDate(startDate)"
          @set-period-locked="(value) => periodLocked = value"
        />
        <DaysLockedNotification
          v-if="showDaysLockedNotification && numberOfDaysLocked > 0"
          :number-of-days="numberOfDaysLocked"
          class="toolbar__days-locked-notification"
          @show-details="showInfoModalDetails"
        />
      </div>
      <div class="toolbar__right-wrapper">
        <div class="toolbar__reset_counters">
          <SkPopover
            v-if="viewMode === 'all' && isCountersResetButtonAvailableAllView"
            :disabled="!areCountersReset"
            placement="bottom"
            as-tooltip
          >
            <template #anchor>
              <SkOroraButton
                data-test="reports__reset-counters"
                :disabled="areCountersReset"
                :loading="loadingData"
                type="information"
                @click="handleCounterReset"
              >
                {{ $t('reports.counter_reset.button_label') }}
              </SkOroraButton>
            </template>
            <template #content>
              {{ $t('reports.counter_reset.tooltip') }}
            </template>
          </SkPopover>
        </div>
        <DropdownButton
          v-if="viewMode === 'shop' &&
            (isCountersResetButtonAvailableShopView || isAnnualizationCountersResetAvailable)
          "
          :items="resetCountersButtonDropdownItems"
          :spinner="loadingData"
          class="reports__dropdown-button"
          data-test="reports__reset-counters"
          fill="outlined"
          placement="bottom-end"
          trigger="click"
        >
          {{ $t('reports.counter_reset.button_label') }}
        </DropdownButton>
        <div v-if="viewMode === 'shop'">
          <SkDropdown
            ref="shopExportDropdown"
            placement="bottom-start"
            trigger="click"
          >
            <template #anchor>
              <SkOroraButton
                data-test="reports__shop-export-dropdown"
                icon="DownloadV2Icon"
                variant="highlighted"
                :loading="loadingData"
              >
                {{ $t('reports.export_options.button_label') }}
              </SkOroraButton>
            </template>
            <template #menu>
              <div
                v-if="!loadingData"
                class="toolbar__dropdown-content"
              >
                <div
                  v-if="currentLicense.attributes.canEditReportColumns"
                  data-test="reports__export-settings"
                  class="toolbar__dropdown-content__item"
                  @click="showExportSettingsModal"
                >
                  {{ $t('reports.export_options.menu.item.export_settings') }}
                </div>
                <div
                  data-test="reports__export-excel"
                  class="toolbar__dropdown-content__item"
                  @click="showSelectEmployeeModal($event, 'excel')"
                >
                  {{ $t('reports.export_options.menu.item.export_to_excel') }}
                </div>
                <div
                  data-test="reports__export-unemployment"
                  class="toolbar__dropdown-content__item"
                  @click="showUnemploymentModalForShop"
                >
                  {{ $t('reports.export_options.menu.item.export_unemployment') }}
                </div>
                <template
                  v-for="(integrationKey) of integrationsForCurrentShop"
                >
                  <div
                    v-if="!saasReportInfos.excluded_integrations.includes(integrationKey)"
                    :key="integrationKey"
                    :ref="`integration-item-${integrationKey}`"
                    v-tooltip.left="integrationTooltip"
                    :data-test="`reports__export-integration-${integrationKey}`"
                    class="toolbar__dropdown-content__item"
                    @click="showSelectEmployeeModal(
                      $event,
                      'custom',
                      integrationKey,
                      formatIntegrationsName(integrationKey)
                    )"
                    @mouseenter="setIntegrationTooltip(integrationKey)"
                  >
                    {{ getIntegrationDisplayName(integrationKey) }}
                  </div>
                </template>
                <template
                  v-for="(globalIntegration) of globalIntegrationsForCurrentShop"
                >
                  <div
                    :key="globalIntegration.name"
                    :ref="`global-integration-item-${globalIntegration.name}`"
                    v-tooltip.left="globalIntegrationTooltip"
                    :data-test="`reports__export-global-integration-${globalIntegration.name}`"
                    class="toolbar__dropdown-content__item"
                    @click="showSelectEmployeeModal(
                      $event,
                      'global',
                      globalIntegration.name,
                      formatGlobalIntegrationsName(globalIntegration)
                    )"
                    @mouseenter="setGlobalIntegrationTooltip(globalIntegration)"
                  >
                    {{ getGlobalIntegrationDisplayName(globalIntegration) }}
                  </div>
                </template>
              </div>
            </template>
          </SkDropdown>
        </div>
      </div>
    </Toolbar>

    <div class="reports__main">
      <div
        v-if="loadingData"
        class="reports-spinner__wrapper"
      >
        <SkLoader size="large" />
      </div>

      <div
        v-else-if="viewMode === 'all'"
        class="reports__cluster-nodes__wrapper"
      >
        <NodeCard
          :cluster-node="parentNode"
          :cluster-node-list="clusterNodes"
          :class="firstNodeCard"
          :is-grouped-export-available="haveClusterNodeShopsSameConfiguration"
          is-root
          @send-excel-report="sendExcelReport"
          @send-grouped-report="sendGroupedExcelReport"
          @send-grouped-integrations="sendGroupedIntegrations"
          @send-integration-report="sendIntegrationReport"
          @send-unemployment-report="sendUnemploymentReportForNode"
        />
        <NodeCard
          v-for="clusterNode in clusterNodes"
          :key="clusterNode.id"
          :cluster-node="clusterNode"
          :cluster-node-list="clusterNodes"
          class="reports__cluster-node-card"
          @send-excel-report="sendExcelReport"
          @send-integration-report="sendIntegrationReport"
          @send-unemployment-report="sendUnemploymentReportForNode"
        />
      </div>

      <div
        v-else-if="viewMode === 'shop'"
        class="reports__view-shop__wrapper"
      >
        <ShopReports
          :employees-infos="saasReportData"
          @send-unemployment-report="sendUnemploymentReportForShop"
        />
      </div>

      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <ResetOrganisationCountersModal
          v-if="!loadingData"
          :is-network-organisation="currentOrganisation.attributes.clusters"
          :cluster-nodes="clusterNodes"
          :primary-node="parentNode"
          :user="currentUser"
          :end-date="endDate"
          @submit="handleResetCountersModalSubmit"
        />

        <SelectEmployeeReportModal
          v-if="!loadingData"
          :export-data="selectEmployeeModalExportData"
          :employees-infos="saasReportData"
          @send-select-employees-report="handleExport"
        />

        <ExportSettingsModal
          v-if="!loadingData && viewMode === 'shop'"
          :export-categories="saasReportInfos.export_categories"
          @export-settings-saved="showSelectEmployeeModal($event, 'excel')"
        />

        <ResetTrackerModal
          v-if="!loadingData"
          :employees-infos="saasReportData"
          @trackers-updated="fetchShopData"
        />

        <UpdateAnnualizationTrackersModal
          v-if="!loadingData"
          :employees-infos="saasReportData"
          @trackers-updated="fetchShopData"
        />

        <LoadingModal
          ref="reportLoadingModal"
          :description="loadingModalDescription"
          @close="handleUnsubscribe"
        />

        <MissingIntermediateLockedDaysModal
          v-if="!loadingData"
          :start-date="startDate"
          :end-date="endDate"
        />
      </MountingPortal>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';
import ActionCablewebsocketClient from '@skello-utils/websocket_client/action_cable_websocket_client';
import { httpClient } from '@skello-utils/clients';
import {
  downloadFile,
  sanitizeFileName,
} from '@skello-utils/file.js';
import { capitalize } from '@skello-utils/formatting/strings';
import { uniq } from '@skello-utils/collection';

import DropdownButton from '@app-js/shared/components/DropdownButton';
import ToolbarDatePicker from '@app-js/shared/components/Toolbar/ToolbarDatePicker';
import Toolbar from '@app-js/shared/components/Toolbar';
import {
  REPORT_NORMAL_PERIOD, REPORT_EXTENDED_PERIOD,
} from '@app-js/shared/constants/report';

import ExportSettingsModal from './shared/components/ExportSettingsModal';
import LoadingModal from './shared/components/LoadingModal';
import NodeCard from './shared/components/NodeCard';
import PeriodLock from './shared/components/PeriodLock';
import ResetOrganisationCountersModal from './shared/components/ResetOrganisationCountersModal';
import ResetTrackerModal from './shared/components/ResetTrackerModal';
import UpdateAnnualizationTrackersModal from './shared/components/UpdateAnnualizationTrackersModal';
import SelectEmployeeReportModal from './shared/components/SelectEmployeeModal';

import ShopReports from './pages/shop/ShopReports';
import DaysLockedNotification from '../shared/components/PayrollPreparation/DaysLockedNotification.vue';
import MissingIntermediateLockedDaysModal from '../shared/components/PayrollPreparation/MissingIntermediateLockedDaysModal.vue';

export default {
  name: 'Reports',
  components: {
    DropdownButton,
    ExportSettingsModal,
    LoadingModal,
    NodeCard,
    PeriodLock,
    ResetOrganisationCountersModal,
    ResetTrackerModal,
    UpdateAnnualizationTrackersModal,
    SelectEmployeeReportModal,
    ShopReports,
    Toolbar,
    ToolbarDatePicker,
    DaysLockedNotification,
    MissingIntermediateLockedDaysModal,
  },
  beforeRouteEnter(to, _from, next) {
    const beginningOfMonth = skDate().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = skDate().endOf('month').format('YYYY-MM-DD');

    next(vm => {
      const query = {
        ...to.query,
      };

      if (!vm.datesAreValid(to.query.start_date, to.query.end_date)) {
        query.start_date = beginningOfMonth;
        query.end_date = endOfMonth;
      }
      vm.$router.push({ query });
    });
  },
  async beforeRouteUpdate(to, from, next) {
    // Set a local loading state to make sure the loadingData() returns true
    // When doing a route update, because we trigger several requests in different stores
    // We have periods where we are in non-loading state when we don't have all the data
    this.switchingRoute = true;

    const hasClusterNodeChanged =
      !!to.query.cluster_node_id &&
      String(to.query.cluster_node_id) !== String(from.query.cluster_node_id);
    const hasShopChanged = String(to.params.shop_id) !== this.currentShop.id;
    const hasDateChanged = to.query.start_date !== from.query.start_date ||
      to.query.end_date !== from.query.end_date;

    // If it's initial loading without date or we're coming from another page,
    // we need to fetch shop data
    const hadNoDate = !from.query.start_date || !from.query.end_date;

    if (hasClusterNodeChanged) {
      const { shop_id: shopId } = to.params;

      await this.updateCurrentShop({ shopId });
      await this.fetchCurrentClusterNode(to.query.cluster_node_id);
      await this.fetchClusterData(to.query);

      this.routeUpdateNext(next);
    } else if (hasShopChanged || hadNoDate) {
      const { shop_id: shopId } = to.params;

      await this.updateCurrentShop({ shopId });

      if (to.params.shop_id === 'all') {
        await this.selectOrganisation(this.currentOrganisation);
        await this.fetchClusterData(to.query);
        await this.selectClusterNode({
          clusterNode: this.parentNode,
          childrenAreShopNodes: this.clusterNodes.some(node => node.attributes.ancestry === `${this.parentNode.attributes.ancestry}/${node.attributes.parentId}`),
        });
      } else {
        await this.selectShop(this.currentShop);
        // If clusterNodes data doesn't contain the shop we are loading -> fetch clusterData for given shop
        if (!this.currentClusterNode) {
          await this.fetchClusterData(to.query);
        }
        await this.fetchShopInitialData();
        await this.fetchShopData();

        if (this.currentLicense.attributes.canLockPlanning) {
          this.fetchPeriodLocked({
            start_date: to.query.start_date,
            end_date: to.query.end_date,
          });
        }

        if (this.isGreenLockActivated && this.canAccessPayrollReport) {
          this.fetchShopPlanningConfig(this.currentShop.id).then(() => {
            this.fetchMonthlyOptions(this.fetchMonthlyOptionsParameters());
          });
        }

        if (this.datesAreSet() && this.currentShop.attributes.cancelled) {
          this.displayShopCancelledWarning();
        }
      }

      this.routeUpdateNext(next);
    } else if (hasDateChanged) {
      if (to.params.shop_id !== 'all') {
        await this.fetchShopData({
          startDate: to.query.start_date,
          endDate: to.query.end_date,
        });
      }

      if (this.currentLicense.attributes.canLockPlanning) {
        this.fetchPeriodLocked({
          start_date: to.query.start_date,
          end_date: to.query.end_date,
        });

        if (this.isGreenLockActivated && this.canAccessPayrollReport) {
          this.fetchShopPlanningConfig(this.currentShop.id).then(() => {
            this.fetchMonthlyOptions(
              this.fetchMonthlyOptionsParameters(to.query.start_date, to.query.end_date),
            );
          });
        }
      }

      this.routeUpdateNext(next);
    }

    this.routeUpdateNext(next);
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.currentShop.attributes.cancelled) {
      this.displayShopCancelledWarning();

      return;
    }

    if (this.viewMode === 'shop') {
      this.selectShop(this.currentShop);
    }
    next();
  },
  data() {
    return {
      switchingRoute: false,
      fetchPeriodLockedLoading: false,
      periodLocked: '',
      areCountersReset: false,
      selectEmployeeModalExportData: {},
      websocketClient: null,
      integrationTooltip: null,
      globalIntegrationTooltip: null,
      loadingModalDescription: '',
    };
  },
  computed: {
    ...mapState('config', ['config', 'loading']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('navContext', ['navContext']),
    ...mapState('platformAlerts', ['platformAlerts']),
    ...mapState('report', [
      'clusterNodes',
      'clusterNodesLoading',
      'parentNode',
      'saasReportData',
      'saasReportDataLoading',
      'saasReportInfos',
      'updateWeekLockLoading',
    ]),
    ...mapState('planningsState', ['monthlyOptions', 'shopPlanningConfig']),
    ...mapState('shopTeams', ['teamsLoading']),
    ...mapGetters('annualization', ['isAnnualizationCurrentlyActive', 'periodBoundsWithHours', 'shopAnnualizationInitDate']),
    ...mapGetters('currentLicense', ['isSystemAdmin', 'canEditEmployeeInfo']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('currentShop', [
      'isAnnualizedWorkingTimeAvailable',
      'isVariableContractHoursAvailable',
      'modulationActivated',
      'isDevFlagEnabled',
    ]),
    ...mapGetters('employees', ['canAccessPayrollReport']),
    ...mapGetters('planningsState', [
      'isWeekIntermediaryLocked',
      'isGreenLockActivated',
      'daysOfWeekInPeriod',
      'daysOfWeekInPeriodWithoutVisibleDays',
    ]),
    resetCountersButtonDropdownItems() {
      const items = [];

      // hours counters
      if (this.isCountersResetButtonAvailableShopView) {
        items.push({
          callback: this.handleHoursCounterReset,
          dataTest: 'reports__reset-hours-counters',
          label: this.$t('reports.counter_reset.hours_tracker'),
        });
      }

      // annualization counters
      if (this.isAnnualizationCountersResetAvailable) {
        items.push({
          callback: this.handleAnnualizationCountersUpdate,
          dataTest: 'reports__reset-annualization-counters',
          label: this.$t('reports.counter_reset.annualization_tracker'),
          tracker: 'report_annualization_update_clicked',
        });
      }

      return items;
    },
    toolbarClass() {
      return {
        'reports__toolbar-wrapper': true,
        'reports__toolbar-wrapper--warned': this.platformAlerts.length > 0,
      };
    },
    loadingData() {
      return !this.datesAreValid() ||
        this.switchingRoute ||
        this.loading ||
        this.saasReportDataLoading ||
        this.clusterNodesLoading ||
        this.teamsLoading;
    },
    viewMode() {
      return this.currentShop.id === 'all' ? 'all' : 'shop';
    },
    additionalQueryParams() {
      return { cluster_node_id: this.$route.query.cluster_node_id };
    },
    rangeDate() {
      return [this.startDate, this.endDate];
    },
    maxMonthRange() {
      return this.isDevFlagEnabled('FEATUREDEV_REPORT_EXPORT_EXTENDED_PERIOD') &&
        this.viewMode === 'all' ? REPORT_EXTENDED_PERIOD : REPORT_NORMAL_PERIOD;
    },
    startDate() {
      return this.$route.query.start_date;
    },
    endDate() {
      return this.$route.query.end_date;
    },
    datesParams() {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
      };
    },
    firstNodeCard() {
      return {
        'reports__organisation-node-card': !this.currentOrganisation.attributes.clusters,
        'reports__cluster-node-card': this.currentOrganisation.attributes.clusters,
        'reports__cluster-node-card--first': this.currentOrganisation.attributes.clusters,
      };
    },
    haveClusterNodeShopsSameConfiguration() {
      const filteredClusterNodes = this.clusterNodes.reduce((filtered, clusterNode) => {
        if (!clusterNode.attributes.shopCancelled) {
          filtered.push({
            convention: clusterNode.attributes.convention,
            conventionIsFlexible: clusterNode.attributes.conventionIsFlexible,
            modulation: clusterNode.attributes.modulation,
            modulationStatus: clusterNode.attributes.modulationStatus,
            modulationMajoration: clusterNode.attributes.modulationMajoration,
          });
        }
        return filtered;
      }, []);

      const mealRules = this.clusterNodes
        .filter(clusterNode => clusterNode.attributes.mealRule)
        .map(clusterNode => clusterNode.attributes.mealRule?.calculation_method);

      if (uniq(mealRules).length > 1) return false;

      return uniq(filteredClusterNodes).length === 1;
    },
    isCountersResetButtonAvailableShopView() {
      return this.canEditEmployeeInfo &&
        this.checkPackOfferFlag('hours_counter_enabled') &&
        this.modulationActivated;
    },
    isCountersResetButtonAvailableAllView() {
      return this.isSystemAdmin &&
        this.clusterNodes.some(node => (
          node.attributes.modulationStatus === this.config.modulation_activated
        ));
    },
    isAnnualizationCountersResetAvailable() {
      return this.canEditEmployeeInfo &&
        this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
        this.isAnnualizationCurrentlyActive &&
        skDate.utc(this.startDate).startOf('isoWeek').isSameOrAfter(this.shopAnnualizationInitDate);
    },
    currentClusterNode() {
      return this.clusterNodes.find(
        clusterNode => String(clusterNode.id) === String(this.currentShop.attributes.clusterNodeId),
      );
    },
    integrationsForCurrentShop() {
      return this.currentClusterNode.attributes.integrations;
    },
    globalIntegrationsForCurrentShop() {
      return this.currentClusterNode.attributes.globalIntegrations;
    },
    currentShopName() {
      return sanitizeFileName(this.currentShop.attributes.name);
    },
    isPeriodLoaderActive() {
      return this.fetchPeriodLockedLoading ||
        this.clusterNodesLoading ||
        this.updateWeekLockLoading;
    },
    parentNodeId() {
      return this.currentOrganisation.attributes.clusters === true ?
        Number(this.parentNode.id) :
        undefined;
    },
    lockShopIds() {
      return this.viewMode === 'all' ?
        this.clusterNodes.map(clusterNode => clusterNode.attributes.shopId) :
        [Number(this.currentShop.id)];
    },
    showDaysLockedNotification() {
      return this.canAccessPayrollReport && this.viewMode === 'shop';
    },
    lockValue() {
      if (this.periodLocked === 'permanent') {
        return this.periodLocked;
      }

      if (!this.isGreenLockActivated) return '';

      return this.greenLockValue;
    },
    greenLockValue() {
      if (this.monthlyOptions?.length === 0 || this.saasReportInfos?.weeks_datas === undefined) return '';

      const isPeriodIntermediaryLocked =
        !this.monthlyOptions
          .filter((_weeklyOption, index) => this.saasReportInfos?.weeks_datas?.[index]?.week_active)
          .map(weeklyOption => this.isWeekIntermediaryLocked(weeklyOption))
          .some(intermediaryLockDay => !intermediaryLockDay);

      if (isPeriodIntermediaryLocked) return 'intermediary';
      return '';
    },
    numberOfDaysLocked() {
      if (this.monthlyOptions?.length === 0 || this.saasReportInfos?.weeks_datas === undefined) {
        return 0;
      }

      return cloneDeep(this.monthlyOptions)
        .map((weeklyOption, index) => {
          const payload = {
            firstDay: this.startDate,
            lastDay: this.endDate,
            days: weeklyOption.intermediateLockedDays,
            weeklyOptionIndex: index,
          };

          const daysInPeriod = this.daysOfWeekInPeriod(payload);

          return this.daysOfWeekInPeriodWithoutVisibleDays({
            ...payload, days: daysInPeriod,
          });
        }).flat().filter(day => !day).length;
    },
  },
  mounted() {
    this.listenOnRoot('week-lock-toggled', this.fetchPeriodLocked);
  },
  async created() {
    this.setBrowserTabTitle();

    await this.setupWebsocketClient();

    // If there is no dates in URL, fetch will be done in beforeRouteUpdate
    if (this.datesAreSet()) {
      this.fetchMonthlyOptions(this.fetchMonthlyOptionsParameters());
      this.fetchClusterData();
    }

    // We will get redirected by the condition in beforeRouteEnter so no need to get data
    if (!this.datesAreValid()) return;

    if (this.viewMode === 'shop') {
      await this.fetchShopInitialData();
      await this.fetchShopData();

      if (this.currentShop.attributes.cancelled) {
        this.displayShopCancelledWarning();
      }

      if (this.isGreenLockActivated && this.canAccessPayrollReport) {
        this.fetchShopPlanningConfig(this.currentShop.id).then(() => {
          this.fetchMonthlyOptions(this.fetchMonthlyOptionsParameters());
        });
      }
    }
  },
  destroyed() {
    this.resetBrowserTabTitle();
    this.handleUnsubscribe();
  },
  methods: {
    ...mapActions('annualization', [
      'fetchShopAnnualizationConfig',
      'setReportPeriod',
    ]),
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('navContext', [
      'fetchCurrentClusterNode',
      'selectClusterNode',
      'selectOrganisation',
      'selectShop',
    ]),
    ...mapActions('report', [
      'fetchClusterNodes',
      'fetchSaasReportData',
      'startReportExport',
      'startIntegrationExport',
    ]),
    ...mapActions('shopTeams', ['fetchTeams']),
    ...mapActions('planningsState', ['fetchMonthlyOptions', 'fetchShopPlanningConfig']),
    setBrowserTabTitle() {
      this.$nextTick(() => {
        document.title = this.$t('reports.tab_title');
      });
    },
    resetBrowserTabTitle() {
      document.title = this.$t('tab_titles.default');
    },
    async setupWebsocketClient() {
      this.websocketClient = new ActionCablewebsocketClient();
      await this.websocketClient.init();
    },
    subscribeToActionCable(channelName, uuid, callback) {
      this.websocketClient.unsubscribeAll();

      this.websocketClient.subscribe({
        channel: channelName,
        channelOptions: { stream_id: uuid },
        callback,
      });
    },
    datesAreSet() {
      return !!this.startDate && !!this.endDate;
    },
    datesAreValid(startDate, endDate) {
      const localStartDate = startDate || this.startDate;
      const localEndDate = endDate || this.endDate;

      return (skDate(localEndDate).isValid() && skDate(localStartDate).isValid()) &&
        (localEndDate >= localStartDate);
    },
    routeUpdateNext(next) {
      this.switchingRoute = false;
      next();
    },
    // One shop
    // Data that doesn't need to be refreshed when changing period
    async fetchShopInitialData() {
      // For SelectEmployeeReportModal
      await this.fetchTeams({
        shopId: this.currentShop.id,
        isVariableContractHoursAvailable: this.isVariableContractHoursAvailable,
      });
    },
    // One shop
    async fetchShopData(dateParams) {
      const shopId = this.currentShop.id;
      const startDate = dateParams?.startDate ?? this.startDate;
      const endDate = dateParams?.endDate ?? this.endDate;

      const params = {
        shopId,
        startDate,
        endDate,
      };

      if (
        this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
        this.currentShop.attributes.isAnnualizationV2Active
      ) {
        await this.fetchShopAnnualizationConfig({ shopId, date: endDate });
        this.setReportPeriod(startDate, endDate);
      }

      return this.fetchSaasReportData(params);
    },
    // View all
    fetchClusterData(datesParams) {
      return this.fetchClusterNodes()
        .then(() => {
          if (this.clusterNodes.length === 0) {
            this.redirectToHome();
          }

          if (this.currentLicense.attributes.canLockPlanning) {
            this.fetchPeriodLocked(datesParams);
          }
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    fetchMonthlyOptionsParameters(startDate = this.startDate, endDate = this.endDate) {
      return {
        shopId: this.currentShop.id,
        startDate,
        endDate,
      };
    },
    redirectToHome() {
      this.$skToast({
        message: this.$t('errors.unauthorized'),
        variant: 'error',
      });

      setTimeout(() => {
        window.location = '/';
      }, 1000);
    },
    fetchPeriodLocked(datesParams = this.datesParams) {
      this.fetchPeriodLockedLoading = true;

      const params = {
        ...datesParams,
        shop_ids: this.clusterNodes.map(clusterNode => clusterNode.attributes.shopId),
      };

      if (params.shop_ids.length === 0) return;

      this.loadingWeeklyOptions = true;

      httpClient
        .get('/v3/api/reports/permanent_locked_period', { params })
        .then(response => {
          this.periodLocked = response.data ? 'permanent' : '';
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.fetchPeriodLockedLoading = false;
        });
    },
    async sendExcelReport({ nodes }) {
      let url;
      let shop;
      const params = { ...this.datesParams };
      if (!(nodes.length > 1)) {
        shop = {
          id: nodes.attributes.shopId,
          attributes: {
            country: nodes.attributes.country,
          },
        };
      }

      if (nodes.length > 1) {
        url = '/v3/api/reports/send_shops_reports';
        params.shop_ids = nodes.map(node => node.attributes.shopId);
      } else {
        url = '/v3/api/reports/send_shop_report';
        params.shop_id = nodes.attributes.shopId;
      }

      this.sendReport(url, params);
    },
    sendGroupedExcelReport({ nodes }) {
      const params = {
        ...this.datesParams,
        shop_ids: nodes.map(node => node.attributes.shopId),
        parent_name: this.parentNode.attributes.name,
      };

      this.sendReport('/v3/api/reports/send_grouped_report', params);
    },
    sendIntegrationReport({ nodes, type, key }) {
      let url;
      const params = {
        ...this.datesParams,
        integration_type: `export_${type}_integration`,
        key,
      };

      if (nodes.length > 1) {
        url = '/v3/api/reports/send_shops_integrations';
        params.shop_ids = nodes.map(node => node.attributes.shopId);
      } else {
        url = '/v3/api/reports/send_shop_integration';
        params.shop_id = nodes.attributes.shopId;
      }

      this.sendReport(url, params);
    },
    sendGroupedIntegrations({ nodes, type, key }) {
      const params = {
        ...this.datesParams,
        integration_type: `export_${type}_integration`,
        parent_name: this.parentNode.attributes.name,
        key,
      };

      if (nodes.length > 1) {
        params.shop_ids = nodes.map(node => node.attributes.shopId);
      } else {
        params.shop_ids = [nodes.attributes.shopId];
      }

      this.sendReport('/v3/api/reports/send_grouped_integrations', params);
    },
    showUnemploymentModalForShop(event) {
      this.$refs.shopExportDropdown.hide();
      const shopClusterNode = this.currentShop.relationships.clusterNode.attributes.id;
      this.emitOnRoot(MODAL_SHOW_EVENT, event, `unemployment-report-modal-${shopClusterNode}`);
    },
    sendUnemploymentReportForNode({ nodes, type }) {
      let url;
      const date = type.date;
      const params = { date };

      if (nodes.length > 1) {
        url = '/unemployment_report/shops/';
        params.shop_ids = nodes.map(node => node.attributes.shopId);
      } else {
        params.shop_selected_id = nodes.attributes.shopId;
        url = '/unemployment_report/shops/';
      }

      this.sendReport(url, params);
    },
    sendUnemploymentReportForShop({ nodes, type }) {
      const streamId = uuidv4();
      const url = `/unemployment_report/shops/${nodes.attributes.shopId}`;
      const params = {
        date: type.date,
        shop_id: nodes.attributes.shopId,
        user_ids: type.user_ids,
        stream_id: streamId,
      };
      const fileName = this.unemploymentReportFilename(params.date);

      this.$skAnalytics.track('downloaded_short_time_working_report');

      this.subscribeToActionCable(
        'UnemploymentReportExportChannel',
        streamId,
        this.onDataStreamChange('unemployment', fileName),
      );
      this.sendReport(url, params);

      this.loadingModalDescription = this.$t('reports.modals.loading.description.unemployment');
      this.emitOnRoot(MODAL_SHOW_EVENT, null, 'loading-modal');
    },
    onDataStreamChange(exportType, fileName) {
      return data => {
        if (data.error) {
          this.displayLoadingError('standard');
        } else if (data.export_error === 'export_error') {
          this.displayLoadingError('standard');
        } else if (data.export_error === 'empty_txt_file') {
          this.displayLoadingError('empty_file');
        } else if (data.percentage) {
          const newPercentage = data?.percentage;
          this.$refs.reportLoadingModal.setPercentage(data?.percentage);

          if (newPercentage === 100) {
            this.emitOnRoot(MODAL_HIDE_EVENT, null, 'loading-modal');

            // These exports receieve the filename as argument
            if (['excel', 'unemployment'].includes(exportType)) {
              downloadFile(data.report_export_url, fileName);
            // These exports receive the filename in the payload
            } else if (['global', 'custom'].includes(exportType)) {
              downloadFile(data.url, data.filename);
            }
          }
        }
      };
    },
    unemploymentReportFilename(date) {
      const formattedDate = skDate(date).format('MM_YYYY');
      // Same as app/services/unemployment_report/report_xlsx_generator.rb
      return `${this.currentShopName}_DI_${formattedDate}.xlsx`;
    },
    excelReportFilename() {
      const month = skDate(this.startDate).format('MMMM');
      return `${this.currentShopName}_${month}.xlsx`;
    },
    sendReport(url, params) {
      return httpClient
        .post(url, params)
        .then(() => {
          if (this.viewMode === 'all') {
            this.$skToast({
              message: this.$t('reports.send_export_success', {
                email: this.currentUser.attributes.email,
              }),
              variant: 'success',
            });
          }
        })
        .catch(error => {
          if (error.response.status >= 500) {
            this.$skToast({
              message: this.$t('errors.standard_message'),
              variant: 'error',
            });
          } else {
            this.$skToast({
              message: error?.response?.data?.message || this.$t('errors.standard_message'),
              variant: 'error',
            });
          }
        });
    },
    handleHoursCounterReset(event) {
      if (this.clusterNodesLoading) return;

      this.$skAnalytics.track('report_update_tracker_click');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'reset-tracker-modal');
    },
    handleAnnualizationCountersUpdate(event) {
      if (this.clusterNodesLoading) return;
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'update-annualization-trackers-modal');
    },
    handleCounterReset(event) {
      if (this.clusterNodesLoading) return;

      this.$skAnalytics.track('report_update_tracker_click');

      if (this.viewMode === 'shop') {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'reset-tracker-modal');
      } else {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'organisation-counters-reset-modal');
      }
    },
    showSelectEmployeeModal(event, type, key, name) {
      this.$refs.shopExportDropdown.hide();

      // set type of the modal (excel, custom, globale,..)
      this.selectEmployeeModalExportData = {
        key,
        type,
        name,
      };

      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'select-employee-report-modal');
    },
    showExportSettingsModal(event) {
      this.$refs.shopExportDropdown.hide();

      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'export-settings-modal');
    },
    handleResetCountersModalSubmit() {
      this.areCountersReset = true;
    },
    handleExport(exportData, selectedEmployees) {
      let tracker = null;
      switch (exportData.type) {
        case 'excel':
          tracker = 'downloaded_report';
          this.handleStartExcelExport(selectedEmployees, 'excel');
          break;
        case 'custom':
          tracker = 'downloaded_payroll_integration_report';
          this.handleStartIntegrationExport(selectedEmployees, exportData);
          break;
        case 'global':
          tracker = 'downloaded_payroll_integration_report';
          this.handleStartIntegrationExport(selectedEmployees, exportData);
          break;
        case 'unemployment':
          tracker = 'downloaded_short_time_working_report';
          break;
        default:
          break;
      }

      if (tracker) {
        this.$skAnalytics.track(tracker);
      }
    },
    handleStartExcelExport(selectedEmployees, exportType) {
      const streamId = uuidv4();
      const fileName = this.excelReportFilename();
      const params = {
        shopId: this.currentShop.id,
        startDate: this.startDate,
        endDate: this.endDate,
        usersChecked: selectedEmployees,
        streamId,
      };

      this.subscribeToActionCable(
        'ReportExportChannel',
        streamId,
        this.onDataStreamChange(exportType, fileName),
      );
      // start http call to start the job that will send the file via websocket and open the progress modal
      this.startReportExport(params);

      this.loadingModalDescription = this.$t('reports.modals.loading.description.excel');
      this.emitOnRoot(MODAL_SHOW_EVENT, null, 'loading-modal');
    },
    formatLockDate(date) {
      return skDate(date).format('YYYY-MM-DD');
    },
    // Handles integration globale & custom
    handleStartIntegrationExport(selectedEmployees, exportData) {
      const streamId = uuidv4();
      const params = {
        shopId: this.currentShop.id,
        startDate: this.startDate,
        endDate: this.endDate,
        usersChecked: selectedEmployees,
        key: exportData.key,
        type: exportData.type,
        streamId,
      };

      this.subscribeToActionCable(
        'IntegrationExportChannel',
        streamId,
        this.onDataStreamChange(exportData.type, null),
      );
      // start http call to start the job that will send the file via websocket and open the progress modal
      this.startIntegrationExport(params);

      this.loadingModalDescription = this.$t('reports.modals.loading.description.integration');
      this.emitOnRoot(MODAL_SHOW_EVENT, null, 'loading-modal');
    },
    formatIntegrationsName(integration) {
      return capitalize(`${this.config.pay_exports[integration]} - ${integration}`);
    },
    getIntegrationDisplayName(integration) {
      return this.$t('reports.export_options.menu.item.export_to_integration', { integrationName: this.formatIntegrationsName(integration) });
    },
    setIntegrationTooltip(integration) {
      const element = this.$refs[`integration-item-${integration}`][0];

      const isEllipsActive = element.offsetWidth < element.scrollWidth;
      this.integrationTooltip = isEllipsActive ? this.getIntegrationDisplayName(integration) : null;
    },
    formatGlobalIntegrationsName(integration) {
      return capitalize(integration.payroll_software);
    },
    getGlobalIntegrationDisplayName(integration) {
      return this.$t('reports.export_options.menu.item.export_to_integration', { integrationName: this.formatGlobalIntegrationsName(integration) });
    },
    setGlobalIntegrationTooltip(integration) {
      const element = this.$refs[`global-integration-item-${integration.name}`][0];

      const isEllipsActive = element.offsetWidth < element.scrollWidth;
      this.globalIntegrationTooltip =
        isEllipsActive ? this.getGlobalIntegrationDisplayName(integration) : null;
    },
    displayLoadingError(errorType) {
      this.$skToast({
        message: this.$t(`reports.modals.loading.errors.${errorType}`),
        variant: 'error',
      });

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'loading-modal');
    },
    displayShopCancelledWarning() {
      this.$skToast({
        message: this.$t('reports.alerts.cancelled_shop'),
        variant: 'error',
      });
    },
    handleUnsubscribe() {
      this.websocketClient.unsubscribeAll();
    },
    showInfoModalDetails(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'missing-intermediate-locked-days-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
div.reports__wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
}

div.reports__main {
  display: flex;
  flex-grow: 1;
  overflow: auto;

  /**
   * View shop
   */
  .reports__view-shop__wrapper {
    height: 100%;
  }

  /**
   * View all
   */

  .reports__cluster-nodes__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 335px 30px;
    overflow: auto;
  }

  .reports-spinner__wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sk-blue;
  }
}

.reports__organisation-node-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 17px 15px 22px;
  margin-top: 50px;
  margin-bottom: 30px;

  ::v-deep .reports__cluster-nodes-card__label {
    padding-left: 19px;
  }
}

.reports__cluster-node-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 17px 15px 22px;
  margin-bottom: 10px;

  &--first {
    margin-top: 50px;
    margin-bottom: 30px;

    ::v-deep .reports__cluster-nodes-card__label {
      padding-left: 19px;
    }
  }

  .reports__cluster-node-card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 17px 15px 22px;
    margin-bottom: 10px;

    &--first {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }
}

/**
 * Toolbar
 */
.reports__toolbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.toolbar__left-wrapper {
  display: flex;
  align-items: center;
}

.toolbar__right-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.toolbar__dropdown-button {
  background-color: $sk-white;
}

.toolbar__dropdown-content {
  background: $sk-white;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .16);
  border-radius: 2px;
  margin-top: 8px;
// mitigate issue with SkDropdown placing dropdown outside screen
  margin-left: -60px;
  padding: 9px 0;
  max-width: 240px;

  &__item {
    padding: 10px 15px;
    color: $sk-black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      background: $sk-grey-10;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.toolbar__days-locked-notification {
  margin: 12px;
}
</style>

<style lang="scss">
.reports__dropdown-button {
  .dropdown-button {
    background-color: transparent;
  }
}
</style>
