import cloneDeep from 'lodash/cloneDeep';
import isNaN from 'lodash/isNaN';
import uniqBy from 'lodash/uniqBy';
import Vue from 'vue';
import { isEmpty } from '@skello-utils/array';
import {
  httpClient,
  svcRequestsClient,
} from '@skello-utils/clients';
import { matchPosteToShift } from '@skello-utils/association_matchers';
import {
  getOpeningTimesByShopAndDate,
  isShiftDateInRange,
  nextWorkShiftTimes,
  openingAndClosingTimeAt,
  filterShiftsForPeriod,
  setShiftsStartsAtForDisplay,
} from '@app-js/plannings/shared/utils/planning_helpers';
import skDate from '@skello-utils/dates';
import { ABSENCE_TYPE_HOURS } from '@app-js/shared/constants/shift';
import { filterUserAndTeamShifts } from '@app-js/shared/utils/filters_helper';
import { ShiftsAlertsService } from '@skelloapp/skello-shifts-alerts';
import { uniq } from '@skello-utils/collection';
import { getEventSubtype } from '@skello-utils/clients/svc_events/utils.js';
import { fetchInChunks } from '@skello-utils/batches';
import { localizeDefaultPoste } from '@skello-utils/default_poste_helper';
import {
  createShiftApiRequest, getShiftsParams, ENDPOINT_NAMESPACE, upsertTasksApiRequest,
} from './api/shift';

const initialState = {
  shifts: [],
  monthlyShifts: [],
  originalShifts: [],
  updatedUserIds: [],
  popularShifts: [],
  shiftsLoading: false,
  popularShiftsLoading: false,
  // Dissociate workShifts and absence to keep data when switching tabs
  absenceShift: {},
  workShifts: { main: {}, secondary: {} },
  isMultipleWorkShiftsActive: false,
  defaultWorkShift: {},
  shiftsDeleting: false,
  error: false,
  shiftsDestroyLoading: false,
  monthlyShiftsLoading: false,
  blockingAlertShiftsByUser: {},
  usersWithWeeklyBlockingAlert: [],
  quarterDataForAlerts: {},
  shiftUserIds: { main: [], secondary: [] },
  pendingLeaveRequestShifts: [],
};

const matchPosteToShifts = (shift, included) => {
  matchPosteToShift(shift, included);
  return shift;
};

const userFromUserId = (users, userId) => (
  users.find(u => String(u.id) === String(userId))
);

// First argument is a collection of shifts, second argument is the shift to check
// Returns only the shifts in this collection that matches theses conditions :
// - On same day as shift to check
// - Same user on shifts
const getUserShiftsOnSameDay = (collection, shiftToCheck) => (
  collection.filter(s => {
    const shiftStartsAt = skDate(s.attributes.startsAt).utc();
    const shopOpensAt = openingAndClosingTimeAt(
      s.attributes.shopOpeningTime,
      s.attributes.shopClosingTime,
      shiftStartsAt.format(),
    ).openingTime;

    const startAtDate = shiftStartsAt.isBefore(shopOpensAt) ?
      shiftStartsAt.subtract(1, 'day').format('YYYY-MM-DD') : shiftStartsAt.format('YYYY-MM-DD');

    return startAtDate === skDate(shiftToCheck.attributes.startsAt).utc().format('YYYY-MM-DD') &&
      String(s.attributes.userId) === String(shiftToCheck.attributes.userId);
  })
);

const incrementQuarterDataDaysWorked = (state, payload) => {
  const dataForUser = state.quarterDataForAlerts[payload.userId];
  // Needed to avoid error in planning when no data in CachedContractsTimeline
  if (!dataForUser) return;

  const shiftStartOfQuarter = skDate(payload.shift.attributes.startsAt).utc().startOf('quarter').format('YYYY-MM-DD');
  const quarterDataForUser = dataForUser[shiftStartOfQuarter];
  // Needed to avoid error in planning when no data in CachedContractsTimeline
  if (!quarterDataForUser) return;

  quarterDataForUser.days_worked += 1;
};

const decrementQuarterDataDaysWorked = (state, payload) => {
  const dataForUser = state.quarterDataForAlerts[payload.userId];
  // Needed to avoid error in planning when no data in CachedContractsTimeline
  if (!dataForUser) return;

  const shiftStartOfQuarter = skDate(payload.shift.attributes.startsAt).utc().startOf('quarter').format('YYYY-MM-DD');
  const quarterDataForUser = dataForUser[shiftStartOfQuarter];
  // Needed to avoid error in planning when no data in CachedContractsTimeline
  if (!quarterDataForUser) return;

  quarterDataForUser.days_worked -= 1;
};

// Set isOutOfShopHours attribute on each shift
// This attribute allows to identify shifts which are out of their shop's opening hours
const setShiftsIsOutOfShopHours = shifts => {
  // Add flag to each shift indicating if it's in or out of shop hours
  shifts.forEach(shift => {
    if (shift.attributes.isOutOfShopHours) return;

    const { startsAtForDisplay } = shift.attributes;

    let shopOpeningSkDate = getOpeningTimesByShopAndDate(
      shift,
      startsAtForDisplay,
    );

    // If shift starts before shop opening time, check if it's in previous day's opening hours
    // -> to handle shop with night hours
    if (startsAtForDisplay.isBefore(shopOpeningSkDate.openingTime)) {
      shopOpeningSkDate = getOpeningTimesByShopAndDate(
        shift,
        startsAtForDisplay.clone().subtract(1, 'day'),
      );
    }

    // Set isOutOfShopHours flag on shift
    shift.attributes.isOutOfShopHours = !isShiftDateInRange(
      startsAtForDisplay,
      shopOpeningSkDate.openingTime,
      shopOpeningSkDate.closingTime,
    );
  });
};

// Returns shifts for period with flag (isOutOfShopHours: true/false)
// Indicating if they are in or out of their shop opening hours
const getShiftsForPeriod = (shifts, startDate = null, endDate = null) => {
  setShiftsStartsAtForDisplay(shifts);

  const periodShifts = filterShiftsForPeriod(shifts, startDate, endDate);

  setShiftsIsOutOfShopHours(periodShifts);

  return periodShifts;
};

const mutations = {
  defaultShiftsValues(state) {
    state.workShifts = {
      main: {
        id: null,
        attributes: {
          startsAt: null,
          endsAt: null,
          note: null,
          pauseTime: null,
          delay: null,
          nbMeal: 0,
          showStartTime: null,
          showEndTime: null,
          showDuration: null,
          tasks: [],
        },
        relationships: {
          poste: {
            id: null,
          },
        },
        errors: {
          duration: false,
        },
      },
      secondary: {
        id: null,
        attributes: {
          startsAt: null,
          endsAt: null,
          note: null,
          pauseTime: null,
          delay: null,
          nbMeal: 0,
          showStartTime: null,
          showEndTime: null,
          showDuration: null,
          tasks: [],
        },
        relationships: {
          poste: {
            id: null,
          },
        },
        errors: {
          duration: false,
        },
      },
    };
    state.absenceShift = {
      id: null,
      attributes: {
        startsAt: null,
        endsAt: null,
        note: null,
        absenceCalculation: ABSENCE_TYPE_HOURS,
        hoursWorth: null,
        absenceDurationInSeconds: null,
        dayAbsence: false,
        userId: null,
        tasks: [],
      },
      relationships: {
        poste: {
          id: null,
          attributes: {
            absenceKey: null,
          },
        },
      },
      errors: {},
    };
  },

  setSelectedShiftValues(state, payload) {
    // If updating a workShift, workShifts.main is used as the actual shift to update
    const stateShift = payload.relationships.poste.attributes.absenceKey ?
      state.absenceShift :
      state.workShifts.main;
    Object.keys(payload.attributes).forEach(attribute => {
      // Vue.set() isNecessary for reactivity
      // https://vuejs.org/v2/guide/reactivity.html
      Vue.set(stateShift, 'attributes', cloneDeep(payload.attributes));
    });
    Object.keys(payload.relationships).forEach(relationship => {
      Vue.set(stateShift.relationships, relationship, payload.relationships[relationship]);
    });
    Vue.set(stateShift, 'id', payload.id);
  },

  setShiftUserIds(state, { userIds, type }) {
    if (!['main', 'secondary'].includes(type)) throw new Error('Type has to be either main or secondary');

    state.shiftUserIds[type] = userIds;
  },

  setDefaultWorkShiftsValues(state, payload) {
    const mainWorkShift = state.workShifts.main;

    if (payload.startsAt && payload.endsAt) {
      // When creating a shift from day view, startsAt and endsAt are already set
      mainWorkShift.attributes.startsAt = payload.startsAt;
      mainWorkShift.attributes.endsAt = payload.endsAt;
    } else if (
      state.absenceShift.id &&
        state.absenceShift.attributes.absenceCalculation === ABSENCE_TYPE_HOURS
    ) {
      // When updating an absence shift, set same startsAt and endsAt for workShift
      // in case user switches from absence to workShift
      mainWorkShift.attributes.startsAt = state.absenceShift.attributes.startsAt;
      mainWorkShift.attributes.endsAt = state.absenceShift.attributes.endsAt;
    } else {
      // Otherwise, calculate startsAt and endsAt from lastUserShift and shop opening hours
      const { startsAt, endsAt } = nextWorkShiftTimes(
        payload.lastUserShift,
        payload.currentShop,
        payload.date,
      );

      mainWorkShift.attributes.startsAt = startsAt;
      mainWorkShift.attributes.endsAt = endsAt;
    }

    const poste = payload.lastUserPoste || payload.postes[0];
    mainWorkShift.relationships.poste = poste;
    mainWorkShift.attributes.note = null;

    // Getting default eyes values
    const currentUserAttributes = payload.currentUser.attributes;
    mainWorkShift.attributes.showStartTime = currentUserAttributes.showStartTime;
    mainWorkShift.attributes.showEndTime = currentUserAttributes.showEndTime;
    mainWorkShift.attributes.showDuration = currentUserAttributes.showDuration;

    // If creating a new shift -> initialize second and default workShifts
    if (!state.absenceShift.id) {
      state.workShifts.secondary = cloneDeep(mainWorkShift);
      state.defaultWorkShift = cloneDeep(mainWorkShift);
    }
  },

  toggleIsMultipleWorkShiftsActive(state, isActive) {
    state.isMultipleWorkShiftsActive = isActive;
  },

  // When opening second workShift form, calculate startsAt and endsAt according to main workShift
  setSecondaryWorkShiftTimes(state, params) {
    let startsAt;
    let endsAt;

    if (params.startsAt && params.endsAt) {
      startsAt = params.startsAt;
      endsAt = params.endsAt;
    } else {
      const shiftTimes = nextWorkShiftTimes(
        state.workShifts.main,
        params.currentShop,
        params.date,
      );

      startsAt = shiftTimes.startsAt;
      endsAt = shiftTimes.endsAt;
    }

    state.workShifts.secondary.attributes.startsAt = startsAt;
    state.workShifts.secondary.attributes.endsAt = endsAt;
  },

  // if a form is closed, reset secondary workShift to base values
  resetSecondWorkShift(state) {
    state.workShifts.secondary = cloneDeep(state.defaultWorkShift);
  },

  // if main workShift form is closed in create shift modal, secondary workShift becomes main
  setSecondWorkShiftAsMain(state) {
    state.workShifts.main = state.workShifts.secondary;
  },

  setDefaultAbsenceShiftValues(state, payload) {
    // Use main workShift to determine absence default endsAt value
    const mainWorkShift = state.workShifts.main;
    state.absenceShift.attributes.startsAt = mainWorkShift.attributes.startsAt;

    // If pause compensation is inactive or starts after current shift's date
    // -> pause isn't paid: we need to deduce it from the absence shift endsAt
    const pauseCompensationStartsAt = payload.currentShop.attributes.pauseCompensationStartsAt;
    if (
      mainWorkShift.attributes.pauseTime &&
      (!pauseCompensationStartsAt || payload.date.isBefore(skDate(pauseCompensationStartsAt)))
    ) {
      state.absenceShift.attributes.endsAt = skDate(mainWorkShift.attributes.endsAt)
        .subtract(mainWorkShift.attributes.pauseTime, 'seconds')
        .format();
    } else {
      state.absenceShift.attributes.endsAt = mainWorkShift.attributes.endsAt;
    }

    const activeAbsences = payload.absences
      .filter(absence => absence.attributes.active);
    let defaultAbsence = activeAbsences.find(
      absence => absence.attributes.absenceKey ===
        payload.config.absence_data.default_absence_key,
    );
    if (!defaultAbsence) { // fallback on first activated absence if default one is disabled
      defaultAbsence = activeAbsences[0];
    }
    // Special check-case where none absences are available for default
    state.absenceShift.relationships.poste.id = defaultAbsence?.id || null;
    state.absenceShift.relationships.poste.attributes.absenceKey =
      defaultAbsence?.attributes.absenceKey || null;
    state.absenceShift.attributes.absenceCalculation = ABSENCE_TYPE_HOURS;
    state.absenceShift.attributes.note = null;
  },

  fetchShiftsSuccess(state, payload) {
    const { isMonthlyFetch, data, included, overwriteStore = true } = payload;
    const matchedPostesToShifts = data.map(shift => matchPosteToShifts(shift, included));
    const assignedShifts = matchedPostesToShifts.filter(shift => shift.attributes.userId);

    const unassignedShifts = overwriteStore ?
      matchedPostesToShifts.filter(shift => !shift.attributes.userId) : [];

    if (isMonthlyFetch) {
      state.monthlyShifts = overwriteStore ?
        assignedShifts.concat(unassignedShifts) :
        [...state.monthlyShifts, ...assignedShifts];

      return;
    }

    // daily and weekly views
    state.shifts = overwriteStore ? assignedShifts.concat(unassignedShifts) :
      [...state.shifts, ...assignedShifts];
    state.shifts.forEach(shift => {
      localizeDefaultPoste(shift.relationships?.poste);
    });
  },

  fetchPopularShiftsSuccess(state, { data, included }) {
    state.popularShifts = data.map(popularShift => (
      matchPosteToShifts(popularShift, included)
    ));
  },

  createShiftsSuccess(state, payload) {
    const newShifts = payload.data.map(shift => (
      matchPosteToShifts(shift, payload.included)
    ));

    state.shifts.push(...newShifts);
    state.monthlyShifts.push(...newShifts);
  },

  replaceShifts(state, payload) {
    const updateShift = (shiftArray, shift) => {
      const existingShift = shiftArray.find(s => parseInt(s.id, 10) === parseInt(shift.id, 10));
      if (existingShift) {
        Vue.set(existingShift, 'attributes', shift.attributes);
        Vue.set(existingShift, 'relationships', shift.relationships);
      }
    };

    payload.forEach(shift => {
      updateShift(state.shifts, shift);
      updateShift(state.monthlyShifts, shift);
    });
  },

  createTmpShifts(state, payload) {
    payload.forEach(shift => {
      state.shifts.push({ ...shift, id: 'tmp' });
      state.monthlyShifts.push({ ...shift, id: 'tmp' });
    });
  },

  upsertShift(state, { payload, isMonthlyView }) {
    const stateShifts = isMonthlyView ? state.monthlyShifts : state.shifts;

    payload.data.forEach(shift => {
      const index = stateShifts.findIndex(stateShift => stateShift.id === shift.id);
      matchPosteToShifts(shift, payload.included);
      // Slice allow to replace an element with an index and param 1
      // but also allow to add into array with -1 as index (not found in array) and 0 as param
      const replaceOrAddParam = index === -1 ? 0 : 1;
      stateShifts.splice(index, replaceOrAddParam, shift);
      // NOTE: If we are in the monthlyView, we have already
      // handled the monthlyShifts above. Therefore, no need to continue
      if (isMonthlyView) return;
      // Ensure that newShifts are on the good month and not unassigned
      const startsAt = skDate(shift.attributes.startsAt);
      const monthlyIndex = state.monthlyShifts.findIndex(stateShift => (
        stateShift.id === shift.id
      ));
      if (startsAt.month() === startsAt.startOf('isoWeek').month() && shift.attributes.userId) {
        const replaceOrAddParamMonthly = monthlyIndex === -1 ? 0 : 1;
        state.monthlyShifts.splice(monthlyIndex, replaceOrAddParamMonthly, shift);
      } else if (monthlyIndex > -1) {
        state.monthlyShifts.splice(monthlyIndex, 1);
      }
    });
  },

  removeShifts(state, payload) {
    const idsToDelete = payload.shift_ids;
    // Only keep shifts that aren't "to delete"
    state.shifts = state.shifts.filter(shift => !idsToDelete.includes(shift.id));
    state.monthlyShifts = state.monthlyShifts.filter(shift => !idsToDelete.includes(shift.id));
  },

  removeAlertForEmployee(state, { employeeId, alertId }) {
    state.shifts = state.shifts.map(shift => {
      // We change shift alerts for a specific employee only
      if (shift.attributes.userId === employeeId && !!shift.relationships.alerts) {
        const alertIndex = shift.relationships.alerts.findIndex(alert => alert.id === alertId);
        if (alertIndex !== -1) {
          shift.relationships.alerts.splice(alertIndex, 1);
        }
      }
      return shift;
    });
  },

  setError(state, error) {
    state.error = error;
  },

  shiftsRequestPending(state, isMonthlyFetch) {
    if (isMonthlyFetch) {
      state.monthlyShiftsLoading = true;
    } else {
      state.shiftsLoading = true;
    }
  },

  shiftsRequestComplete(state, isMonthlyFetch) {
    if (isMonthlyFetch) {
      state.monthlyShiftsLoading = false;
    } else {
      state.shiftsLoading = false;
    }
  },

  popularShiftsRequestComplete(state) {
    state.popularShiftsLoading = false;
  },

  popularShiftsRequestPending(state) {
    state.popularShiftsLoading = true;
  },

  shiftsDeleteComplete(state) {
    state.shiftsDeleting = false;
  },

  shiftsDeletePending(state) {
    state.shiftsDeleting = true;
  },

  shiftDestroyPending(state) {
    state.shiftsDestroyLoading = true;
  },

  shiftsDestroyComplete(state) {
    state.shiftsDestroyLoading = false;
  },

  setAbsenceShiftError(state, params) {
    Vue.set(state.absenceShift.errors, params.key, params.value);
  },

  setUpdatedUserIds(state, updatedUserIds) {
    state.updatedUserIds = updatedUserIds;
  },

  setOriginalShifts(state, shifts) {
    state.originalShifts = shifts;
  },

  setQuarterDataForAlerts(state, payload) {
    Object.assign(state.quarterDataForAlerts, payload || {});
  },

  updateQuarterDataForAlertsFromCreation(state, payload) {
    if (!payload.rootGetters['planningsState/getActiveAlertsList'].includes('extra_contract')) {
      return;
    }

    payload.data.forEach(shift => {
      const userId = shift.attributes.userId;
      const user = userFromUserId(payload.rootState.planningsUsers.users, userId);
      if (!user || !user.attributes.onExtra) return;

      const filteredShifts = getUserShiftsOnSameDay(state.originalShifts, shift)
        .filter(userShift => userShift.id !== 'tmp'); // Exclude undesirable shift from drag & copy

      if (filteredShifts.length === 1) {
        incrementQuarterDataDaysWorked(state, { userId, shift });
      }
    });
  },

  updateQuarterDataForAlertsFromDeletion(state, payload) {
    if (!payload.rootGetters['planningsState/getActiveAlertsList'].includes('extra_contract')) {
      return;
    }

    const deletedShifts =
      state.originalShifts.filter(shift => payload.data.shift_ids.includes(shift.id));

    deletedShifts.forEach(shift => {
      const userId = shift.attributes.userId;
      const user = userFromUserId(payload.rootState.planningsUsers.users, userId);
      if (!user || !user.attributes.onExtra) return;

      const filteredShifts = getUserShiftsOnSameDay(state.originalShifts, shift);

      if (filteredShifts.length === 1) {
        decrementQuarterDataDaysWorked(state, { userId, shift });
      }
    });
  },

  updateQuarterDataForAlertsFromUpdate(state, payload) {
    if (!payload.rootGetters['planningsState/getActiveAlertsList'].includes('extra_contract')) {
      return;
    }

    let prevOriginalFilteredShiftsIds = [];

    payload.shifts.forEach(updatedShift => {
      const originalShift =
        payload.originalShifts.find(shift => String(shift.id) === String(updatedShift.id));
      const originalUserId = originalShift.attributes.userId;
      const updatedUserId = updatedShift.attributes.userId;

      const originalUser = userFromUserId(payload.rootState.planningsUsers.users, originalUserId);
      const updatedUser = userFromUserId(payload.rootState.planningsUsers.users, updatedUserId);

      const originalFilteredShifts = getUserShiftsOnSameDay(state.originalShifts, originalShift);
      const updatedFilteredShifts = getUserShiftsOnSameDay(state.originalShifts, updatedShift);

      // From unassigned to assigned
      if (!originalUserId && updatedUser && updatedUser.attributes.onExtra) {
        incrementQuarterDataDaysWorked(state, { userId: updatedUserId, shift: updatedShift });
        return;
      }

      // From assigned to unassigned
      if (!updatedUserId && originalUser && originalUser.attributes.onExtra) {
        decrementQuarterDataDaysWorked(state, { userId: originalUserId, shift: updatedShift });
        return;
      }

      // From unassigned to unassigned (on other day)
      if (!originalUser && !updatedUser) return;

      // shift swap case
      if (payload.shifts.length > 1 && originalUser && updatedUser) {
        const originalFilteredShiftsIds = [];
        originalFilteredShifts.forEach(s => originalFilteredShiftsIds.push(s.id));

        const daysWorkedAlreadyUpdated = JSON.stringify(originalFilteredShiftsIds) ===
          JSON.stringify(prevOriginalFilteredShiftsIds);

        // with multiple originalFilteredShifts we're going to pass here multiple times
        // we want to update days_worked only once
        if (daysWorkedAlreadyUpdated) {
          return;
        }

        if (originalFilteredShifts.length > 0) {
          if (updatedUser.attributes.onExtra) {
            incrementQuarterDataDaysWorked(state, { userId: updatedUserId, shift: updatedShift });
          }

          if (originalUser.attributes.onExtra) {
            decrementQuarterDataDaysWorked(state, { userId: originalUserId, shift: updatedShift });
          }
        }

        if (updatedFilteredShifts.length > 0) {
          if (originalUser.attributes.onExtra) {
            incrementQuarterDataDaysWorked(state, { userId: originalUserId, shift: updatedShift });
          }

          if (updatedUser.attributes.onExtra) {
            decrementQuarterDataDaysWorked(state, { userId: updatedUserId, shift: updatedShift });
          }
        }

        prevOriginalFilteredShiftsIds = originalFilteredShiftsIds;
        return;
      }

      // What are those cases ?
      if (originalUser && originalUser.attributes.onExtra && originalFilteredShifts.length === 1) {
        decrementQuarterDataDaysWorked(state, { userId: originalUserId, shift: updatedShift });
      }

      if (updatedUser && updatedUser.attributes.onExtra && updatedFilteredShifts.length === 0) {
        incrementQuarterDataDaysWorked(state, { userId: updatedUserId, shift: updatedShift });
      }
    });
  },
  matchFrontAlertsToShifts(state,
    { shifts, weeklyOptionsList, shiftAlertResults, readonly, rootGetters, rootState }) {
    const currentUserId = rootState.currentUser.currentUser.id;
    const transformShifts =
    (
      shiftsToTransform,
      deactivatedAlertsByUser,
    ) => Object.keys(shiftAlertResults).forEach(shiftId => {
      const alertsForShift = shiftAlertResults[shiftId].matchingAlerts;
      const matchedShift = shiftsToTransform.find(
        shift => Number(shift.id) === Number(shiftId),
      );

      if (!matchedShift) return;

      const userId = matchedShift.attributes.userId.toString();
      const matchedActiveAlerts = rootState.planningsState.shopAlerts.filter(
        alert => {
          const isDeactivatedByUser =
            deactivatedAlertsByUser[currentUserId] &&
            deactivatedAlertsByUser[currentUserId][userId] &&
            deactivatedAlertsByUser[currentUserId][userId].includes(
              parseInt(alert.id, 10),
            );
          return (
            alertsForShift.includes(alert.attributes.name) &&
            !isDeactivatedByUser
          );
        },
      );
      Vue.set(matchedShift.relationships, 'alerts', matchedActiveAlerts);

      const hasShiftBlockingAlert = matchedActiveAlerts.some(
        alert => alert.attributes.blocking &&
          alert.attributes.name !== 'minimum_weekly_work_time',
      );

      // Only fill blockingAlertShiftsByUser on these conditions :
      // -> not readonly
      // -> At least one  active alert has been configured as "blocking"
      // -> We are not on the first matchFrontAlertsToShifts() on planning display
      // (To avoid triggering the blockingAlertModal when activating blocking alerts
      // after the fact and going back to a previous week that triggers it.)
      // -> We dont want to raise a blocking alert on shifts that are on locked days
      if (
        readonly ||
        !hasShiftBlockingAlert ||
        rootGetters['planningsState/isHistoryEmpty'] ||
        (rootGetters['planningsState/isAnyDayLocked'] &&
          rootGetters['planningsState/isDayLockedForShift'](matchedShift))
      ) { return; }

      /* eslint-disable max-len */
      const lastBlockingAlertByUserUpdatedAt = state.blockingAlertShiftsByUser[
        matchedShift.attributes.userId
      ] ?
        skDate(
          state.blockingAlertShiftsByUser[matchedShift.attributes.userId]
            .attributes.updatedAt,
        ) :
        null;

      // Put only the latest shift in array per user
      if (
        !state.blockingAlertShiftsByUser[matchedShift.attributes.userId] ||
        lastBlockingAlertByUserUpdatedAt.isBefore(
          skDate(matchedShift.attributes.updatedAt),
        )
      ) {
        Vue.set(
          state.blockingAlertShiftsByUser,
          matchedShift.attributes.userId,
          matchedShift,
        );
      }
    });

    weeklyOptionsList.forEach(weeklyOption => {
      const deactivatedAlertsByUser =
        weeklyOption?.deactivatedAlertsByUser;
      const shiftsToTransform = getShiftsForPeriod(
        shifts,
        skDate(weeklyOption.date).utc(),
        skDate(weeklyOption.date).endOf('isoWeek').utc(),
      );

      transformShifts(shiftsToTransform, deactivatedAlertsByUser);
    });
  },
  resetBlockingAlerts(state) {
    state.blockingAlertShiftsByUser = {};
  },
  setUsersWithWeeklyBlockingAlert(state, payload) {
    state.usersWithWeeklyBlockingAlert = payload;
  },
  setPendingLeaveRequestShifts(state, payload) {
    state.pendingLeaveRequestShifts = payload;
  },
};

const actions = {
  async fetchShifts({ commit, getters, rootState, rootGetters }, { params, overwriteStore = true }) {
    commit('shiftsRequestPending', params.is_monthly_fetch);

    try {
      const accumulatedData = await fetchInChunks(params, `${ENDPOINT_NAMESPACE}`);

      // We save fetched shifts to history when it's a refetch,
      // for example after applying a template, that way, we're
      // able to "reverse/replay" the fetch when undoing/redoing
      if (params.isRefetch) {
        commit('planningsState/historyAddFetch', { payload: accumulatedData, rootState, rootGetters }, { root: true });
        commit('planningsState/incrementHistory', accumulatedData, { root: true });
      } else {
        commit('planningsState/clearHistory', null, { root: true });
      }

      commit(
        'fetchShiftsSuccess',
        { ...accumulatedData, isMonthlyFetch: params.is_monthly_fetch, overwriteStore },
      );

      commit('setOriginalShifts', getters.shiftsForCurrentPeriod);
      return accumulatedData;
    } catch (error) {
      commit('setError', error.response);
      throw error;
    } finally {
      commit('shiftsRequestComplete', params.is_monthly_fetch);
    }
  },

  fetchPopularShifts({ commit }, shopId) {
    commit('popularShiftsRequestPending');

    const params = { shop_id: shopId };
    return httpClient
      .get('/v3/api/plannings/popular_shifts', { params })
      .then(response => {
        commit('fetchPopularShiftsSuccess', response.data);
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('popularShiftsRequestComplete');
      });
  },

  successCallbackCommonActions({ dispatch, state, rootGetters, rootState }, { params }) {
    // Fetch PTOs if we are in the monthly view and the shop has PTOs activated
    if (rootGetters['planningsState/isMonthlyView'] && rootState.currentShop.currentShop.attributes.plcInitialized) {
      const { startsAt } = rootGetters['planningsState/periodDates']('month');
      if (state.updatedUserIds.length === 0) return;

      state.updatedUserIds = state.updatedUserIds.filter(id => id !== 0);

      dispatch('monthlyPlanning/fetchBulkPaidLeavesCounters',
        {
          params: {
            shopId: params.shopId,
            userIds: state.updatedUserIds,
            date: startsAt,
          },
          overwriteStore: false,
        },
        { root: true },
      );
    }
  },

  createShiftSuccessCallback({ commit, dispatch, state, getters, rootGetters, rootState }, { params, response }) {
    commit('createShiftsSuccess', response.data);
    if (!params.isUndo) {
      commit('planningsState/historyAddCreate', response.data, { root: true });
      commit('planningsState/incrementHistory', null, { root: true });
    }

    // The "updated user" is the one who just had a shift created
    const updatedUserIds = uniq(response.data.data
      .map(shift => shift.attributes.userId)
      .filter(id => id)); // remove null values for unassigned
    commit('setUpdatedUserIds', updatedUserIds);
    commit('setOriginalShifts', getters.shiftsForCurrentPeriod);

    commit('updateQuarterDataForAlertsFromCreation', { data: params.shifts, rootGetters, rootState });

    // Only use main shift to save default eyes configuration
    commit('currentUser/setShiftDisplayTemplate', response.data.data[0], { root: true });
    // reload weekly option (to refresh publish button status)
    // and alerts
    if (rootGetters['planningsState/isMonthlyView']) {
      dispatch('planningsState/fetchMonthlyOptions', { shopId: params.shopId }, { root: true });
    } else {
      dispatch('planningsState/fetchWeeklyOptions', params.shopId, { root: true });
    }
    dispatch('fetchShiftAlerts', {
      shop_id: params.shopId,
      starts_at: params.periodStartsAt,
      ends_at: params.periodEndsAt,
      user_ids: state.updatedUserIds,
    });
    dispatch('employeeCounters/fetchUsersHoursCounters', { shopId: params.shopId }, { root: true });
    dispatch('planningsUsers/fetchDayRateUsersDaysWorked', {
      params: {
        starts_at: params.periodStartsAt,
        ends_at: params.periodEndsAt,
        shop_id: params.shopId,
      },
    }, { root: true });
    if (rootGetters['annualization/isAnnualizationCurrentlyActiveForCurrentShop']) {
      const annualizationParams = {
        shopId: params.shopId,
        untilPlanningEndDate: true,
      };
      dispatch('annualization/fetchAndComputeAnnualizationData', annualizationParams, { root: true });
    }
    dispatch('planningsAutomaticPlanning/removeLocalStorageLastResults', {
      shopId: params.shopId,
    }, { root: true });
    // SVC EVENTS
    dispatch('postOnSvcEvent', {
      subtype: getEventSubtype(
        'CREATE',
        params.shifts[0].attributes.absenceCalculation !== '',
        rootGetters['planningsState/isDailyView'],
      ),
      data: params.shifts,
    });

    dispatch('successCallbackCommonActions', { params });

    return response;
  },

  async createShift({ commit, dispatch }, params) {
    if (params.isFromDragAndDrop) {
      // pre-upsert before action to prevent orginal shift to go back to orignal place
      // before being finally displayed where it was dropped
      commit('createTmpShifts', params.shifts);
    }

    try {
      const response = await createShiftApiRequest(params);

      const shiftsTasks = await Promise.all(params.shifts.map((shift, index) => {
        let tasks = shift.attributes.tasks;

        if (isEmpty(tasks)) return Promise.resolve({ data: { tasks: [] } });
        if (params.isFromDragAndDrop) {
          // when creating from drag and drop, we keep tasks but reset their isChecked status
          tasks = shift.attributes.tasks.map(task => {
            delete task.id;
            task.isChecked = false;
            return task;
          });
        }

        return upsertTasksApiRequest({ shiftId: response.data.data[index].id, tasks });
      }));
      response.data.data.forEach((shift, index) => { shift.attributes.tasks = shiftsTasks[index].data.tasks; });

      dispatch('createShiftSuccessCallback', { params, response });
      return response;
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      if (params.isFromDragAndDrop) commit('removeShifts', { shift_ids: ['tmp'] });
    }
  },

  async updateShift({ commit, dispatch, state, getters, rootGetters, rootState }, params) {
    // save original shift to rollback if any backend issue
    const updatedShiftsIds = params.shifts.map(shift => shift.id);
    const originalShifts = cloneDeep(getters.shiftsForCurrentPeriod.filter(s => updatedShiftsIds.includes(s.id)));

    // The "updated users" are a concatenation of the originalShifts
    // And new shifts from params => Handles update but also drag&drop
    const updatedUserIds = uniq(originalShifts.concat(params.shifts)
      .map(shift => Number(shift.attributes.userId))
      .filter(id => !isNaN(id))); // remove null values for unassigned

    if (params.isFromDragAndDrop) {
      // pre-upsert before action to prevent orginal shift to go back to orignal place
      // before being finally displayed where it was dropped
      commit('replaceShifts', params.shifts);
    }

    // For position view quick select, setting 'tmp' as id prevents user to interact while update
    if (params.isFromEmployeeQuickSelect) {
      const shiftIndex = getters.shiftsForCurrentPeriod.findIndex(shift => shift.id === params.shiftId);
      Vue.set(getters.shiftsForCurrentPeriod[shiftIndex], 'id', 'tmp');
    }

    const updateParams = {
      starts_at: params.periodStartsAt,
      ends_at: params.periodEndsAt,
      shop_id: params.shopId,
      shifts: getShiftsParams(params.shifts),
    };

    try {
      const response = await httpClient
        .patch(`${ENDPOINT_NAMESPACE}`, updateParams);

      if (params.isFromEmployeeQuickSelect) {
        // Re-setting original id on shift after request reponse
        const shiftIndex = getters.shiftsForCurrentPeriod.findIndex(shift => shift.id === 'tmp');
        Vue.set(getters.shiftsForCurrentPeriod[shiftIndex], 'id', params.shiftId);
      }

      const shiftsTasks = await Promise.all(params.shifts.map(shift => {
        const shiftBeforeUpdate = state.originalShifts.find(originalShift => originalShift.id === shift.id);
        if (JSON.stringify(shiftBeforeUpdate.attributes.tasks) === JSON.stringify(shift.attributes.tasks)) {
          return Promise.resolve({ data: { tasks: shiftBeforeUpdate.attributes.tasks } });
        }
        return upsertTasksApiRequest({ shiftId: shift.id, tasks: shift.attributes.tasks });
      }));
      response.data.data.forEach((shift, index) => { shift.attributes.tasks = shiftsTasks[index].data.tasks; });

      if (!params.isUndo) {
        if (params.isFromDragAndDrop) {
          response.data.originalShifts = originalShifts;
        }
        commit('planningsState/historyAddUpdate', {
          payload: response.data,
          shifts: rootGetters['planningsShifts/shiftsForCurrentPeriod'],
        },
        { root: true },
        );
        commit('planningsState/incrementHistory', null, { root: true });
      }
      // for blocking alert, to force update in back end but let the
      // user be aware than some shifts are moved to unassigned, we need to prevent
      // the removal of shift in the state at this moment.
      // the remove is done when the user acknowledge in the modal
      if (!params.skipUpsertShift) {
        commit('upsertShift', { payload: response.data, isMonthlyView: rootGetters['planningsState/isMonthlyView'] });
      }
      commit('setUpdatedUserIds', updatedUserIds);
      commit('updateQuarterDataForAlertsFromUpdate', { shifts: params.shifts, originalShifts, rootState, rootGetters });
      commit('setOriginalShifts', getters.shiftsForCurrentPeriod);
      // reload weekly option (to refresh publish button status)
      // and alerts
      if (rootGetters['planningsState/isMonthlyView']) {
        dispatch('planningsState/fetchMonthlyOptions', { shopId: params.shopId }, { root: true });
      } else {
        dispatch('planningsState/fetchWeeklyOptions', params.shopId, { root: true });
      }
      dispatch('fetchShiftAlerts', {
        shop_id: params.shopId,
        starts_at: params.periodStartsAt,
        ends_at: params.periodEndsAt,
        user_ids: state.updatedUserIds,
      });
      dispatch('employeeCounters/fetchUsersHoursCounters', { shopId: params.shopId }, { root: true });
      if (rootGetters['annualization/isAnnualizationCurrentlyActiveForCurrentShop']) {
        const annualizationParams = {
          shopId: params.shopId,
          untilPlanningEndDate: true,
        };
        dispatch('annualization/fetchAndComputeAnnualizationData', annualizationParams, { root: true });
      }
      dispatch('planningsUsers/fetchDayRateUsersDaysWorked', {
        params: {
          starts_at: params.periodStartsAt,
          ends_at: params.periodEndsAt,
          shop_id: params.shopId,
        },
      }, { root: true });
      dispatch('planningsAutomaticPlanning/removeLocalStorageLastResults', {
        shopId: params.shopId,
      }, { root: true });
      // SVC EVENTS
      dispatch('postOnSvcEvent', {
        subtype: getEventSubtype(
          params.isSwappingUserShifts ? 'UPDATE_SWAP_USER_SHIFTS' : 'UPDATE',
          params.isSwappingUserShifts ? false : params.shifts[0].attributes.absenceCalculation !== '',
          params.isSwappingUserShifts ? false : rootGetters['planningsState/isDailyView'],
        ),
        data: params.isSwappingUserShifts ?
          {
            shifts: params.shifts,
            starts_at: params.periodStartsAt,
            ends_at: params.periodEndsAt,
            shop_id: params.shopId,
          } :
          params.shifts,
      });

      dispatch('successCallbackCommonActions', { params });

      return response;
    } catch (error) {
      // if any issue in back end need to put back orginal shift in state
      if (params.isFromDragAndDrop) commit('replaceShifts', originalShifts);
      commit('setError', error);
      throw error;
    }
  },

  /**
   * For batch delete of all week shifts (of one or several users)
   */
  async deleteShifts({ commit, dispatch, state, getters, rootGetters, rootState }, params) {
    commit('shiftsDeletePending');
    const shopId = params.shop_id;

    // The "updated users" is a list of all users from batch deleted shifts
    // Remove null values from unassigned shifts with shift.attributes.userId
    const updatedUserIds = uniq(state.shifts
      .filter(shift => params.shift_ids.includes(String(shift.id)) && shift.attributes.userId)
      .map(shift => shift.attributes.userId));
    const bulkDeletedShifts = state.shifts.filter(shift => params.shift_ids.includes(String(shift.id)));

    return httpClient
      .delete(`${ENDPOINT_NAMESPACE}/bulk_delete`, { data: params })
      .then(response => {
        if (!params.isUndo) {
          commit('planningsState/historyAddDelete', {
            payload: params,
            shifts: rootGetters['planningsShifts/shiftsForCurrentPeriod'],
          },
          { root: true },
          );
          commit('planningsState/incrementHistory', null, { root: true });
        }
        // for blocking alert, to force deletion in back end but let the
        // user be aware than some shifts are deleted, we need to prevent
        // the removal of shift in the state at this moment.
        // the remove is done when the user acknowledge in the modal
        if (!params.skipRemoveShift) {
          commit('removeShifts', params);
        }
        commit('setUpdatedUserIds', updatedUserIds);
        commit('updateQuarterDataForAlertsFromDeletion', { data: params, rootGetters, rootState });
        commit('setOriginalShifts', getters.shiftsForCurrentPeriod);
        if (rootGetters['planningsState/isMonthlyView']) {
          dispatch('planningsState/fetchMonthlyOptions', { shopId }, { root: true });
        } else {
          dispatch('planningsState/fetchWeeklyOptions', shopId, { root: true });
        }
        dispatch('fetchShiftAlerts', {
          shop_id: shopId,
          starts_at: params.starts_at,
          ends_at: params.ends_at,
          user_ids: state.updatedUserIds,
        });

        dispatch('planningsAutomaticPlanning/removeLocalStorageLastResults', {
          shopId,
        }, { root: true });

        if (!rootGetters['planningsState/isDailyView']) {
          dispatch('employeeCounters/fetchUsersHoursCounters', { shopId }, { root: true });
          if (rootGetters['annualization/isAnnualizationCurrentlyActiveForCurrentShop']) {
            const annualizationParams = {
              shopId,
              untilPlanningEndDate: true,
            };
            dispatch('annualization/fetchAndComputeAnnualizationData', annualizationParams, { root: true });
          }
          dispatch('planningsUsers/fetchDayRateUsersDaysWorked', {
            params: {
              shop_id: shopId,
              starts_at: params.starts_at,
              ends_at: params.ends_at,
            },
          }, { root: true });
          // Close totals sidebar when deleting because
          // totals needs to be processed again, this process is made by opening the tab.
          // By closing the tab we force the reprocessing
          commit('planningsState/closeTotalPeriodTab', null, { root: true });
        }
        dispatch('postOnSvcEvent', {
          subtype: getEventSubtype(
            'BULK_DELETE',
            undefined,
            rootGetters['planningsState/isDailyView'],
          ),
          data: {
            shifts: bulkDeletedShifts,
            starts_at: params.starts_at,
            ends_at: params.ends_at,
          },
        });

        dispatch('successCallbackCommonActions', { params });

        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('shiftsDeleteComplete');
      });
  },

  // Destroy of one shift
  destroyShift({ commit, dispatch, state, getters, rootState, rootGetters }, params) {
    commit('shiftDestroyPending');

    // The "updated user" is the one which will have a shift deleted
    // Remove null values from unassigned shifts with shift.attributes.userId
    const updatedUserIds = uniq(getters.shiftsForCurrentPeriod
      .filter(shift => String(shift.id) === String(params.shiftId) && shift.attributes.userId)
      .map(shift => shift.attributes.userId));
    const deletedShift = getters.shiftsForCurrentPeriod.filter(shift => String(shift.id) === String(params.shiftId))[0];

    return httpClient
      .delete(`${ENDPOINT_NAMESPACE}/${params.shiftId}?shop_id=${params.shopId}&starts_at=${params.periodStartsAt}&ends_at=${params.periodEndsAt}`)
      .then(response => {
        if (!params.isUndo) {
          commit('planningsState/historyAddDelete', {
            payload: { shift_ids: [params.shiftId] },
            shifts: rootGetters['planningsShifts/shiftsForCurrentPeriod'],
          },
          { root: true },
          );
          commit('planningsState/incrementHistory', null, { root: true });
        }
        commit('updateQuarterDataForAlertsFromDeletion', { data: { shift_ids: [params.shiftId] }, rootGetters, rootState });

        commit('removeShifts', { shift_ids: [params.shiftId] });

        commit('setUpdatedUserIds', updatedUserIds);
        commit('setOriginalShifts', getters.shiftsForCurrentPeriod);

        if (rootGetters['planningsState/isMonthlyView']) {
          dispatch('planningsState/fetchMonthlyOptions', { shopId: params.shopId }, { root: true });
        } else {
          dispatch('planningsState/fetchWeeklyOptions', params.shopId, { root: true });
        }
        dispatch('fetchShiftAlerts', {
          shop_id: params.shopId,
          starts_at: params.periodStartsAt,
          ends_at: params.periodEndsAt,
          user_ids: state.updatedUserIds,
        });
        dispatch('employeeCounters/fetchUsersHoursCounters', { shopId: params.shopId }, { root: true });
        if (rootGetters['annualization/isAnnualizationCurrentlyActiveForCurrentShop']) {
          const annualizationParams = {
            shopId: params.shopId,
            untilPlanningEndDate: true,
          };
          dispatch('annualization/fetchAndComputeAnnualizationData', annualizationParams, { root: true });
        }
        dispatch('planningsUsers/fetchDayRateUsersDaysWorked', {
          params: {
            starts_at: params.periodStartsAt,
            ends_at: params.periodEndsAt,
            shop_id: params.shopId,
          },
        }, { root: true });
        dispatch('planningsAutomaticPlanning/removeLocalStorageLastResults', {
          shopId: params.shopId,
        }, { root: true });
        // SVC-EVENTS
        dispatch('postOnSvcEvent', {
          subtype: getEventSubtype(
            'DELETE',
            deletedShift.attributes.absenceCalculation !== '',
            rootGetters['planningsState/isDailyView'],
          ),
          data: [deletedShift],
        });

        dispatch('successCallbackCommonActions', { params });

        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('shiftsDestroyComplete');
      });
  },

  fetchQuarterDataForAlerts({ commit }, params) {
    return httpClient
      .get('/v3/api/plannings/alerts/quarter_days_worked_for_extra', { params })
      .then(response => {
        commit('setQuarterDataForAlerts', response.data);
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      });
  },

  fetchShiftAlerts({ commit, dispatch }, params) {
    // Do not call API if array is empty
    if (params.user_ids.length === 0) return null;

    return dispatch('computeFrontShiftAlerts', params);
  },

  postOnSvcEvent({ commit }, params) {
    Vue.prototype.$svcEvents.create(params.subtype, params.data);
  },

  /**
   * ShiftsAlertsService - Front library to handle shift alerts
   * Ideal scenario :
   * Get all shifts needed for data calculation ( V3::Api::Plannings::ShiftsController : userIds, start, end )
   * For alerts that expand further than a week ( take the largest scope )
   * Get the shifts ids to run for userIds
   * Instanciate ShiftsAlertsService with config containing all the data
   * computeShiftsIds(shift_ids)
   * service returns an object with shift_id as key and result object as value (matchingAlerts)
   */
  computeFrontShiftAlerts(
    { state, commit, getters, rootGetters, rootState },
    // eslint-disable-next-line camelcase
    { readonly, user_ids },
  ) {
    // Unify monthly and weekly flows in DEV-18328
    const isMonthlyFlow = rootGetters['planningsState/isMonthlyView'];

    const currentShop = rootState.currentShop.currentShop;
    const convention = currentShop.relationships.convention.attributes;
    const { dataShifts, shiftsSource, weeklyOptionsList } = isMonthlyFlow ? {
      dataShifts: getters.monthlyDataShiftsForAlerts,
      shiftsSource: getters.shiftsCreatedForCurrentMonth,
      weeklyOptionsList: rootState.planningsState.monthlyOptions,
    } : {
      dataShifts: getters.dataShiftsForAlerts,
      shiftsSource: getters.shiftsCreatedForCurrentWeek,
      weeklyOptionsList: [rootState.planningsState.weeklyOptions.attributes],
    };

    // Those are the shifts used for the computation of alerts
    // Their range can vary from a week to more, see getShiftsParams() method
    // in app/javascript/src/v3/app/plannings/pages/Weeks/index.vue
    // contains all postes objects for all dataShifts
    // even the postes from other shops shifts
    const allDataShiftsPostes = uniqBy(
      dataShifts.map(shift => ({
        ...shift.relationships.poste.attributes,
      })),
      'id',
    );

    const config = {
      convention,
      alerts: rootGetters['planningsState/getActiveAlertsList'],
      currentShop: {
        modulation: currentShop.attributes.modulation,
        country: currentShop.attributes.country,
        openingTime: currentShop.attributes.openingTime,
        closingTime: currentShop.attributes.closingTime,
        legalWeeklyHours: currentShop.attributes.legalWeeklyHours,
      },
      // For the ShiftsAlertsService, postes must contain work postes + absences postes
      postes: allDataShiftsPostes,
      quarterDataForAlerts: state.quarterDataForAlerts,
      users: rootState.planningsUsers.users.map(user => ({
        id: Number(user.id),
        ...user.attributes,
        contractHours: user.attributes.currentContractHours, // TODO, what about permanentContractHours?
        interim: user.attributes.onInterim, // TODO - on contract
        dayRate: user.attributes.onDayRate, // TODO - on contract
        partTime: user.attributes.onPartTime, // TODO - on contract type
      })),
      shifts: dataShifts.map(shift => ({
        id: Number(shift.id),
        posteId: shift.relationships.poste.attributes.id,
        ...shift.attributes,
      })),
    };

    if (window.SKELLO_SHIFTS_ALERTS_DEBUG) {
      // eslint-disable-next-line no-console
      console.log('Compute front shift_alerts with config :', config);
    }

    // Filter user_ids to remove null user_id values (from unassigned shifts)
    const userIds = user_ids.filter(id => id).map(id => id.toString());

    // - First filter : compute only for current shop's shifts (not secondary shops)
    // - First filter : compute only for visible users in planning
    // - First filter : remove 'tmp' shifts, used in createTmpShifts()
    // - Second filter : compute only for shifts that require it (work & indemnified absences)
    // Note : Make sure to compare ids as strings because it alternates between string & number
    // Note : Use getters.shiftsCreatedForCurrentWeek because only visible and created shifts need computing
    let shiftsToCompute = shiftsSource;
    shiftsToCompute = shiftsToCompute.filter(
      shift => String(shift.attributes.shopId) === String(currentShop.id) &&
          userIds.includes(String(shift.attributes.userId)),
    );
    shiftsToCompute = shiftsToCompute.filter(shift => {
      // When shop modulation is enabled, shifts not in counter should not be computed
      // Typically non indemnified or neutral absences
      if (currentShop.attributes.modulation) { return shift.attributes.inHoursCounter; }

      const posteAttributes = shift.relationships.poste.attributes;
      const isWorkShift = posteAttributes.absenceType === null;
      const isIndemnifiedAbsence =
          posteAttributes.absenceIndemnifiedByEmployer ||
          posteAttributes.absenceIndemnifiedByOther;

      // Otherwise, the filter should only keep real work shifts
      // And indemnified absences
      return isWorkShift || isIndemnifiedAbsence;
    })
      .map(shift => shift.id);
    if (shiftsToCompute.length === 0) return;

    if (window.SKELLO_SHIFTS_ALERTS_DEBUG) {
      // eslint-disable-next-line no-console
      console.log(
        'ShiftsAlertsService compute these shifts :',
        shiftsToCompute,
      );
    }

    new ShiftsAlertsService(config)
      .compute(shiftsToCompute)
      .then(shiftAlertResults => {
        if (window.SKELLO_SHIFTS_ALERTS_DEBUG) {
          // eslint-disable-next-line no-console
        }
        commit('matchFrontAlertsToShifts', {
          weeklyOptionsList,
          shiftAlertResults,
          readonly,
          shifts: shiftsSource,
          rootGetters,
          rootState,
        });
      })
      .catch(error => {
        commit(
          'setError',
          'ShiftsAlertsService - computeFrontShiftAlerts() error',
        );
        if (window.SKELLO_SHIFTS_ALERTS_DEBUG) {
          // eslint-disable-next-line no-console
        }
        throw error;
      });
  },

  async fetchPendingLeaveRequestShifts({ commit, rootGetters }, params) {
    let response;
    try {
      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.getAllLeaveRequests({ ...params });
        const transformedResponse = response.data.map(request => ({
          attributes: {
            ...request,
            shopId: Number(request.shopId),
            posteId: Number(request.positionId),
            userId: Number(request.employeeId),
            absenceCalculation: request.calculation,
            startDate: request.startsAt,
            endDate: request.endsAt,
          },
          id: request.id,
        }));
        commit('setPendingLeaveRequestShifts', transformedResponse);
      } else {
        response = await httpClient.get('/v3/api/leave_requests', { params });
        commit('setPendingLeaveRequestShifts', response.data.data);
      }
    } catch (error) {
      commit('setError', error.response);
    }
  },
  resetUpdatedUserIds({ commit }) {
    commit('setUpdatedUserIds', []);
  },
};
const gettersList = {
  /**
   * This is the main getter for Shifts, this is why it is important
   * to scope it from monday to sunday, because we have more shifts
   * out of theses boundaries for the shiftsAlerts computation in front
   * - used for shifts display on planning
   * - used for week totals
   * Note: using each shift's shop opening hours to determine wether it is included in week or not
   * TODO : rename monday / sunday to accomodate for future day view
   */
  shiftsForCurrentWeek: (state, _selfGetters, _rootState, rootGetters) => {
    const startDate = skDate(rootGetters['planningsState/monday']).utc(true);
    const endDate = skDate(rootGetters['planningsState/sunday']).utc(true);
    const shifts = [
      ...state.shifts,
      ...rootGetters[
        'planningsAutomaticPlanning/filteredBrainShiftsForPlanning'
      ],
    ];
    return getShiftsForPeriod(shifts, startDate, endDate);
  },

  shiftsForCurrentMonth: (state, _selfGetters, _rootState, rootGetters) => {
    const startDate = skDate.utc(rootGetters['planningsState/firstMondayOfMonth']);
    const endDate = skDate.utc(rootGetters['planningsState/lastSundayOfMonth']);
    return getShiftsForPeriod(state.monthlyShifts, startDate, endDate);
  },

  shiftsForCurrentPeriod: (_, getters, _rootState, rootGetters) => {
    if (rootGetters['planningsState/isMonthlyView']) {
      return getters.shiftsForCurrentMonth;
    }
    return getters.shiftsForCurrentWeek;
  },

  shiftsCreatedForCurrentMonth: (state, _getters, _rootState, rootGetters) => {
    const startDate = skDate(rootGetters['planningsState/firstMondayOfMonth']).utc(true);
    const endDate = skDate(rootGetters['planningsState/lastSundayOfMonth']).utc(true);

    return getShiftsForPeriod(
      state.monthlyShifts.filter(shift => shift.id !== 'tmp'), startDate, endDate);
  },
  // In the case of front shiftsAlerts, all the shifts from the store are used
  // In and out of week
  // In and out of hours
  // Note : remove 'tmp' shifts, used in createTmpShifts() for drag & drop purposes
  dataShiftsForAlerts: state => getShiftsForPeriod(state.shifts).filter(shift => shift.id !== 'tmp'),
  monthlyDataShiftsForAlerts: state => getShiftsForPeriod(state.monthlyShifts).filter(shift => shift.id !== 'tmp'),
  // returns only shift for the current week, existing in database and needing computation
  shiftsCreatedForCurrentWeek: (state, _selfGetters, _rootState, rootGetters) => {
    const startDate = skDate(rootGetters['planningsState/monday']).utc(true);
    const endDate = skDate(rootGetters['planningsState/sunday']).utc(true);
    return getShiftsForPeriod(
      state.shifts.filter(shift => shift.id !== 'tmp'),
      startDate,
      endDate,
    );
  },
  shiftsForUser: (_state, getters) => userId => (
    getters.shiftsForCurrentWeek.filter(
      shift => parseInt(shift.attributes.userId, 10) === parseInt(userId, 10),
    )
  ),
  shiftsForPoste: (_state, getters, rootState) => posteId => {
    const { filters } = rootState.planningsState;
    const { users } = rootState.planningsUsers;
    const shifts = getters.shiftsForCurrentWeek.filter(shift => shift.relationships.poste.id === posteId);
    return filterUserAndTeamShifts(filters, users, shifts);
  },
  unassignedShifts: (_state, getters) => (
    getters.shiftsForCurrentWeek.filter(shift => !shift.attributes.userId)
  ),
  unassignedShiftsForCurrentDay: (state, getters, _rootState, rootGetters) => {
    const date = rootGetters['planningsState/currentDate'];

    return getters.dayCellShifts(date, state.shifts).filter(shift => !shift.attributes.userId);
  },
  monthlyShifts: (state, _selfGetters, _rootState, rootGetters) => getShiftsForPeriod(
    state.monthlyShifts,
    skDate.utc(rootGetters['planningsState/firstMondayOfMonth']),
    skDate.utc(rootGetters['planningsState/lastSundayOfMonth']),
  ),
  unassignedMonthlyShifts: (_state, getters) => (
    getters.monthlyShifts.filter(shift => !shift.attributes.userId)
  ),
  monthlyShiftsForUser: (_state, getters) => userId => (
    getters.monthlyShifts.filter(shift => shift.attributes.userId === parseInt(userId, 10))
  ),
  monthlyShiftsForPoste: (_state, getters, rootState) => posteId => {
    const { filters } = rootState.planningsState;
    const { users } = rootState.planningsUsers;
    const shifts = getters.monthlyShifts.filter(shift => shift.relationships.poste.id === posteId);
    return filterUserAndTeamShifts(filters, users, shifts);
  },
  dayCellShifts: (_state, _selfGetters, _rootState, rootGetters) => (date, shifts) => {
    // Use same filter rules as day_index in shift model (used for v2)
    const formattedDate = skDate(date).utc(true);

    const filteredShifts = shifts.filter(shift => {
      // We use getOpeningTimesByShopAndDate to get the shift's shop opening time
      // and not the current shop's to manage correctly other shops' shifts
      const { openingTime } = getOpeningTimesByShopAndDate(shift, formattedDate);

      // closingTime (upper limit) is set to following day at opening time
      // to stick to v2 behaviour
      const closingTime = openingTime.clone().add(1, 'day');

      return isShiftDateInRange(shift.attributes.startsAtForDisplay, openingTime, closingTime);
    });

    if (rootGetters['planningsState/isDailyView']) {
      // If you drop a shift over another shift, we always show the latest shift moved/created in front
      filteredShifts.sort((a, b) => a.attributes.updatedAt.localeCompare(b.attributes.updatedAt));
    } else if (rootGetters['planningsState/isWeeklyView']) {
      filteredShifts.sort((a, b) => a.attributes.startsAt.localeCompare(b.attributes.startsAt));
    }

    return filteredShifts;
  },
  shouldDisplayTasks: (_state, _selfGetters, _rootState, rootGetters) => (rootGetters['currentOrganisation/checkPackOfferFlag']('shift_activity_tasks_enabled')),
  shouldDisplayComments: (_state, _selfGetters, _rootState, rootGetters) => comments => (rootGetters['currentOrganisation/checkPackOfferFlag']('shift_activity_comments_enabled') &&
    !isEmpty(comments)),
};

export default {
  namespaced: true,
  state: initialState,
  getters: gettersList,
  mutations,
  actions,
};
