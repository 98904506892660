<template>
  <div class="document-manager-container">
    <VueDropzone
      id="shift-documents-dropzone"
      ref="uploadFile"
      :options="dropzoneOptions"
      :include-styling="false"
      :use-custom-slot="true"
      :duplicate-check="false"
      @vdropzone-mounted="handleMounted"
      @vdropzone-drag-drop="handleDrop"
      @vdropzone-file-added="handleAddedFile"
      @vdropzone-files-added="handleAddedFiles"
      @vdropzone-file-added-manually="injectLinkToDocument"
    >
      <div
        v-if="!readOnly"
        :class="dropzoneClasses"
        @click="handleDropzoneClick"
      >
        <DownloadV2Icon
          width="28px"
          height="28px"
          :fill="iconFill"
        />
        <div class="shift-documents__dropzone-text-wrapper">
          <span :class="dropzoneTitleClasses">
            {{ $t('plannings.table.manage_shift_modal.tabs.shift.documents.upload_documents_title') }}
          </span>
          <span :class="dropzoneDescriptionClasses">
            {{ $t('plannings.table.manage_shift_modal.tabs.shift.documents.upload_documents_description', { maxFiles: options.MAX_NUMBER_OF_FILES, maxFileSizeMB: options.MAX_FILE_SIZE_MB }) }}
          </span>
        </div>
      </div>
      <div v-else />
    </VueDropzone>
    <div
      v-if="isLoadingDocuments"
      class="shift-activities__section-container"
    >
      <SkLoader size="medium" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  mapGetters,
  mapMutations,
} from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
  ExternalLinkV2Icon,
  TrashCanV2Icon,
} from '@skelloapp/skello-ui';
import {
  AUTHORIZED_FILE_TYPE,
  INLINE_FILE_TYPE,
  MAX_NUMBER_OF_FILES,
  MAX_FILE_SIZE_MB,
  MAX_FILE_SIZE,
  ERROR_TYPES,
  LOCAL_URL_TIMEOUT,
} from '@skello-store/modules/plannings/shifts-documents';

const CustomExternalLinkIcon = Vue.extend(ExternalLinkV2Icon);
const CustomTrashCanIcon = Vue.extend(TrashCanV2Icon);

export default {
  name: 'DocumentsManager',
  components: { VueDropzone: vue2Dropzone },
  props: {
    shift: {
      type: Object,
      required: true,
    },
    workShiftKey: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attachments: [],
      removedFiles: [],
      options: {
        AUTHORIZED_FILE_TYPE,
        INLINE_FILE_TYPE,
        MAX_NUMBER_OF_FILES,
        MAX_FILE_SIZE_MB,
        MAX_FILE_SIZE,
        ERROR_TYPES,
        LOCAL_URL_TIMEOUT,
      },
    };
  },
  computed: {
    ...mapGetters('shiftsDocuments', ['getDocuments', 'isLoadingDocuments']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    documents() {
      return this.getDocuments(this.shift.id);
    },
    isDragDropEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_ATTACH_DOCUMENTS_DRAG_DROP');
    },
    isMaxReached() {
      return this.attachments.length >= this.options.MAX_NUMBER_OF_FILES;
    },
    dropzoneState() {
      return this.isMaxReached || this.isLoadingDocuments ? 'disabled' : 'enabled';
    },
    dropzoneOptions() {
      return {
        autoProcessQueue: false, // Dropzone should wait for the user to click on submit button to actually upload
        autoQueue: false,
        uploadMultiple: true,
        createImageThumbnails: true, // true for caroussel
        maxFiles: this.options.MAX_NUMBER_OF_FILES,
        acceptedFiles: this.options.AUTHORIZED_FILE_TYPE.join(','),
        hiddenInputContainer: '#shift-documents-dropzone',
        url: '/fake', // We dont use dropzone for the XHR call but it requires a url
        maxFilesize: this.options.MAX_FILE_SIZE_MB,
        previewTemplate: this.template(),
      };
    },
    dropzoneClasses() {
      return {
        ...this.dropzoneStateClasses('shift-documents__dropzone'),
      };
    },
    iconFill() {
      return this.isMaxReached || this.isLoadingDocuments ?
        this.$skColors.skGrey30 : this.$skColors.skBlack;
    },
    dropzoneTitleClasses() {
      return {
        'sk-text-small-semibold': true,
        ...this.dropzoneStateClasses('shift-documents__dropzone-title'),
      };
    },
    dropzoneDescriptionClasses() {
      return {
        'sk-text-x-small-regular': true,
        ...this.dropzoneStateClasses('shift-documents__dropzone-description'),
      };
    },
  },
  watch: {
    isLoadingDocuments(newValue) {
      if (!newValue) {
        if (this.dropzoneState === 'enabled') this.enableDropzone();
      } else {
        this.$refs.uploadFile.removeAllFiles();
        this.disableDropzone();
      }
    },
    documents() {
      this.loadDocuments();
    },
  },
  mounted() {
    if (!this.isLoadingDocuments) this.loadDocuments();
  },
  methods: {
    ...mapMutations('shiftsDocuments', ['addToUploadQueue', 'removeFromUploadQueue', 'addToDeleteQueue']),

    // removes all event listeners from the dropzone:
    // {dragstart: ƒ, dragenter: ƒ, dragover: ƒ, dragleave: ƒ, drop: ƒ, …}
    removeListeners() {
      const dropzoneElement = this.$refs.uploadFile.dropzone.element;
      const listeners = dropzoneElement.dropzone.listeners[0].events;
      Object.keys(listeners).forEach(eventName => {
        const eventHandler = listeners[eventName];
        dropzoneElement.removeEventListener(eventName, eventHandler);
      });
    },
    handleMounted() {
      if (this.isLoadingDocuments) this.disableDropzone();
      if (!this.isDragDropEnabled) {
        this.removeListeners();
      }
    },
    handleDropzoneClick() {
      this.removedFiles = [];
      this.$skAnalytics.track('shift_file_upload_open_picker');
    },
    handleDrop() {
      this.removedFiles = [];
    },
    /**
     * Click on the dropzone to open the file picker and
     * Drag and drop files to the dropzone behave differently.
     * With the click:
     *  - handleAddedFile(s) is(are) called first
     *  - handleAddedFiles is called after
     * With the drag and drop:
     * - handleAddedFiles is called first
     * - handleAddedFile(s) is(are) called after
     */
    handleAddedFile(file) {
      if (this.removedFiles.find(removedFile => removedFile.name === file.name)) {
        this.$refs.uploadFile.removeFile(file);
      } else if (file.previewElement) {
        this.injectLinkToDocument(file);
      }
    },
    handleAddedFiles(files) {
      if (!files || files.length === 0) return;

      const filesArray = Object.values(files);
      if (!this.validateFilesLimit(filesArray)) return;

      this.processValidFiles(filesArray);
    },
    processValidFiles(filesArray) {
      filesArray.forEach(file => {
        if (this.validateFile(file)) {
          this.handleValidFile(file);
        } else {
          this.removeFilesFromDropzone([file]);
        }
      });
    },
    handleValidFile(file) {
      const attachment = this.createAttachment(file);
      this.attachments.push(attachment);
      this.queueFileUpload(file);
    },
    queueFileUpload(file) {
      this.addToUploadQueue({ file, workShiftKey: this.workShiftKey });
    },
    createAttachment(file) {
      return {
        name: file.name,
        type: file.type,
        uploaded: false,
        url: null,
      };
    },
    validateFilesLimit(filesArray) {
      const totalFiles = this.attachments.length + filesArray.length;
      if (totalFiles > this.options.MAX_NUMBER_OF_FILES) {
        this.displayError(this.options.ERROR_TYPES.MAX_FILES_EXCEEDED);
        this.removeFilesFromDropzone(filesArray);
        return false;
      }

      if (totalFiles >= this.options.MAX_NUMBER_OF_FILES) {
        this.disableDropzone();
      }

      return true;
    },
    disableDropzone() {
      this.$refs.uploadFile.dropzone.disable();
    },
    enableDropzone() {
      this.$refs.uploadFile.dropzone.enable();
    },
    removeFilesFromDropzone(files) {
      files.forEach(file => {
        this.removedFiles.push(file);
        this.$refs.uploadFile.removeFile(file);
      });
    },
    showDeleteConfirmation(file) {
      this.$root.$emit('confirm', event, {
        title: this.$t('plannings.table.manage_shift_modal.tabs.shift.documents.delete_confirmation.title'),
        description: this.$t('plannings.table.manage_shift_modal.tabs.shift.documents.delete_confirmation.description'),
        cancelLabel: this.$t('plannings.table.manage_shift_modal.tabs.shift.documents.delete_confirmation.cancel'),
        submitLabel: this.$t('plannings.table.manage_shift_modal.tabs.shift.documents.delete_confirmation.delete'),
        variant: 'danger',
        icon: 'TrashCanIcon',
        iconColorSchema: { fill: this.$skColors.skError50, background: this.$skColors.skError10 },
        onConfirm: () => {
          if (file.uploaded) this.addToDeleteQueue(file.id);

          this.cleanupAfterDelete(file);
          this.$refs.uploadFile.removeFile(file);
          this.$skAnalytics.track('file_delete_confirmed_desktop');
        },
      });
    },

    injectLinkToDocument(file) {
      if (!this.readOnly) {
        const deleteButton = file.previewElement.children[1];
        deleteButton.addEventListener('click', event => {
          event.preventDefault();
          event.stopPropagation();
          this.showDeleteConfirmation(file);
        });
      }

      const filePreviewButton = file.previewElement.children[0];
      const link = document.createElement('a');
      let url;
      if (!file.url) {
        url = URL.createObjectURL(file);
        setTimeout(() => URL.revokeObjectURL(url), this.options.LOCAL_URL_TIMEOUT);
      } else {
        url = file.url ? file.url : URL.createObjectURL(file);
      }
      link.href = url;
      if (this.options.INLINE_FILE_TYPE.includes(file.type)) {
        link.target = '_blank';
      } else {
        link.download = file.name;
      }
      link.className = 'dz-details';
      link.addEventListener('click', () => {
        this.$skAnalytics.track('shift_file_open_desktop');
      });
      link.appendChild(filePreviewButton.children[0].cloneNode(true));
      link.appendChild(filePreviewButton.children[1].cloneNode(true));
      file.previewElement.removeChild(filePreviewButton);
      file.previewElement.prepend(link);
    },
    cleanupAfterDelete(file) {
      const initialLength = this.attachments.length;
      this.attachments = this.attachments.filter(attachment => attachment.name !== file.name);
      if (this.attachments.length === initialLength) return;

      this.removeFromUploadQueue(file.name);

      if (this.attachments.length < this.options.MAX_NUMBER_OF_FILES) {
        this.enableDropzone();
      }

      this.$emit('on-change', this.attachments);
    },
    validateFile(file) {
      if (!file || !file.type) return false;

      if (!this.options.AUTHORIZED_FILE_TYPE.includes(file.type)) {
        this.displayError(this.options.ERROR_TYPES.FILE_TYPE);
        return false;
      }

      if (file.size > this.options.MAX_FILE_SIZE) {
        this.displayError(this.options.ERROR_TYPES.FILE_SIZE);
        return false;
      }

      if (file.accepted === false) {
        this.displayError(this.options.ERROR_TYPES.INVALID);
        return false;
      }

      return true;
    },
    displayError(errorType) {
      const errorMessages = {
        [this.options.ERROR_TYPES.MAX_FILES_EXCEEDED]: this.$t(`plannings.table.manage_shift_modal.tabs.shift.documents.errors.${errorType}`),
        [this.options.ERROR_TYPES.FILE_TYPE]: this.$t(`plannings.table.manage_shift_modal.tabs.shift.documents.errors.${errorType}`),
        [this.options.ERROR_TYPES.FILE_SIZE]: this.$t(`plannings.table.manage_shift_modal.tabs.shift.documents.errors.${errorType}`, { maxFileSizeMB: this.options.MAX_FILE_SIZE_MB }),
        [this.options.ERROR_TYPES.INVALID]: this.$t(`plannings.table.manage_shift_modal.tabs.shift.documents.errors.${errorType}`),
      };

      this.$skToast({
        message: errorMessages[errorType] ?? errorMessages[this.options.ERROR_TYPES.INVALID],
        variant: 'error',
      });
    },
    mapDocumentToAttachment(document) {
      return {
        id: document.id,
        name: document.fileName,
        type: document.mimeType,
        uploaded: true,
        url: document.url,
      };
    },
    loadDocuments() {
      this.$refs.uploadFile.removeAllFiles();
      this.documents.forEach(document => {
        const attachment = this.mapDocumentToAttachment(document);
        this.attachments.push(attachment);
        this.$refs.uploadFile.manuallyAddFile(attachment, attachment.url);
      });
    },
    dropzoneStateClasses(style) {
      return {
        [style]: true,
        [`${style}--${this.dropzoneState}`]: true,
      };
    },
    template() {
      return `<div class="dz-row">
                <div
                  class="dz-details"
                  style="width: ${this.readOnly ? 360 : 340}px;"
                >
                  <span class="dz-filename sk-text-medium-regular" data-dz-name></span>
                  <button class="dz-open--icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      id="workload-open-icon"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      ${(new CustomExternalLinkIcon().$mount().$el.innerHTML)}
                    </svg>
                  </button>
                </div>
                ${!this.readOnly ? this.removeButtonTemplate() : ''}
              </div>
        `;
    },
    removeButtonTemplate() {
      return `<button class="dz-delete--icon" type="button">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  id="workload-trash-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  class="dz-delete--icon-trash"
                >
                  ${(new CustomTrashCanIcon().$mount().$el.innerHTML).replace('black', this.$skColors.skError50)}
                </svg>
              </button>`;
    },
  },
};
</script>

<style lang="scss">
.document-manager-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.shift-documents__dropzone {
  display: flex;
  padding: 12px;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: $sk-white;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23DDDDDDFF' stroke-width='2' stroke-dasharray='16%2c 9' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e");

  &--enabled {
    border-color: $sk-grey-10;

    &:hover {
      cursor: pointer;
      background-color: $sk-blue-5;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%232B66FEFF' stroke-width='2' stroke-dasharray='16%2c 9' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }

  &--disabled {
    border-color: $sk-grey-5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

#shift-documents-dropzone {
  width: 100%;
}

.shift-documents__dropzone-text-wrapper {
  display: flex;
  flex-direction: column;
}

.shift-documents__dropzone-title {
  margin-bottom: 4px;

  &--enabled {
    color: $sk-black;
  }

  &--disabled {
    color: $sk-grey-30;
  }
}

.shift-documents__dropzone-description {
  &--enabled {
    color: $sk-grey-50;
  }

  &--disabled {
    color: $sk-grey-10;
  }
}

.dz-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  &:nth-of-type(2) > .dz-details {
    border-top: 1px solid $sk-grey-10;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type > .dz-details {
    border-bottom: 1px solid $sk-grey-10;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    .dz-delete--icon {
      display: flex;
      outline: none;
      border-radius: 50%;

      .dz-delete--icon-trash {
        visibility: visible;
      }
    }
  }

  .dz-delete--icon {
    width: 40px;
    height: 40px;
    background-color: $sk-white;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $sk-grey-5;
      border-radius: 50%;
      outline: none;
    }

    &:focus {
      outline: none;
    }

    .dz-delete--icon-trash {
      visibility: hidden;
    }
  }

  .dz-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    padding: 0 16px 0 16px;
    border-top: 1px solid $sk-grey-10;
    border-left: 1px solid $sk-grey-10;
    border-right: 1px solid $sk-grey-10;
    border-bottom: 0px;
    color: $sk-black;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: $sk-grey-5;
      color: $sk-black;
      text-decoration: none;

      .dz-open--icon {
        display: flex;
      }
    }

    .dz-filename {
      width: 10px; // hacky hardcoded width that should be replaced by a better solution
      flex-grow: 1;
      padding: 15px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dz-open--icon {
      padding: 0;
      margin: 0 0 0 8px;
      border: 0;
      background-color: transparent;
      display: none;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
