<template>
  <div :class="requestPageClasses">
    <RequestsPageHeader
      v-if="
        !isUpdatingNavigationContext &&
          !isFetchingRequests &&
          (requests.length > 0 || areFiltersActive)
      "
      :title="requestsLabels.title"
      :subtitle="requestsLabels.subtitle"
    >
      <template #actions>
        <RequestsFilterPopover />
        <SkOroraButton
          v-if="!isAvailabilityRequestsView || !isTeamsRequestsView"
          :data-test="dataTest"
          variant="highlighted"
          :is-button-disabled="isAskRequestButtonDisabled"
          @click="handleNewRequestButtonClick"
        >
          {{ requestsLabels.create }}
        </SkOroraButton>
      </template>
    </RequestsPageHeader>
    <RequestsTable
      v-show="
        !isUpdatingNavigationContext && !isManagingRequestThroughUrl && !isFetchingRequests &&
          (requests.length > 0 || areFiltersActive)
      "
    />
    <div
      v-if="isUpdatingNavigationContext || isManagingRequestThroughUrl || isFetchingRequests"
      :class="loaderClasses"
    >
      <SkLoader size="large" />
    </div>
    <EmptyRequestsPage
      v-else-if="
        !isUpdatingNavigationContext &&
          !isManagingRequestThroughUrl &&
          !isFetchingRequests &&
          requests.length === 0 &&
          !areFiltersActive
      "
      v-bind="emptyPageProps"
      :button-callback="emptyPageButtonCallback"
      :is-button-disabled="isAskRequestButtonDisabled"
      :add-button-data-test="dataTest"
    />
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <ManageLeaveRequestModal
        v-if="isLeaveRequestsView"
        :source="{ page: 'leave_requests', sub: 'team' }"
      />
      <NewLeaveRequestModal v-if="isLeaveRequestsView" />
      <TransferLeaveRequestModal
        v-if="isLeaveRequestsView &&
          isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P2')"
      />
      <EmployeeAvailabilitiesModal
        v-if="isAvailabilityRequestsView"
        @submit="initData"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { staticRootUrl } from '@config/env';
import { openFile } from '@skello-utils/file.js';
import { svcRequestsClient } from '@skello-utils/clients';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import GenericFilterHandler from '@app-js/shared/utils/generic_filter_handler';

import ManageLeaveRequestModal from '@app-js/shared/components/ManageLeaveRequestModal';
import RoutesMixins from '@app-js/requests/mixins/RoutesMixins';

import TransferLeaveRequestModal from '@app-js/shared/components/TransferLeaveRequestModal.vue';
import EmployeeAvailabilitiesModal from './EmployeeAvailabilitiesModal/EmployeeAvailabilitiesModal';
import EmptyRequestsPage from './EmptyRequestsPage';
import NewLeaveRequestModal from './NewLeaveRequestModal';
import RequestsPageHeader from './RequestsPageHeader';
import RequestsTable from './RequestsTable';
import RequestsFilterPopover from './RequestsFilterPopover';

export default {
  name: 'RequestsPage',
  components: {
    EmptyRequestsPage,
    ManageLeaveRequestModal,
    RequestsPageHeader,
    RequestsTable,
    NewLeaveRequestModal,
    EmployeeAvailabilitiesModal,
    RequestsFilterPopover,
    TransferLeaveRequestModal,
  },
  mixins: [RoutesMixins],
  data() {
    return {
      isDownloadingGuide: false,
      isManagingRequestThroughUrl: false,
      requestIdToAcceptThroughUrl: null,
      requestIdToTransferThroughUrl: null,
      availabilityRequestsLabels: {
        own: {
          title: this.$t('requests.availability_requests.own.title'),
          subtitle: this.$t('requests.availability_requests.own.subtitle'),
          create: this.$t('requests.availability_requests.own.cta.create'),
        },
        teams: {
          title: this.$t('requests.availability_requests.teams.title'),
          subtitle: this.$t('requests.availability_requests.teams.subtitle'),
        },
      },
      leaveRequestsLabels: {
        own: {
          title: this.$t('requests.leave_requests.own.title'),
          subtitle: this.$t('requests.leave_requests.own.subtitle'),
          create: this.$t('requests.leave_requests.own.cta.create'),
        },
        teams: {
          title: this.$t('requests.leave_requests.teams.title'),
          subtitle: this.$t('requests.leave_requests.teams.subtitle'),
          create: this.$t('requests.leave_requests.teams.cta.create'),
        },
      },
      emptyAvailabilityRequestsPageProps: {
        own: {
          icon: 'TeamV2Icon',
          buttonText: this.$t('requests.availability_requests.empty_page.own.no_request.button'),
          contentText: this.$t('requests.availability_requests.empty_page.own.no_request.content'),
          headerText: this.$t('requests.availability_requests.empty_page.own.no_request.header'),
        },
        teams: {
          icon: 'TeamV2Icon',
          buttonText: null,
          contentText: this.$t('requests.availability_requests.empty_page.teams.no_request.content'),
          headerText: this.$t('requests.availability_requests.empty_page.teams.no_request.header'),
        },
      },
      emptyLeaveRequestsPageProps: {
        own: {
          icon: 'PalmTreeIcon',
          buttonText: this.$t('requests.leave_requests.empty_page.own.no_request.button'),
          contentText: this.$t('requests.leave_requests.empty_page.own.no_request.content'),
          headerText: this.$t('requests.leave_requests.empty_page.own.no_request.header'),
        },
        teams: {
          icon: 'TeamV2Icon',
          buttonText: this.$t('requests.leave_requests.empty_page.teams.no_request.button'),
          contentText: this.$t('requests.leave_requests.empty_page.teams.no_request.content'),
          headerText: this.$t('requests.leave_requests.empty_page.teams.no_request.header'),
        },
      },
      filterHandler: null,
    };
  },
  computed: {
    ...mapGetters('requests', [
      'areFiltersActive',
      'canAccessOwnTab',
      'currentShopIds',
      'isUpdatingNavigationContext',
      'ownAllDefaultFiltersLeaveRequests',
      'ownDefaultFiltersLeaveRequests',
      'tableFilters',
      'teamAllDefaultFiltersLeaveRequests',
      'teamDefaultFiltersLeaveRequests',
    ]),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation', 'currentNodeShops']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('navContext', ['navContext']),
    ...mapState('requests', [
      'availabilitiesPagination',
      'availabilityRequests',
      'isFetchingAvailabilityRequests',
      'isFetchingLeaveRequests',
      'leaveRequests',
      'pagination',
      'sort',
    ]),
    ...mapGetters('currentLicense', ['canManageEmployeeRequests', 'isSystemAdmin']),
    ...mapGetters('currentUser', ['currentUserInPlanningShopIds']),

    dataTest() {
      if (!this.isLeaveRequestsView) {
        return this.isOwnRequestsView ? 'availability-request__add-request__button' : '';
      }

      return this.isOwnRequestsView ?
        'leave-request__add-absence__button' :
        'leave-request__add-request__button';
    },
    defaultFilters() {
      if (this.isAllRequestsView) {
        return this.isOwnRequestsView ?
          this.ownAllDefaultFiltersLeaveRequests : this.teamAllDefaultFiltersLeaveRequests;
      }
      return this.isOwnRequestsView ?
        this.ownDefaultFiltersLeaveRequests : this.teamDefaultFiltersLeaveRequests;
    },
    emptyPageProps() {
      const pageProps = this.isLeaveRequestsView ?
        'emptyLeaveRequestsPageProps' :
        'emptyAvailabilityRequestsPageProps';

      return this.isOwnRequestsView ? this[pageProps].own : this[pageProps].teams;
    },
    emptyPageButtonCallback() {
      if (this.isOwnRequestsView) {
        return this.handleNewRequestButtonClick;
      }

      return this.isLeaveRequestsView ? this.handleDownloadGuideClick : () => {};
    },
    isFetchingRequests() {
      return this.isLeaveRequestsView ?
        this.isFetchingLeaveRequests :
        this.isFetchingAvailabilityRequests;
    },
    requests() {
      return this.isLeaveRequestsView ? this.leaveRequests : this.availabilityRequests;
    },
    requestsLabels() {
      const requestsLabels = this.isLeaveRequestsView ?
        this.leaveRequestsLabels :
        this.availabilityRequestsLabels;

      return this.isOwnRequestsView ? requestsLabels.own : requestsLabels.teams;
    },
    requestPageClasses() {
      return {
        'requests-page': true,
        'requests-page--downloading-guide': this.isDownloadingGuide,
      };
    },
    localStorageKey() {
      if (this.isLeaveRequestsView) {
        if (this.isAllRequestsView) {
          return this.isOwnRequestsView ? 'leave_requests_own_all' : 'leave_requests_teams_all';
        }
        return this.isOwnRequestsView ? 'leave_requests_own' : 'leave_requests_teams';
      }

      return this.isOwnRequestsView ? 'availability_requests_own' : 'availability_requests_teams';
    },
    storageFilterKeys() {
      if (this.isLeaveRequestsView) {
        if (this.isAllRequestsView) {
          return this.isOwnRequestsView ? ['status', 'absenceTypeId', 'shops'] : ['status', 'employeeId', 'teams', 'absenceTypeId', 'availabilityType'];
        }
        return this.isOwnRequestsView ? ['status', 'absenceTypeId'] : ['status', 'employeeId', 'teams', 'absenceTypeId'];
      }

      return this.isOwnRequestsView ? ['status', 'absenceTypeId', 'shops'] : ['status', 'employeeId', 'teams', 'absenceTypeId', 'availabilityType'];
    },
    loaderClasses() {
      return {
        requests__loader: true,
        'requests__loader--visible-sidebar': !this.isUpdatingNavigationContext,
      };
    },
    isAskRequestButtonDisabled() {
      let isCurrentUserDisplayedInPlanning = !this.currentUserInPlanningShopIds.length;

      if (!this.isAllRequestsView) {
        const currentShopId = parseInt(this.currentShop.id, 10);
        isCurrentUserDisplayedInPlanning = !this.currentUserInPlanningShopIds.includes(
          currentShopId,
        );
      }

      return this.isDownloadingGuide || isCurrentUserDisplayedInPlanning;
    },
  },
  watch: {
    async $route(to, from) {
      const hasRouteChanged = to.name !== from.name;
      const hasShopChanged = to.params.shop_id !== 'all' && from.params.shop_id !== to.params.shop_id;
      const hasClusterNodeChanged =
        (to.params.shop_id === 'all' && from.params.shop_id !== to.params.shop_id) ||
        from.params.cluster_node_id !== to.params.cluster_node_id;

      if (hasShopChanged) {
        this.setGrantingAccess({ isSelectedShopLoaded: false });

        await this.updateAndSelectShop(to.params.shop_id);
      } else if (hasClusterNodeChanged) {
        this.setGrantingAccess({
          areClusterNodeShopsLoaded: false,
          isSelectedClusterNodeLoaded: false,
        });

        await Promise.all([
          this.updateAndSelectClusterNode(to.params.cluster_node_id),
          this.fetchClusterNodeShops(to.params.cluster_node_id),
          this.updateCurrentShop({ shopId: 'all' }),
        ]);
      }

      if (to.name.includes('_own') && !this.canAccessOwnTab) {
        this.$router.replace({
          ...this.$route,
          name: 'leave_requests_teams',
        });

        return;
      }

      if (hasRouteChanged || hasShopChanged || hasClusterNodeChanged) {
        this.setFilters(this.defaultFilters);

        this.filterHandler = new GenericFilterHandler(
          filters => this.setFilters({ ...this.defaultFilters, ...filters }),
          this.storageFilterKeys,
          this.localStorageKey,
        );

        const routeFiltersQuery = this.filterHandler.applyFilters(this.$route);

        if (!isEmpty(routeFiltersQuery)) {
          this.$router.replace({
            name: to.name,
            params: to.params,
            query: { ...to.query, ...routeFiltersQuery },
          });

          return;
        }
      }

      this.initData();
    },
  },
  async mounted() {
    this.setFilters(this.defaultFilters);

    this.filterHandler = new GenericFilterHandler(
      filters => this.setFilters({ ...this.defaultFilters, ...filters }),
      this.storageFilterKeys,
      this.localStorageKey,
    );

    this.filterHandler.applyFilters(this.$route);

    if (this.$route.params.shop_id === 'all') {
      this.setGrantingAccess({
        areClusterNodeShopsLoaded: false,
        isSelectedClusterNodeLoaded: false,
      });

      await Promise.all([
        this.updateAndSelectClusterNode(this.$route.params.cluster_node_id),
        this.fetchClusterNodeShops(this.$route.params.cluster_node_id),
      ]);
    }

    if (this.$route.name.includes('_own') && !this.canAccessOwnTab) {
      this.$router.replace({
        ...this.$route,
        name: 'leave_requests_teams',
      });

      return;
    }

    this.initData();
  },
  methods: {
    ...mapActions('currentOrganisation', ['fetchShopsForClusterNode']),
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('navContext', ['selectShop', 'fetchCurrentClusterNode']),
    ...mapActions('requests', [
      'acceptLeaveRequest',
      'fetchAvailabilityRequests',
      'fetchLeaveRequests',
      'fetchManagedEmployees',
    ]),
    ...mapMutations('requests', ['setGrantingAccess', 'resetFilters', 'setFilters']),
    async updateAndSelectShop(shopId) {
      try {
        await this.updateCurrentShop({ shopId });

        this.selectShop(this.currentShop);
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      }

      this.setGrantingAccess({ isSelectedShopLoaded: true });
    },
    async updateAndSelectClusterNode(clusterNodeId) {
      try {
        await this.fetchCurrentClusterNode(clusterNodeId);
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      }

      this.setGrantingAccess({ isSelectedClusterNodeLoaded: true });
    },
    async fetchClusterNodeShops(clusterNodeId) {
      try {
        await this.fetchShopsForClusterNode({ clusterNodeId, onlyActiveShops: true });
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      }

      this.setGrantingAccess({ areClusterNodeShopsLoaded: true });
    },
    async acceptLeaveRequestThroughUrl() {
      this.isManagingRequestThroughUrl = true;
      this.$skAnalytics.track('leave_request_accept_through_url');

      try {
        const targetedRequest = await svcRequestsClient.getLeaveRequest(
          this.requestIdToAcceptThroughUrl,
        );

        if (!targetedRequest || !!targetedRequest.deletedAt) {
          this.$skToast({ message: this.$t('requests.leave_requests.toast.error.not_found'), variant: 'error' });

          return;
        }

        if (targetedRequest.status === 'pending') {
          await this.acceptLeaveRequest({
            leaveRequestId: targetedRequest.id,
            leaveRequestParams: {
              status: 'accepted',
              calculation: targetedRequest.calculation,
              startsAt: targetedRequest.startsAt,
              endsAt: targetedRequest.endsAt,
              shopId: targetedRequest.shopId,
              validatorId: this.currentUser.id,
            },
          });
        }
      } catch (error) {
        if (error.message === 'days_locked') {
          this.$skToast({ message: this.$t('requests.leave_requests.toast.error.days_locked'), variant: 'error' });
        } else if (error.response.status === 403) {
          this.$skToast({ message: error.response.data.error, variant: 'error' });
        } else if (error.response.status === 422) {
          this.$skToast({ message: this.$t('requests.leave_request.toast.error.unprocessable'), variant: 'error' });
        } else {
          this.$skToast({ message: this.$t('requests.toast.error'), variant: 'error' });

          throw error;
        }
      } finally {
        this.requestIdToAcceptThroughUrl = null;
        this.isManagingRequestThroughUrl = false;
      }

      this.$skToast({ message: this.$t('requests.leave_requests.toast.success.accept'), variant: 'success' });
    },
    async transferLeaveRequestThroughUrl() {
      this.isManagingRequestThroughUrl = true;
      this.$skAnalytics.track('leave_request_transfer_through_url');

      try {
        const targetedRequest = await svcRequestsClient.getLeaveRequest(
          this.requestIdToTransferThroughUrl,
        );

        if (!targetedRequest || !!targetedRequest.deletedAt) {
          this.$skToast({ message: this.$t('requests.leave_requests.toast.error.not_found'), variant: 'error' });

          return;
        }

        if (targetedRequest.status === 'pending') {
          this.emitOnRoot('open-transfer-request-modal', {
            request: targetedRequest,
            hideBackButton: true,
            source: { page: 'leave_requests' },
          });
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.$skToast({ message: error.response.data.error, variant: 'error' });
        } else {
          this.$skToast({ message: this.$t('requests.toast.error.unprocessable'), variant: 'error' });

          throw error;
        }
      } finally {
        this.requestIdToTransferThroughUrl = null;
        this.isManagingRequestThroughUrl = false;
      }
    },
    handleNewRequestButtonClick(event) {
      if (this.isLeaveRequestsView) {
        this.emitOnRoot('hide-dropdown-popover');

        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'new-leave-request-modal');
      } else if (this.isAvailabilityRequestsView) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'employee-availabilities-modal');
      }
    },
    async handleDownloadGuideClick() {
      const locale = this.$i18n.locale;

      const documentName = ['en', 'es', 'fr', 'it'].includes(locale) ?
        `employee_commitment_poster_${locale.toUpperCase()}.pdf` :
        'employee_commitment_poster_EN.pdf';

      const url = `${staticRootUrl}/documents/${documentName}`;

      this.isDownloadingGuide = true;

      // URL is an s3 bucket link
      // download attribute of anchor tag does not allow cross domain URLs and opens the file in the browser
      // so in order to download the file without opening it we have to use axios
      const response = await axios.create().get(url, { responseType: 'ps' });

      this.isDownloadingGuide = false;

      openFile({
        blob: response.data,
        fileName: this.$t('requests.leave_requests.guide_filename'),
        type: 'application/pdf',
        format: 'pdf',
      });

      this.$skAnalytics.track('leave_request_download_guide');
    },
    async initData() {
      const pagination = this.isLeaveRequestsView ? this.pagination : this.availabilitiesPagination;

      const shopIds = this.isAllRequestsView ?
        this.currentNodeShops.map(shop => shop.id) :
        [this.currentShop.id];

      const params = {
        filters: this.tableFilters,
        isAllRequestsView: this.isAllRequestsView,
        isOwnRequestsView: this.isOwnRequestsView,
        shopIds,
        sort: this.sort,
        userId: this.currentUser.id,
        pagination: this.$route.query.page ?
          { ...pagination, current_page: parseInt(this.$route.query.page, 10) } :
          pagination,
      };

      const promises = [];

      if (this.isLeaveRequestsView) {
        promises.push(this.fetchLeaveRequests(params));
      }

      if (this.isAvailabilityRequestsView) {
        promises.push(this.fetchAvailabilityRequests(params));
      }

      if (this.canManageEmployeeRequests || this.isSystemAdmin) {
        const managedEmployeeParams = {
          ...(this.isAllRequestsView ?
            { cluster_node_id: this.navContext.clusterNodeId } :
            { shop_id: this.currentShop.id }),
        };

        promises.push(this.fetchManagedEmployees(managedEmployeeParams));
      }

      try {
        await Promise.all(promises);
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.requests-page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;

  &--downloading-guide {
    cursor: progress;
  }

  .requests__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &--visible-sidebar {
      width: calc(100% - 310px + 32px); // sidebar width + global padding
    }
  }
}
</style>
