<template>
  <SkModal
    id="print-modal"
    ref="printModal"
    :modal-title="$t('badgings.print_timesheet_modal.title')"
    :modal-subtitle="$t('badgings.print_timesheet_modal.subtitle')"
    size="ds-medium"
    @show="handleShow"
    @cancel="closeCollapse"
    @close="closeCollapse"
  >
    <template #title-icon>
      <PrinterV2Icon
        class="print-modal__print-icon"
        width="30"
        height="30"
        fill="#727272"
      />
    </template>
    <template #body>
      <SkModalSection
        v-if="showEsignatureUpsellBanner"
        border-bottom="none"
      >
        <UpsellEsignatureBanner source="print" />
      </SkModalSection>
      <SkModalSection border-bottom="none">
        <div class="print-modal__line">
          <span class="print-modal__line__label">
            {{ $t('plannings.toolbar.modal.print.date_picker.label') }}
          </span>
          <div class="print-modal__line__datepickers">
            <!-- eslint-disable max-len -->
            <SkDatePicker
              ref="datepicker"
              v-model="startDate"
              :default-value="mondayAsDate"
              :placeholder="$t('plannings.toolbar.modal.print.date_picker.place_holders.start_date')"
              :disabled-date="disabledStartDate"
              :clearable="false"
              :lang="$i18n.locale"
              input-moment-format="DD MMM YYYY"
              append-to-body
              hide-footer
            />
            <!-- eslint-enable max-len -->
            <div class="print-modal__line__datepickers__separator">
              -
            </div>
            <SkDatePicker
              ref="datepicker"
              v-model="endDate"
              :default-value="sundayAsDate"
              :placeholder="$t('plannings.toolbar.modal.print.date_picker.place_holders.end_date')"
              :disabled-date="disabledEndDate"
              :clearable="false"
              :lang="$i18n.locale"
              input-moment-format="DD MMM YYYY"
              append-to-body
              hide-footer
            />
          </div>
        </div>
        <div
          v-if="notValidatedMatchedBadgings.length"
          class="print-modal__line"
        >
          <SkInfoCard>
            <span>
              {{ $t('badgings.print_timesheet_modal.days_not_validated_yet') }} {{ daysWithNoValidation }}
            </span>
          </SkInfoCard>
        </div>
        <SkCollapse
          v-if="isCollapseDisplayed"
          ref="collapse"
          class="print-modal__collapse"
        >
          <template #labelLeft>
            {{ $t('plannings.toolbar.modal.print.print_parameters.label') }}
          </template>

          <template #content>
            <template v-if="arePrintParametersDisplayed">
              <!-- eslint-disable-next-line max-len -->
              <div class="print-modal__collapse__section_title print-modal__collapse__section_title--details">
                {{ $t('plannings.toolbar.modal.print.print_parameters.details') }}
              </div>
              <div
                v-for="printParameter in displayedPrintParameters"
                :key="printParameter"
                class="
                  print-modal__collapse__parameter_line
                  print-modal__collapse__parameter_line--switch_line"
              >
                <label
                  :class="{ 'parameter__label--unselected': !printParameters[printParameter] }"
                >
                  {{ $t(`plannings.toolbar.modal.print.print_parameters.${printParameter}`) }}
                </label>
                <SkSwitch
                  v-model="printParameters[printParameter]"
                  @click.native="trackParametersChange(printParameter)"
                />
              </div>
            </template>
          </template>
        </SkCollapse>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <!--
        We simply open the pdf file in a new tab. File is server side rendered in real time
        and converted to pdf (on V1 endpoint).
      -->
      <SkOroraButton
        :disabled="submitButtonDisabled"
        @click="handleSubmit"
      >
        {{ $t('plannings.toolbar.modal.print.action.submit-button') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { isEmptyObject } from '@skello-utils/validators';
import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import UpsellEsignatureBanner from '@app-js/shared/components/UpsellEsignatureBanner';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';

const PRINT_PARAMETERS = {
  COUNTER_DISPLAY: 'counter_display',
  MEAL_IN_PDF: 'meal_in_pdf',
};

export default {
  name: 'PrintModal',
  components: {
    UpsellEsignatureBanner,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      pdfFormat: 'landscape',
      printParameters: {},
      areParametersSet: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', [
      'monday',
      'sunday',
      'currentDate',
    ]),
    ...mapGetters('currentShop', [
      'isMealCompensationDone',
      'isDevFlagEnabled',
      'checkFeatureFlag',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('badgings', [
      'isEmployeesView',
    ]),
    ...mapState('badgings', [
      'matchedBadgings',
      'matchedBadgingsLoading',
      'currentDate',
    ]),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsState', ['shopPlanningConfig']),
    ...mapState('currentLicense', ['currentLicense']),
    packOfferName() {
      return this.$t(`pack_offers.${this.currentOrganisation.attributes.packOffer.name}`);
    },
    invalidMonthlyPrint() {
      return !this.currentOrganisation.attributes.packOffer.month_plannings_print_enabled;
    },
    submitButtonDisabled() {
      return (this.isMonthlyPlanningPrint && this.invalidMonthlyPrint);
    },
    arePrintParametersDisplayed() {
      return this.displayedPrintParameters.length > 0;
    },
    mondayAsDate() {
      return skDate(this.monday).toDate();
    },
    sundayAsDate() {
      return skDate(this.sunday).toDate();
    },
    displayedPrintParameters() {
      const printParameters = [];
      if (this.currentShop.attributes.modulation) {
        printParameters.push(PRINT_PARAMETERS.COUNTER_DISPLAY);
      }
      if (this.isMealCompensationDone && this.currentShop.attributes.isMealRuleBenefitInKind) {
        printParameters.push(PRINT_PARAMETERS.MEAL_IN_PDF);
      }

      return printParameters;
    },
    isCollapseDisplayed() {
      if (!this.areParametersSet) return false;

      return this.arePrintParametersDisplayed;
    },
    urlTeamIds() {
      return this.$route.query?.team_ids || [];
    },
    generatePdfUrl() {
      const url = `/week_plannings/${this.currentShop.id}/attendance_sheets?`;

      const printParams = this.displayedPrintParameters.reduce((accumulator, printParameter) => {
        accumulator[printParameter] = this.printParameters[printParameter];
        return accumulator;
      }, {});

      const params = new URLSearchParams({
        export_type: 'attendance_sheets',
        detail: 'weekly_summary',
        start_date: this.startDate,
        end_date: this.endDate,
        // remove users without shifts from the report
        only_users_with_shifts: true,
        pdf_format: this.pdfFormat,
        keep_unassigned: !!printParams.allow_unassigned_shifts,
        birthday_display: !!printParams.birthday_display, // needs to be cast as Boolean if undefined, or backend skips it
        day_plannings_v3: false,
      });

      // If filters are set -> add them to the URL parameters to be displayed in the pdf
      if (this.urlTeamIds.length > 0) {
        this.urlTeamIds.forEach(teamId => params.append('filters[team][]', teamId));
      }

      return `${url}${params.toString()}`;
    },
    showEsignatureUpsellBanner() {
      // The check on the open_esignature_upsell url parameter is to avoid showing the upsell banner
      // if the user has already clicked the discover CTA.
      return this.checkFeatureFlag('FEATURE_ESIGNATURE') &&
        this.currentLicense.attributes.canPublishPlanning &&
        this.isFeatureEnabled(FEATURES.FEATURE_PLANNING, this.currentShop.id, () => true) &&
        !this.isFeatureEnabled(FEATURES.FEATURE_ELECTRONIC_SIGNATURE, this.currentShop.id, () => (
          this.currentShop.attributes.esignatureActive
        )) &&
        this.$route.query?.open_esignature_upsell !== 'true' &&
        this.isDevFlagEnabled('FEATUREDEV_ESIGNATURE_UPSELL');
    },
    pdfFormatSectionClasses() {
      return {
        'print-modal__collapse__section': true,
        'print-modal__collapse__section--no-border': !this.arePrintParametersDisplayed,
      };
    },
    daysWithNoValidation() {
      return [
        ...new Set(
          this.notValidatedMatchedBadgings
            .map(badging => skDate(badging.badgingStartsAt).format('DD/MM/YYYY')),
        ),
      ].join(', ');
    },
    notValidatedMatchedBadgings() {
      if (this.matchedBadgingsLoading || !this.matchedBadgings) return [];

      return this.matchedBadgings.filter(
        matchedBadging => !matchedBadging.validated && this.badgingIsInRange(matchedBadging),
      );
    },
    shiftIdsToPrint() {
      if (this.matchedBadgingsLoading || !this.matchedBadgings) return [];

      return this.matchedBadgings.filter(
        matchedBadging => (
          matchedBadging.validated &&
          matchedBadging.shift !== undefined &&
          this.badgingIsInRange(matchedBadging) &&
          !matchedBadging.ignored
        ),
      ).map(matchedBadging => matchedBadging.shift.id);
    },
    downloadTimesheetTrackerSource() {
      return this.isEmployeesView ? 'employee_history' : 'timeclock';
    },
  },
  methods: {
    handleShow() {
      this.startDate = this.mondayAsDate;
      this.endDate = this.sundayAsDate;

      // set selectecParameters according to shop settings after shopPlanningConfig are fetched
      if (!this.areParametersSet) {
        const currentParameters = this.shopPlanningConfig.attributes;
        this.printParameters = {
          meal_in_pdf: currentParameters?.mealInPdf,
          counter_display: currentParameters?.counterDisplay,
        };
        this.areParametersSet = true;
      }
    },
    tooltipForOption(option) {
      return option.id === 'month' && this.invalidMonthlyPrint ? this.$t('plannings.toolbar.actions_bar.icons_labels.print.tooltip', {
        pack_name_translated: this.packOfferName,
      }) : '';
    },
    async handleSubmit() {
      this.$skAnalytics.track('download_attendance_sheets', { source: this.downloadTimesheetTrackerSource });

      // Update only params for printing
      // allow_unassigned_shifts and birthday_display params are handled in generatePdf
      // because we don't want to update them in the planning config
      const printSettings = this.displayedPrintParameters
        .reduce((accumulator, printParameter) => {
          accumulator[printParameter] = this.printParameters[printParameter];
          return accumulator;
        }, {});

      // No params because there are none to be updated
      if (!isEmptyObject(printSettings)) {
        this.updateShopPrintParams(printSettings);
      }

      this.closeCollapse();
      this.$refs.printModal.hide(); // Close modal

      const newWindow = window.open('', '_blank');
      const response = await httpClient.get(this.generatePdfUrl);
      newWindow.location.href = response.data.url;
    },
    updateShopPrintParams(printSettings) {
      const params = {
        shop_planning_settings: {
          ...printSettings,
          page_break_in_pdf: this.printParameters.page_break_in_pdf,
        },
        shop_id: this.currentShop.id,
      };

      return httpClient.patch('/v3/api/plannings/shop/planning_config', params);
    },
    disabledStartDate(date) {
      if (
        skDate(date).isAfter(skDate(this.endDate))
      ) return true;

      return false;
    },
    disabledEndDate(date) {
      if (
        skDate(date).isBefore(skDate(this.startDate))
      ) return true;

      return false;
    },
    closeCollapse() {
      if (this.isCollapseDisplayed) this.$refs.collapse.closeCollapse();

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'print-modal');
    },
    trackParametersChange(parameter) {
      const action = this.printParameters[parameter] ? 'display' : 'hide';
      this.$skAnalytics.track(`planning_print_${parameter}_${action}`);
    },
    badgingIsInRange(badging) {
      const startsAt = badging.badgingStartsAt || badging.shift.attributes.startsAt;

      return skDate(startsAt)
        .isBetween(skDate(this.startDate), skDate(this.endDate), 'day', '[]');
    },
  },
};
</script>
<style lang="scss" scoped>
/* design adjustments to modal element */
label {
  display: inline-block;
  margin-bottom: .5rem;
}

#print-modal ::v-deep {
  .sk-modal__subtitle {
    margin-top: 0;
  }

  .sk-modal__section {
    padding-bottom: 24px;
  }
}

.print-modal__collapse__parameter_line-input {
  display: flex;
  align-items: center;
  width: 130px;
  white-space: nowrap;
}

/* design adjustments to collapse element */
::v-deep .sk-collapse {
  .sk-collapse__label {
    padding: 15px 24px;
    font-weight: $fw-semi-bold;
  }

  .sk-collapse__content {
    margin-top: 1px;
    padding: 0 24px 3px;
  }
}

/* design adjustments to radio elements */
::v-deep .sk-radio-v3 {
  .sk-radio__label {
    margin: 0;
  }
}

/* design adjustments to switch element */
::v-deep .sk-switch-v3 {
  height: 22px;

  .sk-switch__slider {
    margin: 0;
  }
}

.print-modal {
  &__print-icon {
    display: flex;
    padding: 6px;
    border-radius: 50%;
    background-color: $sk-grey-5;
  }

  &__description {
    margin-bottom: 24px;
    margin-top: -5px;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;

    &__label {
      font-weight: $fw-semi-bold;
      padding-right: 14px;
    }

    &__datepickers {
      display: flex;
      width: 320px;

      &__separator {
        padding: 10px 17px;
      }
    }
  }

  &__collapse {
    width: 572px;
    box-shadow: 4px 0 20px rgba(0, 0, 0, .08);
    border-radius: 4px;
    margin-top: 30px;
  }

  &__submit-button {
    margin-left: 10px;
    text-decoration: none;
  }

  &__select__option--disabled {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.print-modal__collapse__parameter_line {
  display: flex;
  align-items: center;
  padding: 5px 0 20px;

  .parameter__label--unselected {
    opacity: .3;
  }

  .parameter_line__label--clickable {
    cursor: pointer;
  }

  &--switch_line {
    justify-content: space-between;
  }
}

.print-modal__collapse__section {
  border-bottom: 1px solid $sk-grey-10;
  margin-bottom: 12px;
}

.print-modal__collapse__section--no-border {
  border-bottom: none;
}

.print-modal__collapse__section_title {
  padding: 2px 0 7px;
  color: $sk-grey;

  &--details {
    margin-bottom: 3px;
  }
}
</style>
