<template>
  <SkPopover
    v-if="!shopPlanningConfigLoading"
    ref="popularShiftsPopover"
    :class="popularShiftClasses"
    :title="$t('plannings.toolbar.actions_bar.icons_labels.popular_shifts.label')"
    placement="left-start"
    y-offset="12"
    title-separator
    @show-popover="handleShow"
    @hide-popover="handleHide"
  >
    <template #anchor>
      <div
        v-if="isDropdownMenuItem"
        class="popular-popover__anchor"
      >
        <FireV2Icon
          class="dropdown-menu__icon"
          width="15"
          height="15"
        />
        {{ $t('plannings.toolbar.actions_bar.icons_labels.popular_shifts.label') }}
      </div>
      <SkCircleButton
        v-else
        v-tooltip.bottom="$t('plannings.toolbar.actions_bar.icons_labels.popular_shifts.label')"
        icon="FireV2Icon"
      />
    </template>
    <!-- Popular shifts not loaded yet -->
    <template
      v-if="popularShiftsLoading"
      #content
    >
      <div class="popular-popover__loader">
        <LoaderContainer size="medium" />
      </div>
    </template>
    <template
      v-else-if="popularShifts.length === 0"
      #content
    >
      <div
        class="popular-popover__empty-state"
      >
        {{ $t('plannings.toolbar.actions_bar.icons_labels.popular_shifts.none') }}
      </div>
    </template>
    <!-- Popular shifts loaded -->
    <template
      v-else
      #content
    >
      <div class="popular-popover__wrapper">
        <div
          class="popular-popover__content"
          :style="zoomRange.planningWrapper"
        >
          <div
            v-for="popular in popularShifts"
            :key="`popular-${popular.id}`"
            class="content__popular-wrapper"
          >
            <Shift
              :shift="popular"
              :global-config="globalConfig"
              :is-popular-shift="true"
              draggable
              @dragstart.native="handleDragStart(popular, $event)"
              @dragend.native="handleDragEnd"
            />
          </div>
        </div>
      </div>
    </template>
  </SkPopover>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
  mapActions,
} from 'vuex';
import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';

import Shift from '@app-js/plannings/shared/components/Shift';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';
import { getDragDataFormat } from '@skello-utils/drag_and_drop';

export default {
  name: 'PopularShiftsPopover',
  components: { Shift, LoaderContainer },
  props: {
    isDropdownMenuItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSmallScreen: false,
    };
  },
  computed: {
    ...mapState('planningsShifts', ['popularShifts', 'popularShiftsLoading']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsState', ['shopPlanningConfigLoading', 'shopPlanningConfig']),
    ...mapGetters('currentUser', ['planningZoom']),
    zoomRange() {
      const { planningWrapper } = zoomPlanningWeek(this.planningZoom);
      return { planningWrapper };
    },
    popularShiftClasses() {
      return {
        'popular-popover--small-screen': this.isSmallScreen,
        'popular-popover--with-publish': this.currentLicense.attributes.canPublishPlanning,
        'popular-popover--without-publish': !this.currentLicense.attributes.canPublishPlanning,
      };
    },
    globalConfig() {
      return {
        currentShop: this.currentShop,
        customShift: this.shopPlanningConfig.attributes.customShift,
        planningZoom: this.planningZoom,
        /* This is needed to avoid calling getDocumentsCountByShiftId in <Shift />
          since popular shifts don't have documents
        */
        getDocumentsCountByShiftId: () => 0,
      };
    },
  },
  created() {
    this.detectSmallScreen();
    window.addEventListener('resize', this.detectSmallScreen);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectSmallScreen);
  },
  methods: {
    ...mapMutations('planningsState', ['setPopularShiftDragging']),
    ...mapActions('planningsShifts', ['fetchPopularShifts']),
    handleDragStart(popularShift, event) {
      event.dataTransfer.clearData();
      event.dataTransfer.setData(
        getDragDataFormat('shift'), JSON.stringify({ shift: popularShift, user: null }),
      );
      this.setPopularShiftDragging(true);
    },
    handleDragEnd() {
      this.setPopularShiftDragging(false);
    },
    handleHide() {
      this.emitOnRoot('setMenuVisibility', true);
    },
    handleShow() {
      this.emitOnRoot('setMenuVisibility', false);
      this.fetchPopularShifts(this.currentShop.id);
    },
    detectSmallScreen() {
      // When screen size is below 1200px, actionsBar collapse causing popularShift button to move
      this.isSmallScreen = window.innerWidth < 1200;
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-popover {
  // Overide popov placement since default placement isn't good
  // overflow-x isn't enough to correct the placement
  &--with-publish {
    ::v-deep .sk-popover {
      transform: translate3d(calc(100vw - 515px), 100px, 0) !important;
    }
  }

  &--without-publish {
    ::v-deep .sk-popover {
      transform: translate3d(calc(100vw - 330px), 100px, 0) !important;
    }
  }

  &--small-screen {
    width: 100%;

    ::v-deep .sk-popover {
      transform: translate3d(15%, 0, 0) !important;
    }
  }
}

.popular-popover__anchor {
  display: flex;
  align-items: center;
}

.popular-popover__loader {
  height: 92px;
  min-width: 212px;
}

.popular-popover__wrapper {
  width: 212px;
  max-height: 215px;
  overflow: scroll;
}

// This is needed because the layout depends on screen size
::v-deep .sk-popover {
  visibility: visible;
}

.popular-popover__content {
  // Add padding under last shift while preserving scroll
  &::after {
    content: '';
    display: inline-block;
    height: 10px;
  }
}

.popular-popover__empty-state {
  min-width: 212px;
  padding: 24px 16px 24px 16px;
  gap: 8px;
  opacity: 0px;
}

.content__popular-wrapper {
  box-sizing: border-box;
  border: 5px solid transparent;

  &:hover {
    border: 5px solid $sk-grey-10;
    border-radius: 8px;
  }
}

.dropdown-menu__icon {
  margin-right: 15px;
}
</style>
