<template>
  <div>
    <SkForm
      v-if="showFormOnly"
      class="sk-form--no-bottom-border sk-form__annualization"
      :title="$t('shop_settings.tabs.rules.counter_rules.annualization.activate')"
      split
      last
    >
      <template #title-icon>
        <div
          v-if="isAnnualizedWorkingTimeAvailable({ shop: currentShop })"
          class="annualization__icon-title__wrapper"
        >
          <CircledQuestionMarkIcon
            v-tooltip.top="$t('shop_settings.tabs.rules.counter_rules.annualization.description')"
            width="18"
            height="18"
          />
        </div>
      </template>
      <div class="sk-form__annualization__call-to-actions">
        <div class="row sk-form__row">
          <div class="col-3 pl-0 annualization__activation-switch">
            <template v-if="isAnnualizedWorkingTimeAvailable({ shop: currentShop })">
              <SkSwitch
                v-model="isAnnualizationActive"
                v-tooltip="disabledAnnualizationModalTooltip"
                data-test="annualization__switch"
                :disabled="canToggleAnnualization"
              />
              <SkTag
                v-if="isAnnualizationActive"
                variant="light"
              >
                {{ annualizationPeriodFormLabel }}
              </SkTag>
            </template>
            <UpsellPopover
              v-else
              :before-tag-text="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.upsell_tooltip_1')"
              :after-tag-text="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.upsell_tooltip_2')"
              :last-sentence="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.upsell_tooltip_3')"
            />
            <span v-if="!isAnnualizedWorkingTimeAvailable({ shop: currentShop })">
              <PackTag
                type="success"
                shadow
              />
            </span>
          </div>
        </div>
      </div>
    </SkForm>
    <template v-else-if="showCard">
      <div class="annualization-counter__title">
        <h3 class="col-6">
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization.activate') }}
        </h3>
        <SkSwitch
          v-model="isAnnualizationActive"
          data-test="annualization__switch"
          :disabled="canToggleAnnualization"
        />
        <SkTag
          class="annualization-counter__title--tag"
          variant="light"
        >
          {{ annualizationPeriodCardLabel }}
        </SkTag>
      </div>
      <div class="annualization-counter__card">
        <div class="annualization-counter__settings">
          <div class="annualization-counter__description">
            <h3>{{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.card.title') }}</h3>
            <div>{{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.card.sentence_1') }}</div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.card.sentence_2')" />
          </div>
          <CalendarIcon />
        </div>
        <div class="annualization-counter__cta">
          <SkOroraButton
            v-track="'annualization_display_advanced_parameters'"
            variant="tertiary"
            @click="$router.push({ name: 'shop_settings_details_annualization' })"
          >
            <div class="annualization-counter__cta--bold">
              {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.card.configuration.button') }}
            </div>
          </SkOroraButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { captureException } from '@sentry/vue';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

import skDate from '@skello-utils/dates';

import PackTag from '@app-js/shared/components/PackTag';

import CalendarIcon from './CalendarIcon';
import UpsellPopover from '../UpsellPopover';

export default {
  name: 'AnnualizationCounter',
  components: {
    CalendarIcon,
    PackTag,
    UpsellPopover,
  },
  props: {
    canEditEmployeeDataSettings: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentShop', [
      'checkFeatureFlag',
      'isAnnualizedWorkingTimeAvailable',
      'isDevFlagEnabled',
    ]),
    ...mapGetters('shopEmployees', ['annualizableEmployees']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopAnnualizationConfig', ['shopAnnualizationConfig']),
    annualizationPeriodDates() {
      const now = skDate();
      const initDate = skDate.utc(this.shopAnnualizationConfig.attributes.initializationDate);
      const startDate = skDate.utc(this.shopAnnualizationConfig.attributes.resetDate).subtract(1, 'year');

      const referenceDate = skDate.max([now, initDate]);

      startDate.set('year', referenceDate.year());

      if (startDate.isAfter(referenceDate)) {
        startDate.subtract(1, 'year');
      }

      const endDate = startDate.clone().add(1, 'year').subtract(1, 'day');

      return { startDate, endDate };
    },
    annualizationPeriodCardLabel() {
      const { startDate, endDate } = this.annualizationPeriodDates;

      return `${startDate.format('DD MMMM YYYY')} - ${endDate.format('DD MMMM YYYY')}`;
    },
    annualizationPeriodFormLabel() {
      const { startDate, endDate } = this.annualizationPeriodDates;

      return `${startDate.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}`;
    },
    canToggleAnnualization() {
      return !this.canEditEmployeeDataSettings || this.annualizableEmployees.length === 0;
    },
    isAnnualizationActive: {
      get() {
        // null means not set
        return !!this.shopAnnualizationConfig;
      },
      set(isActive) {
        if (isActive) {
          this.$skAnalytics.track('annualization_enable_switch');
          this.emitOnRoot(MODAL_SHOW_EVENT, event, 'annualization-config-modal');
        } else {
          this.$skAnalytics.track('annualization_disable_switch');
          this.$root.$emit('confirm', null, {
            title: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.annualization_v2.title'),
            description: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.annualization_v2.description'),
            actionText: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.annualization_v2.action'),
            onConfirmSuccess: this.deactivateAnnualization,
          });
        }
      },
    },
    disabledAnnualizationModalTooltip() {
      if (this.annualizableEmployees.length > 0 || this.isAnnualizationActive) return '';

      return this.$t('shop_settings.tabs.rules.counter_rules.annualization_v2.empty_users_explanation');
    },
    isAdvancedParameterFlagActive() {
      return this.checkFeatureFlag('FEATURE_ANNUALIZED_WORKING_TIME_V2_ADVANCED_PARAMETERS');
    },
    showCard() {
      return this.isAnnualizationActive &&
        this.isAdvancedParameterFlagActive;
    },
    showFormOnly() {
      return !this.isAnnualizationActive ||
        !this.isAdvancedParameterFlagActive;
    },
  },
  async mounted() {
    if (
      this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
      this.currentShop.attributes.isAnnualizationV2Active
    ) {
      await this.fetchShopAnnualizationConfig({
        shopId: this.currentShop.id,
      });
    } else {
      this.resetAnnualizationState();
    }
  },
  methods: {
    ...mapActions('shopAnnualizationConfig', ['deleteShopAnnualizationConfig', 'fetchShopAnnualizationConfig']),
    ...mapMutations('currentShop', ['setShopAttributes']),
    ...mapMutations('shopAnnualizationConfig', ['resetAnnualizationState']),
    async deactivateAnnualization() {
      return Promise.all([
        this.deleteShopAnnualizationConfig({ shopId: this.currentShop.id }),
        svcEmployeesClient.bulkDeleteAnnualizationConfigs(this.currentShop.id),
      ]).then(() => {
        this.$skAnalytics.track('annualization_deactive');
        this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_ANNUALIZATION_TRACKER_DISABLED);
        this.resetAnnualizationState();
        this.$skToast({
          message: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.annualization_v2.success'),
          variant: 'success',
        });
        this.setShopAttributes({ isAnnualizationV2Active: false });
      }).catch(() => {
        this.$skToast({
          message: this.$t('shop_settings.update_shop.error_message'),
          variant: 'error',
        });
      });
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.annualization-counter__title {
  display: flex;
  align-items: center;
  margin-top: 56px;
  padding-bottom: 24px;

  &--tag {
    height: fit-content;
  }
}

.annualization-counter__card {
  border-radius: 4px;
  border: 1px solid $sk-grey-10;
}

.annualization-counter__settings {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0;
}

.annualization-counter__description {
  display: flex;
  flex-direction: column;
  color: $sk-grey-50;

  h3 {
    color: $sk-black;
  }
}

.annualization-counter__details {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 24px 24px;
}

.annualization-counter__cta {
  display: flex;
  justify-content: center;
  padding: 12px 24px;
  border-top: 1px solid $sk-grey-10;

  &--bold {
    font-weight: $fw-semi-bold;
  }
}

.sk-form--no-bottom-border {
  border-bottom: none;
}

.annualization {
  &__activation-switch {
    padding: 0;
    display: flex;
    align-items: center;
  }
}
</style>
