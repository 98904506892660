<template>
  <SkModalV2
    id="manage-request-modal"
    ref="ManageLeaveRequestModal"
    class="manage-request-modal"
    :subtitle="subtitle"
    :title="title"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template
      v-if="request"
      #body
    >
      <div
        v-if="isContentLoading"
        class="manage-request-modal__loader-wrapper"
      >
        <SkLoader size="large" />
      </div>
      <form
        v-else
        @submit.prevent
      >
        <SkModalSectionV2 v-if="isReadOnly">
          <label>
            {{ $t('requests.leave_requests.manage_modal.status.label') }}
          </label>
          <span class="manage-request-modal__status">
            <SkTag :variant="statusVariant">
              {{ ManageLeaveRequestModalLabels.status[status] }}
            </SkTag>
            <span
              v-if="status !== 'pending'"
              class="manage-request-modal__status__updatedAt"
            >
              {{ statusUpdatedAtLabel }}
            </span>
          </span>
        </SkModalSectionV2>
        <ShopSelectRow
          v-if="isAllRequestsView"
          v-model="absenceShop"
          :shops="currentNodeShops"
          :is-disabled="true"
        />
        <AbsenceSelectRow
          ref="absenceRow"
          v-model="absenceId"
          :absences="absencesByShopId[request.shopId]"
          :is-disabled="isReadOnly"
        />
        <AbsenceTemporalityRow
          v-model="localTemporality"
          :is-disabled="isReadOnly"
        />
        <SkModalSectionV2 v-if="displayWeeklyRestsPicker">
          <label>
            {{ $t('requests.leave_requests.manage_modal.weekly_rests.label') }}
          </label>
          <SkMultipleDaysPicker
            v-if="temporality.fullDays[0] && temporality.fullDays[1]"
            v-model="weeklyRests"
            class="manage-request-modal__weekly-rests"
            :disabled="isReadOnly"
            :end-date="temporality.fullDays[1]"
            :lang="$i18n.locale"
            :see-all-button-label="$t('requests.leave_requests.manage_modal.weekly_rests.see_all')"
            :see-less-button-label="$t('requests.leave_requests.manage_modal.weekly_rests.see_less')"
            :selected-day-label="$t('requests.leave_requests.manage_modal.weekly_rests.selected_label')"
            :start-date="temporality.fullDays[0]"
            date-format="DD-MM-YYYY"
            drag-to-select
          />
        </SkModalSectionV2>
        <SkModalSectionV2>
          <label class="manage-request-modal__label">
            {{ $t('requests.leave_requests.manage_modal.number_of_days.label') }}
          </label>
          <span class="manage-request-modal__number-of-days">
            {{ numberOfDays }}
          </span>
        </SkModalSectionV2>
        <SkModalSectionV2
          v-if="comments.length > 0 || !isReadOnly"
          class="manage-request-modal__comments__section"
          border-bottom="none"
        >
          <label for="comment">
            {{ $t('requests.leave_requests.manage_modal.comments.label') }}
          </label>
          <div class="manage-request-modal__comments">
            <div
              v-for="comment in comments"
              :key="comment.message"
              class="manage-request-modal__comment"
            >
              <div class="manage-request-modal__comment__user-block">
                <SkAvatar
                  :image-url="comment.avatarUrl"
                  :text="comment.initials"
                  size="small"
                />
                <div class="manage-request-modal__comment__message">
                  <span class="manage-request-modal__comment__message--username">
                    {{ comment.userName }}
                  </span>
                  <span>
                    {{ comment.message }}
                  </span>
                </div>
              </div>
              <div
                v-if="!comment.isManager"
                class="manage-request-modal__comment__date"
              >
                {{ `${createDate} ${createTime}` }}
              </div>
              <div
                v-else
                class="manage-request-modal__comment__date"
              >
                {{ `${updateDate} ${updateTime}` }}
              </div>
            </div>
            <span
              v-show="!isReadOnly"
              class="manage-request-modal__manager-comment__textarea"
            >
              <SkMedallion
                icon="ClipboardIcon"
                :background-color="$skColors.skBlue10"
                :color="$skColors.skBlue50"
                size="small"
              />
              <SkTextarea
                v-model="managerMessage"
                data-test="manage-request__select-notes__input"
                :min-height="93"
                text-area-id="comment"
                :label="$t('requests.leave_requests.manage_modal.comments.placeholder')"
              />
            </span>
          </div>
        </SkModalSectionV2>
      </form>
    </template>

    <template
      v-if="request"
      #footer
    >
      <SkOroraButton
        v-if="isTransferButtonDisplayed"
        id="transfer-button"
        data-test="manage-request__transfer-button"
        type="button"
        variant="tertiary"
        :icon="'ArrowHalfCircleRightIcon'"
        :disabled="!canTransfer"
        @click="showTransferModal"
      >
        {{ $t('requests.leave_requests.manage_modal.transfer') }}
      </SkOroraButton>
      <SkOroraButton
        v-if="isReadOnly"
        type="button"
        variant="secondary"
        @click="hideModal()"
      >
        {{ $t('requests.leave_requests.manage_modal.cancel') }}
      </SkOroraButton>
      <SkOroraButton
        v-else-if="!isLoadingData"
        data-test="manage-request__deny-button"
        type="submit"
        variant="primary"
        :disabled="!canSubmit || isAccepting"
        :loading="isRefusing"
        :variant-color="$skColors.skError"
        @click="handleRefuse"
      >
        {{ $t('requests.leave_requests.manage_modal.refuse') }}
      </SkOroraButton>
      <SkOroraButton
        v-if="!isLoadingData && ((isOwnRequest && !isSystemAdmin) || status !== 'pending')"
        data-test="manage-request__delete-button"
        type="submit"
        variant="primary"
        :loading="isDeleting"
        :variant-color="$skColors.skError"
        @click="handleDelete"
      >
        {{ $t('requests.leave_requests.manage_modal.delete') }}
      </SkOroraButton>
      <SkOroraButton
        v-else-if="!isLoadingData && !isReadOnly"
        data-test="manage-request__accept-button"
        type="submit"
        variant="primary"
        :disabled="!canSubmit || isRefusing"
        :loading="isAccepting"
        @click="handleAccept"
      >
        {{ $t('requests.leave_requests.manage_modal.accept') }}
      </SkOroraButton>
      <div v-else />
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';

import { httpClient } from '@skello-utils/clients';

import AbsenceSelectRow from '@app-js/requests/shared/components/LeaveRequests/AbsenceSelectRow';
import RoutesMixins from '@app-js/requests/mixins/RoutesMixins';
import AbsenceTemporalityRow from '../../requests/shared/components/LeaveRequests/AbsenceTemporalityRow.vue';
import ShopSelectRow from '../../requests/shared/components/LeaveRequests/ShopSelectRow.vue';

export default {
  name: 'ManageLeaveRequestModal',
  components: {
    AbsenceSelectRow,
    AbsenceTemporalityRow,
    ShopSelectRow,
  },
  mixins: [RoutesMixins],
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ManageLeaveRequestModalLabels: {
        status: {
          pending: this.$t('requests.leave_requests.manage_modal.status.value.pending'),
          accepted: this.$t('requests.leave_requests.manage_modal.status.value.accepted'),
          refused: this.$t('requests.leave_requests.manage_modal.status.value.refused'),
        },
      },
      absenceId: null,
      comments: [],
      createDate: null,
      createTime: null,
      employeeName: null,
      isLoadingData: true,
      isAccepting: false,
      isDeleting: false,
      isRefusing: false,
      managerName: null,
      validatorName: null,
      managerMessage: null,
      request: null,
      sentTo: null,
      status: null,
      updateDate: null,
      updateTime: null,
      weeklyRests: [],
      temporality: { duration: null, fullDays: [], halfDay: null },
      canTransfer: null,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('currentUser', ['isSystemAdmin']),
    ...mapGetters('employees', ['getAvatarUrlForUser']),
    ...mapGetters('planningsShifts', ['shiftsForCurrentPeriod']),
    ...mapGetters('planningsState', ['periodDates']),
    ...mapGetters('requests', [
      'currentShopIds',
      'isUpdatingNavigationContext',
      'tableFilters',
    ]),
    ...mapState('currentOrganisation', ['currentNodeShops']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('requests', ['absencesByShopId', 'managersByEmployeeId', 'sort', 'pagination']),
    useSvcRequests() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1');
    },
    statusUpdatedAtLabel() {
      const managerName = this.useSvcRequests ? this.validatorName : this.managerName;

      return this.$t('requests.leave_requests.manage_modal.status.updated_at', {
        date: this.updateDate,
        time: this.updateTime,
        managerName,
      });
    },
    title() {
      return this.isLoadingData ?
        this.$t('requests.leave_requests.manage_modal.title.loading') :
        this.$t('requests.leave_requests.manage_modal.title.loaded', { employeeName: this.employeeName });
    },
    subtitle() {
      if (this.isLoadingData) {
        return null;
      }

      if (this.useSvcRequests && this.request.firstReceiver) {
        const managerName = `${this.request.firstReceiver.firstName} ${this.request.firstReceiver.lastName}`;

        return this.$t('requests.leave_requests.manage_modal.subtitle_with_manager', {
          date: this.createDate,
          managerName,
          time: this.createTime,
        });
      }

      return this.$t('requests.leave_requests.manage_modal.subtitle', {
        date: this.createDate,
        time: this.createTime,
      });
    },
    absenceShop() {
      return this.request?.shop?.id ?? '';
    },
    localTemporality: {
      get() {
        return this.temporality;
      },
      set(value) {
        const shop = this.isAllRequestsView ? this.currentNodeShop : this.currentShop;

        this.temporality = value;
        this.weeklyRests =
          this.getSelectedDays(shop?.attributes?.paidVacationCalculationType);
      },
    },
    areDatesValid() {
      return (
        (this.temporality.duration === 'day' && this.temporality.fullDays[0]) ||
        (this.temporality.duration === 'half-day' && this.temporality.halfDay)
      );
    },
    displayWeeklyRestsPicker() {
      return !this.isReadOnly && this.temporality.duration === 'day' && this.temporality.fullDays[0];
    },
    canSubmit() {
      return this.areDatesValid;
    },
    fetchShiftsParams() {
      const { startsAt, endsAt } = this.periodDates(this.source.sub);

      return {
        shop_id: this.request.shopId,
        starts_at: startsAt,
        ends_at: endsAt,
        is_monthly_fetch: this.source.sub === 'month',
        isRefetch: true,
      };
    },
    fetchPendingLeaveRequestShiftsParams() {
      const { startsAt, endsAt } = this.periodDates(this.source.sub);

      if (this.useSvcRequests) {
        return {
          shopIds: JSON.stringify([this.request.shopId]),
          startsAt,
          endsAt,
          statusFilters: JSON.stringify(['pending']),
          skipPagination: true,
        };
      }

      return {
        shop_ids: [this.request.shopId],
        starts_at: startsAt,
        ends_at: endsAt,
        statuses: 'pending',
        skip_pagination: true,
      };
    },
    isAssignedToMe() {
      const receiverId = this.request?.receiver?.id;
      return receiverId && this.currentUser.id === receiverId.toString();
    },
    isOwnRequest() {
      const userId = this.useSvcRequests ? this.request?.employeeId : this.request?.userId;
      return userId && this.currentUser.id === userId.toString();
    },
    isReadOnly() {
      return this.status !== 'pending' || (this.isOwnRequest && !this.isSystemAdmin);
    },
    numberOfDays() {
      if (this.temporality.duration === 'half-day') {
        return this.$t('requests.half_day');
      }

      const { fullDays } = this.temporality;
      const daysDiff = skDate(fullDays[1]).diff(fullDays[0], 'days') + 1;
      const numberOfDays = daysDiff - this.weeklyRests.length;

      return `${numberOfDays} ${this.$tc('requests.day', numberOfDays)}`;
    },
    statusVariant() {
      switch (this.status) {
        case 'accepted':
          return 'green';
        case 'refused':
          return 'danger';
        default:
          return 'orange';
      }
    },
    iAmTheOnlyAdminSystem() {
      const managers = this.managersByEmployeeId[this.request.employeeId];

      if (!this.isSystemAdmin || !managers) {
        return false;
      }

      return managers.length === 1 && managers[0].id === parseInt(this.currentUser.id, 10);
    },
    isTransferButtonDisplayed() {
      return !this.isLoadingData &&
        this.request?.status === 'pending' &&
        this.isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P2');
    },
    currentNodeShop() {
      return this.currentNodeShops.find(shop => shop.id === this.request.shopId);
    },
    isContentLoading() {
      let isContentLoading = this.isLoadingData;

      if (this.source.page === 'leave_requests') {
        isContentLoading ||= this.isUpdatingNavigationContext;
      }

      return isContentLoading;
    },
  },
  async created() {
    this.listenOnRoot('open-manage-request-modal', request => {
      this.request = request;
      // eslint-disable-next-line max-len
      this.canTransfer = this.useSvcRequests && !(this.iAmTheOnlyAdminSystem && this.isAssignedToMe);

      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'manage-request-modal');
    });
  },
  methods: {
    ...mapActions('planningsShifts', ['fetchShifts', 'fetchPendingLeaveRequestShifts']),
    ...mapActions('requests', [
      'acceptLeaveRequest',
      'deleteLeaveRequest',
      'fetchLeaveRequests',
      'fetchManagers',
      'fetchPendingRequestsCount',
      'fetchShopAbsences',
      'refuseLeaveRequest',
    ]),
    ...mapMutations('planningsState', ['closeTotalPeriodTab']),

    async fetchAllShifts() {
      if (this.source.sub !== 'month') {
        this.closeTotalPeriodTab();
      }

      this.fetchShifts({ params: this.fetchShiftsParams });
      this.fetchPendingLeaveRequestShifts(this.fetchPendingLeaveRequestShiftsParams);
    },
    getSelectedDays(paidVacationCalculationType) {
      const defaultSelectedDays = [];
      switch (paidVacationCalculationType) {
        case 'working_day':
          defaultSelectedDays.push(6, 7);
          break;

        case 'opening_day':
          defaultSelectedDays.push(7);
          break;

        default:
          break;
      }

      const days = [];
      const currentDay = skDate(this.temporality.fullDays[0]);
      const endDay = skDate(this.temporality.fullDays[1]);

      while (currentDay <= endDay) {
        if (defaultSelectedDays.includes(currentDay.isoWeekday())) {
          days.push(currentDay.format('DD-MM-YYYY'));
        }
        currentDay.add(1, 'day');
      }

      return days;
    },
    async handleShow() {
      const promises = [];

      if (!this.managersByEmployeeId[this.request.employeeId]) {
        promises.push(
          this.fetchManagers({
            shopId: this.request.shopId,
            employeeId: this.request.employeeId,
          }),
        );
      }

      if (!this.absencesByShopId[this.request.shopId]) {
        promises.push(this.fetchShopAbsences(this.request.shopId));
      }

      if (promises.length > 0) {
        this.isLoadingData = true;

        try {
          await Promise.all(promises);
        } catch (error) {
          this.$skToast({
            message: this.$t('requests.toast.error'),
            variant: 'error',
          });
          throw error;
        }
      }

      this.isLoadingData = false;
      this.setModalData();
    },
    async handleAccept() {
      let leaveRequestParams;
      if (this.useSvcRequests) {
        leaveRequestParams = {
          calculation: this.temporality.duration,
          positionId: this.absenceId,
          weeklyRests: this.weeklyRests.map(date => skDate(date, 'DD-MM-YYYY').format('YYYY-MM-DD')),
          endsAt: this.temporality.duration === 'day' ? this.temporality.fullDays[1] : this.temporality.halfDay,
          managerMessage: this.managerMessage,
          startsAt: this.temporality.duration === 'day' ? this.temporality.fullDays[0] : this.temporality.halfDay,
          status: 'accepted',
          validatorId: this.currentUser.id,
          shopId: this.request.shopId,
          userId: this.request.employeeId,
        };
      } else {
        leaveRequestParams = {
          leave_request: {
            absence_calculation: this.temporality.duration,
            absence_id: this.absenceId,
            breaks: this.weeklyRests,
            end_date: this.temporality.duration === 'day' ? this.temporality.fullDays[1] : this.temporality.halfDay,
            manager_message: this.managerMessage,
            start_date: this.temporality.duration === 'day' ? this.temporality.fullDays[0] : this.temporality.halfDay,
            status: 'accepted',
          },
        };
      }

      this.isAccepting = true;

      try {
        const response = await this.acceptLeaveRequest({
          leaveRequestId: this.request.id,
          leaveRequestParams,
        });

        if (this.source.page === 'plannings') {
          this.$skAnalytics.track('leave_request_processed', {
            source: this.source.sub === 'month' ? 'monthly_schedule' : 'weekly_schedule',
          });

          if (this.useSvcRequests) {
            await this.onUserAbsencesCountChange(leaveRequestParams);
          }

          await this.fetchAllShifts();
        }

        if (this.source.page === 'leave_requests') {
          const shopIds = this.isAllRequestsView ?
            this.currentNodeShops.map(shop => shop.id) :
            [this.currentShop.id];
          this.$skAnalytics.track('leave_request_processed', { source: 'absence_space' });
          await this.fetchLeaveRequests({
            isOwnRequestsView: this.isOwnRequest,
            shopIds,
            userId: this.useSvcRequests ? this.request.employeeId : this.request.userId,
            pagination: this.pagination,
            sort: this.sort,
            filters: this.tableFilters,
            isAllRequestsView: this.isAllRequestsView,
          });
        }

        let toastMessage = this.$t('requests.leave_requests.toast.success.accept');

        if (
          !this.useSvcRequests &&
          response.data.conflict_management
        ) {
          // when a shift is already planned on the period of the leave request
          toastMessage = this.$t('requests.leave_requests.toast.success.accept_with_conflict');
        }

        this.$skToast({ message: toastMessage, variant: 'success' });
      } catch (error) {
        if (error.message === 'days_locked') {
          this.$skToast({
            message: this.$t('requests.leave_requests.toast.error.days_locked'),
            variant: 'error',
          });
        } else if (error.response.status === 403) {
          this.$skToast({
            message: error.response.data.error,
            variant: 'error',
          });
        } else {
          this.$skToast({
            message: this.$t('requests.toast.error'),
            variant: 'error',
          });
          throw error;
        }
      } finally {
        if (this.currentShop.id !== 'all') {
          this.fetchPendingRequestsCount({ shop_id: this.currentShop.id });
        }

        this.hideModal();
        this.isAccepting = false;
      }
    },
    async onUserAbsencesCountChange(leaveRequest) {
      // from svc-requests, shift creation is asynchronous
      // we need to wait for the shifts to be created before reloading planning shifts
      // -->  fetch the shifts of the concerned user and unassigned shifts (conflict)
      //      on the leave request period until the count changes

      const startDate = skDate.utc(leaveRequest.startsAt);
      const endDate = skDate.utc(leaveRequest.endsAt);
      const userId = Number(leaveRequest.userId);

      const currentShiftsCount = this.shiftsForCurrentPeriod
        .reduce((sum, shift) => {
          const isUserShiftOrUnassignedAndInPeriod =
            (!shift.attributes.userId || shift.attributes.userId === userId) &&
            skDate.utc(shift.attributes.startsAt).isSameOrAfter(startDate) &&
            skDate.utc(shift.attributes.endsAt).isSameOrBefore(endDate);

          return isUserShiftOrUnassignedAndInPeriod ? sum + 1 : sum;
        }, 0);

      const urlQueryParams = new URLSearchParams({
        starts_at: leaveRequest.startsAt,
        ends_at: leaveRequest.endsAt,
        user_ids: [leaveRequest.userId],
        shop_id: leaveRequest.shopId,
      });

      const fetchShifts = httpClient.get(`/v3/api/plannings/shifts?${urlQueryParams}`);

      const maxRetries = 20; // 1 try every .5 second = 10s max
      let tries = 1;
      let newShiftsCount;

      do {
        // eslint-disable-next-line no-await-in-loop
        await new Promise(resolve => setTimeout(resolve, 500));
        // eslint-disable-next-line no-await-in-loop
        const shifts = await fetchShifts;

        newShiftsCount = shifts.data.data.length;
        tries += 1;
      } while (tries <= maxRetries && currentShiftsCount === newShiftsCount);
    },
    async handleDelete() {
      this.isDeleting = true;
      try {
        await this.deleteLeaveRequest({ attributes: { id: this.request.id } });
        if (this.source.page === 'plannings') {
          this.$skAnalytics.track('leave_request_deleted', {
            source: this.source.sub === 'month' ? 'monthly_schedule' : 'weekly_schedule',
          });

          await this.fetchAllShifts();
        }

        if (this.source.page === 'leave_requests') {
          this.$skAnalytics.track('leave_request_deleted', { source: 'absence_space' });
        }

        this.$skToast({
          message: this.$t('requests.leave_requests.toast.success.delete'),
          variant: 'success',
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      } finally {
        this.isDeleting = false;
      }

      this.hideModal();
    },
    async handleRefuse() {
      let leaveRequestParams;
      if (this.useSvcRequests) {
        leaveRequestParams = {
          status: 'refused',
          managerMessage: this.managerMessage,
          shopId: this.request.shopId,
          userId: this.request.employeeId,
          startsAt: this.request.startsAt,
          endsAt: this.request.endsAt,
          validatorId: this.currentUser.id,
          posteId: this.absenceId,
        };
      } else {
        leaveRequestParams = {
          leave_request: {
            status: 'refused',
            manager_message: this.managerMessage,
            shop_id: this.request.shopId,
            user_id: this.request.userId,
            start_date: this.request.startDate,
            end_date: this.request.endDate,
          },
        };
      }

      this.isRefusing = true;

      try {
        await this.refuseLeaveRequest({ leaveRequestId: this.request.id, leaveRequestParams });

        if (this.source.page === 'plannings') {
          this.$skAnalytics.track('leave_request_processed', {
            source: this.source.sub === 'month' ? 'monthly_schedule' : 'weekly_schedule',
          });

          await this.fetchAllShifts();
        }

        if (this.source.page === 'leave_requests') {
          const shopIds = this.isAllRequestsView ?
            this.currentNodeShops.map(shop => shop.id) :
            [this.currentShop.id];
          this.$skAnalytics.track('leave_request_processed', { source: 'absence_space' });

          await this.fetchLeaveRequests({
            isOwnRequestsView: this.isOwnRequest,
            shopIds,
            userId: this.useSvcRequests ? this.request.employeeId : this.request.userId,
            pagination: this.pagination,
            sort: this.sort,
            filters: this.tableFilters,
            isAllRequestsView: this.isAllRequestsView,
          });
        }
      } catch {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });
      } finally {
        if (this.currentShop.id !== 'all') {
          this.fetchPendingRequestsCount({ shop_id: this.currentShop.id });
        }

        this.isRefusing = false;
        this.$skToast({
          message: this.$t('requests.leave_requests.toast.success.refuse'),
          variant: 'success',
        });
      }

      this.hideModal();
    },
    showTransferModal() {
      this.emitOnRoot('open-transfer-request-modal', { request: this.request, source: this.source });
      this.hideModal();
    },
    hideModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'manage-request-modal');
    },
    initials(userName) {
      return userName
        .split(' ')
        .map(name => name.charAt(0).toUpperCase())
        .join('');
    },
    handleCancel() {
      this.$nextTick(() => {
        this.absenceId = null;
        this.comments = [];
        this.createDate = null;
        this.createTime = null;
        this.employeeName = null;
        this.employeeMessage = null;
        this.managerMessage = null;
        this.managerName = null;
        this.validatorName = null;
        this.sentTo = null;
        this.status = null;
        this.updateDate = null;
        this.updateTime = null;
        this.weeklyRests = [];
      });
    },
    setModalData() {
      // Title and subtitle
      this.employeeName = this.useSvcRequests ? `${this.request.employee.firstName} ${this.request.employee.lastName}` : `${this.request.userFirstName} ${this.request.userLastName}`;
      this.createDate = skDate(this.request.createdAt).format('DD/MM/YYYY');
      this.createTime = skDate(this.request.createdAt).format('HH[h]mm');

      // Status and update dates
      this.status = this.request.status;
      if (this.isReadOnly) {
        this.updateDate = skDate(this.request.updatedAt).format('DD/MM/YYYY');
        this.updateTime = skDate(this.request.updatedAt).format('HH[h]mm');
        this.managerName = `${this.request.managerFirstName} ${this.request.managerLastName}`;
        this.validatorName = this.request.validator ? `${this.request.validator.firstName} ${this.request.validator.lastName}` : '-';
      }

      const shop = this.isAllRequestsView ? this.currentNodeShop : this.currentShop;

      // Absence type select
      this.$nextTick(() => {
        this.$refs.absenceRow.selectOption(this.request.position?.absenceKey);
      });

      // Duration radios and date pickers
      this.temporality.duration = this.useSvcRequests ?
        this.request.calculation :
        this.request.absenceCalculation;

      if (this.temporality.duration === 'day') {
        this.temporality.fullDays = this.useSvcRequests ?
          [this.request.startsAt, this.request.endsAt] :
          [this.request.startDate, this.request.endDate];
      } else {
        this.temporality.halfDay = this.useSvcRequests ?
          this.request.startsAt :
          this.request.startDate;
      }

      // Default weekly rests
      this.weeklyRests =
        this.getSelectedDays(shop.attributes.paidVacationCalculationType);

      const managerId = this.useSvcRequests && this.request.firstReceiver ?
        this.request.firstReceiver.id :
        this.request.managerId;

      // Send to manager select
      this.sentTo = managerId;

      // Comments
      this.comments = [];

      if (this.request.employeeMessage) {
        this.comments.push({
          userName: this.employeeName,
          avatarUrl: this.getAvatarUrlForUser(this.request.userId),
          message: this.request.employeeMessage,
          initials: this.initials(this.employeeName),
          isManager: false,
        });
      }

      if (this.request.managerMessage) {
        this.comments.push({
          userName: this.validatorName,
          avatarUrl: this.getAvatarUrlForUser(managerId),
          message: this.request.managerMessage,
          initials: this.initials(this.validatorName),
          isManager: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-request-modal__loader-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100px;
}

.manage-request-modal label {
  font-weight: $fw-semi-bold;
  margin: 0;
}

.manage-request-modal__label {
  font-weight: $fw-semi-bold;
}

::v-deep .sk-radio__label {
  margin-bottom: 0;
}

.manage-request-modal__status__updatedAt {
  color: $sk-grey-50;
  line-height: 24px;
}

.sk-modal__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manage-request-modal__comments__section {
  align-items: flex-start;
}

.manage-request-modal__radios,
.manage-request-modal__status {
  display: flex;
  gap: 16px;
  width: 394px;
  align-items: center;
}

.manage-request-modal__weekly-rests {
  width: 394px
}

.manage-request-modal__number-of-days {
  display: flex;
  width: 394px;
  color: $sk-grey-50;
}

.manage-request-modal__comments {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 394px;
}

.manage-request-modal__comment {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.manage-request-modal__comment__user-block {
  display: flex;
  gap: 8px;
}

.manage-request-modal__comment__message {
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 16px;
  max-width: 230px;

  &--username {
    font-weight: $fw-semi-bold;
  }
}

.manage-request-modal__comment__date {
  color: $sk-grey-50;
}

.manage-request-modal__manager-comment__textarea {
  display: flex;
  gap: 8px;
}

#transfer-button {
  margin-right: auto;
}
</style>
