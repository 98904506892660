<template>
  <div>
    <div
      v-show="pdfRendered"
      class="pdf-viewer"
    >
      <button
        :class="leftArrowIconClasses"
        @click="previousPage"
      >
        <ChevronLeftIcon />
      </button>
      <div class="pdf-viewer-canvas-wrapper">
        <canvas
          ref="pdfCanvas"
          class="pdf-viewer-canvas"
          @mousemove="onMouseMove"
          @click="onClick"
        />
        <button
          class="pdf-viewer-icon pdf-viewer-icon-magnifying-glass"
          @click="openDocumentInNewTab"
        >
          <MagnifyingGlassIcon />
        </button>
        <div class="pdf-viewer-page-stats">
          {{ pageStats }}
        </div>
        <SignatureSelector
          v-for="signature in signatures"
          v-show="shouldShow(signature)"
          :ref="`signatureSelector-${signature.signer.id}`"
          :key="signature.signer.name"
          :signature="signature"
          :mouse-x="mouseX"
          :mouse-y="mouseY"
          :width="selectorWidth"
          :height="selectorHeight"
          :box-style="signature.boxStyle"
          @close="removeSignature"
        />
      </div>
      <button
        :class="rightArrowIconClasses"
        @click="nextPage"
      >
        <ChevronRightIcon />
      </button>
    </div>
    <div
      v-show="!pdfRendered && !pdfLoadingFailed"
      class="pdf-viewer-loading"
    >
      <p>{{ $t('employees.tabs.documents.loading') }}</p>
      <SkLoader />
    </div>
    <div v-show="pdfLoadingFailed">
      <p>{{ $t('employees.tabs.documents.error') }}</p>
    </div>
  </div>
</template>

<script>
import * as pdfjs from 'pdfjs-dist';
import * as pdfWorker from 'pdfjs-dist/build/pdf.worker.js';
import { SIGNATURE_SELECTOR_COLORS } from '@app-js/shared/constants/colors';
import { authClient } from '@skello-utils/clients/auth_client';
import SignatureSelector from './SignatureSelector';

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

export default {
  name: 'PdfViewer',
  components: {
    SignatureSelector,
  },
  props: {
    pdfUrl: {
      type: String,
      required: true,
    },
    signers: {
      type: Array,
      required: true,
      validator: signers => (signers.length > 0),
    },
  },
  data() {
    return {
      pdfDoc: null,
      currentPage: 1,
      hoveredPages: {},
      numPages: 0,
      pdfCanvas: null,
      pdfRendered: false,
      pdfLoadingFailed: false,
      mouseX: 0,
      mouseY: 0,
      signatures: [],
    };
  },
  computed: {
    leftArrowIconClasses() {
      return {
        'pdf-viewer-icon': true,
        'pdf-viewer-icon--disabled': this.currentPage === 1,
      };
    },
    rightArrowIconClasses() {
      return {
        'pdf-viewer-icon': true,
        'pdf-viewer-icon--disabled': this.currentPage === this.numPages,
      };
    },
    pageStats() {
      return `${this.currentPage} / ${this.numPages} ${this.$t('employees.tabs.documents.pages')}`;
    },
    currentSignature() {
      return this.signatures.find(signature => !signature.placed);
    },
    currentSignatureSelector() {
      return this.$refs[`signatureSelector-${this.currentSignature.signer.id}`][0];
    },
    allSignaturesPlaced() {
      return this.signatures.every(signature => signature.placed);
    },
    scalingFactor() {
      return this.pdfCanvas.width / this.pdfCanvas.offsetWidth;
    },
    pdfDimensions() {
      return this.pdfCanvas.getBoundingClientRect();
    },
    selectorWidth() {
      return 200; // date field width
    },
    selectorHeight() {
      return 63; // signature + date field height
    },
  },
  mounted() {
    this.buildSignatures();
    this.pdfCanvas = this.$refs.pdfCanvas;
    this.loadPdf();
  },
  methods: {
    buildSignatures() {
      this.signatures = this.signers.map((signer, index) => ({
        signer: { id: signer.id, name: signer.text },
        placed: false,
        x: 0,
        y: 0,
        page: 1,
        // width and height as defined by Product team
        width: 99,
        height: 39,
        boxStyle: SIGNATURE_SELECTOR_COLORS[index],
      }));
    },
    async loadPdf() {
      try {
        const authToken = await authClient.getAuthToken();
        this.pdfDoc = await pdfjs.getDocument({
          url: this.pdfUrl,
          httpHeaders: { Authorization: `Bearer ${authToken.token}` },
          withCredentials: true,
        }).promise;
        this.numPages = this.pdfDoc.numPages;
        this.hoveredPages = Object.fromEntries(
          Array.from({ length: this.numPages }, (_, i) => [i + 1, false]),
        );
        await this.renderPage();
      } catch (error) {
        this.pdfLoadingFailed = true;
      }
    },
    async renderPage() {
      const page = await this.pdfDoc.getPage(this.currentPage);
      const viewport = page.getViewport({ scale: 1 });
      this.pdfCanvas.width = viewport.width;
      this.pdfCanvas.height = viewport.height;
      const canvasContext = this.pdfCanvas.getContext('2d');
      await page.render({ canvasContext, viewport });
      this.pdfRendered = true;
    },
    shouldShow(signature) {
      if (!this.hoveredPages[this.currentPage]) return false;
      if (signature.placed && signature.page === this.currentPage) return true;
      if (!signature.placed && signature === this.currentSignature) return true;
      return false;
    },
    onMouseMove(event) {
      this.mouseX = this.getDimensionInsideRange(event.offsetX, this.pdfDimensions.width);
      this.mouseY = this.getDimensionInsideRange(event.offsetY, this.pdfDimensions.height);

      this.hoveredPages[this.currentPage] = true;
    },
    onClick() {
      if (!this.currentSignature) return;
      if (this.currentSignature.placed) return;
      this.placeSignature();
      if (this.allSignaturesPlaced) this.emitSignatures();
    },
    emitSignatures() {
      const signatures = this.signatures.map(signature => {
        const { signer, page, width, height } = signature;
        let { x, y } = signature;
        x = Math.round(x * this.scalingFactor);
        y = Math.round(y * this.scalingFactor);
        return { signer, x, y, page, width, height };
      });
      this.$emit('load-signatures', { signatures });
    },
    placeSignature() {
      const selector = this.currentSignatureSelector;
      this.currentSignature.x = selector.isOffCanvasHorizontally ? selector.left : this.mouseX;
      this.currentSignature.y = selector.isOffCanvasVertically ? selector.top : this.mouseY;
      this.currentSignature.page = this.currentPage;
      this.currentSignature.placed = true;
    },
    removeSignature(signature) {
      signature.placed = false;
      this.$emit('clean-signatures');
    },
    nextPage() {
      if (this.currentPage >= this.numPages) return;
      this.currentPage += 1;
      this.renderPage();
    },
    previousPage() {
      if (this.currentPage <= 1) return;
      this.currentPage -= 1;
      this.renderPage();
    },
    openDocumentInNewTab() {
      window.open(this.pdfUrl, '_blank');
    },
    getDimensionInsideRange(value, maxValue) {
      if (value <= 0) return 0;
      if (value >= maxValue) return maxValue;
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-viewer {
  display: flex;
  align-items: center;
}

.pdf-viewer-canvas-wrapper {
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: crosshair;
}

.pdf-viewer-canvas {
  width: 100%;
  border: 1px solid $sk-grey-30;
  border-radius: 4.37px;
}

.pdf-viewer-icon {
  height: 50px;
  border: none;
  border-radius: 50%;
  padding: 10px 20px;
  background-color: $sk-white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .12);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-text-l;
  cursor: pointer;
  transition: all .3s ease;
}

.pdf-viewer-icon:hover {
  transform: translateY(-1px);
  box-shadow: 1px 9px 17px rgba(0, 0, 0, .13);
}

.pdf-viewer-icon--disabled {
  opacity: .5;
  cursor: not-allowed;
}

.pdf-viewer-icon-magnifying-glass {
  position: absolute;
  top: 20px;
  left: 90%;
  z-index: 9999;
}

.pdf-viewer-page-stats {
  height: 20px;
  border: none;
  border-radius: 30px;
  padding: 10px;
  background-color: $sk-white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-text-s;
  font-weight: 700;
  position: absolute;
  bottom: 1%;
  z-index: 9999;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .12);
}

.pdf-viewer-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
