<template>
  <div>
    <SkTable
      class="employees-documents__table"
      :columns="formattedHeaders"
      filled-arrow
      @sort="handleSort"
    >
      <tr
        v-for="document in sortedDocuments"
        :key="document.id"
        class="table__row"
      >
        <td
          v-track="'preview_document'"
          class="table__cell"
          @click="openDocument(document)"
        >
          <div class="table__cell-content text-truncate">
            <a
              v-tooltip
              :title="document.attributes.title"
              class="table__link row-title"
              target="_blank"
            >
              {{ document.attributes.title }}
            </a>
          </div>
        </td>
        <td
          class="table__cell"
          @click="openDocument(document)"
        >
          {{ formattedDate(document.attributes.createdAt) }}
        </td>
        <td
          class="table__cell"
          @click="openDocument(document)"
        >
          {{ formattedContentType(document.attributes.format) }}
        </td>
        <td
          class="table__cell"
          @click="openDocument(document)"
        >
          {{ creatorFullName(document) }}
        </td>
        <td
          class="table__cell"
          @click="openDocument(document)"
        >
          {{ formattedDate(document.attributes.expirationDate) }}
        </td>
        <td
          v-if="canSeeDocumentSignatures"
          :class="signatureStatusClasses(document)"
          @click="openDocument(document)"
        >
          {{ formattedStatus(document) }}
        </td>
        <td
          v-if="canManageDocuments"
          class="table__cell"
        >
          <EnhancedActionsDropdown
            :ref="dynamicDropdownRef(document)"
            :document="documentWithUser(document)"
            class="table__actions--enhanced"
            @delete-document="$emit('delete-document', $event, document)"
            @rename-document="handleRenameDocument(document)"
          />
        </td>
      </tr>
    </SkTable>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <sign-document-modal />
      <RenameDocumentModal
        ref="renameDocumentModal"
        :document-id="selectedRenameDocument?.id"
        :document-title="selectedRenameDocument?.attributes.title"
      />
    </MountingPortal>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

import {
  mapState,
  mapGetters,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';
import skDate from '@skello-utils/dates';
import {
  getDocsEsignatureSettingsFromLocalStorage,
  saveDocsEsignatureSettingsToLocalStorage,
} from '@skello-utils/esignature/utils';
import { SvcDocumentsClient } from '@skello-utils/clients/svc_documents/client';
import {
  svcDocumentV2Client, httpClient,
} from '@skello-utils/clients';
import EnhancedActionsDropdown from './hris_pack/EnhancedActionsDropdown';
import SignDocumentModal from '../../../text_document_templates/editor/sign_document_modal/SignDocumentModal';
import RenameDocumentModal from './RenameDocumentModal';

export default {
  name: 'DocumentsTable',
  components: {
    EnhancedActionsDropdown,
    SignDocumentModal,
    RenameDocumentModal,
  },
  props: {
    documents: {
      type: Array,
      default: null,
    },
    employee: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      client: new SvcDocumentsClient(),
      FEATURES,
      dropdownToCloseRef: '',
      mimeTypes: {
        'application/pdf': 'PDF',
        'image/jpeg': 'JPG',
        'image/png': 'PNG',
        'application/msword': 'DOC', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC', // .docx
      },
      headers: [
        {
          name: 'doc_name',
          title: this.$t('employees.tabs.documents.table_headers.doc_name'),
          sort: true,
          class: 'table__header-cell',
        },
        {
          name: 'creation_date',
          title: this.$t('employees.tabs.documents.table_headers.creation_date'),
          sort: true,
          defaultSort: 'desc',
          class: 'table__header-cell',
        },
        {
          name: 'type',
          title: this.$t('employees.tabs.documents.table_headers.type'),
          class: 'table__header-cell',
        },
        {
          name: 'created_by',
          title: this.$t('employees.tabs.documents.table_headers.created_by'),
          class: 'table__header-cell',
        },
        {
          name: 'expiration_date',
          title: this.$t('employees.tabs.documents.table_headers.expiration_date'),
          class: 'table__header-cell',
        },
      ],
      sortedDocuments: [...this.documents],
      lastSortColumnName: 'creation_date',
      lastSortDirection: 'asc',
      selectedRenameDocument: null,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentLicense', [
      'canManageEmployeeDocuments',
      'canUploadSelfDocuments',
      'isSystemAdmin',
    ]),
    ...mapGetters('selectedEmployee', [
      'isStrictSubordinateOfCurrentUser',
      'isCurrentUser',
    ]),
    ...mapGetters('employeeDocuments', ['isDocumentV2']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('features', ['isFeatureEnabled']),
    formattedHeaders() {
      let headers = this.headers;

      if (this.canSeeDocumentSignatures) {
        headers = headers.concat(this.signatureStatusHeader);
      }

      if (this.canManageDocuments) {
        headers = headers.concat({
          name: 'manage',
          title: '',
        });
      }

      return headers;
    },
    canManageDocuments() {
      const canCreateOwnDocuments = this.isCurrentUser && this.canUploadSelfDocuments;

      return canCreateOwnDocuments ||
        this.isSystemAdmin ||
        (this.canManageEmployeeDocuments && this.isStrictSubordinateOfCurrentUser);
    },
    isEsignatureFeatureEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_ELECTRONIC_SIGNATURE,
        this.currentShop.id,
        () => this.isEsignaturePlanActive,
      );
    },
    canSeeDocumentSignatures() {
      return this.isEsignatureFeatureEnabled;
    },
    signatureStatusHeader() {
      return {
        name: 'signature_status',
        title: this.$t('employees.tabs.documents.table_headers.signature_status'),
        class: 'table__header-cell',
      };
    },
    isEsignaturePlanActive() {
      return this.currentShop.id === 'all' ?
        this.currentOrganisation.attributes.esignatureFullyActivated :
        this.currentShop.attributes.esignatureActive;
    },
    shouldShowDocsEsignatureIntroduction() {
      if (!this.isEsignatureFeatureEnabled) return false;

      const atLeastOneDoc = !!this.documents?.filter(this.isDocumentLackingSignature).length;
      const { isAlreadyUsed } = getDocsEsignatureSettingsFromLocalStorage();

      return atLeastOneDoc && !isAlreadyUsed;
    },
  },
  watch: {
    documents() {
      if (this.lastSortColumnName === 'creation_date' && this.lastSortDirection === 'asc') {
        // We want new documents to appear on top even if uploaded on the same day
        this.sortedDocuments = [...this.documents];
      } else {
        this.handleSort(null, this.lastSortColumnName, this.lastSortDirection);
      }

      this.$nextTick(() => {
        this.conditionallyShowDocsEsignatureIntroduction();
      });
    },
  },
  mounted() {
    this.conditionallyShowDocsEsignatureIntroduction();
    this.$root.$on('signature-process-started', this.handleSignatureProcessStarted);
    this.$root.$on('docs-esignature-introduction-done', this.handleCloseDropdownOnDocsEsignatureIntro);
  },
  methods: {
    isSignatureSigned(status) {
      return status === 'signed' || status === 'signedPendingDownload';
    },
    isSignatureNotSentOrCancelled(status) {
      return !status || status === 'cancelled';
    },
    isSignatureSent(status) {
      return !this.isSignatureNotSentOrCancelled(status) && !this.isSignatureSigned(status);
    },
    isDocumentLackingSignature(document) {
      const { status, format } = document.attributes;

      return !status && format === 'application/pdf';
    },
    formattedDate(dateString) {
      if (!dateString) return '';

      return skDate(dateString).format('ll');
    },
    formattedStatus(document) {
      const { status } = document.attributes;

      if (this.isSignatureNotSentOrCancelled(status)) {
        return '-';
      }

      if (this.isSignatureSigned(status)) {
        return this.$t('employees.tabs.documents.esginature.status_is_signed');
      }

      return this.$t('employees.tabs.documents.esginature.status_is_sent');
    },
    creatorFullName(document) {
      const creator = document.relationships.creator;
      if (!creator) return '-';
      return `${creator.attributes.firstName} ${creator.attributes.lastName}`;
    },
    formattedContentType(format) {
      // Attachinary format ( not real content-type )
      if (!format.includes('/')) return format.toUpperCase();

      return this.mimeTypes[format] || '';
    },
    documentWithUser(document) {
      const documentCopy = cloneDeep(document);
      Object.assign(documentCopy.relationships, { user: this.employee });
      return documentCopy;
    },
    async openDocument(document) {
      const newWindow = window.open('', '_blank');
      if (this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2') && this.isDocumentV2(document.id)) {
        const documentUrl = await svcDocumentV2Client.document.getUrl(document.id, 'inline');

        newWindow.location.href = documentUrl;
      } else {
        await httpClient.get('/v3/refresh_me');
        /*
        * Here, we force the refresh of the token_fallback to avoid the disconnection issue when opening a document in a new tab.
        * This solution is temporary, pending the migration to docV2 or the front/back split. After the migration, this part should be removed.
        */

        const { data } = await httpClient.get(
          document.attributes.url,
          { responseType: 'blob' },
        );

        const url = window.URL.createObjectURL(
          new Blob([data], { type: document.attributes.format }),
        );

        newWindow.location.href = url;
        newWindow.onload = () => URL.revokeObjectURL(url);
      }
    },
    handleSort(_event, columnName, direction) {
      this.lastSortColumnName = columnName;
      this.lastSortDirection = direction;

      let sortedDocuments;
      if (columnName === 'doc_name') {
        sortedDocuments = this.documents.sort(this.titleSort);
      } else {
        sortedDocuments = this.documents.sort(this.dateSort);
      }
      this.sortedDocuments =
        direction === 'asc' ? sortedDocuments : sortedDocuments.reverse();
    },
    titleSort(firstDocument, secondDocument) {
      const firstDocumentTitle = firstDocument.attributes.title.toLowerCase();
      const secondDocumentTitle = secondDocument.attributes.title.toLowerCase();

      return firstDocumentTitle.localeCompare(secondDocumentTitle);
    },
    dateSort(firstDocument, secondDocument) {
      const firstDocumentDate = skDate(firstDocument.attributes.createdAt);
      const secondDocumentDate = skDate(secondDocument.attributes.createdAt);

      if (firstDocumentDate.isAfter(secondDocumentDate)) return 1;
      if (firstDocumentDate.isBefore(secondDocumentDate)) return -1;
      return 0;
    },
    handleSignatureProcessStarted(documentId) {
      const index = this.sortedDocuments.findIndex(document => document.id === documentId);

      const newDocument = this.sortedDocuments[index];
      // At this point we know that the signature process has started
      // but the stepFunction will probably not have started yet, which is what
      // tells us that the signature has been sent
      //
      // For this reason, we set a temporal status different that null that let
      // us treat the document as it has signature on-going
      newDocument.attributes.status = 'awaiting-news-from-esignature-svc';

      this.$set(this.sortedDocuments, index, newDocument);
    },
    signatureStatusClasses(document) {
      const { status } = document.attributes;

      return {
        table__cell: true,
        'gray-text': this.isSignatureSent(status),
        'green-text': this.isSignatureSigned(status),
      };
    },
    dynamicDropdownRef(document) {
      return `document${document.id}Dropdown`;
    },
    styleElement(element, styles) {
      if (!element) return;

      Object.keys(styles).forEach(styleKey => {
        element.style[styleKey] = styles[styleKey];
      });
    },
    handleCloseDropdownOnDocsEsignatureIntro() {
      const refKey = this.dropdownToCloseRef;
      if (!this.$refs[refKey]) return;

      const element = this.$refs[refKey][0];
      const {
        actionDropdown,
        actionDropdownMenu,
        actionDropdownAnchor,
        actionDropdownSendSignature,
        actionDropdownSendEmail,
        actionDropdownMoveDocument,
        actionDropdownDeleteDocument,
      } = element.$refs;

      actionDropdown.hide();
      element.toggleForceShowValue();
      element.$el.style.cursor = '';
      this.styleElement(element.$el, { cursor: '' });
      this.styleElement(actionDropdown.$el, { opacity: '' });
      this.styleElement(actionDropdownMenu, { backgroundColor: '' });
      this.styleElement(actionDropdownSendSignature, { backgroundColor: '' });
      this.styleElement(actionDropdownSendEmail, { pointerEvents: '' });
      this.styleElement(actionDropdownMoveDocument, { pointerEvents: '' });
      this.styleElement(actionDropdownDeleteDocument, { pointerEvents: '' });
      this.styleElement(actionDropdownAnchor.$el, { backgroundColor: '', fill: '', zIndex: '' });

      this.dropdownToCloseRef = '';
    },
    async handleOpenDropdownOnDocsEsignatureIntro(document) {
      const refKey = this.dynamicDropdownRef(document);
      const element = this.$refs[refKey][0];
      this.dropdownToCloseRef = refKey;

      const {
        actionDropdown,
        actionDropdownMenu,
        actionDropdownAnchor,
        actionDropdownSendSignature,
        actionDropdownSendEmail,
        actionDropdownMoveDocument,
        actionDropdownDeleteDocument,
      } = element.$refs;

      this.styleElement(actionDropdown.$el, { opacity: 1 });
      this.styleElement(actionDropdownMenu, { backgroundColor: '#D2D2D2' });
      this.styleElement(actionDropdownSendSignature, { backgroundColor: '#ffff' });
      this.styleElement(actionDropdownSendEmail, { pointerEvents: 'none', cursor: 'default !important' });
      this.styleElement(actionDropdownMoveDocument, { pointerEvents: 'none' });
      this.styleElement(actionDropdownDeleteDocument, { pointerEvents: 'none' });
      this.styleElement(actionDropdownAnchor.$el, { backgroundColor: '#ffff', fill: '#eef4ff', zIndex: '20' });
      this.styleElement(element.$el, { cursor: 'default' });
      element.toggleForceShowValue();
      actionDropdown.open();

      await this.$nextTick();

      await this.scrollViewToLowestElementInIntroduction(document);
    },
    getIntroductionModalPosition(document) {
      const refKey = this.dynamicDropdownRef(document);
      const parentElement = this.$refs[refKey][0];
      const element = parentElement.$refs.actionDropdownMenu;
      const boundingRect = element.getBoundingClientRect();
      const top = `${boundingRect.top - 90}px`;
      const left = `${boundingRect.left - 320}px`;

      return { top, left };
    },
    async scrollViewToLowestElementInIntroduction(document) {
      const refKey = this.dynamicDropdownRef(document);
      const { actionDropdownAnchor, actionDropdownMenu } = this.$refs[refKey][0].$refs;
      const { top: anchorTop } = actionDropdownAnchor.$el.getBoundingClientRect();
      const { top: menuTop } = actionDropdownMenu.getBoundingClientRect();
      const elementToScrollTo = anchorTop >= menuTop ?
        actionDropdownAnchor.$el :
        actionDropdownMenu;

      elementToScrollTo.scrollIntoView({ block: 'center' });

      return this.$nextTick();
    },
    handlePlaceAndOpenModal(document) {
      const modalPosition = this.getIntroductionModalPosition(document);
      this.$root.$emit('open-doc-esignature-introduction-modal', event, modalPosition);
    },
    async conditionallyShowDocsEsignatureIntroduction() {
      const shouldShow = this.shouldShowDocsEsignatureIntroduction;

      if (!shouldShow) {
        return;
      }

      const document = this.documents.find(this.isDocumentLackingSignature);

      await this.handleOpenDropdownOnDocsEsignatureIntro(document);
      this.handlePlaceAndOpenModal(document);
      saveDocsEsignatureSettingsToLocalStorage({ isAlreadyUsed: true });
    },
    handleRenameDocument(document) {
      this.selectedRenameDocument = document;
      this.$nextTick(() => {
        this.$refs.renameDocumentModal.show();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.employees-documents__table {
  margin-top: 40px;
  width: 100%;

  .table__row {
    .table__actions,
    .table__actions--enhanced {
      opacity: 0;
    }

    &:hover {
      background: $sk-grey-5;

      a.row-title {
        text-decoration: underline;
        color: $sk-blue;
      }

      .table__actions {
        opacity: 1;
      }

      .table__actions--enhanced {
        opacity: 1;
      }
    }

    .table__actions--enhanced {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .table__actions--simple {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  ::v-deep th {
    padding: 7px 10px;
    border-bottom: 1px solid $sk-grey-10;
  }

  ::v-deep .table__header-cell {
    font-size: $fs-text-m;
    font-weight: $fw-regular;
    color: $sk-grey;
    margin: 0;
  }

  td.table__cell {
    cursor: pointer;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid $sk-grey-10;

    a {
      color: $sk-black;

      &:hover {
        color: $sk-black;
        text-decoration: none;
      }
    }
  }

  .table__cell--empty {
    border: none;
    text-align: center;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }

  .table__link {
    color: $sk-black;
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
      color: $sk-blue;
    }
  }

  .green-text {
    color: $sk-success;
  }

  .gray-text {
    color: #bfbfbf;
  }
}
</style>
