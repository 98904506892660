<template>
  <div class="planning-row__counters-cell__main cell--shadow cell--shadow-left">
    <transition name="slide-counters">
      <div
        v-if="displayTotalPeriodTab"
        class="counters-cell__totals"
        :style="{ height: totalCellHeight }"
      >
        <SkLoader
          v-if="!isProgressiveLoadingEnabled && monthlyShiftsLoading"
          size="small"
        />
        <SkRectSkeleton
          v-else-if="isProgressiveLoadingEnabled && monthlyShiftsLoading"
          width="104"
          height="22"
        />
        <span
          v-else
          :class="counterCellTotalsContent"
        >
          {{
            isUnassignedShiftsRow ?
              '-' :
              minutesToFormattedTime(totalDuration(filteredShifts))
          }}
        </span>
      </div>
    </transition>
    <div
      :ref="`countercell-${rowItem.id}`"
      class="planning-row__counters-cell__wrapper"
    >
      <!-- Total tab selected -->
      <div
        v-if="isActiveItem('total')"
        ref="week_detail_icon_parent"
        class="counters-cell__content"
      >
        <CircledQuestionMarkIcon
          v-if="showQuestionMark && hasAbsenceTotalDetails"
          ref="week_detail_icon"
          v-tooltip.left="$t('plannings.table.body.counters.working_hours')"
          class="counters-cell__details-icon"
          width="14"
          height="14"
          @click.native.stop="openCounterWeekDetail"
        />
        <div class="counters-cell__duration-container">
          <div v-if="displayDaysWorked">
            <div class="total-days">
              {{ $tc('plannings.table.cells.total.n_days', userDaysWorked) }}
            </div>
            <div class="total-hours--day-rate">
              {{ minutesToFormattedTime(totalDuration(shifts)) }}
            </div>
          </div>
          <div v-else>
            <div class="total-hours">
              {{ minutesToFormattedTime(totalDuration(shifts)) }}
            </div>
          </div>
          <div v-if="displayWeeklyTotalDifference">
            <span
              v-tooltip.left="contractWarningText"
              class="diff-hours-row"
            >
              <CounterCellIcon
                class="diff-hours__sign-icon"
                :cell-style="zoomRange.icon"
                :color="backgroundSignColor"
                :icon-name="displayCircleIcon(isWeeklyTotalDifferencePositive)"
                :icon-color="signColor"
              />
              <span
                class="diff-hours__duration"
                :class="weeklyTotalDifferenceClasses"
              >
                {{ minutesToFormattedTime(weeklyTotalDifference) }}
                <span v-if="contractWarningText">
                  *
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <!-- Counters tab selected -->
      <div
        v-else
        class="counters-cell__content"
      >
        <template
          v-if="hasAnnualizationData"
        >
          <SkLoader
            v-if="!isProgressiveLoadingEnabled && isAnnualizationLoading"
            size="small"
          />
          <SkRectSkeleton
            v-else-if="isProgressiveLoadingEnabled && isAnnualizationLoading"
            width="104"
            height="22"
          />
          <div
            v-else
            class="counters-cell__content__counter counters-cell__content__counter--cursor"
            @click.stop="openAnnualizationDetail"
          >
            <CounterCellIcon
              v-if="isAdvanceDelayANumber"
              ref="annualization_icon"
              :cell-style="zoomRange.icon"
              :icon-name="displayCircleIcon(advanceDelay > 0)"
              color="#fdf0c8"
              icon-color="#f0900d"
            />
            <span :class="annualizationCounterClasses">
              {{ formattedAdvanceDelay }}
            </span>
          </div>
        </template>
        <template
          v-else-if="hasUserHoursCounter
            && isShopHoursCounterActivated(rowItem.attributes.shopId)"
        >
          <!-- user hour counter is being fetched -->
          <SkLoader
            v-if="!isProgressiveLoadingEnabled && isUserHoursCounterFetching"
            size="small"
          />
          <SkRectSkeleton
            v-else-if="isProgressiveLoadingEnabled && isUserHoursCounterFetching"
            width="104"
            height="22"
          />
          <div
            v-else
            :class="counterCellContentClasses"
            @click.stop="openCounterWeekMaj"
          >
            <CounterCellIcon
              ref="week_maj_icon"
              :cell-style="zoomRange.icon"
              :icon-name="displayCircleIcon(userHoursCounterEndOfWeek > 0)"
              color="#fdf0c8"
              icon-color="#f0900d"
            />
            <span
              class="
                counters-cell__content__counter__value
                counters-cell__content__counter__value--neutral"
            >
              {{ secondsToFormattedTime(userHoursCounterEndOfWeek) }}
            </span>
          </div>
        </template>
        <template v-else>
          <template v-if="hasUserContractWithCounter && currentShop.attributes.modulation">
            <div
              v-if="isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER')
                && currentShop.attributes.hybridModulation"
              class="counters-cell__content__no-background sk-text-small-regular"
            >
              {{ $t('plannings.table.cells.counters.tracker_deactivated') }}
            </div>
            <div
              v-else
              class="counters-cell__content__background counters-cell__content__no-counter"
            >
              {{ $t('plannings.table.cells.counters.no_counter') }}
            </div>
          </template>
          <template v-else>
            <div class="counters-cell__content__no-content">
              -
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';

import { formatDurationToHoursAndMinutes } from '@app-js/plannings/shared/utils/planning_helpers';

import skDate from '@skello-utils/dates';
import {
  totalDuration,
  totalDurationWithoutModulation,
  workingShiftsDuration,
  absenceShiftsPaidByOtherDuration,
  absenceShiftsPaidByEmployerDuration,
  totalBreakDuration,
} from '@skelloapp/skello-planning-helper';

import CounterCellIcon from './CounterCellIcon';

export default {
  name: 'CountersCell',
  components: {
    CounterCellIcon,
  },
  props: {
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
    rowItem: {
      type: Object,
      required: true,
    },
    shifts: {
      type: Array,
      required: true,
    },
    periodShifts: {
      type: Array,
      default: () => [],
    },
    daysWorked: {
      type: Object,
      required: true,
    },
    contractWarningText: {
      type: String,
      required: true,
    },
    showQuestionMark: {
      type: Boolean,
      required: false,
      default: true,
    },
    shouldShowExtraHours: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      totalCellHeight: '54px',
    };
  },
  computed: {
    ...mapState('annualization', ['employeeAnnualizationConfigs']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    ...mapState('planningsState', ['displayTotalPeriodTab']),
    ...mapState('planningsShifts', ['monthlyShiftsLoading']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled']),
    ...mapGetters('annualization', [
      'annualizationAdvanceDelayAt',
      'annualizationRemainingBalanceAt',
      'annualizationImpactedTheoreticalBalance',
      'annualizationImpactedTheoreticalBalancePerWeek',
      'employeeAnnualizationConfigs',
      'isAnnualizationCurrentlyActive',
      'isAnnualizationLoading',
    ]),
    ...mapGetters('currentShop', [
      'isAnnualizedWorkingTimeAvailable',
      'isShopHoursCounterActivated',
      'isAnnualizationContractHoursChangeEnabled',
      'isDevFlagEnabled',
      'arePaidBreaksActivated',
    ]),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('employeeCounters', ['counterHoursByUserId', 'countersHoursFetchingStatusForUserId']),
    ...mapGetters('planningsState', ['monday', 'isDailyView', 'isEmployeesView', 'isActiveItem', 'isMonthlyView']),
    annualizationCounterClasses() {
      return {
        'counters-cell__content__counter__value': true,
        'counters-cell__content__counter__value--neutral': this.isAdvanceDelayANumber,
        'counters-cell__content__counter__value--primary': !this.isAdvanceDelayANumber,
      };
    },
    counterCellContentClasses() {
      return {
        'counters-cell__content__counter': true,
        'counters-cell__content__counter--cursor': true,
        'counters-cell__content--faded': this.isProgressiveLoadingEnabled,
      };
    },
    counterCellTotalsContent() {
      return {
        'counters-cell__totals-content--faded': this.isProgressiveLoadingEnabled,
      };
    },
    formattedAdvanceDelay() {
      return this.isAdvanceDelayANumber ? this.minutesToFormattedTime(this.advanceDelay * 60) : '-';
    },
    annualizationPopoverData() {
      return {
        advanceDelayEndOfWeek: this.advanceDelay,
        advanceDelayBeginningOfWeek: this.advanceDelayBeginningOfWeek,
        differenceBetween: this.advanceDelay - this.advanceDelayBeginningOfWeek,
        remainingBalance: this.remainingBalance,
        theoreticalBalance: this.theoreticalBalance,
      };
    },
    advanceDelay() {
      return this.annualizationAdvanceDelayAt(this.monday, this.rowItem.id);
    },
    advanceDelayBeginningOfWeek() {
      // this is undefined on the first week of initialization, the fallback on 0 fixes the `differenceBetween` value
      return this.annualizationAdvanceDelayAt(skDate(this.monday).clone().subtract(7, 'd').format('YYYY-MM-DD'), this.rowItem.id) ?? 0;
    },
    remainingBalance() {
      return this.annualizationRemainingBalanceAt(this.monday, this.rowItem.id);
    },
    theoreticalBalance() {
      return this.isAnnualizationContractHoursChangeEnabled ?
        this.annualizationImpactedTheoreticalBalancePerWeek(this.monday, this.rowItem.id) :
        this.annualizationImpactedTheoreticalBalance(this.rowItem.id);
    },
    filteredShifts() {
      if (this.isDailyView) {
        return this.periodShifts;
      }

      // NOTE: Otherwise, until DEV-17415, we're on weekly view
      const currentMonth = skDate(this.monday).month();
      return this.periodShifts
        .filter(({ attributes }) => attributes.startsAtForDisplay.month() === currentMonth);
    },

    // common methods
    hasUserContractWithCounter() {
      return !this.isUnassignedShiftsRow && !this.rowItem.attributes.onExtra &&
        !this.rowItem.attributes.onDayRate && !this.isUnassignedShiftsRow;
    },
    isShopWithModulationMajoration() {
      return this.currentShop.attributes.modulationMajoration;
    },
    hasAbsenceTotalDetails() {
      return !this.isDailyView &&
        !this.currentShop.attributes.modulation &&
        !this.currentShop.attributes.isAnnualizationV2Active &&
        (
          absenceShiftsPaidByEmployerDuration(this.shifts) > 0 ||
          absenceShiftsPaidByOtherDuration(this.shifts) > 0
        );
    },
    zoomRange() {
      const { diffHours } = zoomPlanningWeek(this.planningZoom);
      return diffHours;
    },
    // Total tab
    weeklyTotalDifference() {
      const value = Math.round(this.totalDuration(this.shifts)) -
        this.rowItem.attributes.currentContractHours * 60;
      // DEV-10713 Delete this and put all computed in a store
      // We need the weeklyTotalDifference for the blocking alerts modal
      this.setWeeklyTotalDifferenceFor({ userId: this.rowItem.id, diff: value });

      return value;
    },
    isWeeklyTotalDifferencePositive() {
      return this.weeklyTotalDifference > 0;
    },
    weeklyTotalDifferenceClasses() {
      return {
        'diff-hours__duration--positive': this.isWeeklyTotalDifferencePositive,
        'diff-hours__duration--negative': !this.isWeeklyTotalDifferencePositive,
      };
    },
    displayWeeklyTotalDifference() {
      const shouldDisplayInMonthly = this.isMonthlyView && this.shouldShowExtraHours;

      return !this.isDailyView &&
        (this.isEmployeesView || shouldDisplayInMonthly) &&
        !this.isUnassignedShiftsRow &&
        this.hasUserContractWithCounter &&
        this.weeklyTotalDifference !== 0;
    },
    // Counter tab
    hasUserHoursCounter() {
      return this.hasUserContractWithCounter &&
        this.rowItem.attributes.counterInitializationDoneAt &&
        skDate(this.rowItem.attributes.counterInitializationDoneAt).isSameOrBefore(this.monday, 'd') &&
        this.hasUserEverHaveShift;
    },
    hasUserEverHaveShift() {
      if (this.shifts.length > 0) return true;

      return !!this.rowItem.attributes.firstShiftStartsAt &&
        skDate(this.rowItem.attributes.firstShiftStartsAt).isBefore(this.monday, 'd');
    },
    isUserHoursCounterFetching() {
      return this.countersHoursFetchingStatusForUserId(this.rowItem.id) ||
        // Prevent error when counters tab is open and user scrolls
        // when progressive loading is enabled
        !this.userHoursCounter;
    },
    userHoursCounter() {
      return this.counterHoursByUserId(this.rowItem.id);
    },
    userHoursCounterBeginOfWeek() {
      // To prevent fetching the user hours counter of previous week,
      // counter in begin of week is computed here thanks to userHoursCounter attributes
      return Math.round(
        this.userHoursCounterEndOfWeek -
        this.userHoursCounter.attributes.hoursWorked -
        this.userHoursCounter.attributes.manualChanges -
        this.userHoursCounter.attributes.absenceHoursInCounter -
        this.userHoursCounterMajoration +
        this.rowItem.attributes.currentContractHours * 3600,
      );
    },
    userHoursCounterMajoration() {
      return this.userHoursCounter.attributes.majoration;
    },
    userHoursCounterEndOfWeek() {
      return this.isShopWithModulationMajoration ?
        Math.round(this.userHoursCounter.attributes.hoursCounterAtEndOfWeek) :
        Math.round(this.userHoursCounter.attributes.hoursCounterAtEndOfWeekWoMajoration);
    },
    signColor() {
      return this.isWeeklyTotalDifferencePositive ? '#d03e50' : '#2b66fe';
    },
    backgroundSignColor() {
      return this.isWeeklyTotalDifferencePositive ? '#fce8e7' : '#d9e6ff';
    },
    userDaysWorked() {
      return this.daysWorked[this.rowItem.id] || 0;
    },
    displayDaysWorked() {
      if (this.isUnassignedShiftsRow) {
        return false;
      }
      return !this.isDailyView &&
        !this.isUnassignedShiftsRow &&
        this.rowItem.attributes.onDayRate;
    },
    hasAnnualizationData() {
      return this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
      this.isAnnualizationCurrentlyActive &&
      this.employeeAnnualizationConfigs[this.rowItem.id];
    },
    isAdvanceDelayANumber() {
      return typeof this.advanceDelay === 'number';
    },
  },
  watch: {
    shifts() {
      setTimeout(() => {
        if (!this.$refs[`countercell-${this.rowItem.id}`]) return;
        this.totalCellHeight = `${this.$refs[`countercell-${this.rowItem.id}`].getBoundingClientRect().height}px`;
      }, 1);
    },
  },
  mounted() {
    this.totalCellHeight = `${this.$refs[`countercell-${this.rowItem.id}`].getBoundingClientRect().height}px`;
  },
  methods: {
    ...mapMutations('planningsUsers', ['setWeeklyTotalDifferenceFor']),
    openAnnualizationDetail() {
      const elRect = this.$refs.annualization_icon.$el.getBoundingClientRect();

      this.emitOnRoot('show-counter-detail', {
        counters: {
          ...this.annualizationPopoverData,
          zoomRange: this.zoomRange,
        },
        x: elRect.x,
        y: elRect.y,
        origin: 'annualization-detail',
      });
    },
    openCounterWeekMaj() {
      const elRect = this.$refs.week_maj_icon.$el.getBoundingClientRect();

      this.emitOnRoot('show-counter-detail', {
        counters: {
          circleIconCounterBeginning: this.displayCircleIcon(this.userHoursCounterBeginOfWeek > 0),
          circleIconWeeklyDifference: this.displayCircleIcon(this.isWeeklyTotalDifferencePositive),
          circleIconCounterEnd: this.displayCircleIcon(this.userHoursCounterEndOfWeek > 0),
          zoomRange: this.zoomRange,
          userHoursCounterBeginOfWeek:
            this.secondsToFormattedTime(this.userHoursCounterBeginOfWeek),
          weeklyTotalDifferencePlaceholder: this.isWeeklyTotalDifferencePositive ? 'additional_hours' : 'substracted_hours',
          weeklyTotalDifferenceFormatted: this.minutesToFormattedTime(this.weeklyTotalDifference),
          weeklyTotalDifference: this.weeklyTotalDifference,
          userHoursCounterMajoration: this.secondsToFormattedTime(this.userHoursCounterMajoration),
          signColor: this.signColor,
          backgroundSignColor: this.backgroundSignColor,
          userHoursCounterEndOfWeek: this.secondsToFormattedTime(this.userHoursCounterEndOfWeek),
          isWeeklyTotalDifferencePositive: this.isWeeklyTotalDifferencePositive,
          totalBreakDuration:
            this.secondsToFormattedTime(totalBreakDuration(this.shifts)),
          isShopWithModulationMajoration: this.isShopWithModulationMajoration,
          arePaidBreaksActivated: this.arePaidBreaksActivated,
        },
        x: elRect.x,
        y: elRect.y,
        origin: 'counter-maj-detail',
      });
    },
    openCounterWeekDetail() {
      const elRect = this.$refs.week_detail_icon.$el.getBoundingClientRect();

      this.emitOnRoot('show-counter-detail', {
        counters: {
          workHours: this.minutesToFormattedTime(
            workingShiftsDuration(this.shifts),
          ),
          employerAbsHours: this.minutesToFormattedTime(
            absenceShiftsPaidByEmployerDuration(this.shifts),
          ),
          otherAbsHours: this.minutesToFormattedTime(
            absenceShiftsPaidByOtherDuration(this.shifts),
          ),
          totalHours: this.minutesToFormattedTime(
            totalDurationWithoutModulation(this.shifts),
          ),
        },
        x: elRect.x,
        y: elRect.y,
        origin: 'counter-week-detail',
      });
    },
    minutesToFormattedTime(timeInMinutes) {
      const { hours, minutes } = formatDurationToHoursAndMinutes(Math.round(timeInMinutes));
      return this.$t('dates.hours_format', { hours, minutes });
    },
    secondsToFormattedTime(timeInSeconds) {
      const timeInMinutes = timeInSeconds / 60;

      return this.minutesToFormattedTime(timeInMinutes);
    },
    displayCircleIcon(isPositive) {
      return isPositive ? 'PlusSignV2Icon' : 'MinusSignIcon';
    },
    totalDuration(shifts) {
      return totalDuration(shifts, this.currentShop.attributes.modulation);
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-row__counters-cell__main {
  flex: 0 0 146px;
  position: relative;
}

.planning-row__counters-cell__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  z-index: 30;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  .counters-cell__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .counters-cell__content__no-content {
      color: $sk-grey;
      font-weight: bold;
    }

    .counters-cell__content__background {
      background: $sk-grey-10;
      border-radius: 3px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fs-text-s;
    }

    .counters-cell__content__no-background {
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $sk-grey;
    }

    .counters-cell__content__no-counter {
      width: 104px;
      color: $sk-grey;
    }

    .counters-cell__content__annualization {
      color: $sk-black;
      padding: 0 7px;
    }

    .counters-cell__content__counter {
      display: flex;
      align-items: center;
    }

    .counters-cell__content--faded {
      animation: fadeIn 0.15s ease-out;
    }

    .counters-cell__content__counter--cursor {
      cursor: pointer;
    }

    .counters-cell__content__counter__value {
      font-size: $fs-text-s;
      margin-left: 3px;
    }

    .counters-cell__content__counter__value--bold {
      font-weight: $fw-semi-bold;
    }

    .counters-cell__content__counter__value--information {
      color: $sk-grey;
    }

    .counters-cell__content__counter__value--neutral {
      color: $sk-warning;
    }

    .counters-cell__content__counter__value--primary {
      color: $sk-black;
    }

    .counters-cell__counter__details {
      font-size: $fs-text-s;
      display: flex;
      justify-content: space-between;
    }

    .counters-cell__counter__details--separator {
      border-bottom: 1px solid $sk-grey-5;
      margin: 20px 0;
    }

    .counters-cell__details-icon {
      position: absolute;
      top: 50%;
      left: 12%;
      transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      cursor: pointer;
    }

    .counters-cell__duration-container {
      flex: 1 1 50%;
      text-align: center;

      .total-hours,
      .total-days {
        color: $sk-grey;
        font-weight: bold;
        line-height: 15px;
        font-size: 1.4em;
      }

      .total-hours--day-rate {
        font-size: 1.2em;
        color: $sk-grey;
      }

      .diff-hours__duration {
        font-size: 1.2em;
        margin-left: 6px;

        &--positive {
          color: $sk-error;
        }

        &--negative {
          color: $sk-blue;
        }
      }

      .diff-hours-row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1px;
      }
    }
  }
}

.counters-cell__totals {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sk-grey-5;
  right: 146px;
  width: 68px;
  color: $sk-grey;
  font-size: 1.2em;
  z-index: 20;

  .counters-cell__totals-content--faded {
    animation: fadeIn 0.15s ease-out;
  }
}

.slide-counters-enter-active,
.slide-counters-leave-active {
  transition: all 1s ease;
}

.slide-counters-enter,
.slide-counters-leave-to {
  transform: translateX(68px);
}

@media screen and (max-width: 1200px) {
  .planning-row__counters-cell__wrapper {
    flex: 0 0 130px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
