import {
  computeSelectedCellKey, buildShiftsToPaste, buildMultiCellShiftsToPaste,
} from '@app-js/shared/utils/planning_as_matrix_helper';

const initialState = {
  selectedCells: [],
  copiedCells: {},
};

const mutations = {
  resetSelectedCells(state) {
    state.selectedCells = [];
  },

  addToSelectedCells(state, selectedCellKey) {
    // state.selectedCells.push(selectedCellKey);
    state.selectedCells.push(selectedCellKey);
  },

  removeFromSelectedCells(state, selectedCellToRemove) {
    const index = state.selectedCells.indexOf(selectedCellToRemove);

    state.selectedCells.splice(index, 1);
  },

  setCopiedSelectedCells(state, newValue) {
    state.copiedCells = newValue;
  },
};

const actions = {
  copySelectedCells({ commit, getters }) {
    const copiedCells = getters.selectedCellsWithShifts;

    commit('setCopiedSelectedCells', copiedCells);
  },

  async pasteCopiedCells({ dispatch, state, getters, rootState, rootGetters }) {
    const visibleDays = rootGetters['planningsState/visibleDays'];
    const displayedInPlanningUsers = rootGetters['planningsUsers/displayedInPlanningUsers'];
    const isUnassignedRowDisplayed = rootGetters['planningsState/isUnassignedRowDisplayed'];

    let shiftsToPaste = [];
    // If we want to handle the case where multiple cells are selected
    // Remove Object.keys(this.globalConfig.copiedCells).length === 1
    if (
      getters.selectedCellsCount > 1 &&
      Object.keys(state.copiedCells).length === 1
    ) {
      shiftsToPaste = buildMultiCellShiftsToPaste({
        destinationCells: state.selectedCells,
        copiedCells: state.copiedCells,
        visibleDays,
        displayedInPlanningUsers,
        isUnassignedRowDisplayed,
      });
    } else {
      shiftsToPaste = buildShiftsToPaste({
        destinationCell: state.selectedCells[0],
        copiedCells: state.copiedCells,
        visibleDays,
        displayedInPlanningUsers,
        isUnassignedRowDisplayed,
      });
    }

    if (shiftsToPaste.length === 0) return;

    const parseParams = {
      shifts: shiftsToPaste,
      shopId: rootState.currentShop.currentShop.id,
      periodStartsAt: rootGetters['planningsState/monday'],
      periodEndsAt: rootGetters['planningsState/sunday'],
    };
    await dispatch('planningsShifts/createShift', parseParams, { root: true });
  },

  async deleteSelectedCells({ commit, getters, dispatch, rootState, rootGetters }) {
    const selectedCellShiftIds = getters.selectedCellsShifts.map(shift => shift.id);

    if (selectedCellShiftIds.length === 0) return;

    const deleteParams = {
      shop_id: rootState.currentShop.currentShop.id,
      shift_ids: selectedCellShiftIds,
      starts_at: rootGetters['planningsState/monday'],
      ends_at: rootGetters['planningsState/sunday'],
    };
    await dispatch('planningsShifts/deleteShifts', deleteParams, { root: true });

    commit('resetSelectedCells');
  },
};

const gettersList = {
  displayBulkEditShifts: (_state, _getters, _rootState, rootGetters) => rootGetters['planningsState/isEmployeesView'],

  getSelectedCellKey: (_state, _getters, _rootState, rootGetters) => (rowItemId, date) => {
    const x = rootGetters['planningsState/visibleDays'].findIndex(day => day.date === date);
    let y = 0;
    if (rowItemId) {
      y = rootGetters['planningsUsers/displayedInPlanningUsers'].findIndex(user => user.id === rowItemId);

      // if unassigned shifts line is displayed, we need to increment the row index if rowItem is defined
      if (rootGetters['planningsState/isUnassignedRowDisplayed'] && rowItemId) {
        y += 1;
      }
    }

    return computeSelectedCellKey(rowItemId, date, x, y);
  },

  selectedCellsCount: state => state.selectedCells.length,

  // Shifts for the selected cell and the current shop
  // Since we don't want to impact shifts from other shops
  shiftsForSelectedCell:
    (_state, _getters, rootState, rootGetters) => selectedCellKey => {
      const { rowItemId, date } = JSON.parse(selectedCellKey);
      const userShifts = rowItemId ?
        rootGetters['planningsShifts/shiftsForUser'](rowItemId) :
        rootGetters['planningsShifts/unassignedShifts'];
      const currentShopId = Number(rootState.currentShop.currentShop.id);
      const currentShopUserShifts = userShifts
        .filter(shift => shift.attributes.shopId === currentShopId);
      return rootGetters['planningsShifts/dayCellShifts'](date, currentShopUserShifts);
    },

  // Map of selected cell keys with their corresponding shifts
  selectedCellsWithShifts: (state, getters) => (
    state.selectedCells
      .reduce((acc, selectedCellKey) => {
        const selectedCellShifts = getters.shiftsForSelectedCell(selectedCellKey);
        acc[selectedCellKey] = selectedCellShifts;
        return acc;
      }, {})
  ),

  // Array of all selected cells shifts
  selectedCellsShifts: (_state, getters) => Object.values(getters.selectedCellsWithShifts).flat(),

  isLockedDayInSelectedCells: (state, _getters, _rootState, rootGetters) => (
    state.selectedCells.some(selectedCellKey => {
      const { date } = JSON.parse(selectedCellKey);
      const day = rootGetters['planningsState/visibleDays'].find(visibleDay => visibleDay.date === date);
      return day.isLocked;
    })
  ),

  canPasteCopiedCells: (state, getters, rootState) => {
    if (getters.selectedCellsCount === 0) return false;
    if (getters.isLockedDayInSelectedCells) return false;
    if (!rootState.currentLicense.currentLicense.attributes.canCreateShifts) return false;

    // If we want to handle the case where multiple cells are selected
    // Remove Object.keys(state.copiedCells).length > 1
    if (Object.keys(state.copiedCells).length > 1 && getters.selectedCellsCount > 1) return false;

    return Object.values(state.copiedCells).flat().length > 0;
  },

  canCopySelectedCells: (state, getters) => {
    if (getters.selectedCellsCount === 0) return false;

    const distinctRowItemIds = new Set();
    const distinctDates = new Set();
    state.selectedCells.forEach(selectedCellKey => {
      const { rowItemId, date } = JSON.parse(selectedCellKey);
      distinctRowItemIds.add(rowItemId);
      distinctDates.add(date);
    });

    // We should only allow copying when all selected cells are from the same row or same column
    if (distinctDates.size > 1 && distinctRowItemIds.size > 1) return false;

    return getters.selectedCellsShifts.length > 0;
  },

  canDeleteSelectedCells: (_state, getters, rootState) => {
    if (getters.isLockedDayInSelectedCells) return false;
    if (!rootState.currentLicense.currentLicense.attributes.canCreateShifts) return false;

    return getters.selectedCellsShifts.length > 0;
  },

  canUnassignSelectedCells: (_state, getters, rootState, rootGetters) => {
    if (getters.isLockedDayInSelectedCells) return false;
    if (
      !rootState.currentLicense.currentLicense.attributes.canEditShifts ||
      getters.selectedCellsShifts.length === 0 ||
      getters.selectedCellsShifts.some(shift => !shift.attributes.userId)
    ) {
      return false;
    }

    return rootGetters['planningsState/isUnassignedRowDisplayed'];
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersList,
};
