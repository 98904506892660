import {
  msHttpClient, httpClient,
} from '@skello-utils/clients';
import {
  brainApiDataToPlanningShift,
  BRAIN_API_PROVENANCE_LABEL,
} from '@skello-utils/smart_planner/brain_transformers';
import {
  splitList,
  saveBrainSettingsToLocalStorage,
  getBrainSettingsFromLocalStorage,
  retriablePromise,
} from '@skello-utils/smart_planner/utils';
import { brainShiftsFilterForPlanning } from '@skello-utils/smart_planner/brain_filters';
import { brainApiBaseUrl } from '@config/env';
import { createShiftApiRequest } from './api/shift';

const savedBrainSettings = getBrainSettingsFromLocalStorage();

const initialState = {
  config: {},
  predictionModelReady: false,
  brainLoading: false,
  brainShifts: [],
  brainSettings: savedBrainSettings,
};

const mutations = {
  setConfig(state, config) {
    state.config = config;
  },
  setPredictionModelReady(state, predictionModelReady) {
    state.predictionModelReady = predictionModelReady;
  },
  createBrainShifts(state, payload, rootState) {
    state.brainShifts = brainApiDataToPlanningShift({
      rawShiftFromApi: payload.data,
      currentShop: rootState.currentShop.currentShop,
      postes: rootState.planningsPostes.postes,
      planningsUsers: rootState.planningsUsers.users,
    }).map(
      (brainShift, index) => ({ ...brainShift, id: `brain-${index}` }),
    );
  },
  setBrainShiftsActivation(state, enabled) {
    state.brainSettings.enabled = enabled;
    saveBrainSettingsToLocalStorage(state.brainSettings);
  },
  addBrainActionCount(state, actionKey) {
    const actionCount = {
      ...state.brainSettings.actionCount,
      [actionKey]: state.brainSettings.actionCount[actionKey] + 1,
    };
    state.brainSettings.actionCount = actionCount;
    saveBrainSettingsToLocalStorage(state.brainSettings);
  },
  resetBrainActionCount(state, actionKey) {
    const actionCount = {
      ...state.brainSettings.actionCount,
      [actionKey]: 0,
    };
    state.brainSettings.actionCount = actionCount;
    saveBrainSettingsToLocalStorage(state.brainSettings);
  },
  setBrainLoading(state, loading) {
    state.brainLoading = loading;
  },
  removeBrainShifts(state) {
    state.brainShifts = [];
  },
};

const actions = {
  fetchBrainShifts({ commit, getters, rootState, rootGetters }) {
    if (!getters.isBrainFeatureFlagActive) return Promise.resolve();

    const shopId = rootState.currentShop.currentShop.id;
    const monday = rootGetters['planningsState/monday'];
    const shopOpening = rootState.currentShop.currentShop.attributes.openingTime;

    return msHttpClient
      .get(`${brainApiBaseUrl}/shifts_classification/v2/predict/${shopId}`, {
        params: {
          monday,
          shop_opening: shopOpening,
        },
      })
      .then(({ data }) => commit('createBrainShifts', data, rootState))
      .catch(error => {
        throw error;
      });
  },

  deleteBrainShiftsOnPlanningLegacy({ dispatch, rootState, rootGetters }) {
    const shopId = rootState.currentShop.currentShop.id;
    const periodStartsAt = rootGetters['planningsState/monday'];
    const periodEndsAt = rootGetters['planningsState/sunday'];

    const planningsShifts = rootState.planningsShifts.shifts;
    const fromBrainApi = planningsShifts.filter(
      shift => shift.attributes.provenance === BRAIN_API_PROVENANCE_LABEL,
    );

    if (fromBrainApi.length === 0) return Promise.resolve();

    return dispatch('planningsShifts/deleteShifts', {
      shift_ids: fromBrainApi.map(shift => shift.id),
      shop_id: shopId,
      starts_at: periodStartsAt,
      ends_at: periodEndsAt,
    }, { root: true }).then(() => {
      this.brainShifts = [];
    });
  },

  createBrainShiftsOnPlanningLegacy({ dispatch, state, getters, rootState, rootGetters }) {
    if (!getters.isBrainEnabled) return Promise.reject(new Error('Brain is disabled'));
    const shopId = rootState.currentShop.currentShop.id;

    const periodStartsAt = rootGetters['planningsState/monday'];
    const periodEndsAt = rootGetters['planningsState/sunday'];
    const planningsShifts = rootState.planningsShifts.shifts;

    const brainShifts = state.brainShifts;

    const filteredBrainShifts = brainShiftsFilterForPlanning({
      planningsUsers: rootState.planningsUsers.users,
      availabilities: rootState.planningsUsers.availabilities,
      planningsShifts,
      brainShifts,
    });

    // If no shifts to create, return a resolved promise after a random wait time
    if (filteredBrainShifts.length === 0) {
      return new Promise(resolve => {
        const randomWaitTime = Math.floor(Math.random() * 1000) + 1000;
        const t = setTimeout(() => {
          clearTimeout(t);
          resolve();
        }, randomWaitTime);
      });
    }

    const chunks = splitList(
      filteredBrainShifts,
      filteredBrainShifts.length > 20 ?
        Math.ceil(filteredBrainShifts.length / 4) :
        4,
    );

    return Promise.all(
      chunks.map((shifts, index) => new Promise((resolve, reject) => {
        let waitTimeMs = 250;
        let retryCount = 0;

        const onFail = (retryPromise, error) => {
          if (retryCount > 3) {
            return Promise.reject(error);
          }
          retryCount += 1;
          waitTimeMs += waitTimeMs;
          return retryPromise();
        };

        retriablePromise(
          () => new Promise((retriableResolve, retriableReject) => {
            const retriablePromiseTimeout = setTimeout(() => {
              dispatch(
                'planningsShifts/createShift',
                {
                  shifts,
                  shopId,
                  periodStartsAt,
                  periodEndsAt,
                  performLater: true,
                }, {
                  root: true,
                },
              )
                .then(() => {
                  clearTimeout(retriablePromiseTimeout);
                  retriableResolve();
                })
                .catch(error => {
                  clearTimeout(retriablePromiseTimeout);
                  retriableReject(error);
                });
            }, index * waitTimeMs);
          }),
          onFail,
        ).then(resolve).catch(reject);
      })),
    );
  },

  async createBrainShiftsOnPlanning({ getters, rootGetters, rootState, dispatch }) {
    if (!getters.isBrainEnabled) return Promise.reject(new Error('Brain is disabled'));

    const shopId = rootState.currentShop.currentShop.id;

    const periodStartsAt = rootGetters['planningsState/monday'];
    const periodEndsAt = rootGetters['planningsState/sunday'];

    const filteredBrainShifts = getters.filteredBrainShiftsForPlanning
      .map(filteredBrainShiftForPlanning => ({ ...filteredBrainShiftForPlanning, id: undefined }));

    // If no shifts to create, return a resolved promise after a random wait time
    if (filteredBrainShifts.length === 0) {
      return new Promise(resolve => {
        const randomWaitTime = Math.floor(Math.random() * 1000) + 1000;
        const t = setTimeout(() => {
          clearTimeout(t);
          resolve();
        }, randomWaitTime);
      });
    }

    let waitTimeMs = 250;
    let retryCount = 0;

    const params = {
      shifts: filteredBrainShifts,
      shopId,
      periodStartsAt,
      periodEndsAt,
      performLater: true,
    };

    const onFail = (retryPromise, error) => {
      if (retryCount > 3) {
        return Promise.reject(error);
      }
      retryCount += 1;
      waitTimeMs += waitTimeMs;
      return retryPromise();
    };

    const response = await retriablePromise(
      async () => createShiftApiRequest({
        shifts: filteredBrainShifts,
        shopId,
        periodStartsAt,
        periodEndsAt,
        performLater: true,
      }, {
        root: true,
      }),
      onFail,
    );

    dispatch('planningsShifts/createShiftSuccessCallback', { params, response }, { root: true });
    return response;
  },

  fetchConfig({ commit }, { shopId, date }) {
    const params = { shop_id: shopId, date: date.toString() };

    return httpClient
      .get('/v3/api/automatic_planning/config', { params })
      .then(response => {
        commit('setConfig', response.data.data);
      });
  },
  // this action is triggered when a shift is updated, deleted or a template is applied
  // When the planning is updated we delete the automatic_planning results of the current week from the localstorage
  //
  /*
    results are structured this way in the localstorage:
    automatic_planning_last_results_${shopId}: {
      [userId_1]: {
        date: monday1,
        results: {results},
      },
      [userId_2]: {
        date: monday2,
        results: {results},
      }
    }
  */
  removeLocalStorageLastResults({ rootGetters }, { shopId }) {
    const monday = rootGetters['planningsState/monday'];
    const results = localStorage.getItem(`automatic_planning_last_results_${shopId}`);

    if (results) {
      const parsedResults = JSON.parse(results);

      // We need to loop throw the user's results to delete the one,
      // which has a result on this week
      Object.keys(parsedResults).forEach(key => {
        if (parsedResults[key].date === monday) {
          delete parsedResults[key];
        }
      });
      // If the localstorage is empty after that,
      // then we remove the key from the local storage
      if (!Object.keys(parsedResults).length) {
        localStorage.removeItem(`automatic_planning_last_results_${shopId}`);
      } else {
        // If it's not empty we update the localstorage with the datas without the user's results
        localStorage.setItem(
          `automatic_planning_last_results_${shopId}`,
          JSON.stringify(parsedResults),
        );
      }
    }
  },
  fetchPredictionModelStatus({ commit }, shopId) {
    const params = { shop_id: shopId };

    return httpClient
      .get('/v3/api/automatic_planning/prediction_model_status', { params })
      .then(response => {
        commit('setPredictionModelReady', response.data.model_ready);
      })
      .catch(() => {
        commit('setPredictionModelReady', false);
      });
  },

  async submitAutomaticPlanning(
    { rootState, getters },
    { monday, templateId, selectedPosteIds, selectedEmployeeIds, weekSelected, step, uuid },
  ) {
    const shopId = rootState.currentShop.currentShop.id;
    const params = {
      schedule: {
        date: monday,
        poste_ids: selectedPosteIds,
        user_ids: selectedEmployeeIds,
        shift_provenance: step,
      },
      provenance: 'v3',
      uuid,
    };

    if (step === getters.provenanceSteps.from_template) {
      params.schedule.planning_template_id = templateId;
    } else if (step === getters.provenanceSteps.previous_week) {
      params.schedule.previous_week_date = weekSelected.toString();
    }

    try {
      const response = await httpClient.post(
        `/v3/api/shops/${shopId}/automatic_planning/assignments/prepare_assignment`,
        params,
      );

      return { success: true, data: response.data };
    } catch (error) {
      console.error('Automatic planning assignment failed:', error);
      return { success: false, error };
    }
  },
};

const getters = {
  provenanceSteps: state => state.config.provenance_steps || {},
  canAskDeactivation: ({ brainSettings }) => (
    brainSettings.actionCount.deleteSchedule +
    brainSettings.actionCount.closePopover
  ) >= 3,
  isBrainEnabled: ({ brainSettings }) => brainSettings.enabled,
  isBrainFeatureFlagActive: (_state, _selfGetters, _rootState, rootGetters) => rootGetters['currentShop/isDevFlagEnabled']('FEATUREFLAGDEV_SUGGESTED_PLANNING_CANARY_SHOP_IDS'),
  isBrainShiftsInPlanning: (state, otherGetters, _rootState, rootGetters) => {
    if (!otherGetters.isBrainFeatureFlagActive) return false;
    if (!otherGetters.isBrainEnabled) return false;

    const shiftsForCurrentWeek = rootGetters['planningsShifts/shiftsForCurrentWeek'];

    const fromBrainApi = shiftsForCurrentWeek.filter(
      shift => shift.attributes.provenance === BRAIN_API_PROVENANCE_LABEL,
    );

    return fromBrainApi.length > 0;
  },
  isPlanningElligibleForBrain: (state, otherGetters, _rootState, rootGetters) => {
    if (!otherGetters.isBrainFeatureFlagActive) return false;
    if (!otherGetters.isBrainEnabled) return false;

    const shiftsForCurrentWeek = rootGetters['planningsShifts/shiftsCreatedForCurrentWeek'];

    // If there is no shift on the planning, we can suggest a planning
    if (shiftsForCurrentWeek.length === 0) return true;

    const absenceShifts = shiftsForCurrentWeek.filter(
      shift => shift.relationships.poste.attributes.absenceKey,
    );

    // If there is only absences on the planning, we can suggest a planning
    const isOnlyAbsencesOnPlanning = absenceShifts.length > 0 &&
      absenceShifts.length === shiftsForCurrentWeek.length;

    // If there is no shift from brain api on the planning
    // and if there is only absences, we can suggest a planning
    return otherGetters.isBrainShiftsInPlanning === false && isOnlyAbsencesOnPlanning;
  },
  filteredBrainShiftsForPlanning(state, _getters, rootState) {
    const planningsShifts = rootState.planningsShifts.shifts;

    const brainShifts = state.brainShifts;

    const filteredBrainShifts = brainShiftsFilterForPlanning({
      planningsUsers: rootState.planningsUsers.users,
      availabilities: rootState.planningsUsers.availabilities,
      planningsShifts,
      brainShifts,
    });

    return filteredBrainShifts;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
