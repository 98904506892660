<template>
  <div class="cluster-navigation-dropdown">
    <SkSelect
      ref="clusterNavigationSelect"
      v-model="localCurrentItem"
      :ajax-client="ajaxClient"
      :ajax-format-params="ajaxFormatParams"
      :ajax-format-response="ajaxFormatResponse"
      :unselectable-options="intermediaryNodeIds"
      :search-placeholder="$t('navbar.cluster_navigation.search_placeholder')"
      force-search-bar-behavior="force-show"
      ajax-url="/v3/api/cluster_nodes"
      @close="clearQuery"
      @click-on-unselectable-option="navigateDown"
    >
      <template #selected-option="{ value }">
        <SkMedallion
          :icon="value.icon"
          background-color="#d9e6ff"
          class="cluster-navigation__child__medallion"
          color="#2b66fe"
          size="x-small"
        />
        {{ value.text }}
      </template>
      <template
        v-if="!isSearchResults"
        #menu-header
      >
        <div class="cluster-navigation__parent">
          <div
            class="cluster-navigation__parent__info"
            @click="navigateUp"
          >
            <SkOroraButton
              size="small"
              variant="tertiary"
              :icon="showBackCaret ? 'BackArrowV2Icon' : null"
            >
              {{ parentName }}
            </SkOroraButton>
          </div>
          <SkOroraButton
            v-if="hasAllShopsView || hasIntermediateNodeView"
            size="small"
            variant="tertiary"
            @click="closeDropdown"
          >
            <router-link
              class="cluster-navigation__parent__view-all-shops-btn"
              :to="allShopsRoute"
            >
              {{ $t('navbar.cluster_navigation.see_all_shops') }}
            </router-link>
          </SkOroraButton>
        </div>
      </template>
      <template #empty-option>
        {{ $t('navbar.cluster_navigation.no_search_results') }}
      </template>
      <template #option="{ option }">
        <div class="cluster-navigation__child">
          <template v-if="isSearchResults">
            <template v-if="option.attributes.shopId">
              <SkMedallion
                icon="ShopIcon"
                background-color="#d9e6ff"
                class="cluster-navigation__child__medallion"
                color="#2b66fe"
                size="x-small"
              />
              <div class="cluster-navigation__child__details">
                <p class="name text-truncate">
                  {{ option.text }}
                </p>
                <p class="child_count">
                  {{ option.attributes.employeeCount }} {{ collectiveNoun(option) }}
                </p>
              </div>
            </template>
            <template v-else>
              <SkMedallion
                icon="NetworkIcon"
                background-color="#d9e6ff"
                color="#2b66fe"
                class="cluster-navigation__child__medallion"
                size="x-small"
              />
              <div class="cluster-navigation__child__details">
                <p class="name text-truncate">
                  {{ option.text }}
                </p>
                <p class="child_count">
                  {{ option.attributes.childCount }} {{ collectiveNoun(option) }}
                </p>
              </div>
              <CaretIcon
                fill=""
                class="caret--forward"
              />
            </template>
          </template>
          <template v-else>
            <SkMedallion
              v-if="option.attributes.shopId"
              icon="ShopIcon"
              background-color="#d9e6ff"
              class="cluster-navigation__child__medallion"
              color="#2b66fe"
              size="x-small"
            />
            <SkMedallion
              v-else
              icon="NetworkIcon"
              background-color="#d9e6ff"
              color="#2b66fe"
              class="cluster-navigation__child__medallion"
              size="x-small"
            />
            <div class="cluster-navigation__child__details">
              <p class="name text-truncate">
                {{ option.text }}
              </p>
              <p
                v-if="option.attributes.shopId"
                class="child_count"
              >
                {{ option.attributes.employeeCount }} {{ collectiveNoun(option) }}
              </p>
              <p
                v-else
                class="child_count"
              >
                {{ option.attributes.childCount }} {{ collectiveNoun(option) }}
              </p>
            </div>
            <CaretIcon
              v-if="!option.attributes.shopId"
              fill=""
              class="caret--forward"
            />
          </template>
        </div>
      </template>
    </SkSelect>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'ClusterNavigationDropdown',
  data() {
    return {
      ajaxClient: () => httpClient,
      intermediaryNodeIds: null,
      parentNode: {
        id: null,
        attributes: {},
      },
      isSearchResults: null,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentUser', ['currentUserUserLicenses']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentOrganisation', ['atLeastOneShopWithPunchClock']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    hasAllShopsView() {
      if (!this.parentNode) return false;

      if (this.parentNode.attributes.ancestry) return false;

      // add mode === 'shop' when you don't want the shopsDropDown to have the "view all" option
      return !this.showBackCaret &&
        (this.$route.name.startsWith('user') || this.$route.name.startsWith('home_dashboard') ||
        this.$route.name.startsWith('leave_requests_teams') ||
        this.$route.name.startsWith('leave_requests_own') ||
        (this.$route.name.startsWith('analytics') && this.$route.query.mode !== 'shop'));
    },
    hasIntermediateNodeView() {
      return (['user', 'reports'].some(page => this.$route.name.startsWith(page)) ||
      ['leave_requests_teams', 'leave_requests_own'].some(page => this.$route.name.startsWith(page)));
    },
    showBackCaret() {
      return this.parentNode && this.parentNode.attributes.ancestry;
    },
    parentName() {
      if (this.parentNode) return this.parentNode.attributes.name;

      return null;
    },
    allShopsRoute() {
      if (this.$route.name.startsWith('user')) {
        return this.allShopsEmployees;
      }
      if (this.$route.name.startsWith('analytics')) {
        return this.allShopsAnalyticsDashboard;
      }
      if (this.$route.name.startsWith('home_dashboard')) {
        return this.allShopsHomeDashboard;
      }
      if (this.$route.name.startsWith('leave_requests_teams')) {
        return this.allShopsLeaveRequestsTeam;
      }
      if (this.$route.name.startsWith('leave_requests_own')) {
        return this.allShopsLeaveRequestsOwn;
      }
      return this.allShopsReports;
    },
    allShopsEmployees() {
      return {
        name: 'users',
        params: {
          shop_id: 'all',
          select_first: true,
        },
        query: {
          ...this.$route.query,
          cluster_node: this.parentNode,
        },
      };
    },
    allShopsAnalyticsDashboard() {
      return {
        name: 'analytics_dashboard',
        params: {
          shop_id: 'all',
        },
        query: this.$route.query,
      };
    },
    allShopsHomeDashboard() {
      return {
        name: 'home_dashboard',
        params: {
          shop_id: 'all',
        },
        query: this.$route.query,
      };
    },
    allShopsLeaveRequestsTeam() {
      return {
        name: 'leave_requests_teams',
        params: {
          shop_id: 'all',
          cluster_node_id: this.parentNode.id,
        },
      };
    },
    allShopsLeaveRequestsOwn() {
      return {
        ...this.allShopsLeaveRequestsTeam,
        name: 'leave_requests_own',
      };
    },
    allShopsReports() {
      return {
        name: 'reports',
        params: {
          shop_id: 'all',
        },
        query: {
          ...this.$route.query,
          cluster_node_id: this.parentNode.id,
        },
      };
    },
    localCurrentItem: {
      get() {
        let icon;

        if (this.navContext.viewAllShops || this.navContext.viewIntermediateNodes) {
          icon = 'NetworkIcon';
        } else if (this.$route.name.startsWith('organisation_settings')) {
          icon = 'CogIcon';
        } else {
          icon = 'ShopIcon';
        }

        return {
          id: this.currentShop.id,
          text: this.navContext.name,
          icon,
        };
      },
      set(node) {
        if (node.attributes.shopId) {
          const route = {
            name: this.$route.name,
            params: { shop_id: node.attributes.shopId, badging: node.attributes.badging },
            query: { date: this.$router.currentRoute.query.date },
          };

          if (this.$route.name.startsWith('user')) {
            // The select_first option reloads the first employee
            route.params.select_first = true;
          } else if (this.$route.name.startsWith('organisation_settings')) {
            route.name = 'shop_settings';
          } else if (this.$route.name.startsWith('analytics')) {
            route.params = { shop_id: node.attributes.shopId };
            route.query = this.$route.query;
          }

          if (this.$route.name.startsWith('reports')) {
            route.name = 'reports';
            route.query = { ...this.$route.query };
            // When selecting a node using "view all" from dropdown it adds a cluster_node_id
            // in the query params; when selecting a shop, it needs to be removed
            delete route.query.cluster_node_id;

            this.$router.replace(route);
          } else {
            this.$router.push(route);
          }
        }
      },
    },
  },
  methods: {
    ajaxFormatParams(params) {
      const clusterNodeId =
        this.currentShop.id === 'all' ?
          this.navContext.clusterNodeId || this.currentOrganisation.attributes.rootNodeId :
          this.currentShop.relationships.clusterNode.attributes.parentId;

      let clusterNodeAncestry;

      if (this.navContext.viewIntermediateNodes && this.navContext.ancestry) {
        clusterNodeAncestry = `${this.navContext.ancestry}/${this.navContext.clusterNodeId}`;
      } else if (this.currentShop.id !== 'all') {
        clusterNodeAncestry = this.currentShop.relationships.clusterNode.attributes.ancestry;
      } else {
        clusterNodeAncestry = null;
      }

      // Only shops with punch clock if the currentOrganisation has at least one shop with punch clock
      if (this.$route.name.includes('badgings')) {
        params.badging = this.atLeastOneShopWithPunchClock;
      }

      return {
        ...params,
        shop_level: this.navContext.childrenAreShopNodes,
        ancestry: clusterNodeAncestry,
        parent_id: clusterNodeId,
        root_child: ((!this.navContext.viewIntermediateNodes && this.currentShop.id === 'all') || this.hasAllShopsView) &&
          this.$refs.clusterNavigationSelect.searchQuery === '',
        include_cluster_nodes_infos: true,
        report_nodes: ['reports'].some(page => this.$route.name.startsWith(page)),
        only_active_shops: true,
      };
    },
    closeDropdown(event) {
      this.$refs.clusterNavigationSelect.closeDropdown(event);
    },
    ajaxFormatResponse(response) {
      this.isSearchResults = response.meta.is_search_results;
      this.parentNode = response.meta.parent.data;
      Object.assign(this.parentNode.attributes, { isCeiling: response.meta.parent_is_ceiling });
      this.parentNode.children = response.data;
      this.intermediaryNodeIds = this.parentNode.children.filter(child => (
        child.attributes.shopId === null
      )).map(child => child.id);

      return this.parentNode.children.map(child => ({
        id: child.id,
        text: child.attributes.name,
        ...child,
      }));
    },
    collectiveNoun(node) {
      if (node.attributes.shopId) {
        return this.$t('navbar.cluster_navigation.employee_count');
      }

      if (node.attributes.childrenAreShopNodes || node.attributes.childCount === 0) {
        return this.$t('navbar.cluster_navigation.shop_count');
      }

      return this.$t('navbar.cluster_navigation.cluster_count');
    },
    navigateUp() {
      if (!this.parentNode.attributes.ancestry) return;

      this.clearQuery();
      const params = {
        ancestry: this.parentNode.attributes.ancestry,
        parent_id: this.parentNode.attributes.parentId,
        skip_pagination: true,
        include_cluster_nodes_infos: true,
        report_nodes: ['reports'].some(page => this.$route.name.startsWith(page)),
        only_active_shops: true,
      };

      // If badging page, display shops with punchclock only
      if (this.$route.name.includes('badgings')) {
        params.badging = this.atLeastOneShopWithPunchClock;
      }

      // only trigger fetchOptions if searchInput is empty
      // fetchOptions will be triggered in SkSelect with debounce if there was a searchQuery
      if (this.$refs.clusterNavigationSelect.$refs.searchInput.value === '') {
        this.$refs.clusterNavigationSelect.fetchOptions(params);
      }
    },
    navigateDown(child) {
      if (child.attributes.isDeadEnd) return;

      this.clearQuery();
      const params = {
        shop_level: child.attributes.childrenAreShopNodes,
        ancestry: `${child.attributes.ancestry}/${child.id}`,
        parent_id: child.id,
        skip_pagination: true,
        include_cluster_nodes_infos: true,
        report_nodes: ['reports'].some(page => this.$route.name.startsWith(page)),
        only_active_shops: true,
      };

      // If badging page, display shops with punchclock only
      if (this.$route.name.includes('badgings')) {
        params.badging = this.atLeastOneShopWithPunchClock;
      }

      // only trigger fetchOptions if searchInput is empty
      // fetchOptions will be triggered in SkSelect with debounce if there was a searchQuery
      if (this.$refs.clusterNavigationSelect.$refs.searchInput.value === '') {
        this.$refs.clusterNavigationSelect.fetchOptions(params);
      }
    },
    clearQuery() {
      this.$refs.clusterNavigationSelect.$refs.searchInput.clearQuery();
    },
  },
};
</script>

<style lang="scss">
.cluster-navigation-dropdown {
  margin: 0 40px;

  .sk-select__options,
  .sk-select-spinner {
    min-height: 200px;
  }

  a {
    text-decoration: none !important;
  }
}

.cluster-navigation__parent {
  height: 47px;
  border-top: 1px solid $sk-grey-10;
  border-bottom: 1px solid $sk-grey-10;
  padding: 0 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-text-s;

  &__info {
    display: flex;
    align-items: center;
  }

  .router-link-exact-active,
  &__view-all-shops-btn {
    color: $sk-black;
  }
}

.cluster-navigation__child {
  display: flex;
  align-items: center;
  position: relative;
  width: 220px;

  &__medallion {
    margin-right: 15px;
  }

  &__details {
    p.name {
      margin: 0;
      max-width: 160px;
    }

    p.child_count {
      color: $sk-grey;
      font-size: $fs-text-s;
      margin-bottom: -4px;
    }
  }

  .caret--forward {
    transform: rotate(270deg);
    fill: $sk-black;
    position: absolute;
    right: 0;
  }
}
</style>
