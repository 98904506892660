import { svcKpisBaseUrl } from '@config/env';
import { HttpClient } from '@skelloapp/client-http-lib/src/Client/HttpClient';
import { authClient } from './auth_client';

class SvcKpisClient {
  constructor() {
    const httpClient = new HttpClient(svcKpisBaseUrl, {
      clientSource: 'skelloApp:web',
      timeout: 10000,
    });

    this.kpisClient = httpClient;
  }

  async getKpis(params, date) {
    const { token } = await authClient.getAuthToken();

    return this.kpisClient.clientWithRetry.get('/kpis', {
      params: {
        ...params,
        date,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async getUserKpiSettings(userId) {
    const { token } = await authClient.getAuthToken();

    return this.kpisClient.clientWithRetry.get(`/user_kpis_settings/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async patchUserKpiSettings(userId, params) {
    const { token } = await authClient.getAuthToken();

    return this.kpisClient.clientWithoutRetry.patch(`/user_kpis_settings/user/${userId}`,
      {
        user_kpis_settings: params,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}

export const svcKpisClient = new SvcKpisClient();

export default svcKpisClient;
