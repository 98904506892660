<template>
  <div
    v-show="isMenuDisplayed"
    ref="shiftMenuWrapper"
    :style="menuPosition"
    class="planning-row__shift__menu-wrapper"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <template v-if="!hasMoreThanOneActionOnDaily && isDailyView">
      <div
        v-show="canDeleteShift"
        class="planning-row__shift__menu-button"
        data-test="quickbutton__delete"
        @click.stop="handleAction('DELETE_SHIFT')"
      >
        <SkLoader
          v-if="isShiftDeleting"
          size="small"
        />
        <TrashCanV2Icon
          v-else
          width="16"
          height="14"
          fill="#d03e50"
        />
      </div>
      <div
        v-show="canMoveToUnassigned"
        class="planning-row__shift__menu-button"
        data-test="quickbutton__move-to-unassigned"
        @click.stop="handleAction('MOVE_TO_UNASSIGNED')"
      >
        <SkLoader
          v-if="isShiftMovingToUnassigned"
          size="small"
        />
        <UserWithCrossmarkIcon
          v-else
          width="16"
          height="16"
          fill="#727272"
        />
      </div>
    </template>
    <template v-else-if="isMonthlyView">
      <div
        v-show="canDeleteShift"
        class="planning-row__shift__menu-button"
        data-test="quickbutton__delete"
        @click.stop="handleAction('DELETE_SHIFT')"
      >
        <SkLoader
          v-if="isShiftDeleting"
          size="small"
        />
        <TrashCanV2Icon
          v-else
          width="16"
          height="14"
          fill="#d03e50"
        />
      </div>
      <div
        class="planning-row__shift__menu-button"
        data-test="quickbutton__add"
        @click.stop="handleAction('ADD_NEW_SHIFT')"
      >
        <PlusSignIcon
          width="9"
          height="9"
          fill="#2b66fe"
        />
      </div>
    </template>
    <template v-else>
      <div
        class="planning-row__shift__menu-button"
        data-test="quickbutton__menu"
        @click.stop="toggleMenu"
      >
        <KebabIcon
          width="3"
          height="12"
        />
      </div>
      <div
        v-show="canAddShift"
        class="planning-row__shift__menu-button"
        data-test="quickbutton__add"
        @click.stop="handleAction('ADD_NEW_SHIFT')"
      >
        <PlusSignIcon
          width="9"
          height="9"
          fill="#2b66fe"
        />
      </div>
      <div
        v-show="isMenuOpened && canDeleteShift"
        class="planning-row__shift__menu-button"
        data-test="quickbutton__delete"
        @click.stop="handleAction('DELETE_SHIFT')"
      >
        <SkLoader
          v-if="isShiftDeleting"
          size="small"
        />
        <TrashCanV2Icon
          v-else
          width="16"
          height="14"
          fill="#d03e50"
        />
      </div>
      <div
        v-show="isMenuOpened && canMoveToUnassigned"
        class="planning-row__shift__menu-button"
        data-test="quickbutton__move-to-unassigned"
        @click.stop="handleAction('MOVE_TO_UNASSIGNED')"
      >
        <SkLoader
          v-if="isShiftMovingToUnassigned"
          size="small"
        />
        <UserWithCrossmarkIcon
          v-else
          width="16"
          height="16"
          fill="#727272"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobile } from '@skello-utils/mobile.js';

export default {
  name: 'ShiftMenu',
  data() {
    return {
      id: null,
      isShiftFromAnotherShop: false,
      isMouseHover: false,
      isMenuDisplayed: false,
      isMenuOpened: false,
      isPendingLeaveRequest: false,
      isShiftDeleting: false,
      isShiftMovingToUnassigned: false,
      isUnassignedShiftsRow: false,
      absoluteTop: 0,
      absoluteLeft: 0,
      shiftId: null,
      menuHeight: 0,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['isDailyView', 'isMonthlyView', 'isPostesView', 'areUnassignedShiftsAllowed']),
    ...mapGetters('planningsBulkEdit', ['displayBulkEditShifts']),
    menuPosition() {
      return {
        top: `${this.menuTop}px`,
        left: `${this.absoluteLeft}px`,
        pointerEvents: this.isMonthlyView ? 'none' : 'auto',
      };
    },
    menuTop() {
      return this.absoluteTop - this.menuHeight + 14; // offset the menu from the alerts (design validated)
    },
    canDeleteShift() {
      return !this.isShiftFromAnotherShop && !this.isPendingLeaveRequest;
    },
    canMoveToUnassigned() {
      return this.areUnassignedShiftsAllowed &&
        !this.isPostesView &&
        !this.isShiftFromAnotherShop &&
        !this.isPendingLeaveRequest &&
        !this.isUnassignedShiftsRow;
    },
    hasMoreThanOneActionOnDaily() {
      // On daily view, we have only 2 options currently. Might evolve in the future.
      return this.canDeleteShift && this.canMoveToUnassigned;
    },
    canAddShift() {
      return this.isMenuOpened &&
        !this.isDailyView &&
        (!this.displayBulkEditShifts || isMobile());
    },
  },
  mounted() {
    this.listenOnRoot('show-shift-menu', e => {
      this.id = e.id;
      this.isMenuDisplayed = true;
      this.absoluteTop = e.top;
      this.absoluteLeft = e.left;
      this.shiftId = e.shiftId;
      this.isShiftFromAnotherShop = e.isShiftFromAnotherShop;
      this.isPendingLeaveRequest = e.isPendingLeaveRequest;
      this.isUnassignedShiftsRow = e.isUnassignedShiftsRow;

      this.setMenuHeight();
    });
    this.listenOnRoot('hide-shift-menu', e => {
      this.isMenuDisplayed = false;
      this.isShiftDeleting = false;
      this.isShiftMovingToUnassigned = false;
    });
    this.listenOnRoot('shift-menu-action-failed', e => {
      this.isShiftDeleting = false;
      this.isShiftMovingToUnassigned = false;
    });
  },
  methods: {
    handleAction(action) {
      let source;

      if (this.isDailyView) source = 'day';
      else if (this.isMonthlyView) source = 'month';
      else source = 'week';

      if (action === 'DELETE_SHIFT') {
        if (this.isShiftDeleting) return; // Avoids multiple clicks whilst already loading
        this.isShiftDeleting = true;
        this.$skAnalytics.track('click_on_shift_destroy_button', { source });
      }

      if (action === 'MOVE_TO_UNASSIGNED') {
        if (this.isShiftMovingToUnassigned) return; // Avoids multiple clicks whilst already loading
        this.isShiftMovingToUnassigned = true;
        this.$skAnalytics.track('move_to_unassigned_shift', { source });
      }

      this.emitOnRoot('shift-menu-clicked', {
        action,
        id: this.id,
        shiftId: this.shiftId,
      });
    },
    setMenuHeight() {
      this.$nextTick(() => {
        this.menuHeight = this.$refs.shiftMenuWrapper?.getBoundingClientRect()?.height || 0;
      });
    },
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened;
      this.setMenuHeight();
    },
    onMouseEnter() {
      this.isMenuDisplayed = true;
      this.isMouseHover = true;
    },
    onMouseLeave() {
      this.isMenuOpened = false;
      this.isMouseHover = false;
      this.isMenuDisplayed = false;
    },
  },
};
</script>

<style lang="scss">
.planning-row__shift__menu-wrapper {
  position: fixed;
  flex-direction: column-reverse;
  display: flex;
  z-index: 7;
  width: 30px; // little more space on the right to avoid mouse-out too soon (UX choice)
  padding-right: 5px; // compensation for the width: 30px
}

.planning-row__shift__menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $sk-white;
  width: 25px;
  height: 25px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, .16);
  margin-top: 4px;
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    background-color: $sk-grey-10;
    transition: all .2s ease-out;
  }

  &:active {
    background-color: $sk-blue-5;
  }

  &--disabled {
    background: $sk-grey-10;
    cursor: not-allowed;
  }
}

.planning-row__shift-wrapper:hover .planning-row__shift__menu-wrapper {
  display: flex;
}
</style>
