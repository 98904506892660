<template>
  <!-- Planning fully loaded -->
  <SkModal
    v-if="isPlanningDataCompleted"
    id="print-modal"
    ref="printModal"
    :modal-title="$t('plannings.toolbar.modal.print.header.title')"
    :modal-subtitle="$t('plannings.toolbar.modal.print.header.subtitle')"
    size="ds-medium"
    @show="handleShow"
    @cancel="closeCollapse"
    @close="closeCollapse"
  >
    <template #title-icon>
      <PrinterV2Icon
        class="print-modal__print-icon"
        width="30"
        height="30"
        fill="#727272"
      />
    </template>
    <template #body>
      <SkModalSection
        v-if="showEsignatureUpsellBanner"
        border-bottom="none"
      >
        <UpsellEsignatureBanner source="print" />
      </SkModalSection>
      <SkModalSection border-bottom="none">
        <div class="print-modal__description">
          {{ $t('plannings.toolbar.modal.print.description') }}
        </div>
        <div class="print-modal__line">
          <span class="print-modal__line__label">
            {{ $t('plannings.toolbar.modal.print.select_document.label') }}
          </span>
          <SkSelectV2
            v-model="selectedPrintType"
            :options="printOptions"
            :disabled-options="invalidMonthlyPrint ? ['month'] : []"
            :label="$t('plannings.toolbar.modal.print.select_document.placeholder')"
            width="320px"
            append-to-body
          >
            <template #option="{ option }">
              <div
                v-if="option.id === 'month' && invalidMonthlyPrint"
                v-tooltip="tooltipForOption(option)"
                class="print-modal__select__option--disabled"
              >
                <span>{{ option.text }}</span>
                <PackTag
                  type="success"
                  shadow
                />
              </div>
              <span v-else>{{ option.text }}</span>
            </template>
          </SkSelectV2>
        </div>
        <div class="print-modal__line">
          <span class="print-modal__line__label">
            {{ $t('plannings.toolbar.modal.print.date_picker.label') }}
          </span>
          <div class="print-modal__line__datepickers">
            <!-- eslint-disable max-len -->
            <SkDatePicker
              ref="datepicker"
              v-model="startDate"
              :default-value="mondayAsDate.toDate()"
              :placeholder="$t('plannings.toolbar.modal.print.date_picker.place_holders.start_date')"
              :disabled-date="disabledStartDate"
              :errored="invalidStartDate"
              :error-message="$t('plannings.toolbar.modal.print.date_picker.error')"
              :clearable="false"
              :lang="$i18n.locale"
              input-moment-format="DD MMM YYYY"
              append-to-body
              hide-footer
            />
            <!-- eslint-enable max-len -->
            <div class="print-modal__line__datepickers__separator">
              -
            </div>
            <SkDatePicker
              ref="datepicker"
              v-model="endDate"
              :default-value="sundayAsDate.toDate()"
              :placeholder="$t('plannings.toolbar.modal.print.date_picker.place_holders.end_date')"
              :disabled-date="disabledEndDate"
              :errored="invalidEndDate"
              :error-message="$t('plannings.toolbar.modal.print.date_picker.error')"
              :clearable="false"
              :lang="$i18n.locale"
              input-moment-format="DD MMM YYYY"
              append-to-body
              hide-footer
            />
          </div>
        </div>
        <div class="print-modal__line">
          <span
            class="print-modal__line__label"
            style="align-self: flex-start;"
          >
            {{ $t('plannings.toolbar.modal.print.absences.label') }}
          </span>

          <div class="print-modal__line__absences">
            <SkRadio
              id="absences-only-no"
              v-model="selectedAbsencesOnly"
              :data-value="false"
            >
              {{ $t('plannings.toolbar.modal.print.absences.options.shifts_and_absences') }}
            </SkRadio>
            <SkRadio
              id="absences-only-yes"
              v-model="selectedAbsencesOnly"
              :data-value="true"
            >
              {{ $t('plannings.toolbar.modal.print.absences.options.absences_only') }}
            </SkRadio>
          </div>
        </div>

        <SkCollapse
          v-if="isCollapseDisplayed"
          ref="collapse"
          class="print-modal__collapse"
        >
          <template #labelLeft>
            {{ $t('plannings.toolbar.modal.print.print_parameters.label') }}
          </template>

          <template #content>
            <div
              v-if="isPdfFormatDisplayed"
              :class="pdfFormatSectionClasses"
            >
              <div class="print-modal__collapse__section_title">
                {{ $t('plannings.toolbar.modal.print.print_parameters.format.title') }}
              </div>
              <div class="print-modal__collapse__parameter_line">
                <div class="print-modal__collapse__parameter_line-input">
                  <SkRadio
                    id="pdf-format-landscape"
                    v-model="pdfFormat"
                    data-value="landscape"
                  />
                  <label
                    for="pdf-format-landscape"
                    class="parameter_line__label--clickable"
                  >
                    {{ $t('plannings.toolbar.modal.print.print_parameters.format.landscape') }}
                  </label>
                </div>
                <div class="print-modal__collapse__parameter_line-input">
                  <SkRadio
                    id="pdf-format-portrait"
                    v-model="pdfFormat"
                    data-value="portrait"
                  />
                  <label
                    for="pdf-format-portrait"
                    class="parameter_line__label--clickable"
                  >
                    {{ $t('plannings.toolbar.modal.print.print_parameters.format.portrait') }}
                  </label>
                </div>
              </div>
              <div
                v-if="isDailyView"
                class="print-modal__collapse__section_title"
              >
                {{ $t('plannings.toolbar.modal.print.print_parameters.page_break.title') }}
              </div>
              <div
                v-if="isDailyView"
                class="print-modal__collapse__parameter_line"
              >
                <div class="print-modal__collapse__parameter_line-input">
                  <SkRadio
                    id="page-break-one-day"
                    v-model="printParameters['page_break_in_pdf']"
                    :data-value="true"
                  />
                  <label
                    for="page-break-one-day"
                    class="parameter_line__label--clickable"
                  >
                    {{ $t('plannings.toolbar.modal.print.print_parameters.page_break.one_day') }}
                  </label>
                </div>
                <div class="print-modal__collapse__parameter_line-input">
                  <SkRadio
                    id="page-break-several-days"
                    v-model="printParameters['page_break_in_pdf']"
                    :data-value="false"
                  />
                  <label
                    for="page-break-several-days"
                    class="parameter_line__label--clickable"
                  >
                    {{ $t('plannings.toolbar.modal.print.print_parameters.page_break.several_days') }}
                  </label>
                </div>
              </div>
            </div>
            <template v-if="arePrintParametersDisplayed">
              <!-- eslint-disable-next-line max-len -->
              <div class="print-modal__collapse__section_title print-modal__collapse__section_title--details">
                {{ $t('plannings.toolbar.modal.print.print_parameters.details') }}
              </div>
              <div
                v-for="printParameter in displayedPrintParameters"
                :key="printParameter"
                class="
                  print-modal__collapse__parameter_line
                  print-modal__collapse__parameter_line--switch_line"
              >
                <label
                  :class="{ 'parameter__label--unselected': !printParameters[printParameter] }"
                >
                  {{ $t(`plannings.toolbar.modal.print.print_parameters.${printParameter}`) }}
                </label>
                <SkSwitch
                  v-model="printParameters[printParameter]"
                  @click.native="trackParametersChange(printParameter)"
                />
              </div>
            </template>
          </template>
        </SkCollapse>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <!--
        We simply open the pdf file in a new tab. File is server side rendered in real time
        and converted to pdf (on V1 endpoint).
      -->
      <SkOroraButton
        :disabled="submitButtonDisabled"
        @click="handleSubmit"
      >
        {{ $t('plannings.toolbar.modal.print.action.submit-button') }}
      </SkOroraButton>
    </template>
  </SkModal>
  <!-- Planning loading -->
  <SkModal
    v-else
    id="print-modal"
    ref="printModal"
    :modal-title="$t('plannings.toolbar.modal.print.header.title')"
    :modal-subtitle="$t('plannings.toolbar.modal.print.header.subtitle')"
    :display-footer="isPlanningDataCompleted"
    size="ds-medium"
    @show="handleShow"
    @cancel="closeCollapse"
    @close="closeCollapse"
  >
    <template #title-icon>
      <PrinterV2Icon
        class="print-modal__print-icon"
        width="30"
        height="30"
        fill="#727272"
      />
    </template>
    <template #body>
      <SkModalSection
        border-bottom="none"
      >
        <LoaderContainer />
      </SkModalSection>
    </template>
  </SkModal>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { isEmptyObject } from '@skello-utils/validators';
import { httpClient } from '@skello-utils/clients';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';
import skDate from '@skello-utils/dates';
import UpsellEsignatureBanner from '@app-js/shared/components/UpsellEsignatureBanner';
import PackTag from '@app-js/shared/components/PackTag';

const PRINT_PARAMETERS = {
  COUNTER_DISPLAY: 'counter_display',
  MEAL_IN_PDF: 'meal_in_pdf',
  TOTAL_IN_PDF: 'total_in_pdf',
  PAUSE_IN_PDF: 'pause_in_pdf',
  UNASSIGNED_SHIFTS_ENABLED: 'unassigned_shifts_enabled',
  ALLOW_UNASSIGNED_SHIFTS: 'allow_unassigned_shifts',
  BIRTHDAY_DISPLAY: 'birthday_display',
};

export default {
  name: 'PrintModal',
  components: {
    UpsellEsignatureBanner,
    PackTag,
    LoaderContainer,
  },
  data() {
    return {
      selectedPrintType: null,
      selectedAbsencesOnly: false,
      startDate: null,
      endDate: null,
      pdfFormat: 'landscape',
      printParameters: {},
      areParametersSet: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', [
      'monday',
      'sunday',
      'isDailyView',
      'currentDate',
      'isPostesView',
      'isMonthlyView',
      'firstMondayOfMonth',
      'lastSundayOfMonth',
    ]),
    ...mapGetters('planningsUsers', ['filteredUsers']),
    ...mapGetters('planningsPostes', ['filteredPostes']),
    ...mapGetters('currentShop', ['isMealCompensationDone', 'isDevFlagEnabled', 'checkFeatureFlag']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsState', ['filters', 'shopPlanningConfig']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isFirstBatchLoaded', 'isLoadingCompleted']),
    isPlanningDataCompleted() {
      if (this.isProgressiveLoadingEnabled) {
        return this.isLoadingCompleted;
      }
      return true;
    },
    packOfferName() {
      return this.$t(`pack_offers.${this.currentOrganisation.attributes.packOffer.name}`);
    },
    invalidMonthlyPrint() {
      return !this.currentOrganisation.attributes.packOffer.month_plannings_print_enabled;
    },
    submitButtonDisabled() {
      return (this.isMonthlyPlanningPrint && this.invalidMonthlyPrint) ||
      this.invalidStartDate || this.invalidEndDate;
    },
    isPdfFormatDisplayed() {
      return this.isWeeklyPlanningPrint || this.isDailyPlanningPrint || this.isMonthlyPlanningPrint;
    },
    arePrintParametersDisplayed() {
      return this.displayedPrintParameters.length > 0;
    },
    mondayAsDate() {
      const firstMonday = this.isMonthlyView ?
        this.firstMondayOfMonth :
        this.monday;

      return skDate(firstMonday);
    },
    sundayAsDate() {
      const lastSunday = this.isMonthlyView ?
        this.lastSundayOfMonth :
        this.sunday;

      return skDate(lastSunday);
    },
    printOptionsDay() {
      return [
        { id: 'day', text: this.$t('plannings.toolbar.modal.print.select_document.option.day') },
        { id: 'attendance', text: this.$t('plannings.toolbar.modal.print.select_document.option.attendance') },
      ];
    },
    printOptionsWeek() {
      const printOptions = [
        { id: 'week', text: this.$t('plannings.toolbar.modal.print.select_document.option.week') },
        { id: 'attendance', text: this.$t('plannings.toolbar.modal.print.select_document.option.attendance') },
      ];
      if (this.currentOrganisation.attributes.packOffer.month_plannings_print_enabled &&
          !this.isPostesView) {
        printOptions.splice(1, 0, { id: 'weeks_range_summary', text: this.$t('plannings.toolbar.modal.print.select_document.option.weeks_range_summary') });
      }
      return printOptions;
    },
    printOptionsMonth() {
      return [
        { id: 'month', text: this.$t('plannings.toolbar.modal.print.select_document.option.month') },
        { id: 'attendance', text: this.$t('plannings.toolbar.modal.print.select_document.option.attendance') },
      ];
    },
    printOptions() {
      if (this.isDailyView) return this.printOptionsDay;

      if (this.isMonthlyView) return this.printOptionsMonth;

      return this.printOptionsWeek;
    },
    displayedMonthlyViewParameters() {
      if (this.isMonthlyPlanningPrint) return [];

      // Attendance sheet
      const printParameters = [];
      if (this.currentShop.attributes.modulation) {
        printParameters.push(PRINT_PARAMETERS.COUNTER_DISPLAY);
      }

      if (this.isMealCompensationDone && this.currentShop.attributes.isMealRuleBenefitInKind) {
        printParameters.push(PRINT_PARAMETERS.MEAL_IN_PDF);
      }

      return printParameters;
    },
    displayedPrintParametersByPrintType() {
      if (this.isWeeklyPlanningPrint || this.isDailyPlanningPrint) {
        const printParameters = [
          PRINT_PARAMETERS.TOTAL_IN_PDF,
          PRINT_PARAMETERS.PAUSE_IN_PDF,
        ];
        if (this.isMealCompensationDone && this.currentShop.attributes.isMealRuleBenefitInKind) {
          printParameters.push(PRINT_PARAMETERS.MEAL_IN_PDF);
        }
        if (this.shopPlanningConfig.attributes.allowUnassignedShifts &&
          this.checkPackOfferFlag(PRINT_PARAMETERS.UNASSIGNED_SHIFTS_ENABLED)) {
          printParameters.push(PRINT_PARAMETERS.ALLOW_UNASSIGNED_SHIFTS);
        }
        if (this.shopPlanningConfig.attributes.birthdayDisplay) {
          printParameters.push(PRINT_PARAMETERS.BIRTHDAY_DISPLAY);
        }
        return printParameters;
      }
      if (this.isWeeksRangeSummaryPlanningPrint &&
            this.shopPlanningConfig.attributes.allowUnassignedShifts &&
            this.checkPackOfferFlag(PRINT_PARAMETERS.UNASSIGNED_SHIFTS_ENABLED)
      ) {
        return [
          PRINT_PARAMETERS.ALLOW_UNASSIGNED_SHIFTS,
        ];
      }

      // Attendance sheet
      const printParameters = [];
      if (this.currentShop.attributes.modulation) {
        printParameters.push(PRINT_PARAMETERS.COUNTER_DISPLAY);
      }
      if (this.isMealCompensationDone && this.currentShop.attributes.isMealRuleBenefitInKind) {
        printParameters.push(PRINT_PARAMETERS.MEAL_IN_PDF);
      }

      return printParameters;
    },
    displayedPrintParameters() {
      // If only absences are displayed -> no need to display other parameters
      if (this.selectedAbsencesOnly) return [];

      // NOTE: Handling parameters by view type
      if (this.isMonthlyView) return this.displayedMonthlyViewParameters;

      // NOTE: Otherwise -> Handling parameters by selected print type
      return this.displayedPrintParametersByPrintType;
    },
    // this is used when user manually input a date
    hasDateError() {
      return skDate(this.startDate).isAfter(skDate(this.endDate));
    },
    // this is used when user manually input a date
    isValidMonday() {
      if (!this.startDate) return false;
      if (this.selectedPrintType !== 'week') return true;
      return skDate(this.startDate).isMonday();
    },
    // this is used when user manually input a date
    isValidSunday() {
      if (!this.endDate) return false;
      if (this.selectedPrintType !== 'week') return true;
      return skDate(this.endDate).isSunday();
    },
    invalidStartDate() {
      return !this.isValidMonday || this.hasDateError;
    },
    invalidEndDate() {
      return !this.isValidSunday || this.hasDateError;
    },
    isDailyPlanningPrint() {
      return this.selectedPrintType === 'day';
    },
    isWeeklyPlanningPrint() {
      return this.selectedPrintType === 'week';
    },
    isMonthlyPlanningPrint() {
      return this.selectedPrintType === 'month';
    },
    isWeeksRangeSummaryPlanningPrint() {
      return this.selectedPrintType === 'weeks_range_summary';
    },
    isAttendancePlanningPrint() {
      return this.selectedPrintType === 'attendance';
    },
    isCollapseDisplayed() {
      if (!this.areParametersSet) return false;
      if (this.isMonthlyPlanningPrint) return true;

      return this.arePrintParametersDisplayed || this.isPdfFormatDisplayed;
    },
    generatePdfUrl() {
      // Using endpoints from V1 since generating pdf in V3 is too complex atm
      // V1 is using an html erb to do a server side rendering and then convert that into a pdf using wkhtmltopdf
      // it's too complexe and useless to reproduce this behaviour on a V3 endpoint
      let endpoint;
      if (this.isDailyView) {
        endpoint = this.isAttendancePlanningPrint ? 'week_plannings' : 'day_plannings';
      } else if (this.isPostesView && this.isWeeklyPlanningPrint) {
        endpoint = 'poste_plannings';
      } else if (this.isMonthlyPlanningPrint || this.isWeeksRangeSummaryPlanningPrint) {
        endpoint = 'month_plannings';
      } else {
        endpoint = 'week_plannings';
      }
      const type = this.isAttendancePlanningPrint ? 'attendance_sheets' : 'pdf';
      const url = `/${endpoint}/${this.currentShop.id}/${type}?`;

      const printParams = this.displayedPrintParameters.reduce((accumulator, printParameter) => {
        accumulator[printParameter] = this.printParameters[printParameter];
        return accumulator;
      }, {});

      const params = new URLSearchParams({
        export_type: this.isAttendancePlanningPrint ? 'attendance_sheets' : 'planning',
        detail: this.isWeeksRangeSummaryPlanningPrint ? 'monthly_summary' : 'weekly_summary',
        start_date: this.startDate,
        end_date: this.endDate,
        absences_only: this.selectedAbsencesOnly,
        pdf_format: this.pdfFormat,
        keep_unassigned: printParams.allow_unassigned_shifts,
        birthday_display: !!printParams.birthday_display, // needs to be cast as Boolean if undefined, or backend skips it
        day_plannings_v3: this.isDailyView,
      });

      if (this.isPostesView) {
        params.append('poste_ids', this.filteredPostes.map(poste => poste.id).join(','));
      } else {
        params.append('users', this.filteredUsers.map(user => user.id));
      }

      // If filters are set -> add them to the URL parameters to be displayed in the pdf
      if (this.filters.postes) {
        this.filters.postes.forEach(posteId => params.append('filters[poste][]', posteId));
      }
      if (this.filters.teams) {
        this.filters.teams.forEach(teamId => params.append('filters[team][]', teamId));
      }
      if (this.filters.users) {
        this.filters.users.forEach(userId => params.append('filters[employee][]', userId));
      }

      return `${url}${params.toString()}`;
    },
    showEsignatureUpsellBanner() {
      // The check on the open_esignature_upsell url parameter is to avoid showing the upsell banner
      // if the user has already clicked the discover CTA.
      return this.checkFeatureFlag('FEATURE_ESIGNATURE') &&
        this.currentLicense.attributes.canPublishPlanning &&
        !this.currentShop.attributes.esignatureActive &&
        this.$route.query?.open_esignature_upsell !== 'true' &&
        this.isDevFlagEnabled('FEATUREDEV_ESIGNATURE_UPSELL');
    },
    pdfFormatSectionClasses() {
      return {
        'print-modal__collapse__section': true,
        'print-modal__collapse__section--no-border': !this.arePrintParametersDisplayed,
      };
    },
  },
  methods: {
    handleShow() {
      this.selectedPrintType = this.getDefaultSelectedType();

      this.startDate = this.isDailyView ? this.currentDate : this.mondayAsDate.format('YYYY-MM-DD');
      this.endDate = this.isDailyView ? this.currentDate : this.sundayAsDate.format('YYYY-MM-DD');

      // set selectecParameters according to shop settings after shopPlanningConfig are fetched
      if (!this.areParametersSet) {
        const currentParameters = this.shopPlanningConfig.attributes;
        this.printParameters = {
          total_in_pdf: currentParameters.totalInPdf,
          page_break_in_pdf: currentParameters.pageBreakInPdf,
          pause_in_pdf: currentParameters.pauseInPdf,
          absences_only: currentParameters.onlyAbsences,
          meal_in_pdf: currentParameters.mealInPdf,
          allow_unassigned_shifts: currentParameters.allowUnassignedShifts,
          birthday_display: currentParameters.birthdayDisplay,
          counter_display: currentParameters.counterDisplay,
        };
        this.areParametersSet = true;
      }

      if (this.isProgressiveLoadingEnabled && this.isFirstBatchLoaded) {
        this.$root.$emit('need-all-planning-data');
      }
    },
    tooltipForOption(option) {
      return option.id === 'month' && this.invalidMonthlyPrint ? this.$t('plannings.toolbar.actions_bar.icons_labels.print.tooltip', {
        pack_name_translated: this.packOfferName,
      }) : '';
    },
    trackErrorOnNewWindow(newWindow) {
      newWindow.addEventListener('load', () => {
        const { error: errorMsg } = JSON.parse(newWindow.document.body.innerText);

        newWindow.close();
        this.$skAnalytics.track('export_planning_day_exceeded_shifts');
        this.handleErrorToaster(errorMsg);
      });
    },
    async handleSubmit() {
      if (this.isMonthlyView) {
        this.$skAnalytics.track(`month_view_V3_print_${this.selectedPrintType}`);
      } else {
        this.$skAnalytics.track(`export_planning_${this.selectedPrintType}`);
      }

      // Update only params for printing
      // allow_unassigned_shifts and birthday_display params are handled in generatePdf
      // because we don't want to update them in the planning config
      const paramsToOmit = [
        PRINT_PARAMETERS.ALLOW_UNASSIGNED_SHIFTS,
        PRINT_PARAMETERS.BIRTHDAY_DISPLAY,
      ];
      const printSettings = this.displayedPrintParameters
        .filter(param => !paramsToOmit.includes(param))
        .reduce((accumulator, printParameter) => {
          accumulator[printParameter] = this.printParameters[printParameter];
          return accumulator;
        }, {});

      // No params because there are none to be updated
      if (!isEmptyObject(printSettings)) {
        this.updateShopPrintParams(printSettings);
      }

      this.closeCollapse();
      this.$refs.printModal.hide(); // Close modal

      const newWindow = window.open('', '_blank');
      const response = await httpClient.get(this.generatePdfUrl);
      newWindow.location.href = response.data.url;
      if (this.isDailyView) this.trackErrorOnNewWindow(newWindow);
    },
    updateShopPrintParams(printSettings) {
      const params = {
        shop_planning_settings: {
          ...printSettings,
          page_break_in_pdf: this.printParameters.page_break_in_pdf,
        },
        shop_id: this.currentShop.id,
      };

      return httpClient.patch('/v3/api/plannings/shop/planning_config', params);
    },
    handleErrorToaster(errorMessage) {
      this.$skToast({
        message: errorMessage,
        variant: 'error',
      });
    },
    disabledStartDate(date) {
      if (skDate(date).isAfter(skDate(this.endDate))) return true;
      if (!skDate(date).isMonday() && this.selectedPrintType === 'week') return true;
      return false;
    },
    disabledEndDate(date) {
      if (skDate(date).isBefore(skDate(this.startDate))) return true;
      if (!skDate(date).isSunday() && this.selectedPrintType === 'week') return true;
      return false;
    },
    closeCollapse() {
      if (this.isCollapseDisplayed) this.$refs.collapse?.closeCollapse();

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'print-modal');
    },
    trackParametersChange(parameter) {
      const action = this.printParameters[parameter] ? 'display' : 'hide';
      this.$skAnalytics.track(`planning_print_${parameter}_${action}`);
    },
    getDefaultSelectedType() {
      if (this.isDailyView) return 'day';

      if (this.isMonthlyView) {
        if (this.invalidMonthlyPrint) return 'attendance';
        return 'month';
      }

      return 'week';
    },
  },
};
</script>
<style lang="scss" scoped>

label {
  display: inline-block;
  margin-bottom: .5rem;
}

/* design adjustments to modal element */
#print-modal ::v-deep {
  .sk-modal__subtitle {
    margin-top: 0;
  }

  .sk-modal__section {
    padding-bottom: 24px;
  }
}

.print-modal__collapse__parameter_line-input {
  display: flex;
  align-items: center;
  width: 130px;
  white-space: nowrap;
}

/* design adjustments to collapse element */
::v-deep .sk-collapse {
  .sk-collapse__label {
    padding: 15px 24px;
    font-weight: $fw-semi-bold;
  }

  .sk-collapse__content {
    margin-top: 1px;
    padding: 0 24px 3px;
  }
}

/* design adjustments to radio elements */
::v-deep .sk-radio-v3 {
  .sk-radio__label {
    margin: 0;
  }
}

/* design adjustments to switch element */
::v-deep .sk-switch-v3 {
  height: 22px;

  .sk-switch__slider {
    margin: 0;
  }
}

.print-modal {
  &__print-icon {
    display: flex;
    padding: 6px;
    border-radius: 50%;
    background-color: $sk-grey-5;
  }

  &__description {
    margin-bottom: 24px;
    margin-top: -5px;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;

    &__label {
      font-weight: $fw-semi-bold;
      padding-right: 14px;
    }

    &__datepickers {
      display: flex;
      width: 320px;

      &__separator {
        padding: 10px 17px;
      }
    }

    &__absences {
      width: 320px;
      display: flex;
      flex-direction: column;
    }
  }

  &__collapse {
    width: 572px;
    box-shadow: 4px 0 20px rgba(0, 0, 0, .08);
    border-radius: 4px;
    margin-top: 30px;
  }

  &__submit-button {
    margin-left: 10px;
    text-decoration: none;
  }

  &__select__option--disabled {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.print-modal__collapse__parameter_line {
  display: flex;
  align-items: center;
  padding: 5px 0 20px;

  label {
    margin: 0;
  }

  .parameter__label--unselected {
    opacity: .3;
  }

  .parameter_line__label--clickable {
    cursor: pointer;
  }

  &--switch_line {
    justify-content: space-between;
  }
}

.print-modal__collapse__section {
  border-bottom: 1px solid $sk-grey-10;
  margin-bottom: 12px;
}

.print-modal__collapse__section--no-border {
  border-bottom: none;
}

.print-modal__collapse__section_title {
  padding: 2px 0 7px;
  color: $sk-grey;

  &--details {
    margin-bottom: 3px;
  }
}
</style>
