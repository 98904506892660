import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import {
  httpClient,
  svcRequestsClient,
} from '@skello-utils/clients';
import { matchAssociatedCollection } from './employees/selected-employee';

const initialState = {
  error: null,
  leaveRequests: [],
  availabilityRequests: [],
  availabilityUsers: [],
  absencesByShopId: {},
  allShopsAbsences: {},
  managersByEmployeeId: {},
  managedEmployees: [],
  shopTeams: [],
  isFetchingLeaveRequests: false,
  isFetchingAvailabilityRequests: false,
  pagination: {
    current_page: 1,
    per_page: 50,
    total_pages: 0,
  },
  availabilitiesPagination: {
    current_page: 1,
    per_page: 50,
    total_pages: 0,
  },
  sort: { column: 'status', order: 'asc' },
  tableLeaveRequestFilters: null,
  tableAvailabilityRequestsFilters: null,
  areEmployeesLoading: false,
  areTeamsLoading: false,
  availabilityRequestsTableHeaders: {
    own: {
      type: { active: true },
      recurrence: { active: true },
      creation_date: { active: true },
      date: { active: true },
      hours: { active: true },
      status: { active: true },
      actions: { active: true },
    },
    teams: {
      employee: { active: true },
      type: { active: true },
      recurrence: { active: true },
      creation_date: { active: true },
      date: { active: true },
      hours: { active: true },
      actions: { active: true },
      status: { active: true },
    },
  },
  pendingRequestCounts: {
    pendingLeaveRequestsCount: 0,
    pendingAvailabilitiesCount: 0,
    total: 0,
  },
  leaveRequestsTableHeadersAllShops: {
    own: {
      shop: { active: true },
      duration: { active: true },
      dates: { active: true, sort: true, name: 'dates' },
      type: { active: true },
      sent_at: { active: true, sort: true, name: 'sent_at' },
      status: { active: true, sort: true, name: 'status', defaultSort: 'asc' },
      actions: { active: true },
    },
    teams: {
      shop: { active: true },
      employee: { active: true, sort: true, name: 'employee' },
      duration: { active: true },
      dates: { active: true, sort: true, name: 'dates' },
      type: { active: true },
      sent_at: { active: true, sort: true, name: 'sent_at' },
      status: { active: true, sort: true, name: 'status', defaultSort: 'asc' },
      actions: { active: true },
    },
  },
  leaveRequestsTableHeadersSingleShop: {
    own: {
      duration: { active: true },
      dates: { active: true, sort: true, name: 'dates' },
      type: { active: true },
      validator: { active: true },
      sent_at: { active: true, sort: true, name: 'sent_at' },
      status: { active: true, sort: true, name: 'status', defaultSort: 'asc' },
      actions: { active: true },
    },
    teams: {
      employee: { active: true, sort: true, name: 'employee' },
      duration: { active: true },
      dates: { active: true, sort: true, name: 'dates' },
      type: { active: true },
      validator: { active: true },
      sent_at: { active: true, sort: true, name: 'sent_at' },
      status: { active: true, sort: true, name: 'status', defaultSort: 'asc' },
      actions: { active: true },
    },
  },
  isLicenseAuthorized: false,
  isSelectedShopLoaded: true,
  isSelectedClusterNodeLoaded: true,
  areClusterNodeShopsLoaded: true,
};

const mutations = {
  deleteLeaveRequestSuccess(state, requestId) {
    const requestIndex = state.leaveRequests.findIndex(
      ({ attributes }) => attributes.id === requestId,
    );

    if (requestIndex === -1) return;

    state.leaveRequests.splice(requestIndex, 1);
  },
  fetchAbsencesSuccess(state, { absences, shopId }) {
    Vue.set(state.absencesByShopId, shopId, absences);
  },
  fetchAllAbsencesSuccess(state, payload) {
    state.allShopsAbsences = payload;
  },
  fetchLeaveRequestsSuccess(state, payload) {
    state.leaveRequests = payload;
  },
  fetchManagersSuccess(state, { managers, employeeId }) {
    Vue.set(state.managersByEmployeeId, employeeId, managers);
  },
  fetchShopTeamsSuccess(state, payload) {
    state.shopTeams = payload.data;
  },
  fetchAvailabilityRequestsSuccess(state, payload) {
    state.availabilityRequests = payload;
  },
  setIsFetchingAvailabilityRequests(state, isFetchingAvailabilityRequests) {
    state.isFetchingAvailabilityRequests = isFetchingAvailabilityRequests;
  },
  setAvailabilitiesPagination(state, pagination) {
    state.availabilitiesPagination = pagination;
  },
  setAvailabilityUsers(state, payload) {
    state.availabilityUsers = payload;
  },
  replaceAvailability(state, newAvail) {
    const availability = state.availabilityRequests
      .find(availabilityRequest => availabilityRequest.id === newAvail?.id);
    if (availability) {
      Object.assign(availability, cloneDeep(newAvail));
    }
  },
  removeAvailability(state, availabilityId) {
    // eslint-disable-next-line arrow-body-style
    state.availabilityRequests = state.availabilityRequests.filter(availabilityRequest => {
      return availabilityRequest.id !== availabilityId;
    });
  },
  setSort(state, sort) {
    state.sort = sort;
  },
  fetchPendingLeaveRequestsCountSuccess(state, pendingRequestCounts) {
    state.pendingRequestCounts.pendingLeaveRequestsCount = pendingRequestCounts;
    state.pendingRequestCounts.total += state.pendingRequestCounts.pendingLeaveRequestsCount;
  },
  fetchPendingAvailabilitiesRequestsCountSuccess(state, pendingRequestCounts) {
    state.pendingRequestCounts.pendingAvailabilitiesCount =
      pendingRequestCounts.pendingAvailabilitiesCount;
    state.pendingRequestCounts.total += state.pendingRequestCounts.pendingAvailabilitiesCount;
  },
  fetchManagedEmployeesSuccess(state, payload) {
    state.managedEmployees = payload.data.map(
      employee => matchAssociatedCollection(
        employee,
        payload.included,
        'memberships',
        'membership',
      ),
    );
  },
  requestError(state, error) {
    state.error = error;
  },
  setIsFetchingLeaveRequests(state, isFetchingLeaveRequests) {
    state.isFetchingLeaveRequests = isFetchingLeaveRequests;
  },
  setIsFetchingManagedEmployees(state, isFetchingManagedEmployees) {
    state.areEmployeesLoading = isFetchingManagedEmployees;
  },
  setIsFetchingTeams(state, isFetchingTeams) {
    state.areTeamsLoading = isFetchingTeams;
  },
  setIsFetchingPendingRequests(state) {
    state.pendingRequestCounts = {
      pendingLeaveRequestsCount: 0,
      pendingAvailabilitiesCount: 0,
      total: 0,
    };
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setFilters(state, filters) {
    if (this.getters['requests/isAvailabilityRequestsView']) {
      state.tableAvailabilityRequestsFilters = {
        ...state.tableAvailabilityRequestsFilters,
        ...filters,
      };

      return;
    }

    const allFilters = {
      ...state.tableLeaveRequestFilters,
      ...filters,
    };

    if (!state.tableLeaveRequestFilters) {
      state.tableLeaveRequestFilters = allFilters;

      return;
    }

    const currentKeysNotInNewFilters = Object.keys(state.tableLeaveRequestFilters).filter(
      stateFilterKey => filters[stateFilterKey] === undefined,
    );

    state.tableLeaveRequestFilters = omit(allFilters, currentKeysNotInNewFilters);
  },
  setGrantingAccess(state, {
    isLicenseAuthorized,
    isSelectedClusterNodeLoaded,
    isSelectedShopLoaded,
    areClusterNodeShopsLoaded,
  }) {
    state.areClusterNodeShopsLoaded = areClusterNodeShopsLoaded ?? state.areClusterNodeShopsLoaded;
    state.isLicenseAuthorized = isLicenseAuthorized ?? state.isLicenseAuthorized;
    state.isSelectedShopLoaded = isSelectedShopLoaded ?? state.isSelectedShopLoaded;
    state.isSelectedClusterNodeLoaded =
      isSelectedClusterNodeLoaded ?? state.isSelectedClusterNodeLoaded;
  },
};

const actions = {
  async deleteLeaveRequest({ commit, rootGetters }, params) {
    try {
      let response;
      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.deleteLeaveRequest(params.attributes.id);
      } else {
        response = await httpClient.delete(`/v3/api/leave_requests/${params.attributes.id}`, { params });
      }
      commit('deleteLeaveRequestSuccess', params.attributes.id);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchShopAbsences({ commit }, shopId) {
    let response;

    try {
      response = await httpClient.get(`/v3/api/absences?shop_id=${shopId}`);

      commit('fetchAbsencesSuccess', { shopId, absences: response.data.data });

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchAllAbsences({ commit, rootState }) {
    let response;

    try {
      const shopIds = rootState.currentOrganisation.currentNodeShops.map(shop => shop.id);
      const params = {
        poste_type: 'absence',
        shop_id: shopIds[0],
        shop_ids: shopIds,
        absence_flags: 'leave_request_absences',
        active: true,
      };
      response = await httpClient.get('/v3/api/postes', { params });

      const allAbscences = response.data?.data.reduce(
        (absencesByShopId, poste) => {
          const shopId = poste.attributes.shopId;
          const posteId = poste.id;
          const absenceKey = poste.attributes.absenceKey;
          if (!absencesByShopId[shopId]) {
            const absenceShop =
              rootState.currentOrganisation.currentNodeShops.find(
                shop => parseInt(shop.id, 10) === shopId,
              );
            absencesByShopId[shopId] = {
              absencesCountry: absenceShop.attributes.absencesCountry,
              absences: [],
            };
          }
          absencesByShopId[shopId].absences.push({ posteId, absenceKey });
          return absencesByShopId;
        }, {});

      commit('fetchAllAbsencesSuccess', allAbscences);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchLeaveRequests({ dispatch, rootGetters }, {
    isOwnRequestsView,
    shopIds,
    userId,
    pagination,
    sort,
    filters,
    isAllRequestsView,
  }) {
    if (isOwnRequestsView) {
      dispatch('fetchLeaveRequestsOwn', { shopIds, userId, pagination, sort, filters });
    } else {
      if (!isAllRequestsView && filters.teams.length > 0 && rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        const teamsUsersResponse = (await httpClient.get('/v3/api/teams/user_ids', { params: { shop_id: shopIds[0], teams_id: filters.teams } }));
        const teamUserIds = teamsUsersResponse.data.user_ids.map(item => `${item}`);
        filters.employeeId = [...new Set([...filters.employeeId, ...teamUserIds])];
      }
      dispatch('fetchLeaveRequestsTeam', { shopIds, pagination, sort, filters });
    }
  },
  async fetchLeaveRequestsOwn({ commit, rootGetters },
    { shopIds, userId, pagination, sort, filters }) {
    let params;
    if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
      params = {
        shopIds: JSON.stringify(shopIds),
        userId,
        currentPage: pagination.current_page,
        perPage: pagination.per_page,
        sortBy: sort.column,
        sortOrder: sort.order.toUpperCase(),
        ...(filters.status.length > 0 && { statusFilters: JSON.stringify(filters.status) }),
        ...(filters.absenceTypeId.length > 0 && {
          positionFilters: JSON.stringify(filters.absenceTypeId),
        }),
        ...(filters.shops?.length > 0 && { shopFilters: JSON.stringify(filters.shops) }),
      };
    } else {
      params = {
        shop_id: shopIds[0],
        user_id: userId,
        current_page: pagination.current_page,
        per_page: pagination.per_page,
        sort_by: sort.column,
        sort_order: sort.order,
        status_filters: filters.status,
        poste_filters: filters.absenceTypeId,
      };
    }

    commit('setIsFetchingLeaveRequests', true);
    try {
      let response;
      let leaveRequests;

      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.getAllLeaveRequests({ ...params });
        leaveRequests = response.data.map(item => ({ attributes: item }));

        pagination = {
          current_page: params.currentPage,
          per_page: params.perPage,
          total_pages: Math.ceil(response.totalCount / params.perPage),
          total_count: response.totalCount,
        };
      } else {
        response = await httpClient.get('/v3/api/leave_requests', { params });
        pagination = JSON.parse(response.headers['x-pagination']);
        leaveRequests = response.data.data;
      }

      commit('fetchLeaveRequestsSuccess', leaveRequests);
      commit('setPagination', pagination);
      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingLeaveRequests', false);
    }
  },
  async fetchLeaveRequestsTeam({ commit, rootGetters }, { shopIds, pagination, sort, filters }) {
    let params;
    if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
      params = {
        shopIds: JSON.stringify(shopIds),
        currentPage: pagination.current_page,
        perPage: pagination.per_page,
        sortBy: sort.column,
        sortOrder: sort.order.toUpperCase(),
        ...(filters.employeeId.length > 0 && {
          employeeFilters: JSON.stringify(filters.employeeId),
        }),
        ...(filters.status.length > 0 && { statusFilters: JSON.stringify(filters.status) }),
        ...(filters.absenceTypeId.length > 0 && {
          positionFilters: JSON.stringify(filters.absenceTypeId),
        }),
        ...(filters.shops?.length > 0 && { shopFilters: JSON.stringify(filters.shops) }),
      };
    } else {
      params = {
        shop_id: shopIds[0],
        current_page: pagination.current_page,
        per_page: pagination.per_page,
        sort_by: sort.column,
        sort_order: sort.order,
        employee_filters: filters.employeeId,
        status_filters: filters.status,
        poste_filters: filters.absenceTypeId,
        teams_filters: filters.teams,
      };
    }

    commit('setIsFetchingLeaveRequests', true);
    try {
      let response;
      let leaveRequests;
      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.getAllLeaveRequests({ ...params });

        leaveRequests = response.data.map(item => ({ attributes: item }));

        pagination = {
          current_page: params.currentPage,
          per_page: params.perPage,
          total_pages: Math.ceil(response.totalCount / params.perPage),
          total_count: response.totalCount,
        };
      } else {
        response = await httpClient.get('/v3/api/leave_requests', { params });

        leaveRequests = response.data.data;

        pagination = JSON.parse(response.headers['x-pagination']);
      }

      commit('fetchLeaveRequestsSuccess', leaveRequests);
      commit('setPagination', pagination);
      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingLeaveRequests', false);
    }
  },
  async fetchManagers({ commit }, { shopId, employeeId }) {
    try {
      const params = new URLSearchParams({
        shop_id: shopId,
        employee_id: employeeId,
      });

      const response = await httpClient.get(`/v3/api/employees/managers?${params}`);

      commit(
        'fetchManagersSuccess', {
          managers: response.data.managers?.data?.map(manager => manager.attributes) ?? [],
          employeeId,
        },
      );

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchPreSelectedManager({ commit, rootGetters }, { employeeId, shopId, managerIds }) {
    try {
      let managerId = null;

      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P2')) {
        const response = await svcRequestsClient.getPreSelectedManager({
          employeeId,
          shopId,
          managerIds,
        });

        managerId = response.managerId;
      }

      return managerId;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchManagedEmployees({ commit }, params) {
    try {
      commit('setIsFetchingManagedEmployees', true);

      const response = await httpClient.get('/v3/api/leave_requests/managed_users', { params });
      commit('fetchManagedEmployeesSuccess', response.data);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingManagedEmployees', false);
    }
  },
  async fetchShopTeams({ commit }, shopId) {
    try {
      commit('setIsFetchingTeams', true);
      const response = await httpClient.get('/v3/api/teams', { params: { shop_id: shopId } });
      commit('fetchShopTeamsSuccess', response.data);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingTeams', false);
    }
  },
  async createLeaveRequest({ commit, rootGetters }, leaveRequestParams) {
    try {
      let response;
      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.createLeaveRequest(leaveRequestParams);

        // P3 = send mails and notifications from svc-requests
        if (!rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P3')) {
          await httpClient.post('/v3/api/mailing/leave_request_created', {
            leave_request: {
              absence_calculation: leaveRequestParams.calculation,
              start_date: leaveRequestParams.startsAt,
              end_date: leaveRequestParams.endsAt,
              status: 'pending',
              employee_message: leaveRequestParams.employeeMessage,
              user_id: leaveRequestParams.employeeId,
              shop_id: leaveRequestParams.shopId,
              poste_id: leaveRequestParams.positionId,
              manager_id: leaveRequestParams.receiverId,
            },
          });
        }
      } else {
        response = await httpClient.post('/v3/api/leave_requests', leaveRequestParams);
      }

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchPendingRequestsCount({ commit, rootGetters, rootState }, params) {
    commit('setIsFetchingPendingRequests');
    try {
      const response = await httpClient.get('/v3/api/pending_requests', { params });
      commit('fetchPendingAvailabilitiesRequestsCountSuccess', response.data);

      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        const currentUserId = rootState.currentUser.currentUser.id;
        const svcResponse = await svcRequestsClient.getAllLeaveRequests({
          shopIds: JSON.stringify([params.shop_id]),
          receiverId: String(currentUserId),
          statusFilters: JSON.stringify(['pending']),
          skipPagination: true,
        });
        commit('fetchPendingLeaveRequestsCountSuccess', svcResponse.totalCount);
      } else {
        commit('fetchPendingLeaveRequestsCountSuccess', response.data);
      }

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async acceptLeaveRequest(
    { commit, rootGetters },
    { leaveRequestId, leaveRequestParams }) {
    try {
      let response;

      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        const validateUnlockedDays = await httpClient.get('/v3/api/weekly_options/validate_unlocked_days',
          {
            params: {
              shop_id: leaveRequestParams.shopId,
              start_date: leaveRequestParams.startsAt,
              end_date: leaveRequestParams.endsAt,
            },
          });

        if (!validateUnlockedDays.data.areDaysUnlocked) {
          throw new Error('days_locked');
        }
        response = await svcRequestsClient.updateLeaveRequest(leaveRequestId, leaveRequestParams);

        // P3 = send mails and notifications from svc-requests
        if (!rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P3')) {
          await httpClient.post('/v3/api/mailing/leave_request_updated', {
            leave_request: {
              absence_calculation: leaveRequestParams.calculation,
              start_date: leaveRequestParams.startsAt,
              end_date: leaveRequestParams.endsAt,
              status: leaveRequestParams.status,
              manager_message: leaveRequestParams.managerMessage,
              user_id: leaveRequestParams.userId,
              shop_id: leaveRequestParams.shopId,
              poste_id: leaveRequestParams.posteId,
            },
          });
        }
      } else {
        response = await httpClient.patch(`/received_leave_requests/${leaveRequestId}`, leaveRequestParams);
      }

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async refuseLeaveRequest({ commit, rootGetters }, { leaveRequestId, leaveRequestParams }) {
    try {
      let response;
      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.updateLeaveRequest(leaveRequestId, leaveRequestParams);

        // P3 = send mails and notifications from svc-requests
        if (!rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P3')) {
          await httpClient.post('/v3/api/mailing/leave_request_updated', {
            leave_request: {
              absence_calculation: leaveRequestParams.calculation,
              start_date: leaveRequestParams.startsAt,
              end_date: leaveRequestParams.endsAt,
              status: leaveRequestParams.status,
              manager_message: leaveRequestParams.managerMessage,
              user_id: leaveRequestParams.userId,
              shop_id: leaveRequestParams.shopId,
              poste_id: leaveRequestParams.posteId,
            },
          });
        }
      } else {
        response = await httpClient.patch(`/v3/api/leave_requests/${leaveRequestId}`, leaveRequestParams);
      }

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async transferLeaveRequest({ commit, rootGetters }, { leaveRequestId, leaveRequestParams }) {
    try {
      let response;
      if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.updateLeaveRequest(leaveRequestId, leaveRequestParams);
      }
      return response;
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    }
  },
  async fetchAvailabilityRequests({ dispatch },
    {
      isOwnRequestsView,
      shopId,
      userId,
      pagination,
      sort,
      filters,
    },
  ) {
    if (shopId === 'all') throw new Error('Cannot fetch availability requests for all shops');
    if (isOwnRequestsView) {
      dispatch('fetchAvailabilityRequestsOwn', { shopId, userId, pagination, filters });
    } else {
      dispatch('fetchAvailabilityRequestsTeam', { shopId, pagination, sort, filters });
    }
  },
  async fetchAvailabilityRequestsOwn({ commit, dispatch }, { userId, pagination, filters }) {
    // here we don't use the shop_id because we want ALL the availabilities in ALL shops for the user
    const params = {
      user_id: userId,
      status_order: 'asc',
      use_serializer: true,
      include_users: true,
      status_filters: filters?.status ?? [],
      type_filters: filters?.availabilityType ?? [],
    };

    if (pagination) {
      params.current_page = pagination.current_page;
      params.per_page = pagination.per_page;
    } else {
      params.skip_pagination = true;
    }

    try {
      commit('setIsFetchingAvailabilityRequests', true);

      const response = await httpClient
        .get('/v3/api/availabilities', { params });

      commit('fetchAvailabilityRequestsSuccess', response.data.availabilities.data);
      commit('setAvailabilityUsers', response.data.availabilities.included);

      if (pagination) {
        commit('setAvailabilitiesPagination', response.data.meta_options.pagination);
      }

      // fetchAvailabilityRequestsOwn is used from the EmployeeAvailabilitiesModal
      // This modal has its own store so it needs the availabilities we just fetched
      dispatch('employeeAvailabilities/setAvailabilities', response.data.availabilities, { root: true });
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    } finally {
      commit('setIsFetchingAvailabilityRequests', false);
    }
  },
  async fetchAvailabilityRequestsTeam({ commit }, { shopId, pagination, sort, filters }) {
    const params = {
      shop_id: shopId,
      current_page: pagination.current_page,
      per_page: pagination.per_page,
      status_order: 'asc',
      use_serializer: true,
      include_users: true,
      sort_by: sort.column,
      sort_order: sort.order,
      employee_filters: filters.employeeId,
      status_filters: filters.status,
      poste_filters: filters.absenceTypeId,
      team_filters: filters.teams,
      type_filters: filters.availabilityType,
    };

    try {
      commit('setIsFetchingAvailabilityRequests', true);

      const response = await httpClient.get('/v3/api/availabilities', { params });
      commit('fetchAvailabilityRequestsSuccess', response.data.availabilities.data);
      commit('setAvailabilityUsers', response.data.availabilities.included);
      commit('setAvailabilitiesPagination', response.data.meta_options.pagination);

      return response;
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    } finally {
      commit('setIsFetchingAvailabilityRequests', false);
    }
  },
  async updateAvailabilityRequest({ commit }, { availability }) {
    try {
      // Same endpoint used than the availabilities modale in the employees space
      const endpoint = `/v3/api/users/${availability.attributes.userId}/availabilities/bulk_update`;

      const params = {
        shop_id: availability.attributes.shopId,
        availabilities: [{
          id: availability.id,
          recurrence: availability.attributes.recurrence,
          day_of_week: availability.attributes.dayOfWeek,
          ends_at: availability.attributes.endsAt,
          starts_at: availability.attributes.startsAt,
          status: availability.attributes.status,
          user_id: availability.attributes.userId,
          shop_id: availability.attributes.shopId,
          request_status: availability.attributes.requestStatus,
          _destroy: false,
        }],
      };

      const response = await httpClient.patch(endpoint, params);
      const updatedAvailability = response.data.data.find(availabilityResponse => (
        availabilityResponse.id === availability.id
      ));
      commit('replaceAvailability', updatedAvailability);
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    }
  },
  async destroyAvailabilityRequest({ commit }, { availability }) {
    try {
      // Same endpoint used than the availabilities modal in the employees space
      // It can be used to destroy resources as well with _destroy: true
      const endpoint = `/v3/api/users/${availability.attributes.userId}/availabilities/bulk_update`;

      const params = {
        availabilities: [{
          id: availability.id,
          shop_id: availability.attributes.shopId,
          _destroy: true,
        }],
      };

      await httpClient.patch(endpoint, params);
      commit('removeAvailability', availability.id);
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    }
  },
};

const getters = {
  isLeaveRequestsView: (_state, _getters, rootState) => rootState.route.name.includes('leave_requests'),
  isAvailabilityRequestsView: (_state, _getters, rootState) => rootState.route.name.includes('availability_requests'),
  tableFilters: (state, selfGetters) => (
    selfGetters.isLeaveRequestsView ?
      state.tableLeaveRequestFilters :
      state.tableAvailabilityRequestsFilters
  ),
  areRequestsLoading: state => (
    state.isFetchingLeaveRequests ||
    state.isFetchingAvailabilityRequests
  ),
  areFiltersActive: (_, selfGetters) => {
    if (!selfGetters.tableFilters) return false;

    return Object.values(selfGetters.tableFilters).some(filter => filter.length > 0);
  },
  decoratedPendingRequests: state => (state.pendingRequestCounts.total > 99 ? '99+' : `${state.pendingRequestCounts.total}`),
  totalPendingLeaveRequests: state => state.pendingRequestCounts.pendingLeaveRequestsCount,
  totalPendingAvailabilityRequests: state => state.pendingRequestCounts.pendingAvailabilitiesCount,
  decoratedPendingLeaveRequests: (_, selfGetters) => (
    selfGetters.totalPendingLeaveRequests > 99 ? '99+' : `${selfGetters.totalPendingLeaveRequests}`
  ),
  decoratedPendingAvailabilityRequests: (_, selfGetters) => (
    selfGetters.totalPendingAvailabilityRequests > 99 ? '99+' : `${selfGetters.totalPendingAvailabilityRequests}`
  ),
  ownDefaultFiltersLeaveRequests: () => ({
    status: [],
    absenceTypeId: [],
    availabilityType: [],
  }),
  teamDefaultFiltersLeaveRequests: () => ({
    status: [],
    absenceTypeId: [],
    employeeId: [],
    teams: [],
    availabilityType: [],
  }),
  teamAllDefaultFiltersLeaveRequests: () => ({
    status: [],
    absenceTypeId: [],
    employeeId: [],
    availabilityType: [],
    shops: [],
  }),
  ownAllDefaultFiltersLeaveRequests: () => ({
    status: [],
    absenceTypeId: [],
    availabilityType: [],
    shops: [],
  }),
  areFiltersLoading: state => state.areEmployeesLoading || state.areTeamsLoading,
  managedEmployeesByShopId: state => (
    state.managedEmployees.reduce(
      (employeesByShopId, employee) => ({
        ...employeesByShopId,
        [employee.attributes.shopId]: employee,
      }),
      {},
    )
  ),
  currentNodeShopsWithEmployees: (_state, selfGetters, rootState) => (
    rootState.currentOrganisation.currentNodeShops.filter(
      shop => selfGetters.managedEmployeesByShopId[shop.id],
    )
  ),
  absenceIdsForKey:
    state => key => Object.values(state.allShopsAbsences)
      .reduce((absenceIds, shop) => {
        const absenceKey = key.split('.')[1];
        const absencesCountry = key.split('.')[0];
        const shopAbsence = shop.absencesCountry ===
          absencesCountry &&
          shop.absences.find(absence => absence.absenceKey === absenceKey);
        if (shopAbsence) {
          absenceIds.push(shopAbsence.posteId);
        }
        return absenceIds;
      }, []),
  absenceKeysForIds:
    state => ids => Object.values(state.allShopsAbsences)
      .reduce((absenceKeys, shop) => {
        const absences = shop.absences.filter(absence => ids && ids.includes(absence.posteId));
        const absenceKeysWithCountryCode = absences.map(absence => `${shop.absencesCountry}.${absence.absenceKey}`);
        for (const key of absenceKeysWithCountryCode) {
          if (!absenceKeys.includes(key)) {
            absenceKeys.push(key);
          }
        }
        return absenceKeys;
      }, []),
  allUniqueAbsences: state => {
    const absenceKeys = Array.from(
      new Set(
        Object.values(state.allShopsAbsences).map(
          shop => shop.absences.map(absence => `${shop.absencesCountry}.${absence.absenceKey}`),
        ).flat(),
      ),
    );
    return absenceKeys.map(key => ({
      id: key,
      type: 'poste',
      attributes: {
        absenceKey: key.split('.')[1],
        absencesCountry: key.split('.')[0],
      },
    }));
  },
  isUpdatingNavigationContext: state => !state.isLicenseAuthorized ||
      !state.isSelectedShopLoaded ||
      !state.isSelectedClusterNodeLoaded ||
      !state.areClusterNodeShopsLoaded,
  canAccessOwnTab: (_state, _getters, rootState, rootGetters) => {
    if (rootState.currentShop.currentShop.id === 'all') {
      const currentClusterNodeShopIds = rootState.currentOrganisation.currentNodeShops.map(
        ({ id }) => Number(id),
      );

      return rootGetters['currentUser/currentUserInPlanningShopIds'].some(
        shopId => currentClusterNodeShopIds.includes(shopId),
      );
    }

    const currentShopId = Number(rootState.currentShop.currentShop.id);

    return rootGetters['currentUser/currentUserInPlanningShopIds'].includes(currentShopId);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
