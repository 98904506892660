<template>
  <div
    v-if="atLeastOneItemToDisplay && showTooltip"
    ref="shiftTooltip"
    class="day-shift__tooltips__wrapper"
    :style="tooltipsStyle"
  >
    <div class="day-shift__tooltips__header">
      <span
        v-if="isPosteDisplayed"
        class="day-shift__tooltips__poste"
      >
        {{ posteName }}
      </span>
      <span
        v-if="isMealsDisplayed"
        class="day-shift__tooltips__meals"
      >
        <i :class="mealRuleIconClass" />
        <span class="day-shift__tooltips__meals-number"> x{{ meals }} </span>
      </span>
    </div>
    <div
      v-if="isOtherShopNameDisplayed"
      class="day-shift__tooltips__sub-header"
    >
      {{ shopName }}
    </div>
    <div
      v-if="(isHoursDisplayed || isDurationDisplayed)"
      class="day-shift__tooltips__separator"
    />
    <div
      v-if="isHoursDisplayed || isDurationDisplayed"
      class="day-shift__tooltips__time-wrapper"
    >
      <div
        v-if="isHoursDisplayed"
        :class="timeRangeClasses"
      >
        <div v-if="previsionalTime">
          {{ $t('plannings.shift.badging.shift_planned') }}: {{ previsionalTime }}
        </div>
        <div v-if="previsionalType">
          {{ previsionalType }}
        </div>
        <div>
          {{ shiftHours }}
        </div>
      </div>
      <span
        v-if="isDurationDisplayed"
        class="day-shift__tooltips__time-duration"
      >
        {{ shiftDuration }}h
      </span>
    </div>
    <div
      v-if="isHoursDisplayed || isDurationDisplayed || isMealsDisplayed"
    />
    <div class="day-shift__tooltips__activities-wrapper">
      <div
        v-if="isNoteDisplayed"
        class="day-shift__tooltips__activity"
      >
        <ClipboardV2Icon
          width="16"
          height="16"
          :fill="$skColors.skWhite"
        />
        <span>{{ note }}</span>
      </div>
      <div
        v-if="shouldDisplayTasks && !isEmpty(tasks)"
        class="day-shift__tooltips__activity"
      >
        <CheckMarkV2Icon
          width="16"
          height="16"
          :fill="$skColors.skWhite"
        />
        <span>{{ tasksInfo }}</span>
      </div>
      <div
        v-if="shouldDisplayComments(comments)"
        class="day-shift__tooltips__activity"
      >
        <ChatBubbleWithDotsIcon
          width="16"
          height="16"
          :fill="$skColors.skWhite"
        />
        <span>{{ commentsInfo }}</span>
      </div>
      <div
        v-if="shouldDisplayAttachments"
        class="day-shift__tooltips__activity"
      >
        <AttachmentV2Icon
          width="16"
          height="16"
          :fill="$skColors.skWhite"
        />
        <span>{{ attachmentsInfo }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '@skello-utils/array';

export default {
  name: 'Tooltips',
  data() {
    return {
      showTooltip: true,
      shift: null,
      shiftStyle: '',
      globalConfig: null,
      posteName: '',
      shopName: '',
      formattedShiftHours: '',
      shiftDuration: 0,
      shiftDurationInMinutes: 0,
      displayIcons: false,
      meals: 0,
      note: '',
      previsionalTime: null,
      previsionalType: null,
      left: 0,
      top: 0,
      tasks: [],
      comments: [],
      attachmentsCount: 0,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('planningsShifts', ['shouldDisplayTasks', 'shouldDisplayComments']),

    isAttachDocumentsEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_ATTACH_DOCUMENTS');
    },
    timeRangeClasses() {
      return {
        'day-shift__tooltips__time-range': true,
        'day-shift__tooltips__time-range__separator': this.isDurationDisplayed,
      };
    },
    tooltipsStyle() {
      return {
        left: `${this.left}px`,
        top: `${this.top}px`,
      };
    },
    mealRuleIconClass() {
      return ['em', this.globalConfig.currentShop.attributes.mealEmoji];
    },
    shiftHours() {
      let value = '';

      if (this.previsionalTime) {
        value += `${ this.$t('plannings.shift.badging.shift_badged') }: `;
      }

      value += this.formattedShiftHours;

      return value;
    },
    isPosteDisplayed() {
      return this.shiftDurationInMinutes < 60 ||
        this.isMealsDisplayed ||
        this.isOtherShopNameDisplayed;
    },
    isMealsDisplayed() {
      // Only show meals when shift duration is 1h15 or less
      return !!this.meals && this.displayIcons && !this.shopName;
    },
    isDurationDisplayed() {
      // Only show duration when shift duration is 1h or less
      return this.shiftDurationInMinutes < 45;
    },
    isHoursDisplayed() {
      return this.shiftDurationInMinutes <= 45 || this.previsionalTime || this.previsionalType;
    },
    isNoteDisplayed() {
      // Only show note when shift duration is less than 45 minutes.
      return !!this.note && this.shiftDurationInMinutes <= 45;
    },
    shouldDisplayAttachments() {
      return this.isAttachDocumentsEnabled && this.attachmentsCount > 0;
    },
    isOtherShopNameDisplayed() {
      // Only show other shop name when shift duration is less than 1h15
      return !!this.shopName && this.displayIcons;
    },
    atLeastOneItemToDisplay() {
      return this.isPosteDisplayed ||
        this.isOtherShopNameDisplayed ||
        this.isMealsDisplayed ||
        this.isDurationDisplayed ||
        this.isHoursDisplayed ||
        this.isNoteDisplayed;
    },
    tasksInfo() {
      if (!this.shouldDisplayTasks || isEmpty(this.tasks)) return '';

      const checkedTasksNb = this.tasks.reduce((tasksNb, task) => {
        if (task.isChecked) tasksNb += 1;
        return tasksNb;
      }, 0);

      return this.$tc('plannings.table.manage_shift_modal.tabs.shift.activity_icon.tasks', checkedTasksNb, { count: checkedTasksNb, total: this.tasks.length });
    },
    commentsInfo() {
      if (!this.shouldDisplayComments(this.comments)) return '';

      return this.$tc('plannings.table.manage_shift_modal.tabs.shift.activity_icon.comments', this.comments.length);
    },
    attachmentsInfo() {
      if (!this.shouldDisplayAttachments) return '';

      return this.$tc('plannings.table.manage_shift_modal.tabs.shift.activity_icon.attachments', this.attachmentsCount);
    },
  },
  mounted() {
    this.listenOnRoot('show-day-shift-tooltip', e => {
      this.showTooltip = true;

      this.shiftStyle = e.shiftStyle;
      this.globalConfig = e.globalConfig;
      this.posteName = e.posteName;
      this.shopName = e.shopName;
      this.formattedShiftHours = e.formattedShiftHours;
      this.shiftDuration = e.shiftDurationInHours;
      this.shiftDurationInMinutes = e.shiftDurationInMinutes;
      this.meals = e.meals;
      this.note = e.note;
      this.displayIcons = e.displayIcons;
      this.previsionalTime = e.previsionalTime;
      this.previsionalType = e.previsionalType;
      this.tasks = e.tasks;
      this.comments = e.comments;
      this.attachmentsCount = e.attachmentsCount;

      if (!this.atLeastOneItemToDisplay) {
        return;
      }

      // Reverse tooltip placement when it's out of screen
      this.$nextTick(() => {
        let left = e.shiftElRect.right;
        const popovWidth = this.$refs.shiftTooltip.offsetWidth;
        if (left + popovWidth >= window.innerWidth) {
          left = e.shiftElRect.left - popovWidth - 10;
        }
        this.left = left;

        const popovHeight = this.$refs.shiftTooltip.offsetHeight;
        // Center tooltip vertically
        this.top = e.shiftElRect.top - popovHeight / 2 + e.shiftElRect.height / 2;
        if (this.top + popovHeight >= window.innerHeight) {
          this.top = e.shiftElRect.top - popovHeight + e.shiftElRect.height;
        }
      });
    });

    this.listenOnRoot('hide-shift-menu', this.hideTooltip);
    this.listenOnRoot('hide-day-shift-tooltip', this.hideTooltip);
  },
  methods: {
    isEmpty,
    hideTooltip() {
      this.showTooltip = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.day-shift__tooltips__wrapper {
  position: fixed;
  color: white;
  background-color: $sk-black;
  margin-left: 4px;
  max-width: 300px;
  padding: 16px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, .16);
  border-radius: 4px;
  font-weight: 400;
  font-size: $fs-text-xs;
  line-height: 13px;
  overflow: hidden;
  z-index: 1;

  .day-shift__tooltips__header {
    display: flex;
    justify-content: space-between;

    .day-shift__tooltips__poste {
      font-weight: 700;
      font-size: $fs-text-m;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .day-shift__tooltips__meals {
      display: flex;
      align-items: center;
      margin-left: 10px;

      .em {
        width: 14px;
        height: 14px;
      }

      .day-shift__tooltips__meals-number {
        display: flex;
        margin-left: 3px;
      }
    }
  }

  .day-shift__tooltips__sub-header {
    line-height: 17px;
  }

  .day-shift__tooltips__time-wrapper {
    display: flex;
    font-size: $fs-text-s;

    .day-shift__tooltips__time-range {
      display: flex;
      flex-direction: column;
      padding-right: 10px;
      margin-right: 10px;

      &__separator {
        border-right: 1px solid rgba(255, 255, 255, .16);
      }
    }

    .day-shift__tooltips__time-duration {
      display: flex;
    }
  }

  .day-shift__tooltips__separator {
    height: 20px;
  }
}

.day-shift__tooltips__activities-wrapper {
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 11px;
}

.day-shift__tooltips__activity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 10px;
}
</style>
