<template>
  <UsersLayout>
    <UsersForm>
      <template #header>
        <h1 class="users-form__header-title">
          {{ $t('forgotten_password.title') }}
        </h1>
        <h3 class="users-form__header-subtitle">
          {{ $t('forgotten_password.subtitle') }}
        </h3>
        <Banner
          :hidden="!showBanner"
          :message="bannerMessage"
          :type="bannerType"
        />
      </template>

      <div class="row">
        <div class="col-12">
          <Input
            v-model="email"
            :placeholder="$t('email')"
            :errored="!validEmail && displayInputValidations"
            type="text"
          />

          <SubmitBtn
            v-sendFormWEnterKey="{ handler: 'submitResetPassword' }"
            :text="$t('forgotten_password.button')"
            :spinner="loadingInProgress"
            class="forgotten-password__submit-btn"
            @click="submitResetPassword"
          />

          <router-link
            :to="{ name: 'Login' }"
            class="login-link"
          >
            <SkOroraButton
              variant="tertiary"
              size="small"
              class="forgotten-password__to-login-btn"
            >
              {{ $t('login.title') }}
            </SkOroraButton>
          </router-link>
        </div>
      </div>

      <template #footer>
        <p>
          {{ $t('no_account_yet') }}
          <SkOroraLink
            href="https://www.skello.io/contact"
          >
            {{ $t('ask_for_demo') }}
          </SkOroraLink>
        </p>
      </template>
    </UsersForm>
  </UsersLayout>
</template>

<script>
import { unauthenticatedHttpClient } from '@skello-utils/clients';

// Utils
import { isValidEmail } from '@skello-utils/validators';

// Components
import {
  SubmitBtn,
  Banner,
  Input,
} from '@app-js/static/shared/components/ui';
import {
  SkOroraButton,
  SkOroraLink,
} from '@skelloapp/skello-ui';
import UsersLayout from '../shared/components/Layout';
import UsersForm from '../shared/components/Form';

export default {
  name: 'ForgottenPassword',
  components: {
    Banner,
    Input,
    SkOroraButton,
    SkOroraLink,
    SubmitBtn,
    UsersForm,
    UsersLayout,
  },
  data() {
    return {
      email: '',
      formSuccessfullySent: false,
      formError: false,
      displayInputValidations: false,
      loadingInProgress: false,
    };
  },
  computed: {
    validEmail() {
      return isValidEmail(this.email);
    },
    showBanner() {
      return this.formSuccessfullySent || this.formError;
    },
    isSuccess() {
      return this.formSuccessfullySent && !this.formError;
    },
    bannerMessage() {
      if (this.isSuccess) {
        return this.$t('forgotten_password.success_notification');
      }
      return this.$t('warnings.empty_email');
    },
    bannerType() {
      return this.isSuccess ? 'success' : 'error';
    },
  },
  methods: {
    submitResetPassword() {
      this.displayInputValidations = true;
      this.loadingInProgress = true;
      if (!this.validEmail) {
        this.formError = true;
        this.loadingInProgress = false;
        return;
      }

      unauthenticatedHttpClient
        .post('/v3/users/password', {
          email: this.email,
        }).then(() => {
          this.formSuccessfullySent = true;
          this.formError = false;
          this.loadingInProgress = false;
        }).catch(() => {
          this.formSuccessfullySent = false;
          this.formError = true;
          this.loadingInProgress = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-link {
  color: $sk-grey;
  font-size: $fs-text-m;
  line-height: 24px;
  margin-top: 30px;
}

.login__link--gray {
  text-decoration: underline;
  color: $sk-grey;

  &:hover,
  &:focus,
  &:active {
    color: $sk-black;
    border: none;
    text-decoration: underline;
  }
}

.forgotten-password__to-login-btn {
  margin-top: 8px;
}
</style>
