<template>
  <div v-if="isCommercialDashboardDisplayed">
    <CommercialDashboard />
  </div>
  <div
    v-else-if="canAccessAnalyticsDashboardView"
    class="analytics-dashboard"
  >
    <Toolbar class="analytics-dashboard__toolbar">
      <ToolbarDatePicker
        v-if="rangeDate"
        :current-date="rangeDate"
        tracker-prefix="analytics_dashboard"
        :additional-query-params="routeQueryParams"
        :is-year-range-navigation="true"
        :is-one-day-range-authorized="true"
        range
        :max-month-range="analyticsMaximumMonthsRange"
        :is-loading="kpisMetricsLoading"
        @track-max-range="trackMaxRange"
      />
    </Toolbar>
    <div class="analytics-dashboard__container">
      <div
        v-if="isKpisDashboardDisplayed"
        class="analytics-dashboard__cards-container"
      >
        <Card
          v-for="(card, index) in cards"
          :key="index"
          :title="card.title"
          :size="card.size"
          :title-details="card.titleDetails"
          :subtitle="card.subtitle"
          :unit="card.unit"
          :card-name="card.name"
          :metrics="card.metrics"
          :chart-component="card.chartComponent"
          :tooltip-subtitle="card.tooltipSubtitle"
          :show-performance-background="card.showPerformanceBackground"
          :is-data-loading="kpisMetricsLoading"
          :computation-params="card.computationParams"
        />
      </div>
      <div
        v-if="showViewAllBanner"
        class="analytics-dashboard__banner-box"
      >
        <div class="analytics-dashboard__banner__container__inner__content">
          <CircledIIcon
            fill="#2b66fe"
            class="analytics-dashboard__banner__container__inner__content-icon"
          />
          <span>{{ $t('analytics_dashboard.view_all_banner') }}</span>
        </div>
      </div>
      <div v-if="isMetabaseDashboardEnabled && metabaseIframeUrl">
        <span class="analytics-dashboard__delay-banner">
          {{ $t('analytics_dashboard.metabase_sync.banner') }}
          <strong>{{ $t('analytics_dashboard.metabase_sync.every_hour') }}</strong>
        </span>
        <div class="analytics-dashboard__iframe-container">
          <iframe
            :src="metabaseIframeUrl"
            frameborder="0"
            width="100%"
            height="99%"
            scrolling="no"
            allowtransparency
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';
import skDate from '@skello-utils/dates';
import ToolbarDatePicker from '@app-js/shared/components/Toolbar/ToolbarDatePicker';
import Toolbar from '@app-js/shared/components/Toolbar';
import { httpClient } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';
import { SkelloAnalyticsClient } from '@skelloapp/skello-analytics-client';
import {
  skelloApiBaseUrl,
  analyticsMaximumMonthsRange,
} from '@config/env';
import Card from './Card';
import CommercialDashboard from './CommercialDashboard';

export default {
  name: 'AnalyticsDashboard',
  components: {
    Toolbar,
    ToolbarDatePicker,
    CommercialDashboard,
    Card,
  },

  /*
    There is 3 scenarios that has to be taken into account :

    - Coming from url "app.skello.io/v3/shops/1234/analytics" without query params.
      -> It triggers beforeRouteEnter where the query params are added.
      -> It triggers beforeRouteUpdate => the API calls are made.
      -> It triggers mounted, where we don't need to make additional API calls since everything
         has been made in the beforeRouteUpdate.
    Redirection from other parts of the app will follow this pattern.

    - Coming from url "app.skello.io/v3/shops/1234/analytics?start_date:2022-10-01&end_date=2022-10-31"
      -> It triggers beforeRouteEnter where the query params are not touched
      -> It triggers mounted => the API calls are made.
      -> It does NOT trigger beforeRouteUpdate.

    - Coming from a change in the url (update of the dates or shops).
      -> It triggers beforeRouteUpdate => the API calls are made
      -> It does not trigger the beforeRouteEnter or mounted callbacks
  */
  async beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (!vm.isCommercialDashboardDisplayed) {
        await vm.checkUserAccessOnViewAll();
      }

      const query = vm.getQueryFromRouterUrl(to);

      // Force the selection of the Organisation
      // In the case we are in a sub-network cluster node from another space
      if (vm.isViewAll) {
        // If we land on this page from a viewAll page on another page, you are redirected if you don't have
        // all the rights
        if (vm.userHasAccessToViewAll) {
          vm.selectOrganisation(vm.currentOrganisation);
        } else {
          vm.redirectNotAuthorized(vm.firstShopPath(query.start_date, query.end_date), false);
        }
      }

      vm.$router.replace({ query });
    });
  },
  beforeRouteUpdate(to, from, next) {
    const query = this.getQueryFromRouterUrl(to);

    // We need to call setLoadingKpisMetrics() early because
    // if updateAndSelectShop() takes time, there is a window where
    // it is not in a loading state and it would break the interface visuals
    if (this.isKpisDashboardDisplayed) this.setLoadingKpisMetrics();

    if (String(to.params.shop_id) !== this.currentShop.id) {
      this.updateAndSelectShop(to.params.shop_id)
        .then(() => {
          if (!this.isCommercialDashboardDisplayed) {
            this.loadNecessaryData(query.start_date, query.end_date, true);
          }
          next();
        });
    } else {
      this.loadNecessaryData(query.start_date, query.end_date, false);
      next();
    }
  },
  data() {
    return {
      analyticsClient: null,
      loadingMetabaseDashboard: false,
      metabaseIframeUrl: null,
      userClickTimeout: null,
      userHasAccessToViewAll: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('navContext', ['navContext']),
    ...mapState('analyticsDashboard', ['kpisMetricsLoading']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('currentShop', ['isDevFlagEnabled', 'checkFeatureFlag']),
    ...mapGetters('analyticsDashboard', ['getMetric']),

    isCommercialDashboardDisplayed() {
      return this.checkFeatureFlag('FEATURE_COMMERCIAL_DASHBOARD_TAB_ENABLED') &&
        !this.currentOrganisation.attributes.analyticsModule &&
        this.currentOrganisation.attributes.packOffer.name === 'premium' &&
        this.isSystemAdmin;
    },
    canAccessAnalyticsDashboardView() {
      const dateParam = this.$route.query.start_date || skDate().format('YYYY-MM-DD');
      const planningPath = `/v3/shops/${this.currentShop.id}/plannings/weeks/employees?date=${dateParam}`;

      if (!this.currentOrganisation.attributes.analyticsModule) {
        if (this.isSystemAdmin) {
          window.location = '/';
          return false;
        }

        this.redirectNotAuthorized(planningPath, false);
        return false;
      }

      if (this.currentLicense.attributes.canEditManagementIndicators === false) {
        this.redirectNotAuthorized(planningPath, true);
        return false;
      }

      return true;
    },
    routeQueryParams() {
      return { ...this.$route.query };
    },
    rangeDate() {
      return [this.$route.query.start_date, this.$route.query.end_date];
    },
    showViewAllBanner() {
      return this.isMetabaseDashboardEnabled &&
        this.isKpisDashboardEnabled &&
        this.isViewAll &&
        this.metabaseIframeUrl;
    },
    isKpisDashboardDisplayed() {
      return this.isKpisDashboardEnabled && !this.isViewAll && !this.isCommercialDashboardDisplayed;
    },
    isKpisDashboardEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_KPI_DASHBOARD_ENABLED');
    },
    isMetabaseDashboardEnabled() {
      if (this.isCommercialDashboardDisplayed) return false;
      if (this.isViewAll) return true;

      return this.checkFeatureFlag('FEATURE_METABASE_DASHBOARD_ENABLED');
    },
    analyticsMaximumMonthsRange() {
      return parseInt(analyticsMaximumMonthsRange || '1', 10);
    },
    isViewAll() {
      return this.currentShop.id === 'all';
    },
    currencySymbol() {
      return this.currentShop.attributes.currencySymbol;
    },
    cards() {
      return [
        {
          name: 'revenues',
          title: this.splitTitleOnVs(
            this.$t('analytics_dashboard.cards.titles.revenues'),
          ),
          subtitle: this.getNumberChartSubtitle(),
          titleDetails: this.$t('analytics_dashboard.cards.titles.units.wo_taxes'),
          size: 'small',
          chartComponent: 'NumberComparison',
          showPerformanceBackground: true,
          unit: this.currencySymbol,
          metrics: [
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.predicted'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.predicted_tooltip'),
              color: '#727272',
              data: this.getDataForMetric('predictedRevenue'),
            },
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.real'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.real_tooltip'),
              color: '#727272',
              data: this.getDataForMetric('realRevenue'),
            },
          ],
        },
        {
          name: 'revenue_ratio',
          title: this.splitTitleOnVs(
            this.$t('analytics_dashboard.cards.titles.ratio'),
          ),
          size: 'medium',
          chartComponent: 'MixedLineAreaChart',
          unit: '%',
          computationParams: {
            metricsType: 'ratio', // Used when aggregate per week / month
            objective: 'decrease', // If the goal is to have the more possible or the less possible
          },
          metrics: [
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.predicted'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.predicted_tooltip'),
              chartType: 'line',
              color: '#D24A2F',
              data: this.getDataForMetric('predictedTotalSalaryMassWithCost'),
              ratioData: this.getDataForMetric('predictedRevenue'),
              premiumMetric: true,
            },
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.real'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.real_tooltip'),
              chartType: 'area',
              color: '#FB8C76',
              data: this.getDataForMetric('realTotalSalaryMassWithCost'),
              ratioData: this.getDataForMetric('realRevenue'),
            },
          ],
        },
        {
          name: 'details_predicted_turnover_vs_real',
          title: this.splitTitleOnVs(
            this.$t('analytics_dashboard.cards.titles.details_predicted_turnover_vs_real'),
          ),
          size: 'small',
          chartComponent: 'BasicColumnChart',
          unit: this.currencySymbol,
          metrics: [
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.predicted'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.predicted_tooltip'),
              color: '#FFE19D',
              data: this.getDataForMetric('predictedRevenue'),
            },
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.real'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.real_tooltip'),
              color: '#FFBF2F',
              data: this.getDataForMetric('realRevenue'),
            },
          ],
        },
        {
          name: 'real_salary_mass_with_costs',
          title: this.splitTitleOnVs(
            this.$t('analytics_dashboard.cards.titles.real_salary_mass_with_costs'),
          ),
          size: 'small',
          chartComponent: 'StackedColumnChart',
          unit: this.currencySymbol,
          tooltipSubtitle: this.$t('analytics_dashboard.cards.titles.total_charged_ms'),
          metrics: [
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.productive'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.productive_tooltip'),
              color: '#3983FF',
              data: this.getDataForMetric('realProductiveWithCostSalaryMass'),
            },
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.unproductive'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.unproductive_tooltip'),
              color: '#9ED1FF',
              data: this.getDataForMetric('realUnproductiveWithCostSalaryMass'),
            },
          ],
        },
        {
          name: 'details_salary_mass_with_costs',
          title: this.splitTitleOnVs(
            this.$t('analytics_dashboard.cards.titles.details_salary_mass_with_costs'),
          ),
          size: 'small',
          chartComponent: 'BasicColumnChart',
          unit: this.currencySymbol,
          metrics: [
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.predicted'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.predicted_tooltip'),
              color: '#A7A4FF',
              data: this.getDataForMetric('predictedTotalSalaryMassWithCost'),
              premiumMetric: true,
            },
            {
              legendLabel: this.$t('analytics_dashboard.cards.legends.real'),
              tooltipLabel: this.$t('analytics_dashboard.cards.legends.real_tooltip'),
              color: '#4E49C1',
              data: this.getDataForMetric('realTotalSalaryMassWithCost'),
            },
          ],
        },
      ];
    },
  },
  mounted() {
    if (this.isCommercialDashboardDisplayed) return;

    this.analyticsClient = new SkelloAnalyticsClient(skelloApiBaseUrl);

    if (this.isKpisDashboardDisplayed) this.setLoadingKpisMetrics();

    // To avoid a double call to loadNecessaryData, we only make the call there if we already have the query params
    if (this.$route.query.start_date && this.$route.query.end_date) {
      const { startDate, endDate } = this.getRangeFromRouterUrl(this.$route);
      this.loadNecessaryData(startDate, endDate, true);
    }
  },
  destroyed() {
    this.resetBrowserTabTitle();
  },
  methods: {
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('analyticsDashboard', [
      'setLoadingKpisMetrics',
      'setKpisMetrics',
      'setLoadingKpisMetricsCompleted',
    ]),
    ...mapActions('navContext', [
      'selectShop',
      'selectOrganisation',
    ]),
    trackMaxRange() {
      this.$skAnalytics.track('analytics_dashboard_max_period_datepicker');
    },
    getQueryFromRouterUrl(to) {
      const { startDate, endDate } = this.getRangeFromRouterUrl(to);

      const queryParams = {
        ...to.query,
        start_date: startDate,
        end_date: endDate,
      };

      // We set a special parameter for the Navbar/ShopsDropDown.vue component
      if (!this.userHasAccessToViewAll) queryParams.mode = 'shop';

      return queryParams;
    },
    getRangeFromRouterUrl(to) {
      /* We want the last complete month, which is the current month only if we are at the last day of the month,
       * otherwise it's the previous month. */
      const now = skDate();
      const displayedMonthByDefault = now.date() === now.daysInMonth() ? now : now.subtract(1, 'M');
      const defaultStartDate = displayedMonthByDefault.startOf('month').format('YYYY-MM-DD');
      const defaultEndDate = displayedMonthByDefault.endOf('month').format('YYYY-MM-DD');

      const startDate = to.query.start_date !== undefined && skDate(to.query.start_date).isValid() ?
        to.query.start_date :
        defaultStartDate;

      const endDate = to.query.end_date !== undefined && skDate(to.query.end_date).isValid() ?
        to.query.end_date :
        defaultEndDate;

      return { startDate, endDate };
    },
    loadNecessaryData(startDate, endDate, loadNow) {
      if (this.userClickTimeout) clearTimeout(this.userClickTimeout);

      const loadData = () => {
        this.loadKpisMetrics(startDate, endDate);
        this.fetchMetabaseDashboardUrl(startDate, endDate);
      };

      // To protect us from spam click on the date arrow buttons, we add a timeout that wait 0.75 seconds before sending
      // the requests.
      if (loadNow) {
        loadData();
      } else {
        this.userClickTimeout = setTimeout(loadData, 750);
      }
    },
    loadKpisMetrics(startDate, endDate) {
      if (this.isViewAll) {
        // Needed to stop the datepicker from loading waiting for
        // kpi dashboard to finish loading since he's not displayed in view all.
        this.setLoadingKpisMetricsCompleted();
        return;
      }

      this.setLoadingKpisMetrics();

      this.analyticsClient.getAllMetrics({
        accept: 'application/json',
        authorization: authClient.authToken.token,
        clientSource: 'skelloApp:web',
      }, {
        shopId: this.currentShop.id,
        rangeStart: startDate,
        rangeEnd: endDate,
      }).then(response => {
        this.setKpisMetrics({ kpisMetrics: response });
      }).catch(() => {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      }).finally(() => {
        this.setLoadingKpisMetricsCompleted();
      });
    },
    getDataForMetric(metricName) {
      return this.getMetric({
        metricName,
        shopId: this.currentShop.id,
      });
    },
    // Used to know wether or not the user has access to "view all"
    checkUserAccessOnViewAll() {
      return httpClient
        .get('/v3/api/dashboards/view_all_access')
        .then(() => {
          this.userHasAccessToViewAll = true;
        })
        .catch(() => {
          this.userHasAccessToViewAll = false;
        });
    },
    fetchMetabaseDashboardUrl(startDate, endDate) {
      this.metabaseIframeUrl = null;

      if (!this.isMetabaseDashboardEnabled) return;

      this.loadingMetabaseDashboard = true;

      const params = {
        shop_id: this.navContext.shopId,
        resource_type: this.navContext.shopId ? 'shop' : 'organisation',
        start_date: startDate,
        end_date: endDate,
      };

      httpClient
        .get('/v3/api/dashboards', { params })
        .then(response => {
          this.metabaseIframeUrl = response.data.iframe_url;
        })
        .catch(error => {
          // Error status when :
          // View shop : user does not have can_read_all_employees and can_edit_management_indicators
          // View all : user does not have the previous license on EVERY SHOPS
          if (error.response.status === 401) {
            // If we are in view all => error message and redirect
            // If we are in view shop => no error message and do not show metabase
            if (this.isViewAll) {
              this.redirectNotAuthorized(this.firstShopPath(startDate, endDate), true);
            }
          } else {
            this.$skToast({
              message: this.$t('analytics_dashboard.errors.fetch_kpis'),
              variant: 'error',
            });
          }
        })
        .finally(() => {
          this.loadingMetabaseDashboard = false;
        });
    },
    redirectNotAuthorized(path, showMessage) {
      if (showMessage) {
        this.$skToast({
          message: this.$t('analytics_dashboard.errors.forbidden_area'),
          variant: 'error',
        });
      }

      setTimeout(() => {
        this.$router.replace(path);
      }, showMessage ? 1000 : 10);
    },
    firstShopPath(startDate, endDate) {
      return `/v3/shops/${this.currentOrganisation.attributes.firstShopId}/analytics?mode=shop&start_date=${startDate}&end_date=${endDate}`;
    },
    setBrowserTabTitle() {
      this.$nextTick(() => {
        document.title = this.isViewAll ?
          this.$t('analytics_dashboard.tab_title') :
          this.$t('analytics_dashboard.tab_title_with_shop_name', {
            shopName: this.currentShop.attributes.name,
          });
      });
    },
    resetBrowserTabTitle() {
      document.title = this.$t('tab_titles.default');
    },
    updateAndSelectShop(shopId) {
      return this.updateCurrentShop({ shopId })
        .then(() => {
          if (shopId === 'all') {
            this.selectOrganisation(this.currentOrganisation);
          } else {
            this.selectShop(this.currentShop);
          }
          this.setBrowserTabTitle();
        });
    },
    getNumberChartSubtitle() {
      const startDate = skDate(this.$route.query.start_date).format('DD MMM YYYY');
      const endDate = skDate(this.$route.query.end_date).format('DD MMM YYYY');

      return `${startDate} - ${endDate}`;
    },
    splitTitleOnVs(value) {
      return value.split(' vs ');
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-dashboard {
  overflow-y: auto;
  height: 100%;
}

.analytics-dashboard__toolbar {
  position: sticky;
}

.analytics-dashboard__container {
  padding: 0 90px;

  @media (max-width: 1139px) {
    padding: 0 45px;
  }
}

.analytics-dashboard__cards-container {
  padding-bottom: 30px;
  padding-top: 1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
}

.analytics-dashboard__iframe-container {
  display: flex;
  justify-content: center;
  height: 3300px;
  margin-top: 20px;

  @media (min-width: 844px) {
    height: 1900px;
  }

  @media (min-width: 1530px) {
    height: 2000px;
  }

  @media (min-width: 1600px) {
    height: 2250px;
  }

  @media (min-width: 1800px) {
    height: 2400px;
  }
}

.analytics-dashboard__delay-banner {
  flex: 1;
  font-size: $fs-text-s;
  color: $sk-grey-50;
  width: 100%;
  margin-left: 6px;
}

.analytics-dashboard__banner-box {
  width: initial;
  height: 44px;
  margin: 25px 6px 30px;
  border: 1px solid $sk-grey-10;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: $sk-grey-50;
  border-radius: 4px;
}

.analytics-dashboard__banner__container__inner__content {
  display: flex;
  align-items: center;
}

.analytics-dashboard__banner__container__inner__content-icon {
  margin-right: 16px;
}
</style>
