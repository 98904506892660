import { isEmpty } from '@skello-utils/array';
import skDate from '@skello-utils/dates';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Indicates the translation coordinates that needs to be applied on the most upper-left cell
 * amongst the selected cells, to reach the destination coordinates.
 *
 * @param {*} copiedCells All the copied cells on the planning to compute on most upper-left cell.
 * @param {*} destinationCell The destination cell on the planning
 *
 * @returns translation coordinates at the format {translationX: 4, translationY: 4}
 */
const computeTranslationToApply = (copiedCells, destinationCell) => {
  const xValues = [];
  const yValues = [];

  Object.keys(copiedCells)
    .forEach(copiedCellKey => {
      const { x, y } = JSON.parse(copiedCellKey);

      xValues.push(x); // final result [1, 1] in the example
      yValues.push(y); // final result [2, 4] in the example
    });

  // since we're either on a line or a column but not a mix of both
  const firstCellToCopy = {
    x: Math.min(...xValues), // 1
    y: Math.min(...yValues), // 2
  };

  const { x: destinationX, y: destinationY } = JSON.parse(destinationCell);

  return {
    translationX: destinationX - firstCellToCopy.x,
    translationY: destinationY - firstCellToCopy.y,
  };
};

/**
* This function is used to apply the bulk update of shifts when pasting copied cells.
*
* @param {number} userId - The ID of the user.
* @param {string} startsAt - The start date and time of the shift.
* @param {string} endsAt - The end date and time of the shift.
* @param {Object} shift - The shift to update.
*
* @returns {Object} The built shift.
*/
const updateShiftUserAndDate = (userId, startsAt, endsAt, shift) => {
  shift.attributes.userId = userId;
  shift.attributes.startsAt = startsAt;
  shift.attributes.endsAt = endsAt;
  return shift;
};

/**
 * Processes a shift by calculating the new start and end times based on the destination day,
 * and updates the shift with the target user's information.
 *
 * @param {Object} shift - The shift to be processed.
 * @param {Object} destinationDate - The day to which the shift is being pasted.
 * @param {Object|null} targetUser - The user to whom the shift is being assigned. Null if unassigned.
 *
 * @returns {Object} The built shift with new id and new date.
 */
const buildShiftToPaste = (shift, destinationDate, targetUser) => {
  const oldStartsAt = skDate.utc(shift.attributes.startsAt);
  const oldEndsAt = skDate.utc(shift.attributes.endsAt);
  // check if the shift start and end are on the same day
  const isSameDay = oldEndsAt.isSame(oldStartsAt, 'day');
  let newShift = cloneDeep(shift);

  // Clean id to avoid pk conflict when creating new shift
  newShift.id = null;
  const newShiftStartsAt = skDate.utc(destinationDate);
  newShiftStartsAt.set({
    h: oldStartsAt.hour(),
    m: oldStartsAt.minute(),
  });
  const newShiftEndsAt = skDate.utc(destinationDate);
  newShiftEndsAt.set({
    h: oldEndsAt.hour(),
    m: oldEndsAt.minute(),
  });

  // To handle the case where the shift ends at the next day (ex: 23:00 - 01:00)
  if (!isSameDay) {
    newShiftEndsAt.add(1, 'days');
  }
  newShift = updateShiftUserAndDate(
    targetUser ? targetUser.id : null,
    newShiftStartsAt,
    newShiftEndsAt,
    newShift,
  );
  return newShift;
};

/**
 * Key representing a selected cell on the planning. It contains information about the selected cell.
 *
 * @param {*} rowItemId Id of the row item containing the cell. Must be present in the userList.
 * @param {*} date date of the cell. format: "2024-10-28"
 * @param {*} x position of the column in the planning
 * @param {*} y position of the row in the planning
 *
 * @returns string with JSON format { rowItemId, date, x, y }
 */
export const computeSelectedCellKey = (rowItemId, date, x, y) => (
  JSON.stringify({ rowItemId, date, x, y })
);

/**
 * Builds an array of shifts to be pasted based on the copied cells and the destination coordinates.
 *
 * @param {Object} params - The parameters for building the shifts to paste.
 * @param {string} params.destinationCell - The selected cell key where the shifts will be pasted. format: { rowItemId, date, x, y }
 * @param {Object} params.copiedCells - The cells that have been copied.
 * @param {Array} params.displayedInPlanningUsers - The list of users displayed in the planning.
 * @param {Array} params.visibleDays - The days visible in the planning.
 * @param {boolean} params.isUnassignedRowDisplayed - Whether the unassigned line is enabled in the shop.
 *
 * @returns {Array} The array of shifts to be pasted.
 */
export const buildShiftsToPaste = ({
  destinationCell,
  copiedCells,
  visibleDays,
  displayedInPlanningUsers,
  isUnassignedRowDisplayed,
}) => {
  const translations = computeTranslationToApply(copiedCells, destinationCell);
  const shiftsToPaste = [];
  const users = [...displayedInPlanningUsers];
  if (isUnassignedRowDisplayed) {
    users.unshift({ id: null, name: 'Unassigned' });
  }

  Object.entries(copiedCells).forEach(([copiedCellKey, cellShifts]) => {
    if (isEmpty(cellShifts)) return;
    const { x: copiedCellX, y: copiedCellY } = JSON.parse(copiedCellKey);
    const newX = copiedCellX + translations.translationX;
    const newY = copiedCellY + translations.translationY;
    if (newX >= 0 && newX < visibleDays.length &&
        newY >= 0 && newY < users.length && !visibleDays[newX]?.isLocked) {
      cellShifts.forEach(shift => {
        shiftsToPaste.push(buildShiftToPaste(
          shift,
          skDate.utc(visibleDays[newX].date),
          users[newY],
        ));
      });
    }
  });
  return shiftsToPaste;
};

/**
 * Builds an array of shifts to be pasted when multiple cells are selected.
 *
 * @param {Object} params - The parameters for building the shifts to paste.
 * @param {Object} params.destinationCells - The currently selected cells where shifts will be pasted.
 * @param {Array} params.displayedInPlanningUsers - The list of users displayed in the planning.
 * @param {Array} params.visibleDays - The days visible in the planning.
 * @param {Object} params.copiedCells - The cells that have been copied.
 * @param {boolean} params.isUnassignedRowDisplayed - Whether the unassigned line is enabled in the shop.
 *
 * @returns {Array} The array of shifts to be pasted.
 */
export const buildMultiCellShiftsToPaste = ({
  destinationCells,
  copiedCells,
  visibleDays,
  displayedInPlanningUsers,
  isUnassignedRowDisplayed,
}) => {
  const shiftsToPaste = [];
  const users = [...displayedInPlanningUsers];
  if (isUnassignedRowDisplayed) {
    users.unshift({ id: null, name: 'Unassigned' });
  }

  const copiedEntries = Object.entries(copiedCells);
  // If there is only one copied cell
  if (copiedEntries.length === 1) {
    const [_copiedCellKey, cellShifts] = copiedEntries[0];
    if (isEmpty(cellShifts)) return shiftsToPaste;

    destinationCells.forEach(destinationCellKey => {
      const { x: destinationX, y: destinationY } = JSON.parse(destinationCellKey);

      if (destinationX >= 0 && destinationX < visibleDays.length &&
        destinationY >= 0 && destinationY < users.length &&
          !visibleDays[destinationX]?.isLocked) {
        const destinationUser = users[destinationY];
        const destinationDate = visibleDays[destinationX].date;

        cellShifts.forEach(shift => {
          shiftsToPaste.push(buildShiftToPaste(
            shift,
            skDate.utc(destinationDate),
            destinationUser,
          ));
        });
      }
    });
    return shiftsToPaste;
  }

  // Handle the case where multiple cells are selected
  const copiedCoordinates =
    Object.keys(copiedCells).map(copiedCellKey => JSON.parse(copiedCellKey).x);

  const minCopiedX = Math.min(copiedCoordinates);

  Object.keys(destinationCells).forEach(desinationCellKey => {
    const { x: destinationX, y: destinationY } = JSON.parse(desinationCellKey);

    Object.entries(copiedCells).forEach(([copiedCellKey, cellShifts]) => {
      if (isEmpty(cellShifts)) return;

      const { x: copiedX } = JSON.parse(copiedCellKey);
      const xOffset = copiedX - minCopiedX;
      const destinationXWithOffset = destinationX + xOffset;

      if (
        destinationXWithOffset >= 0 &&
        destinationXWithOffset < visibleDays.length &&
        !visibleDays[destinationXWithOffset]?.isLocked
      ) {
        const destinationUser = users[destinationY];
        const destinationDate = visibleDays[destinationXWithOffset].date;

        cellShifts.forEach(shift => {
          shiftsToPaste.push(buildShiftToPaste(
            shift,
            skDate.utc(destinationDate),
            destinationUser,
          ));
        });
      }
    });
  });

  return shiftsToPaste;
};
