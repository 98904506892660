import Vue from 'vue';
import Router from 'vue-router';
import store from './shared/store/index';
import { badgingsRoutes } from './badgings';
import { analyticsDashboardsRoutes } from './analytics_dashboard';
import { homeDashboardRoutes } from './home_dashboard';
import { usersRoutes } from './users';
import { reportsRoutes } from './reports';
import { organisationSettingsRoutes } from './organisation_settings';
import { planningsRoutes } from './plannings';
import { shopSettingsRoutes } from './shop_settings';
import { textDocumentTemplatesRoutes } from './text_document_templates';
import { employeesRoutes } from './employees';
import { adminOnboardingRoutes } from './admin_onboarding';
import { timeclockOnboardingRoutes } from './timeclock_onboarding';
import { requestsRoutes } from './requests';
import { profileRoutes } from './profile/profile_routes';
import { staticRoutes } from './static';

import ErrorPage from './shared/components/ErrorPage';
import App from './App';
import Static from './static/Static';

Vue.use(Router);

const appRoutes = [
  ...employeesRoutes,
  ...badgingsRoutes,
  ...analyticsDashboardsRoutes,
  ...homeDashboardRoutes,
  ...usersRoutes,
  ...planningsRoutes,
  ...reportsRoutes,
  ...organisationSettingsRoutes,
  ...shopSettingsRoutes,
  ...textDocumentTemplatesRoutes,
  ...adminOnboardingRoutes,
  ...timeclockOnboardingRoutes,
  ...requestsRoutes,
  ...profileRoutes,
];

const routes = [
  {
    path: '/v3',
    redirect: '/v3/shops/all/employees',
    name: 'App',
    component: App,
    children: appRoutes,
  },
  {
    path: '/users',
    component: Static,
    children: staticRoutes,
  },
  {
    path: '*',
    name: 'error-page',
    component: ErrorPage,
  },
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, _from, next) => {
  if (performance) {
    performance.clearMarks();
    performance.clearMeasures();
    performance.mark(to.name);
  }

  next();
});

router.afterEach((to, from) => {
  // allows us to expose new route as a reactive state in store
  store.commit('newRoute', { to, from });

  // resets selected cells in plannings state when navigating to a new route
  store.commit('planningsBulkEdit/resetSelectedCells');
});

export default router;
