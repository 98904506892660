import Vue from 'vue';
import { employeeFullName } from '@skello-utils/formatting/strings';
import { svcKpisClient } from '@skello-utils/clients/svc_kpis_client';
import skDate from '@skello-utils/dates';
import { concatenateObjects } from './kpis_helpers';

const MISSING_DATA_USERS_FULLY_DISPLAYED_COUNT = 6; // product decision

const initialState = {
  kpisLoading: true,
  loadingCells: [], // String[], each element is a KpiCell identifier (check computed)
  numberOfEmployees: null,
  kpis: {},
  userKpisSettingsLoading: true,
  userKpisSettingLoading: {},
  userKpisSettings: {},
  error: false,
  blockKpiUpdate: false,
  isSalaryMassCollapseOpen: false,
};

const displayedUsersMissingData = dataMissing => {
  // dataMissing can be of type HourlyWage or HourlyWageWithCosts
  const usersCount = dataMissing.length;

  const userNames = dataMissing
    .slice(0, MISSING_DATA_USERS_FULLY_DISPLAYED_COUNT)
    .map(user => {
      const fullName = employeeFullName(
        user.attributes.firstName,
        user.attributes.lastName,
      );
      // too long name case
      const charactersNbLimit = 30;
      if (fullName.length > charactersNbLimit) {
        return fullName.substr(0, charactersNbLimit).concat('...');
      }

      return fullName;
    });

  if (usersCount > MISSING_DATA_USERS_FULLY_DISPLAYED_COUNT) {
    // We display N first names and we count the rest as more users left to handle like "+ 20 more"
    const moreUsersCount = usersCount - MISSING_DATA_USERS_FULLY_DISPLAYED_COUNT;

    if (moreUsersCount > 0) {
      userNames.push(`... + ${moreUsersCount}`);
    }
  }

  return userNames;
};

const mutations = {
  holdKpis(state) {
    state.blockKpiUpdate = true;
  },
  resumeKpis(state) {
    state.blockKpiUpdate = false;
  },
  // user kpis settings
  userKpisSettingsPending(state) {
    state.userKpisSettingsLoading = true;
  },
  userKpisSettingsComplete(state) {
    state.userKpisSettingsLoading = false;
  },
  setUserKpisSettings(state, payload) {
    state.userKpisSettings = payload;
  },
  setKpisLoading(state, value) {
    state.kpisLoading = value;
  },
  setLoadingCells(state, payload) {
    state.loadingCells = payload;
  },
  // userKpisSettingLoading
  userKpisSettingPending(state, payload) {
    Vue.set(state.userKpisSettingLoading, payload.attribute, true);
  },
  userKpisSettingComplete(state, payload) {
    Vue.set(state.userKpisSettingLoading, payload.attribute, false);
  },

  // kpis
  kpisPending(state) {
    state.kpisLoading = true;
  },
  kpisComplete(state) {
    state.kpisLoading = false;
  },
  setKpis(state, payload) {
    state.kpis = payload.kpis;
  },
  setNumberOfEmployees(state, payload) {
    state.numberOfEmployees = payload.numberOfEmployees;
  },

  // isSalaryMassCollapseOpen
  toggleSalaryMassCollapse(state) {
    state.isSalaryMassCollapseOpen = !state.isSalaryMassCollapseOpen;
  },

  // common & other
  setError(state) {
    state.error = true;
  },
};

const actions = {
  fetchUserKpisSettings({ commit }, { userId }) {
    commit('userKpisSettingsPending');

    return svcKpisClient.getUserKpiSettings(userId)
      .then(response => {
        commit('setUserKpisSettings', response.data);
        return response.data;
      })
      .catch(error => {
        commit('setError');
        throw error;
      })
      .finally(() => {
        commit('userKpisSettingsComplete');
      });
  },
  updateUserKpisSettings({ commit }, params) {
    commit('userKpisSettingPending', params);
    const userKpisSettingsParams = {};
    userKpisSettingsParams[params.attribute] = params.newValue;

    if (params.attribute === 'total_salary_mass_with_costs' && !params.newValue) {
      userKpisSettingsParams.salary_mass_productive_with_costs = false;
      userKpisSettingsParams.salary_mass_unproductive_with_costs = false;
    }

    return svcKpisClient.patchUserKpiSettings(params.userId, userKpisSettingsParams)
      .then(response => commit('setUserKpisSettings', response.data))
      .catch(() => this.emitOnRoot('kpi-request-failure'))
      .finally(() => commit('userKpisSettingComplete', params));
  },
  fetchKpis({ commit, state, rootGetters }, params) {
    if (rootGetters['planningsState/isDailyView']) return null;
    if (state.blockKpiUpdate) return null;

    commit('kpisPending');

    const kpiRequests = [];
    const requestParams = {
      shop_id: params.shopId,
      worked_hours_only: params.workedHoursOnly,
      postes: params.filters.postes ? params.filters.postes.join(',') : undefined,
      teams: params.filters.teams ? params.filters.teams.join(',') : undefined,
      employees: params.filters.users ? params.filters.users.join(',') : undefined,
      source: params.source,
      absences_country: params.absencesCountry || 'FR',
    };

    const requestDate = skDate(params.date);
    const weeksToFetch = params.weeksToFetch || 1;
    for (let i = 0; i < weeksToFetch; i++) {
      kpiRequests.push(svcKpisClient.getKpis(requestParams, requestDate.format('YYYY-MM-DD')));
      requestDate.add(1, 'week');
    }

    return Promise.all(kpiRequests)
      .then(responses => {
        const kpis = concatenateObjects(responses.map(response => response.data));
        commit('setKpis', kpis);
        commit('setNumberOfEmployees', kpis);
      })
      .catch(error => {
        commit('setError');
        throw error;
      })
      .finally(() => {
        commit('kpisComplete');
      });
  },
};

const gettersList = {
  usersMissingHourlyWage: (_state, getters, _rootState, rootGetters) => {
    const users = rootGetters['planningsUsers/filteredUsers'];

    // We filter before sorting so we sort fewer elements
    return getters.orderedUsersByFullName(users
      .filter(user => !user.relationships.contract?.attributes?.hourlyWage),
    );
  },
  usersMissingHourlyWageWithCosts: (_state, getters, _rootState, rootGetters) => {
    const users = rootGetters['planningsUsers/filteredUsers'];

    // We filter before sorting so we sort fewer elements
    return getters.orderedUsersByFullName(users
      .filter(user => !user.relationships.contract?.attributes?.hourlyWageWithCosts),
    );
  },
  orderedUsersByFullName: _state => users => users.sort((userA, userB) => {
    // We put the lastName first so we order by lastname first,
    // and only for equivalent lastname, we check firstname.
    const userAFullName = employeeFullName(userA.attributes.lastName, userA.attributes.firstName);
    const userBFullName = employeeFullName(userB.attributes.lastName, userB.attributes.firstName);
    if (userAFullName < userBFullName) return -1;
    if (userAFullName > userBFullName) return 1;
    return 0;
  }),
  displayedUsersMissingHourlyWage: (state, getters) => displayedUsersMissingData(
    getters.usersMissingHourlyWage,
  ),
  displayedUsersMissingHourlyWageWithCosts: (state, getters) => displayedUsersMissingData(
    getters.usersMissingHourlyWageWithCosts,
  ),
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersList,
};
